import React from 'react'
import { formik } from 'formik'
import { Formik, Field, ErrorMessage } from 'formik'
import { FormFeedback, FormControl } from '@/components/_common/Inputs'
import { Row, Col, Form, Button, Spinner } from 'react-bootstrap'
import SubmitBtn from '../../components/SubmitBtn'
import * as Yup from 'yup'
import Counter from '../../components/Counter'
import { withContext } from './MobileContext'
import withToken from '@/components/_common/withToken'
import { MobileAuthError } from '../../components/ErrorMessage'

const moibleInitialValues = { member: { mobile_phone: '' } }

const schema = Yup.object().shape({
  member: Yup.object().shape({
    mobile_phone: Yup.string()
      .required(MobileAuthError.mobile_phone.required)
      .matches(/^09[0-9]{8}/, MobileAuthError.mobile_phone.matches),
  }),
})

function MobileForm({ onSubmit, isLoading, isOtpGot, context }) {
  const formEl = React.useRef(null)
  if (context.mobileSetting.mobile_phone) {
    moibleInitialValues.member.mobile_phone = context.mobileSetting.mobile_phone
  }
  return (
    <Formik
      initialValues={withToken(moibleInitialValues)}
      onSubmit={context.mobileSubmitHandler}
      validationSchema={schema}
    >
      {({
        values,
        handleSubmit,
        handleChange,
        errors,
        touched,
        handleBlur,
      }) => {
        return (
          <Form onSubmit={handleSubmit} ref={formEl}>
            <Form.Group as={Row}>
              <Form.Label column xs={12} md={3}>
                手機號碼
              </Form.Label>
              <Form.Group as={Col} xs={12} md={9}>
                <Field
                  name="member[mobile_phone]"
                  id="member[mobile_phone]"
                  type="text"
                  placeholder="0900 000 000"
                  component={Form.Control}
                  onBlur={handleBlur}
                  value={values.member.mobile_phone}
                  onChange={handleChange}
                />
                {context.mobileSetting.isSetting && (
                  <span>
                    系統已發送OTP驗證碼至您輸入的手機，請將驗證碼輸入下方欄位。若
                    <Counter
                      time={180}
                      ref={context.counter}
                      className="text-primary"
                    />
                    秒後未收到，請按
                    <a
                      href="#"
                      onClick={() =>
                        formEl.current.dispatchEvent(new Event('submit'))
                      }
                    >
                      重新發送驗證碼
                    </a>
                    。
                  </span>
                )}
                <ErrorMessage
                  name="member[mobile_phone]"
                  component={FormFeedback}
                />
                {/* {this.state.isOtpGot && this.renderCounterText()} */}
              </Form.Group>
            </Form.Group>

            {!context.mobileSetting.isSetting && (
              <SubmitBtn
                isLoading={context.isLoading}
                disabled={context.isBtnDisabled}
              >
                發送驗證碼
              </SubmitBtn>
            )}
          </Form>
        )
      }}
    </Formik>
  )
}

export default withContext(MobileForm)
