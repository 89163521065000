import React from 'react'
import { Form } from 'react-bootstrap'
import styled from 'styled-components'

export const InputContainer = styled.div`
  padding: 5px 0 5px 10px;
  display: block;
  position: relative;
  height: 75px;
  margin-bottom: 1rem;
  border-radius: 5px;
  cursor: pointer;
  :hover {
    background-color: #dee2e6 !important;
  }
`
export const FormLabel = styled(Form.Label)`
  position: absolute;
  left: 10px;
  top: 12px;
  margin: 0;
  font-weight: 500;
`
const FormControl = styled(Form.Control)`
  background-color: transparent;
  border: none;
  color: #356a64;
  display: block;
  width: 100%;
  height: 100%;
  padding: 30px 30px 8px 9px;
  position: absolute;
  top: 0;
  left: 0;
  font-weight: 300;
  -webkit-appearance: none;
  appearance: none;
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='20'><polygon fill='%23333' points='10,13 4,6 16,6' /></svg>")
    no-repeat scroll right center transparent;
`

class SearchOption extends React.PureComponent {
  renderOption(options) {
    return (
      <>
        {options.map((c) => {
          return (
            <option key={c[1]} value={c[1]}>
              {c[0]}
            </option>
          )
        })}
      </>
    )
  }

  render() {
    return (
      <InputContainer className="bg-gray-200">
        <FormLabel className="text-primary-dark">
          {this.props.optionName}
        </FormLabel>
        <FormControl
          name={this.props.name}
          as="select"
          value={this.props.value}
          onChange={this.props.onChange}
          className="text-primary-dark"
        >
          {this.props.nullOption ? (
            <option value="">{this.props.nullOption}</option>
          ) : (
            ''
          )}
          {this.renderOption(this.props.options)}
        </FormControl>
      </InputContainer>
    )
  }
}

export default SearchOption
