import React, { Component } from 'react'
import { MemberCard as Card, Flex, NavItem, Title } from '../common/index'
import { SvgIcon } from '@/components/_common/SvgIcon'
import styled from 'styled-components'
import { Nav, Container, Tab } from 'react-bootstrap'
import Info from './Info'
import Password from './Password'


function Edit() {
  return (
    <Card>
      <Title>
        <SvgIcon width={30} href="#i-profile" />
        <h3>資料修改</h3>
      </Title>
      <Tab.Container defaultActiveKey="edit">
        <Container>
          <Nav justify variant="tabs" style={{ marginBottom: 0 }}>
            <NavItem>
              <Nav.Link eventKey="edit">個人資料</Nav.Link>
            </NavItem>
            <NavItem>
              <Nav.Link eventKey="change_password">修改密碼</Nav.Link>
            </NavItem>
          </Nav>
        </Container>
        <Tab.Content>
          <Tab.Pane eventKey="edit" className="border-0">
            <Info />
          </Tab.Pane>
          <Tab.Pane eventKey="change_password" className="border-0">
            <Password />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </Card>
  )
}

export default Edit
