import React from 'react'

function Footer() {
  return (
    <>
      <h4 className="m-0 mt-3">一年期</h4>
    </>
  )
}

export default Footer;

