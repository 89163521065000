export default () => {
    var pix = 0
    var banner = $('#parallax-banner')



    if (banner.length == 0) {


        banner = $('.parallax-banner')
    }

    banner.each(function () {

        var pix = 0

        if ($(this).data('pix')) {
            pix = $(this).data('pix')
            //初始位置補償
        }

        var scrollPos = 0;
        scrollPos = $(window).scrollTop() + pix - $(this).offset().top;
        //滾動到哪了 加上補償




        $(this).css({
            'background-size': "cover",
            'background-position': 'center ' // + (-scrollPos / speed) + "px"
            //控制背景位置
        });


    })

    //設定好背景  
    banner.each(function () {

        if ($(this).data('image-src') != undefined) {
            $(this).css({
                'background-image': 'url(' + $(this).data('image-src') + ')'
            });
        };

        if ($(this).data('max-height')) {
            $(this).css({
                'max-height': $(this).data('max-height'),
                'height': $(this).data('height')
            });
        } else if ($(this).data('height')) {
            $(this).css({
                'min-height': $(this).data('height')
            });
        }



        //寬高
        $(this).css({
            'background-size': "cover",
            'background-position': 'center'
        });
        //上下調整補償


    })




    //$(window).scroll(function () {

    //    scrollBanner(banner);
    //});

};