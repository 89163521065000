import React, { Component } from 'react'
import { Flex } from './index'
import styled from 'styled-components'
import { Button } from 'react-bootstrap'
import ReactTooltip from 'react-tooltip'
import { withTheme } from 'styled-components'
import { Link } from 'react-router-dom'
import { Scrollbars } from 'react-custom-scrollbars'
import moment from 'moment'

const Plan = styled.div`
  min-width: 120px;
  width: 120px;
  height: 100px;
  border-radius: ${({ theme }) => theme.borderRadius};
  background: ${({ theme }) => theme.color.primaryLight};
  text-align: center;
  padding: ${({ theme }) => theme.spacers[2]};
  margin-right: ${({ theme }) => theme.spacers[3]};
  display: flex;
  flex-direction: column;
  align-items: center;

  h6 {
    font-weight: ${({ theme }) => theme.fontWeight.normal};
    margin-bottom: 0;
    color: ${({ theme }) => theme.grayColor.gray600};
  }
  .small {
    margin-bottom: 0;
    color: ${({ theme }) => theme.grayColor.gray600};
  }
  .content {
    text-align: center;
    display: flex;
    max-width: 100%;
    align-items: center;
    justify-items: center;
    flex: auto;
  }
  p {
    max-width: 100%;
    margin-bottom: 0;
    color: ${({ theme }) => theme.grayColor.gray600};
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`
export const PetPlan = ({ policy }) => {
  return (
    <Plans autoHeight autoHide autoHeightMin="105px">
      {' '}
      <Flex>
        <Plan>
          <h6>保單期間</h6>
          <p>
            自{policy.insured_from && moment(policy.insured_from).format("YYYY/MM/DD")}
            <br />至{moment(policy.insured_from).add(1, "year").format("YYYY/MM/DD")}
          </p>
        </Plan>
        <Plan>
          <h6>寵物</h6>
          <div className="content">
            <p>{policy.information?.pet_name}</p>
          </div>
        </Plan>
        {policy.vaccine_policy_renew_insurance == "是" && (
          <Plan>
            <h6>自動續約</h6>
            <div className="content">
              <p>是</p>
            </div>
          </Plan>
        )}
        <Plan>
          <h6>保費</h6>
          <div className="content">
            <p>$ {policy.premium}</p>
          </div>
        </Plan>
      </Flex>
    </Plans>
  )
}

const Plans = styled(Scrollbars)`
  width: 100%;
  display: flex;
  & > div {
    overflow-y: hidden !important;
  }
`
export const TravelPlan = ({ policy }) => {
  console.log(policy)
  return (
    <Plans autoHeight autoHide autoHeightMin="115px">
      {' '}
      <Flex>
        <Plan>
          <h6>保單期間</h6>
          <p>
            自{policy.insured_from && moment(policy.insured_from).format("YYYY/MM/DD")}
            <br />至{policy.insured_till && moment(policy.insured_till).format("YYYY/MM/DD")}
          </p>
        </Plan>
        <Plan>
          <h6>國家</h6>
          <div className="content">
            <p>{policy.countries_name || "國內"}</p>
          </div>
        </Plan>
        <Plan>
          <h6>天數</h6>
          <div className="content">
            <p>{policy.duration}天</p>
          </div>
        </Plan>
        <Plan>
          <h6>保費</h6>
          <div className="content">
            <p>$ {policy.premium}</p>
          </div>
        </Plan>
      </Flex>
    </Plans>
  )
}

export const AccidentPlan = ({ policy }) => {
  console.log(policy)
  return (
    <Plans autoHeight autoHide autoHeightMin="115px">
      {' '}
      <Flex>
        <Plan>
          <h6>保單期間</h6>
          <p>
            自{policy.accident_policy_insured_from}
            <br />至{policy.accident_policy_insured_till}
          </p>
        </Plan>
        <Plan>
          <h6>職業</h6>
          <div className="content">
            <p>{policy.occupation}</p>
          </div>
        </Plan>
        <Plan>
          <h6>保額</h6>
          <div className="content">
            <p>{policy.main_coverage} 萬</p>
          </div>
        </Plan>
        <Plan>
          <h6>保費</h6>
          <div className="content">
            <p>$ {policy.premium}</p>
          </div>
        </Plan>
      </Flex>
    </Plans>
  )
}

export const CarPlan = ({ policy }) => {
  return (
    <Plans autoHeight autoHide autoHeightMin="105px">
      {' '}
      <Flex>
        <Plan>
          <h6>強制險期間</h6>
          {policy.insured_from ? (
            <p>
              自{policy.insured_from && moment(policy.insured_from).format("YYYY/MM/DD")}
              <br />至{moment(policy.insured_from).add(1, "year").format("YYYY/MM/DD")}
            </p>
          ) : (
            <div className="content">
              <p>本保單不包含強制險</p>
            </div>
          )}
        </Plan>
        <Plan>
          <h6>任意險期間</h6>
          {policy.insured_from2 ? (
            <p>
              自{policy.insured_from2 && moment(policy.insured_from2).format("YYYY/MM/DD")}
              <br />至{moment(policy.insured_from2).add(1, "year").format("YYYY/MM/DD")}
            </p>
          ) : (
            <div className="content">
              <p>本保單不包含任意險</p>
            </div>
          )}
        </Plan>
        <Plan>
          <h6>車種</h6>
          <div className="content">
            <p>{policy.information?.car_name}</p>
          </div>
        </Plan>
        <Plan>
          <h6>車牌</h6>
          <div className="content">
            <p>{`${policy.information?.license_plate_first}-${policy.information?.license_plate_last}`}</p>
          </div>
        </Plan>
        <Plan>
          <h6>保費</h6>
          <div className="content">
            <p>$ {policy.premium}</p>
          </div>
        </Plan>
      </Flex>
    </Plans>
  )
}

export const VaccinePlan = ({ policy }) => {
  return (
    <Plans autoHeight autoHide autoHeightMin="105px">
      {' '}
      <Flex>
        <Plan>
          <h6>投保生效日</h6>
          <div className="content">
            <p>{policy.insured_from && moment(policy.insured_from).format("YYYY/MM/DD")}</p>
          </div>
        </Plan>
        <Plan>
          <h6>保單到期日</h6>
          <div className="content">
            <p>{policy.insured_from && moment(policy.insured_from).add(1, "years").format("YYYY/MM/DD")}</p>
          </div>
        </Plan>
        <Plan>
          <h6>自動續約</h6>
          <div className="content">
            <p>{policy.kyc.renew_insurance ? "是" : "否"}</p>
          </div>
        </Plan>
        <Plan>
          <h6>保費</h6>
          <div className="content">
            <p>$ {policy.premium}</p>
          </div>
        </Plan>
      </Flex>
    </Plans>
  )
}

// renderPetPlan() {
//   return (
//     <Flex>
//       <Plan>
//         <h6>保單期間</h6>
//         <p>
//           自2020/05/05
//               <br />
//               至2020/05/06
//             </p>
//       </Plan>
//       <Plan>
//         <h6>保單期間</h6>
//         <p>
//           自2020/05/05
//               <br />
//               至2020/05/06
//             </p>
//       </Plan>
//     </Flex>
//   )
// }
// renderCarPlan() {
//   let { policy } = this.props
//   return (

//   )
// }
// renderTravelPlan() {
//   let { policy } = this.props
//   return (

//   )
// }
