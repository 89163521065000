import React, { Component } from 'react'
import { MemberCard as Card, Flex } from './index'
import styled from 'styled-components'
import { SvgIcon } from '@/components/_common/SvgIcon'
import { NavLink } from 'react-router-dom'

const Button = styled(NavLink)`
  padding: 5px;
  font-weight: ${({ active, theme }) =>
    active ? theme.fontWeight.normal : theme.fontWeight.lighter};
  color: ${({ active, theme }) =>
    active ? theme.fontWeight.primary : theme.color.primaryDark};
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
  p {
    margin-bottom: 0;
  }
  svg {
    margin-right: 7px;
    margin-left: 7px;
    fill: ${({ active, theme }) =>
      active ? theme.color.primary : theme.color.primaryDark};
    transition: all 0.3s ease;
  }
  &.actived {
    font-weight: ${({ theme }) => theme.fontWeight.normal};
    color: ${({ theme }) => theme.color.primary};
    svg {
      fill: ${({ theme }) => theme.color.primary};
    }
  }
  &:hover {
    color: ${({ theme }) => theme.color.primary};
    svg {
      fill: ${({ theme }) => theme.color.primary};
    }
  }
`

const ButtonWithoutReactRoute = styled.a`
  padding: 5px;
  font-weight: ${({ active, theme }) =>
    active ? theme.fontWeight.normal : theme.fontWeight.lighter};
  color: ${({ active, theme }) =>
    active ? theme.fontWeight.primary : theme.color.primaryDark};
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
  p {
    margin-bottom: 0;
  }
  svg {
    margin-right: 7px;
    margin-left: 7px;
    fill: ${({ active, theme }) =>
      active ? theme.color.primary : theme.color.primaryDark};
    transition: all 0.3s ease;
  }
  &.actived {
    font-weight: ${({ theme }) => theme.fontWeight.normal};
    color: ${({ theme }) => theme.color.primary};
    svg {
      fill: ${({ theme }) => theme.color.primary};
    }
  }
  &:hover {
    color: ${({ theme }) => theme.color.primary};
    svg {
      fill: ${({ theme }) => theme.color.primary};
    }
  }
`

const ListGroup = styled.div`
  border-width: 0 0 0.5px 0;
  border-style: solid;
  border-color: ${({ theme }) => theme.grayColor.gray300};
  padding-bottom: ${({ theme }) => theme.spacers[2]};
  margin-bottom: ${({ theme }) => theme.spacers[2]};
  &:last-of-type {
    padding-bottom: 0;
    margin-bottom: 0;
    border-width: 0;
  }
`

export class MemberList extends Component {
  render() {
    return (
      <div>
        <Card>
          <ListGroup>
            <Button to="/member/notifications" activeClassName="actived">
              <SvgIcon width={25} href="#i-message" />
              <p>個人訊息</p>
            </Button>
            <Button to="/member/shares" activeClassName="actived">
              <SvgIcon width={25} href="#i-member-share" />
              <p>推薦好友</p>
            </Button>
          </ListGroup>

          <ListGroup>
            <ButtonWithoutReactRoute href="/sarapoint">
              <SvgIcon width={25} href="#i-saracoin" />
              <p>累積莎拉幣</p>
            </ButtonWithoutReactRoute>
            <ButtonWithoutReactRoute href="/lottery/chance_fate">
              <SvgIcon width={25} href="#i-lottery" />
              <p>翻轉莎幣2選1</p>
              <span className="small text-secondary ml-1">HOT!</span>
            </ButtonWithoutReactRoute>
            <Button to="/member/bonus" activeClassName="actived">
              <SvgIcon width={25} href="#i-exchange" />
              <p>莎拉領好康</p>
            </Button>
          </ListGroup>

          {/* <ListGroup>
            <Button to="###" activeClassName="actived">
              <SvgIcon width={25} href="#i-for-beginner" />
              <p>新手投保須知</p>
            </Button>
            <Button to="###" activeClassName="actived">
              <SvgIcon width={25} href="#i-infographics" />
              <p>保險懶人包</p>
            </Button>
            <Button to="###" activeClassName="actived">
              <SvgIcon width={25} href="#i-message" />
              <p>人生規劃</p>
            </Button>
            <Button to="###" activeClassName="actived">
              <SvgIcon width={25} href="#i-life-plan" />
              <p>小額儲蓄</p>
            </Button>
          </ListGroup> */}
        </Card>
      </div>
    )
  }
}

export default MemberList
