import yup from '../yup';
import carSchema from './carSchema';
import motorSchema from './motorSchema';
import petSchema from './petSchema';
import travelSchema from './travelSchema';
import vaccineSchema from './vaccineSchema';

const nillSchema = yup.object().shape({});
const getInformationSchema = (type) => {
  switch (type) {
    case 'travel':
      return travelSchema;
    case 'vaccine':
      return vaccineSchema;
    case 'pet':
      return petSchema;
    case 'car':
      return carSchema;
    case 'motor':
      return motorSchema;
    default:
      return nillSchema;
  }
};

export default getInformationSchema;
