import React from 'react'

const AwardChanceCard = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 257.51648 349.41199"
    className={props.className}
  >
    <g id="other">
      <path
        id="Path_5817"
        data-name="Path 5817"
        d="M252.93329,0H4.58319a4.62405,4.62405,0,0,0-4.583,4.66467V344.747a4.62493,4.62493,0,0,0,4.583,4.665h248.3501a4.62493,4.62493,0,0,0,4.583-4.665V4.66492A4.624,4.624,0,0,0,252.93353,0Z"
        style={{ fill: '#fff' }}
      />
      <path
        d="M126.40966,283.45894q-.35659-.8216-.74408-1.64331a84.12246,84.12246,0,0,0-3.75165-14.92346,9.6279,9.6279,0,0,0,2.42877,2.00488c6.43854,3.40027,1.49854-15.07861-15.2337-26.01282,0,0,.17578.43409.47546,1.17811-14.66528-19.23328-37.40216-31.60413-62.96032-31.60413a77.68716,77.68716,0,0,0-26.96387,4.816c-.2699.02234-.534.05347-.80109.0802V329.16793h101.8136c.26288-1.34082.48053-2.72022.63147-4.14185a84.63628,84.63628,0,0,0,3.96851-14.25171c.04138.07227.07226.15479.1137.22705C129.076,316.86141,133.73705,300.42891,126.40966,283.45894Z"
        style={{ fill: '#f7a21b' }}
      />
      <path
        d="M114.89648,278.16553a15.46633,15.46633,0,0,0,2.07151-.91429q.2413-.12259.48-.25027c.06312-.03374.215-.08158.25487-.138-.07426.10508-.35772.20261-.18162.09968.06686-.03908.1352-.076.20249-.1143q.60779-.34632,1.19852-.72146a32.32331,32.32331,0,0,0,2.787-1.98471q.7644-.60689,1.49313-1.25691.37557-.33484.74134-.68047c.06241-.059.12414-.11867.18659-.1776.176-.16606-.20329.201-.02974.0295.14137-.13974.28271-.27946.42232-.421a37.006,37.006,0,0,0,6.00364-8.04914,43.15984,43.15984,0,0,0,2.59413-5.70567,55.7994,55.7994,0,0,0,3.18014-17.12835,1.624,1.624,0,0,1,1.83361-1.358,1.6514,1.6514,0,0,1,1.35795,1.83359c-.40319,9.70731-2.81133,19.48678-8.411,27.54754a36.00942,36.00942,0,0,1-12.34473,11.16838,17.20532,17.20532,0,0,1-2.67772,1.23247,1.64891,1.64891,0,0,1-2.08675-.92421,1.63037,1.63037,0,0,1,.92427-2.08678Z"
        style={{ fill: '#4d3b21' }}
      />
      <path
        d="M145.64213,233.86766l-6.1,6.76663-.86909.96407a.99441.99441,0,0,1-1.75168-.75334l.833-8.58132.12075-1.244c.12287-1.26582,2.07279-.97023,1.9504.29063l-.833,8.58133-.12076,1.244-1.75167-.75334,6.1-6.76662.86909-.96408a1.0107,1.0107,0,0,1,1.38452-.16847.99555.99555,0,0,1,.16843,1.38452Z"
        style={{ fill: '#4d3b21' }}
      />
      <path
        d="M143.105,230.92094l-3.76174,9.14449-.54007,1.31288a.99422.99422,0,0,1-1.69894.19885l-4.02288-5.65579c-.7347-1.03291.84755-2.21,1.58468-1.17364l4.02288,5.65578-1.69893.19885,3.76174-9.14448.54008-1.31288a.98669.98669,0,0,1,1.81318.77594Z"
        style={{ fill: '#4d3b21' }}
      />
      <path
        d="M137.5414,240.07031l6.77653-3.1819.96548-.45334a.98621.98621,0,1,1,.7104,1.84l-6.77652,3.1819-.96549.45334a.98621.98621,0,1,1-.7104-1.84Z"
        style={{ fill: '#4d3b21' }}
      />
      <path
        d="M38.16806,218.96108c10.74875-20.8039,4.16082-37.79387,5.54764-40.91439,1.387-3.1207,12.82916,13.52257,1.7337,34.67317,7.97493-4.50752,9.70846-7.628,12.82916-10.74856,3.12052-3.12071,6.58793,12.829-15.603,16.64308C41.63529,221.3882,38.16806,218.96108,38.16806,218.96108Z"
        style={{ fill: '#f7a21b' }}
      />
      <path
        d="M65.583,252.94968A15.59072,15.59072,0,1,1,47.79292,239.923,15.59064,15.59064,0,0,1,65.583,252.94968Z"
        style={{ fill: '#eeeae8' }}
      />
      <path
        d="M61.18092,253.63019a11.13678,11.13678,0,1,1-12.70743-9.30468A11.13443,11.13443,0,0,1,61.18092,253.63019Z"
        style={{ fill: '#4d3b21' }}
      />
      <path
        d="M114.37362,245.40718a15.59076,15.59076,0,1,1-17.78929-13.02677A15.59049,15.59049,0,0,1,114.37362,245.40718Z"
        style={{ fill: '#eeeae8' }}
      />
      <path
        d="M109.97151,246.0877a11.13636,11.13636,0,1,1-12.7066-9.30481A11.13371,11.13371,0,0,1,109.97151,246.0877Z"
        style={{ fill: '#4d3b21' }}
      />
      <path
        d="M46.801,272.46425a85.81578,85.81578,0,0,0,10.47261-1.8657c3.47467-.764,7.05-1.59339,10.64413-2.03018a15.591,15.591,0,0,0-30.63458,2.74707A19.20157,19.20157,0,0,0,46.801,272.46425Z"
        style={{ fill: '#f7a21b' }}
      />
      <path
        d="M46.801,272.46425a85.81578,85.81578,0,0,0,10.47261-1.8657c2.04181-.44906,4.1201-.92,6.21547-1.322A11.13622,11.13622,0,0,0,41.692,272.46288,25.22081,25.22081,0,0,0,46.801,272.46425Z"
        style={{ fill: '#f7a21b' }}
      />
      <path
        d="M103.84421,265.07577c4.35817-1.08195,8.66576-2.33844,13.047-3.25348a15.58782,15.58782,0,0,0-30.81181,4.335C91.765,267.75237,98.00127,266.52437,103.84421,265.07577Z"
        style={{ fill: '#f7a21b' }}
      />
      <path
        d="M103.84421,265.07577c2.91212-.72323,5.80129-1.52457,8.70395-2.25a11.13518,11.13518,0,0,0-21.93464,3.82816c.01165.07531.02852.149.041.22416C95.01245,267.09158,99.53564,266.14382,103.84421,265.07577Z"
        style={{ fill: '#f7a21b' }}
      />
      <circle
        cx="53.44528"
        cy="249.75443"
        r="1.4848"
        style={{ fill: '#eeeae8' }}
      />
      <path
        d="M104.07,241.9284a1.48475,1.48475,0,1,1-1.695-1.24041A1.485,1.485,0,0,1,104.07,241.9284Z"
        style={{ fill: '#eeeae8' }}
      />
      <path
        d="M80.294,275.647a7.95989,7.95989,0,0,1-7.74309-6.21417A2.78928,2.78928,0,1,1,77.994,268.211a2.37308,2.37308,0,1,0,4.63078-1.04019,2.78928,2.78928,0,0,1,5.44311-1.22181,7.96365,7.96365,0,0,1-7.77391,9.698Z"
        style={{ fill: '#4d3b21' }}
      />
      <path
        d="M38.258,286.12618c.04113.00976.08258.01831.12371.02807.19068.04468.08692-.02392-.04266-.04687a.37752.37752,0,0,0-.1416.00464Z"
        style={{ fill: 'none' }}
      />
      <polygon
        points="38.197 286.112 38.196 286.112 38.187 286.112 38.197 286.112"
        style={{ fill: 'none' }}
      />
      <path
        d="M31.36864,283.5981l-.0014-.00073-.00366-.00171C31.0833,283.45858,31.66875,283.7585,31.36864,283.5981Z"
        style={{ fill: '#1c4151' }}
      />
      <path
        d="M39.78637,286.38082c-.24573-.022-.49054-.06177-.73371-.10254-.149-.0249-.49163-.14856-.71362-.1709.12958.023.23334.09155.04266.04687-.04113-.00976-.08258-.01831-.12371-.02807l-.06055-.01416-.0108.00036.00927-.00073c-.06537-.01526-.13086-.03039-.19616-.046q-.30918-.07434-.61646-.15625a33.11163,33.11163,0,0,1-6.01605-2.312l.0014.00073c.30011.1604-.28534-.13952-.00506-.00244l.00366.00171c-.06012-.03223-.12109-.063-.1814-.09473q-.23163-.12158-.4613-.24695-.42563-.232-.84473-.476-.98565-.5733-1.93292-1.20947a36.62266,36.62266,0,0,1-3.868-2.99121c.03937.03613.04309.04053-.03894-.03235.01263.01123.0263.02112.03894.03235-.08191-.07544-.3327-.30286-.38916-.35523q-.22962-.21313-.45581-.42968-.48854-.46839-.96063-.95313-1.01578-1.043-1.95466-2.15722c-.51214-.60718-.99371-1.22364-1.45947-1.84241v5.011a38.95029,38.95029,0,0,0,12.13129,9.1305,26.01343,26.01343,0,0,0,8.5285,2.6145A1.61479,1.61479,0,0,0,39.78637,286.38082Z"
        style={{ fill: '#4d3b21' }}
      />
      <path
        d="M38.339,286.10738a.29652.29652,0,0,0-.14313.00427l.00153.00037A.37752.37752,0,0,1,38.339,286.10738Z"
        style={{ fill: '#1c4151' }}
      />
      <path
        d="M24.07891,278.57906c-.01264-.01123-.02631-.02112-.03894-.03235C24.122,278.61959,24.11828,278.61519,24.07891,278.57906Z"
        style={{ fill: '#1c4151' }}
      />
      <rect
        id="Rectangle_2325-2-2"
        data-name="Rectangle 2325-2-2"
        x="18.85918"
        y="20.24293"
        width="219.79601"
        height="308.92499"
        rx="5"
        style={{
          fill: 'none',
          stroke: '#ff762a',
          strokeMiterlimit: '10',
          strokeWidth: '3.56114363670349px',
        }}
      />
      <path
        d="M111.295,194.95015a2.49856,2.49856,0,0,1-2.06933-1.09765,6.67288,6.67288,0,0,0-1.1211-1.1211,2.49986,2.49986,0,0,1,.03614-4.08984,7.23246,7.23246,0,0,0,.749-.5918q-.41016-.3955-.78613-.76269a2.49837,2.49837,0,0,1-.67871-2.39551c.10937-.4375.21191-.88477.30761-1.334a2.50115,2.50115,0,0,1-.42285-1.17187l-.2207-2.3291a2.50076,2.50076,0,0,1,1.50293-2.5332c.03711-.458.0664-.91211.08691-1.35352a2.4984,2.4984,0,0,1,2.61817-2.38281l2.44726.11914a2.499,2.499,0,0,1,1.75684.84765,2.43559,2.43559,0,0,1,.27051.3711,2.48882,2.48882,0,0,1,1.43945-.45606h5.96582l.32812-.06738a2.49621,2.49621,0,0,1,1.96778.4209l1.64844,1.18945a2.50025,2.50025,0,0,1,.626,3.40137q-.438.665-.8789,1.27148a2.5009,2.5009,0,0,1,1.40918,2.25v2.34571a2.49982,2.49982,0,0,1-2.5,2.5h-.7129v1.835a4.53417,4.53417,0,0,1-2.47656,4.45605,10.13237,10.13237,0,0,1-4.23828.64453,2.50151,2.50151,0,0,1-2.0498-1.06836,2.93924,2.93924,0,0,1-.64942.082,2.50255,2.50255,0,0,1-1.55957-.55665,14.44724,14.44724,0,0,1-1.31445,1.09082A2.49867,2.49867,0,0,1,111.295,194.95015Z"
        style={{ fill: '#ff762a' }}
      />
      <path
        d="M138.86138,194.88179h-2.41406a2.49981,2.49981,0,0,1-2.5-2.5v-1.59668h-.457v1.59668a2.49981,2.49981,0,0,1-2.5,2.5h-2.24317a2.49981,2.49981,0,0,1-2.5-2.5v-2.291c-.03418-.01367-.06836-.02735-.10254-.042a2.503,2.503,0,0,1-1.46289-1.88184,10.08124,10.08124,0,0,0-.54687-1.80371,2.4818,2.4818,0,0,1,.918-3.335,2.49325,2.49325,0,0,1-.72657-1.7627v-2.21a2.49971,2.49971,0,0,1,1.48926-2.28711c.01074-.05273.02246-.10547.03613-.1582a2.4918,2.4918,0,0,1,1.25586-1.58594l1.9375-1.01953a2.49963,2.49963,0,0,1,3.17188.72168c.25976.35156.5918.83105.90722,1.33594h.82911a2.50028,2.50028,0,0,1,2.49414-2.3291h2.41406a2.50029,2.50029,0,0,1,2.49414,2.3291h.87109a2.49981,2.49981,0,0,1,2.5,2.5v9.53613a2.49981,2.49981,0,0,1-2.5,2.5h-.86523v1.7832A2.49981,2.49981,0,0,1,138.86138,194.88179Z"
        style={{ fill: '#ff762a' }}
      />
      <path
        d="M153.01568,194.916a2.50155,2.50155,0,0,1-2.38575-1.751h-.88574a2.496,2.496,0,0,1-2.01562,1.0205h-2.34571a2.49981,2.49981,0,0,1-2.5-2.5v-4.501a2.501,2.501,0,0,1-1.47851-2.28223v-2.19238a8.31326,8.31326,0,0,0,.88476-2.56738l.00977-.02247a2.41078,2.41078,0,0,1-.02734-.36816v-2.1416a2.49981,2.49981,0,0,1,2.5-2.5H148.087a2.49936,2.49936,0,0,1,2.24218-1.39356H152.879a2.501,2.501,0,0,1,2.24219,1.39356h3.43554a2.49981,2.49981,0,0,1,2.5,2.5v2.1416a2.49478,2.49478,0,0,1-.15527.87012,2.49973,2.49973,0,0,1,1.124,2.08789v2.19238a2.49963,2.49963,0,0,1-1.98828,2.44727v2.55078a4.36346,4.36346,0,0,1-2.67383,4.44726A11.64725,11.64725,0,0,1,153.01568,194.916Z"
        style={{ fill: '#ff762a' }}
      />
      <path
        d="M163.44732,194.916h-2.14258a2.49981,2.49981,0,0,1-2.5-2.5V176.9814a2.49981,2.49981,0,0,1,2.5-2.5l3.456-.01074a2.505,2.505,0,0,1,1.82032.24024l.12109.06738c.06445-.00488.12988-.00781.19531-.00781h9.876a2.49981,2.49981,0,0,1,2.5,2.5v2.27734a2.49822,2.49822,0,0,1-1.12207,2.08594v8.18164a4.42076,4.42076,0,0,1-2.751,4.54687,13.29578,13.29578,0,0,1-4.87207.51953,2.49986,2.49986,0,0,1-2.45215-2.16113,6.51376,6.51376,0,0,0-.44824-1.35742h-.4248a2.48789,2.48789,0,0,1-1.25586-.33789V192.416A2.49981,2.49981,0,0,1,163.44732,194.916Z"
        style={{ fill: '#ff762a' }}
      />
      <path
        d="M183.99224,188.66011a5.24161,5.24161,0,0,1-3.02832-.98828,2.50114,2.50114,0,0,1-3.207.12793l-1.56348-1.20605a2.50051,2.50051,0,0,1-.56055-3.35449c1.72266-2.61622,3.71289-3.165,5.07911-3.165a5.28018,5.28018,0,0,1,3.04.99414,2.49989,2.49989,0,0,1,3.18165-.127l1.56445,1.19043a2.49859,2.49859,0,0,1,.57324,3.36524C187.34771,188.11129,185.35845,188.66011,183.99224,188.66011Z"
        style={{ fill: '#ff762a' }}
      />
      <path
        d="M116.293,181.72359a16.64617,16.64617,0,0,1-1.05469,5.57617A25.53782,25.53782,0,0,1,117.295,189.373l-1.63086,2.05665c-.4082-.52637-.98632-1.15528-1.63281-1.835a11.10877,11.10877,0,0,1-2.73633,2.85547,8.86684,8.86684,0,0,0-1.73437-1.751,9.56943,9.56943,0,0,0,2.7207-2.85547c-.85058-.81641-1.70019-1.63184-2.43164-2.34571.23828-.95214.49316-2.125.69727-3.40039l-.74805.05176-.2207-2.3291,1.292-.05078c.15332-1.19043.25488-2.37988.30566-3.48535l2.44727.11914c-.084,1.03711-.20313,2.1416-.33985,3.24707l.73145-.03418v-.96875h2.29492v.84961l.86621-.05078-.0166,2.21Zm-2.959,3.85938a13.90077,13.90077,0,0,0,.64648-3.70606l-1.03711.06836c-.16992.98535-.33984,1.9375-.52637,2.82129Zm12.44336-.10254h-3.2129v4.335c0,1.207-.23828,1.81836-1.0371,2.19238a8.04967,8.04967,0,0,1-3.17774.4082,9.27581,9.27581,0,0,0-.81641-2.3623c.76563.0332,1.80176.0332,2.07422.0332.28907,0,.39063-.06738.39063-.32226v-4.28418h-3.416v-2.34571h3.416v-1.41113a11.63427,11.63427,0,0,0,1.85351-2.32812H117.211V177.1689h6.2207l.57813-.11914,1.64843,1.18946a22.73378,22.73378,0,0,1-3.09375,3.84179v1.05371h3.2129Z"
        style={{ fill: '#fff' }}
      />
      <path
        d="M142.22661,178.56246v9.53613h-2.32812v-.56152h-1.03711v4.84472h-2.41406v-4.84472h-1.05371v.748h-2.22754v-2.68554l-1.291,1.90429c-.23828-.3916-.54492-.85058-.88476-1.32617v6.2041h-2.24317v-5.915a10.83607,10.83607,0,0,1-1.59863,1.292,11.527,11.527,0,0,0-.81445-2.584,10.57477,10.57477,0,0,0,3.77343-3.90918h-3.28125v-2.21h2.49805a19.3216,19.3216,0,0,0-1.05273-1.81933l1.9375-1.01954a15.67386,15.67386,0,0,1,1.34277,2.14161l-1.17285.69726h.98633l.39062-.085,1.207.93457a17.96976,17.96976,0,0,1-1.73437,3.53613c.57812.52637,1.54687,1.49512,1.9375,1.90332v-6.78222h3.28125v-2.3291h2.41406v2.3291Zm-6.833,2.17578v1.24121h1.05371v-1.24121Zm1.05371,4.62305v-1.24024h-1.05371v1.24024Zm2.41406-4.62305v1.24121h1.03711v-1.24121Zm1.03711,4.62305v-1.24024h-1.03711v1.24024Z"
        style={{ fill: '#fff' }}
      />
      <path
        d="M159.52544,184.9023h-1.98828v4.99805c0,1.15625-.28906,1.78515-1.13965,2.1416a9.663,9.663,0,0,1-3.38183.374,9.00239,9.00239,0,0,0-.61328-1.751h-4.67383v1.0205h-2.34571v-5.96679h7.6836v4.43652c.64551.0166,1.27441.0166,1.46094.0166.32324-.0166.44238-.085.44238-.32226V184.9023H143.90435v-2.19238H145.587a7.87985,7.87985,0,0,0-.98633-1.61524c2.56738-.25488,3.96093-.6455,4.72558-1.34277h-4.55468v-2.1416h5.47265c.05078-.42481.06836-.88379.085-1.39356H152.879c-.01758.49317-.05078.95215-.10156,1.39356h5.77929v2.1416h-4.52148c1.59765.52734,3.21289,1.12207,4.38476,1.58106l-1.46093,1.377h2.5664Zm-3.68945-2.19238c-1.25781-.52735-2.88867-1.15625-4.419-1.666a7.37073,7.37073,0,0,1-3.79,1.666Zm-5.11524,4.89551h-2.99218v1.17285h2.99218Z"
        style={{ fill: '#fff' }}
      />
      <path
        d="M176.77349,179.54781h-1.12207v10.26758c0,1.30859-.28809,1.88672-1.13867,2.21a11.62243,11.62243,0,0,1-3.96.35644,8.73866,8.73866,0,0,0-.71485-2.19238c1.13867.05078,2.56641.05078,2.958.03418.3916,0,.52637-.10254.52637-.44238v-10.2334h-6.42481v-1.44434c-.3916,1.39356-.86621,2.97461-1.27441,4.18164a5.75013,5.75013,0,0,1,1.08789,3.46778,2.77941,2.77941,0,0,1-.81641,2.39648,2.27574,2.27574,0,0,1-1.01953.4082,6.98891,6.98891,0,0,1-1.08789.05079,5.21715,5.21715,0,0,0-.33984-1.7334v5.541h-2.14258V176.9814h3.72266l.33984-.085,1.53027.84961v-.47558h9.876Zm-13.32617,6.96973a3.597,3.597,0,0,0,.42383,0,.73067.73067,0,0,0,.42578-.11914,1.22743,1.22743,0,0,0,.25488-.93458,4.73436,4.73436,0,0,0-.98535-2.92382c.2539-.95215.56054-2.27735.78125-3.36524h-.90039Zm5.83008,1.41113v.93457h-2.07422v-7.64942h5.11718v6.71485Zm0-4.6582V185.872h.90039v-2.60156Z"
        style={{ fill: '#fff' }}
      />
      <path
        d="M180.67779,184.69918c-.52735,0-1.00293.4248-1.39356,1.12109l-1.56348-1.20605c.98536-1.4961,2.00586-2.04,2.99122-2.04,1.59765,0,2.26074,1.44433,3.31445,1.44433.52734,0,1.00293-.40722,1.39355-1.08789l1.56446,1.19043c-.98633,1.4961-2.00586,2.03906-2.99219,2.03906C182.378,186.16011,181.73247,184.69918,180.67779,184.69918Z"
        style={{ fill: '#fff' }}
      />
      <rect
        x="40.98374"
        y="78.51481"
        width="10.5916"
        height="2.82342"
        transform="translate(-31.03032 29.39702) rotate(-26.75953)"
        style={{ fill: '#ee8caa' }}
      />
      <rect
        x="191.64252"
        y="60.77434"
        width="10.5916"
        height="2.82342"
        transform="translate(-6.90779 95.33075) rotate(-26.75953)"
        style={{ fill: '#ee8caa' }}
      />
      <polygon
        points="180.561 164.928 176.477 159.085 178.822 157.556 182.906 163.399 180.561 164.928"
        style={{ fill: '#ee8caa' }}
      />
      <polygon
        points="204.308 98.504 195.219 93.024 196.661 90.57 205.75 96.049 204.308 98.504"
        style={{ fill: '#4e9cda' }}
      />
      <polygon
        points="73.812 52.39 72.824 49.582 75.468 48.445 76.456 51.253 73.812 52.39"
        style={{ fill: '#4e9cda' }}
      />
      <polygon
        points="93.546 157.727 85.85 160.097 84.962 157.385 92.657 155.015 93.546 157.727"
        style={{ fill: '#4e9cda' }}
      />
      <rect
        x="129.0529"
        y="42.85765"
        width="5.57375"
        height="2.82295"
        transform="translate(-0.56843 86.80793) rotate(-36.37167)"
        style={{ fill: '#fc8848' }}
      />
      <polygon
        points="220.815 150.198 213.485 155.596 211.809 153.324 219.138 147.927 220.815 150.198"
        style={{ fill: '#fc8848' }}
      />
      <rect
        x="117.15471"
        y="121.19939"
        width="5.57402"
        height="2.82244"
        transform="translate(141.79705 292.73328) rotate(-142.951)"
        style={{ fill: '#fc8848' }}
      />
      <rect
        x="37.99621"
        y="155.43227"
        width="5.57402"
        height="2.82244"
        transform="translate(-21.16472 306.5953) rotate(-142.951)"
        style={{ fill: '#fc8848' }}
      />
      <rect
        x="54.94605"
        y="99.97395"
        width="8.79093"
        height="2.82314"
        transform="translate(39.91272 -14.47198) rotate(20.821)"
        style={{ fill: '#8f97de' }}
      />
      <rect
        x="175.28444"
        y="39.97583"
        width="8.79093"
        height="2.82314"
        transform="translate(26.44505 -61.16437) rotate(20.821)"
        style={{ fill: '#8f97de' }}
      />
      <rect
        x="128.46025"
        y="163.10539"
        width="8.79093"
        height="2.82314"
        transform="translate(67.15358 -36.47981) rotate(20.821)"
        style={{ fill: '#8f97de' }}
      />
      <polygon
        points="114.258 78.335 111.283 81.657 109.207 79.778 112.182 76.456 114.258 78.335"
        style={{ fill: '#8f97de' }}
      />
      <path
        d="M80.30587,65.77831A13.84156,13.84156,0,0,0,86.35372,69.25a14.85936,14.85936,0,0,0-2.54785,3.21973,12.96089,12.96089,0,0,1-1.792-.81152,29.198,29.198,0,0,1,2.21191,4.36816l-3.5,1.54a31.94307,31.94307,0,0,0-3.6958-7.084l2.1001-.83984a15.56768,15.56768,0,0,1-3.13623-3.86426H70.25411a17.787,17.787,0,0,1-4.144,4.64746l2.37988.98047a19.44573,19.44573,0,0,1-3.668,6.04785l-3.35986-1.98828a14.6787,14.6787,0,0,0,2.88379-3.80761,20.76632,20.76632,0,0,1-2.044,1.12011A21.39258,21.39258,0,0,0,59.446,69.64257a14.42914,14.42914,0,0,0,5.96386-3.86426h-4.956V62.0537H66.53V59.95409H62.10616V56.23046H66.53V53.51464h4.03174v2.71582h4.73193V53.51464H79.382v2.71582h4.53565v3.72363H79.382V62.0537h6.29981v3.72461ZM69.302,67.626h3.86377v8.51172c0,1.76465-.27978,2.80078-1.456,3.38867a9.91655,9.91655,0,0,1-4.45166.64355,14.27935,14.27935,0,0,0-1.12012-3.78027c1.00781.05664,2.2959.05664,2.688.05664.36426,0,.47607-.084.47607-.39258Zm1.25977-5.57227h4.73193V59.95409H70.56173Zm6.104,8.59668A31.27667,31.27667,0,0,1,78.094,76.69823l-3.47217.78418a31.527,31.527,0,0,0-1.23194-6.16015Z"
        style={{ fill: '#ff762a' }}
      />
      <path
        d="M113.99,69.30566v3.02441H88.00607V69.30566h6.15966a10.21763,10.21763,0,0,0-.55957-1.23145l1.2876-.30762H92.094V61.96972h18.25586v5.79687h-1.39991a18.27952,18.27952,0,0,1-1.03613,1.53907ZM98.842,57.82616H89.0422V54.998H98.842v-1.54h4.14405v1.54H113.01v2.82812H102.98605v.7002h8.48388V61.2705H90.91817V58.52636H98.842ZM91.50607,73.03027h19.4038v7.084h-4.20019v-.67187H95.51v.67187h-4.0039ZM95.51,75.6621v1.12012h11.19971V75.6621Zm.44776-10.30371h10.27636v-.95215H95.95773Zm1.73632,2.4082a10.3201,10.3201,0,0,1,.67188,1.53907h5.31982c.19629-.50391.41992-1.03516.58838-1.53907Z"
        style={{ fill: '#ff762a' }}
      />
      <path
        d="M125.07784,54.83007A49.862,49.862,0,0,1,122.53,60.458V80.08593h-3.86426V66.47851c-.44775.55957-.92382,1.09179-1.37158,1.5957A27.68383,27.68383,0,0,0,115.16573,63.79,28.64225,28.64225,0,0,0,121.24191,53.626Zm10.08008,7.252v13.6084c0,1.84766-.28027,2.96778-1.42822,3.63965a8.76424,8.76424,0,0,1-4.396.81152,14.67711,14.67711,0,0,0-1.37158-4.08789c1.0918.02832,2.26758.02832,2.65967.02832.36426,0,.5039-.084.5039-.44824V62.082h-2.688a24.151,24.151,0,0,1-2.604,4.00391,28.41306,28.41306,0,0,0-3.27588-2.6875c2.15625-2.26855,3.91993-6.04785,4.87207-9.7998l4.00391.92382a35.60869,35.60869,0,0,1-1.28809,3.80762H138.882l.61572-.1123,2.88428.50488c-.3081,2.40723-.812,5.123-1.2041,6.88769l-3.47217-.61621c.11231-.78418.252-1.82031.36426-2.91211ZM130.426,67.17773a28.8274,28.8274,0,0,1-3.668,9.32422,22.50888,22.50888,0,0,0-3.44385-2.1836,19.51748,19.51748,0,0,0,3.16358-7.95215Zm8.792-1.14746a34.94054,34.94054,0,0,1,2.82764,9.01562L138.12569,76.418a33.77854,33.77854,0,0,0-2.54785-9.15625Z"
        style={{ fill: '#ff762a' }}
      />
      <path
        d="M169.822,73.11425a12.83966,12.83966,0,0,1-4.11621,3.47168c1.56836.22461,3.24805.39258,4.98438.50391a14.79812,14.79812,0,0,0-2.26807,3.08007,37.98037,37.98037,0,0,1-7.78418-1.54,45.00918,45.00918,0,0,1-7.5039,1.45605,12.4457,12.4457,0,0,0-1.42774-2.77148,5.24544,5.24544,0,0,1-.44824.7832,3.15231,3.15231,0,0,1-1.76416,1.2041,11.00032,11.00032,0,0,1-3.80762.28027,8.26673,8.26673,0,0,0-1.064-3.86425,16.18907,16.18907,0,0,0,3.02392.05664,1.0206,1.0206,0,0,0,.812-.50391c.44776-.61621.67188-2.60449.728-5.18066a20.89949,20.89949,0,0,1-3.44433,3.55664,16.51721,16.51721,0,0,0-2.15577-3.38868,15.99749,15.99749,0,0,0,5.34815-5.79589,14.92877,14.92877,0,0,0-.5044-2.21192,26.57206,26.57206,0,0,1-2.7998,2.63184A17.71141,17.71141,0,0,0,143.446,61.2705a20.14766,20.14766,0,0,0,3.248-2.57617,15.64873,15.64873,0,0,0-2.49218-2.66016l2.688-2.15625a16.37352,16.37352,0,0,1,2.21241,2.18457,23.93187,23.93187,0,0,0,1.3999-1.96l2.688,1.84766v-.81153h3.63965V53.542H160.386v5.68457H157.1379l1.56836.55958c-.22412.33593-.47607.67187-.75635,1.03613h2.46387c-.30762-.3916-.64355-.78418-.92383-1.12012l2.82813-1.42773.33593.36328V53.542h3.752V55.0537h3.77978v2.85645H166.406v1.3164h-3.22022c.5044.55958.95215,1.11915,1.31641,1.59571h4.564v2.26758h-5.04v.58789h4.42383v1.93261h-4.42383v.64356h4.42383v1.93262h-4.42383v.61523h5.376v2.2959H157.67013v.4209H154.198v-6.4961l-.41992.3916c-.27979-.30761-.728-.81152-1.2041-1.28808a31.62964,31.62964,0,0,1,.28027,4.64844c0,3.08007-.19629,6.16015-1.0083,8.17578q2.09985-.126,4.03223-.41992a14.198,14.198,0,0,1-1.93213-1.40039l1.34424-.53223h-1.68018V71.7705h13.356l.61572-.1123ZM153.19015,56.2578c-.58789.92481-1.28809,1.9043-2.04395,2.88477a16.152,16.152,0,0,1,1.03565,2.88379,20.05244,20.05244,0,0,0,3.13623-3.44434l1.51172.53223V57.99413h-3.63965Zm7.252,6.832h-2.772v.58789h2.772Zm0,2.5205h-2.772v.64356h2.772Zm0,2.57618h-2.772v.61523h2.772Zm-1.98828,6.41113a13.41131,13.41131,0,0,0,2.2124.86816,15.95439,15.95439,0,0,0,2.32373-.86816Z"
        style={{ fill: '#ff762a' }}
      />
      <path
        d="M181.6379,61.18652a35.02495,35.02495,0,0,1-2.52,3.584V80.11425h-3.94775V69.11034a24.21652,24.21652,0,0,1-2.1001,1.81934,28.81124,28.81124,0,0,0-1.84814-4.25586,26.32831,26.32831,0,0,0,6.86035-7Zm-.56006-6.07618a31.39516,31.39516,0,0,1-7.67187,7.252,26.9673,26.9673,0,0,0-1.9878-3.47168,20.20225,20.20225,0,0,0,5.7959-5.34864ZM197.822,73.00195h-3.64013v3.21972c0,1.76465-.30762,2.7168-1.51172,3.27637a10.41136,10.41136,0,0,1-4.42432.58789,14.03081,14.03081,0,0,0-1.148-3.416l-2.15576,1.51172a23.62735,23.62735,0,0,0-3.10791-3.80761l2.04395-1.37207h-3.584v-3.416h9.772V68.46581H181.05V65.1621h16.15576v3.30371h-3.02392v1.12012H197.822Zm-1.28808-8.67969h-14.252V54.29784h14.252Zm-6.46778,8.67969h-4.84423A23.6421,23.6421,0,0,1,187.966,76.0537l-.61621.44825c.86816.02832,1.90429.02832,2.2124.02832.3916-.02832.5039-.11231.5039-.41993Zm2.352-15.90332h-6.2163v.8955h6.2163Zm0,3.5h-6.2163v.92382h6.2163Z"
        style={{ fill: '#ff762a' }}
      />
      <text
        y="120"
        x="128"
        fill="#ff762a"
        text-anchor="middle"
        style={{
          fontFamily: "'Noto Sans TC',Lato,'微軟正黑體'",
          fontSize: '26px',
          fontWeight: '700',
        }}
      >
        {props.line1}
      </text>
      <text
        y="155"
        x="128"
        fill="#ff762a"
        text-anchor="middle"
        style={{
          fontFamily: "'Noto Sans TC',Lato,'微軟正黑體'",
          fontSize: '26px',
          fontWeight: '700',
        }}
      >
        {props.line2}
      </text>
    </g>
  </svg>
)

export default AwardChanceCard
