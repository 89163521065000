import React, { Component } from 'react'
import { Row, Col, Form, Button } from 'react-bootstrap'
import styled from 'styled-components'
import SearchOptionContext from '@/components/_context/SearchOptionContext'
import TagManager from 'react-gtm-module'
import { gtmId } from '@/components/_common/index'

const Label = styled.div`
  border-radius: 5px;
  color: #adb5bd;
  padding: 4px 20px;
  border: 1px solid #adb5bd;
  position: relative;
  font-size: 14px;
  margin: 2px;
  display: inline-block;
  ${(props) => {
    if (props.active) {
      return `
        border-color: #62cae3;
        color:#62cae3;
        :after {
          content: "";
          display: block;
          position: absolute;
          right: 0px;
          bottom: 0px;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0 0 10px 10px;
          border-color: transparent transparent #62cae3 transparent
        }`
    }
  }}
`

const Amount = styled.div`
  border-left: 1px solid #e9ecef;
  text-align: center;
  padding-right: 10px;
  padding-left: 10px;
  :first-child {
    border: none;
    padding-left: 0;
  }
  @media (max-width: 991px) {
    justify-content: space-between;
    display: flex;
    border: none;
    padding-left: 10px !important;
  }
`

const PlanBox = styled.div`
  border-radius: 5px;
  border: 1px solid #ced4da;
  overflow: hidden;
  margin-bottom: 1rem;
`

const PlanLeft = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 15px 5px 20px 30px;
  @media (max-width: 991px) {
    padding: 15px !important;
  }
`
const PlanRight = styled.div`
  height: 100%;
  background: #ffffff;
  text-align: center;
  padding: 15px;
  display: flex;
  flex-direction: column;
`

const Badge = styled.span`
  display: inline-block;
  border-radius: 2px;
  padding: 0.25em 0.4em;
  font-size: 65%;
  font-weight: 300;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  color: white;
  background-color: ${(props) => props.color};
  margin-left: 5px;
`

const color = {
  pink: '#EC94AF',
  blue: '#5DA3DA',
  orange: '#F99157',
  purple: '#8F97DE',
}

export class plan extends Component {
  setTagManager(e) {
    e.preventDefault()
    let plan = this.props.plan

    let tagManagerArgs = {
      gtmId,
      dataLayer: {
        event: 'Product Click',
        tag: plan['package'].name,
        ecommerce: {
          click: {
            actionField: {
              list: plan.type,
            },
            products: [
              {
                name: plan['package'].name,
                id: plan['package'].id,
                brand: plan.insurance_company.name,
                category: plan.type,
                list: `search ${plan.type}`,
              },
            ],
          },
        },
      },
    }
    TagManager.initialize(tagManagerArgs)
    window.open(e.currentTarget.href, '_blank')
  }

  planBuyHandler(e) {
    e.preventDefault()
    let plan = this.props.plan

    let tagManagerArgs = {
      gtmId,
      dataLayer: {
        event: 'checkout',
        tag: plan.type,
        ecommerce: {
          click: {
            actionField: {
              list: plan.type,
            },
            products: [
              {
                name: plan['package'].name,
                id: plan['package'].id,
                brand: plan.insurance_company.name,
                category: plan.type,
                list: `search ${plan.type}`,
              },
            ],
          },
        },
      },
    }
    TagManager.initialize(tagManagerArgs)
    window.location = e.currentTarget.href
  }

  render() {
    let plan = this.props.plan
    let {
      id,
      package_id,
      full_plan_title,
      amount_coverages,
      available_coverages,
      cost,
      plan_search_id,
      buy_path,
      compare,
      type,
    } = this.props.plan
    return (
      <PlanBox key="package_id">
        <Row>
          <Col lg="9" sx="12" className="pr-lg-0">
            <PlanLeft>
              <h5>
                <SearchOptionContext.Consumer>
                  {({ searchForm }) => {
                    return searchForm.productLine == 'travel'
                      ? full_plan_title
                      : plan.insurance_company.name +
                          ' - ' +
                          plan['package'].name
                  }}
                </SearchOptionContext.Consumer>
                {this.props.plan['package'].for_sale ? (
                  <Badge className="bg-primary">獨家</Badge>
                ) : (
                  ''
                )}
                {this.props.plan['package'].hot ? (
                  <Badge className="bg-secondary">熱門</Badge>
                ) : (
                  ''
                )}
                {this.props.plan['package'].featured ? (
                  <Badge className="bg-success">精選</Badge>
                ) : (
                  ''
                )}
                {this.props.plan['package'].car_level_4 ? (
                  <Badge color={color.purple}>豪華車體款</Badge>
                ) : (
                  ''
                )}
                {this.props.plan['package'].car_level_3 ? (
                  <Badge color={color.orange}>豪華款</Badge>
                ) : (
                  ''
                )}
                {this.props.plan['package'].car_level_2 ? (
                  <Badge color={color.blue}>入門款</Badge>
                ) : (
                  ''
                )}
                {this.props.plan['package'].car_level_1 ? (
                  <Badge color={color.pink}>上路款</Badge>
                ) : (
                  ''
                )}
              </h5>
              <div className="mb-4 d-flex flex-wrap">
                {available_coverages.map((c) => (
                  <Label key={c.id} active={c.has_plan}>
                    {c.name}
                  </Label>
                ))}
              </div>
              <div className="d-flex mt-auto flex-lg-row flex-column ">
                {amount_coverages.map((a) => {
                  return (
                    <Amount key={a.id}>
                      <h6 className="m-0">{a.name}</h6>
                      <h6 className="m-0 text-primary">{a.amount}</h6>
                    </Amount>
                  )
                })}
              </div>
            </PlanLeft>
          </Col>
          <Col lg="3" sx="12" className="pl-lg-0">
            <PlanRight>
              <div className="mt-2 mb-auto d-flex d-lg-block justify-content-between align-items-baseline">
                <p className="mb-0 d-none d-lg-block">
                  {type == 'car' || type == 'motorcycle'
                    ? '預估保費'
                    : '方案保費'}
                </p>
                <p className="h1 text-secondary">$ {cost}</p>
                <Form.Group controlId="formBasicCheckbox">
                  <Form.Check
                    type="checkbox"
                    label="加入比較清單"
                    checked={compare}
                    value={id}
                    id={id}
                    onChange={(e) => {
                      this.props.onCompareClick(e)
                    }}
                  />
                </Form.Group>
              </div>
              <div className="">
                <a
                  href={`/packages/${package_id}?plan_search=${plan_search_id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={this.setTagManager.bind(this)}
                >
                  <Button variant="outline-primary mb-2" block>
                    方案詳情
                  </Button>
                </a>
                <a onClick={this.planBuyHandler.bind(this)} href={buy_path}>
                  {plan['package'].purchasable ? (
                    <Button variant="primary" block>
                      {' '}
                      立即購買{' '}
                    </Button>
                  ) : (
                    <Button variant="primary" block disabled>
                      {' '}
                      暫不銷售
                    </Button>
                  )}
                </a>
              </div>
            </PlanRight>
          </Col>
        </Row>
      </PlanBox>
    )
  }
}

export default plan
