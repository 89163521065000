import React from 'react'
import styled from 'styled-components'
import { Card, Nav } from 'react-bootstrap'
import { Link } from 'react-router-dom'

export const MemberCard = styled(Card)`
  border: 0;
  background-color: white;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.1) !important;
  padding: 1rem;
  margin-bottom: 1rem;
  @media (max-width: 576px) {
    margin-left: -10px;
    margin-right: -10px;
  }
`

export const Icon = (props) => (
  <svg
    height={props.height || '38px'}
    width={props.width || '38px'}
    viewBox="0 0 48 48"
    fill={props.fill || '#1c4151'}
  >
    <use href={`#${props.name}`}></use>
  </svg>
)

export const Flex = styled.div`
  display: flex;
  ${({ center }) => (center ? 'align-items:center;' : '')};
  ${({ column }) => (column ? 'flex-direction: column;' : '')};
  ${({ justify }) => (justify ? `justify-content: ${justify};` : '')};
  ${({ align }) => (align ? `align-items: ${align}` : '')};
  ${({ direction }) => (direction ? `flex-direction: ${direction}` : '')}
`
export const TextLink = styled(Link)`
  color: #ced4da;
  font-size: 14px;
  margin-right: 10px;
  :hover {
    color: #62cae3;
  }
`

export const MemberTag = styled.div`
  padding: 2px 5px;
  margin-left: 5px;
  color: white;
  border-radius: 3px;
  font-size: 80%;
`
export const NavItem = styled(Nav.Item)`
  color: ${({ theme }) => theme.color.primaryDark};
  border-style: solid;
  border-width: 0 0 0.5px 0;
  border-color: ${({ theme }) => theme.grayColor.gray300};
  & .nav-link {
    border-color: transparent;
    border-width: 0 0 3px 0;
    &.active {
      background-color: transparent;
      color: ${({ theme }) => theme.color.primaryDark};
      font-weight: ${({ theme }) => theme.fontWeight.bold};
      border-width: 0 0 3px 0;
      &:hover {
        font-weight: ${({ theme }) => theme.fontWeight.bold};
        border-color: ${({ theme }) => theme.color.secondary};
      }
    }
    &:hover {
      font-weight: ${({ theme }) => theme.fontWeight.bold};
      border-color: inherit;
    }
  }
`

export const Title = styled.div`
  display: flex;
  align-items: center;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }
  svg {
    margin-right: ${({ theme }) => theme.spacers[1]};
  }
`
