import React, { Component } from 'react'
import { MyPopup } from '@/components/_common/StyledPopup'
import { Formik, Field, ErrorMessage } from 'formik'
import Inputs from '@/components/_common/Inputs'
import createFormData from '@/components/_common/createFormData'
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import Flex from '@/components/_common/Flex'
import Axios from 'axios'

export class SignIn extends Component {
  submitHandler(values, actions) {
    let formData = createFormData(values)
    Axios.post('/members/sign_in', formData, {
      header: { 'Content-Type': 'text/html; charset=utf-8' },
    }).then(({ data }) => {
      console.log(data)
    })
  }

  render() {
    return (
      <MyPopup trigger={<a href="###">登入 \ 註冊</a>} modal>
        <div className="text-center">
          <h2 className="mb-1">會員登入</h2>
          <p className="text-gray-500">
            還沒加入會員嗎？<a href="/members/sign_up">馬上註冊</a>
          </p>
          <Formik
            // validationSchema={schema}
            initialValues={{
              utf8: '✓',
              authenticity_token: document
                .querySelector("meta[name='csrf-token']")
                .getAttribute('content'),
              member: {
                citizen_id: '',
                password: '',
              },
            }}
            onSubmit={this.submitHandler}
          >
            {({ values, handleSubmit, handleChange, errors, touched }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col xs={12} sm={10} className="mx-auto">
                      <Inputs.Input
                        label="帳號"
                        name="member[citizen_id]"
                        className="text-gray-600 text-left"
                      />
                    </Col>
                    <Col xs={12} sm={10} className="mx-auto">
                      <Inputs.Input
                        label="密碼"
                        name="member[password]"
                        className="text-gray-600 text-left"
                      />
                    </Col>
                    <Col xs={12} sm={10} className="mx-auto">
                      <Flex justify="space-between">
                        <Field
                          as={Form.Check}
                          name="member[remember_me]"
                          inline
                          label="保持登入狀態"
                          id="member[remember_me]"
                          type="checkbox"
                          className="text-gray-600"
                        />
                        <a href="###">忘記密碼</a>
                      </Flex>
                    </Col>
                    <Col xs={12} sm={10} className="mx-auto mt-2">
                      <Button type="submit" variant="primary" block size="lg">
                        登入
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )
            }}
          </Formik>
        </div>
      </MyPopup>
    )
  }
}

export default SignIn
