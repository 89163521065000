//步驟1
const SignUpError = {
  citizenId: {
    checkCanBeRegistered: '此身分證字號錯誤或已註冊',
    citizenIdFormat: '此身分證字號錯誤或已註冊',
    required: '請輸入身分證字號',
  },
  birthday: {
    typeError: '格式錯誤',
    max: '須年滿18方可註冊會員',
    required: '請輸入生日 例：1990-01-01',
  },
  password_confirmation: {
    password: '密碼輸入不一致',
    required: '請再次輸入密碼',
  },
  xname: {
    required: '請輸入您的姓名',
    matches: '請輸入您身分證上的姓名'
  },
  kyc_read: '請詳細閱讀相關資訊，並勾選同意',
}

//步驟2
const MobileAuthError = {
  mobile_phone: {
    matches: '格式錯誤，手機號碼應為09開頭的10碼數字',
    required: '請輸入手機號碼',
  },
  counter: '請稍後再試',
  didNotCompleted:
    '您尚未完成手機及E-mail驗證，請繼續完成以獲得最完整的會員體驗。',
}

//步驟三
const ConfirmationError = {
  email: {
    email: 'E-mail 格式錯誤',
    required: '請輸入您的 E-mail',
  },
}

export { SignUpError, MobileAuthError, ConfirmationError }
