import React, { Component } from "react";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.css";
import { ScCol } from "../SearchFrom/TravelSearchFrom";
import styled from "styled-components";
import { InputContainer, FormLabel } from "../SearchOption";

const FlatPickr = styled(Flatpickr)`
  background-color: transparent;
  border: none;
  color: #356a64;
  display: block;
  width: 100%;
  height: 100%;
  padding: 30px 30px 5px 9px;
  position: absolute;
  top: 0;
  left: 0;
  font-weight: 300;
  -webkit-appearance: none;
  appearance: none;
  -moz-appearance: none;
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='20'><polygon fill='%23333' points='10,13 4,6 16,6' /></svg>")
    no-repeat scroll right center transparent;
  ::-ms-expand {
    display: none;
  }
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    display: none;
  }
  ::-webkit-calendar-picker-indicator {
    -webkit-appearance: none;
    display: none;
  }
`;

export class DateDurationView extends Component {
  render() {
    let { insuredFrom, insuredTo } = this.props;
    return (
      <>
        <ScCol xs="12" lg="3">
          <InputContainer className="bg-gray-200">
            <FormLabel className="text-primary-dark">出發時間</FormLabel>
            <FlatPickr
              name={insuredFrom.name}
              data-enable-time
              value={insuredFrom.default}
              options={{
                minDate: insuredFrom.min,
                maxDate: insuredFrom.max,
                dateFormat: "Y/m/d H:i",
                disableMobile: true,
                minuteIncrement: 60,
                onChange: (e) => {
                  this.props.onFromChange(e);
                },
              }}
            />
          </InputContainer>
        </ScCol>
        <ScCol xs="12" lg="3">
          <InputContainer className="bg-gray-200">
            <FormLabel className="text-primary-dark">返家時間</FormLabel>
            <FlatPickr
              name={insuredTo.name}
              data-enable-time
              value={insuredTo.default}
              options={{
                disableMobile: true,
                minDate: insuredTo.min,
                maxDate: insuredTo.max,
                dateFormat: "Y/m/d H:i",
                minuteIncrement: 60,
                onChange: (e) => {
                  this.props.onToChange(e);
                },
              }}
            />
          </InputContainer>
        </ScCol>
      </>
    );
  }
}

export default DateDurationView;
