import React, { Component } from 'react'
import { MemberCard as Card } from '../../common'
import MemberContext from '@/components/_context/MemberContext'
import { SvgButton } from '@/components/_common/SvgIcon'
import EmailsShare from './EmailsShare'
import styled, { ThemeProvider } from 'styled-components'
import themes from '@/components/Themes'
import { withShareMethod } from '../../Share/index'
import { Link } from 'react-router-dom'

const CenterLine = styled.div`
  position: relative;
  margin: 0 15px 0 20px;
  height: 42px;
  & p {
    font-size: 12px;
    margin: 0px;
    line-height: 42px;
  }
  &:after {
    content: '';
    position: absolute;
    height: 10px;
    border-width: 0 1px 0 0;
    border-style: solid;
    border-color: ${({ theme }) => theme.grayColor.gray300};
    bottom: 0;
    right: 50%;
    background-color: white;
  }
  &:before {
    content: '';
    position: absolute;
    height: 10px;
    border-width: 0 1px 0 0;
    border-style: solid;
    border-color: ${({ theme }) => theme.grayColor.gray300};
    top: 0;
    right: 50%;
    background-color: white;
  }
`
const ShareInputs = styled.div`
  overflow: hidden;
  display: flex;
  align-items: center;
`
const InfoIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <path
      d="M13,15H2a2,2,0,0,1-2-2V2A2,2,0,0,1,2,0H13a2,2,0,0,1,2,2V13A2,2,0,0,1,13,15ZM6.405,10.376A.406.406,0,0,0,6,10.782v.812A.406.406,0,0,0,6.405,12H9.383a.406.406,0,0,0,.406-.406v-.812a.406.406,0,0,0-.406-.406H8.977V6.992a.406.406,0,0,0-.406-.406H6.405A.406.406,0,0,0,6,6.992V7.8a.407.407,0,0,0,.406.407h.406v2.165ZM7.761,3A1.37,1.37,0,1,0,9.13,4.37,1.371,1.371,0,0,0,7.761,3Z"
      fill="#1c4151"
    />
  </svg>
)
export class ShareWithFriend extends Component {
  render() {
    return (
      <MemberContext.Consumer>
        {({ member }) => {
          return (
            <ThemeProvider theme={themes}>
              <Card>
                <h5 className="m-0">
                  <Link to="/member/shares">
                    <i className="far fa-thumbs-up mr-2 text-primary-dark"></i>
                    <span className="text-primary-dark"> 你已成功推薦</span>
                    <span className="text-secondary h3">
                      {' '}
                      {member && member.recommendedCountThisYear}{' '}
                    </span>
                    <span className="text-primary-dark mr-1">位好友</span>
                    <InfoIcon />
                  </Link>
                </h5>
                {/* <p className="small text-gray-600">當年度還有{member && 10 - member.recommendedCountThisYear}次推薦好友可獲得莎拉幣</p> */}
                <hr />
                <h5 className="m-0">立即邀請好友</h5>
                <ShareInputs className="d-flex align-items-center">
                  <EmailsShare />
                  <CenterLine>
                    <p>or</p>
                  </CenterLine>
                  <SvgButton
                    width="38"
                    iconhref="#facebook"
                    href="###"
                    onClick={() =>
                      member && this.props.onFbShare(member.recommendCode)
                    }
                    className="mx-1"
                  />
                  <SvgButton
                    width="38"
                    iconhref="#line"
                    href="###"
                    onClick={() =>
                      member && this.props.onLineShare(member.recommendCode)
                    }
                    className="mx-1"
                  />
                </ShareInputs>
                {/* <p className="text-gray-500 m-0"><i className="fas fa-exclamation-circle"></i> 多組email請使用空白鍵分開</p> */}
              </Card>
            </ThemeProvider>
          )
        }}
      </MemberContext.Consumer>
    )
  }
}
export { CenterLine, ShareInputs }
export default withShareMethod(ShareWithFriend)
