import { useFormikContext } from 'formik'
import React, { useCallback, useEffect } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

function ReCaptcha({ action, onReady }) {
  const formik = useFormikContext()
  const { executeRecaptcha } = useGoogleReCaptcha()

  const handleVerify = useCallback(() => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }

    onReady();

    // executeRecaptcha(action)
    //   .then(token => {
    //     formik.setFieldValue('g-recaptcha-response-data', { [action]: token })
    //   })
  })

  useEffect(() => handleVerify(), [executeRecaptcha])

  return null
}

export default ReCaptcha