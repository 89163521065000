import React from 'react'
import OptionButton from './OptionButton'
import styled from 'styled-components'
import OptionsSelect from './OptionsSelect'

const Container = styled.div`
  & div:first-child{
    button {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      border: none;
    }
  }
  & div:last-child {
    button{

    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    }
  }
`

function OptionsContainer({ options, value, onChange }) {

  const optionsRender = () => {
    if (options && (options.length > 5)) {
      return (<OptionsSelect options={options} value={value} onChange={onChange}></OptionsSelect>)
    }
    if (options) {
      return (
        <>
          {options && options.map(option => (
            <div className="col p-0" key={option[1]}>
              <OptionButton value={option[1]} onClick={onChange} actived={value == option[1]} >
                {option[0]}
              </OptionButton>
            </div>
          ))}
        </>
      )
    }
    return
  }

  return (
    <Container className="row px-3 mb-4">
      {optionsRender()}
    </Container>
  )
}

export default OptionsContainer
