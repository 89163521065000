import React, { useContext } from 'react'
import context from '../../context'

function Footer() {
  const { productInfo } = useContext(context)
  const { main_coverage, duration } = productInfo.otherInfo
  return (
    <>
      <h4 className="d-inline">保障</h4>
      <h1 className="d-inline text-secondary mx-2">{duration || "--"}</h1>
      <h4 className="d-inline">天</h4>
      <h4 className="d-inline pl-4">保額</h4>
      <h1 className="d-inline text-secondary mx-2">{main_coverage || "--"}</h1>
      <h4 className="d-inline">萬</h4>
    </>
  )
}

export default Footer;

