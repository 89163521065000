import React, { Children, Component } from 'react'
import { Accordion, Card } from 'react-bootstrap'
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle'
import styled, { ThemeProvider } from 'styled-components'
import themes from '@/components/Themes'
import { Scrollbars } from 'react-custom-scrollbars'
import { withContext } from '../../Context'

const Content = styled.div`
  margin: ${({ theme }) => theme.spacers[3]};
  max-height: 300px;
  height: 80vh;
  width: auto;
`

function Trigger({ children, eventKey, callback, currentEventKey }) {
  const isOpen = currentEventKey === eventKey
  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(isOpen ? -1 : eventKey),
  )

  return (
    <Card.Header
      onClick={decoratedOnClick}
      className={
        (isOpen ? 'bg-primary text-white' : '') + ' d-flex align-items-center'
      }
    >
      {children}
      {isOpen ? (
        <i className="fa fa-angle-up ml-auto" />
      ) : (
        <i className="fa fa-angle-down ml-auto" />
      )}
    </Card.Header>
  )
}

function _TermsMobile(props) {
  const [currentEventKey, setCurrentEventKey] = React.useState(0)
  return (
    <div className={props.className}>
      <Accordion defaultActiveKey={0}>
        {props.context &&
          props.context.terms.map((term, i) => (
            <Card key={i}>
              <Trigger
                eventKey={i}
                currentEventKey={currentEventKey}
                callback={setCurrentEventKey}
              >
                {term.title}
              </Trigger>
              <Accordion.Collapse eventKey={i}>
                <Card.Body className="p-0">
                  <Content>
                    <Scrollbars style={{ height: '100%' }}>
                      <p
                        className="mr-2"
                        dangerouslySetInnerHTML={{ __html: term.term }}
                      />
                    </Scrollbars>
                  </Content>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          ))}
      </Accordion>
    </div>
  )
}

let TermsMobile = styled(_TermsMobile)`
  .card {
    border-radius: 10px;
  }
`

export default withContext(TermsMobile)
