import { Completed, Confirmation, MobileAuthentication, SignUp } from './pages'

const rootPath = process.env.PUBLIC_URL

const routes = [
  //step1
  {
    path: `/members/sign_up`,
    component: SignUp,
    step: 1,
    title: '建立帳戶',
  },
  //step2
  {
    path: `/member/mobile_authentications/new`,
    component: MobileAuthentication,
    step: 2,
    title: '手機驗證',
    unconfirmTitle: '手機尚未完成驗證',
  },
  //step3
  {
    path: `/members/confirmation/new`,
    component: Confirmation,
    step: 3,
    title: 'E-mail 驗證',
    unconfirmTitle: 'E-mail 尚未完成驗證',
  },
  //step4
  {
    path: `/members/confirmation`,
    component: Completed,
  },
]

export default routes
export { rootPath }
