import styled from 'styled-components'

export const Scroll = styled.div`
  max-height: 400px;
  overflow: scroll;
  display: flex;
`

export const Thead = styled.thead`
  border: 2px solid ${({ theme }) => theme.color.primary};
  border-style: none none solid none;
`

export const Tbody = styled.tbody`
  tr:nth-of-type(odd) {
    background-color: ${({ theme }) => theme.color.primaryLight};
  }
`

export const TbodyScroll = styled.tbody`
  height: ${({ showAll }) => (showAll ? '200px' : 'auto')};
  tr:nth-of-type(odd) {
    background-color: ${({ theme }) => theme.color.primaryLight};
  }
`

export const Th = styled.th`
  font-size: 14px;
  color: ${({ theme }) => theme.color.landingColor};
`
