//保險智囊 checkbox邏輯


$(function () {
  var futureInsurancePage = 2;
  var isTheLastPage = $(".tabblock").length < 8;

  (function () {
    console.log(document)
    $('.futurefilterbox__checkbox input[id^=all]').on('change', function () {
      var status = $(this).prop('checked');
      $(this).closest('.futurefilterbox__checkbox').find('input[type=checkbox]').prop('checked', status);
      counting($(this).closest('.futurefilterbox__checkbox'));
    });
    $('.futurefilterbox__checkbox input[name=filtertype]').on('change', function () {
      var checkedLength = $(this).closest('.futurefilterbox__checkbox').find('input[name=filtertype]:checked').length;
      var inputLength = $(this).closest('.futurefilterbox__checkbox').find('input[name=filtertype]').length;
      if (checkedLength === inputLength) {
        $(this).closest('.futurefilterbox__checkbox').find('input[id^=all]').prop('checked', true);
      } else {
        $(this).closest('.futurefilterbox__checkbox').find('input[id^=all]').prop('checked', false);
      }
      counting($(this).closest('.futurefilterbox__checkbox'));
    });

    function counting(e) {
      var length = e.find('input[name=filtertype]:checked').length;
      if (length != 0) {
        e.siblings('.futurefilterbox__btn-count').text(length);
      } else {
        e.siblings('.futurefilterbox__btn-count').text('');
      }
    }
  })()

  //點選單隱藏其他選單
  $('.futurefilterbox__btn').on('click', function (ev) {

    if ($(this).hasClass('futurefilterbox__btn--active')) {
      $(this).removeClass('futurefilterbox__btn--active')
    } else {
      $('.futurefilterbox__btn').removeClass('futurefilterbox__btn--active');
      $(this).toggleClass('futurefilterbox__btn--active')
    }
    isTheLastPage = false
    futureInsurancePage = 2
  });

  //點擊空白處隱藏選單
  $(document).click(function (event) {
    var _con = $('.futurefilterbox__btn--active');
    if (!_con.is(event.target) && _con.has(event.target).length === 0) {
      $('.futurefilterbox__btn').removeClass('futurefilterbox__btn--active');
    }
  });
  var loading = false;
  $(window).scroll(function () {
    function isEmptyOrSpaces(str) {
      return str === null || str.match(/^ *$/) !== null;
    }
    var hT = $('#scroll-to').offset().top,
      hH = $('#scroll-to').outerHeight(),
      wH = $(window).height(),
      wS = $(this).scrollTop();
    var data = collectAllFilter()
    if (wS > (hT + hH - wH)) {
      if (!loading && !isTheLastPage) {
        showLoading();
        loading = true;
        $.ajax(`${window.location.pathname}`, {
          type: 'get',
          data: {
            page: futureInsurancePage,
            author_ids: data.authorIds,
            tag_ids: data.tagIds,
            order: data.order
          },
          success: (res) => {
            $('.article-container').append(res);
            if (isEmptyOrSpaces(res)) {
              isTheLastPage = true
            }
            futureInsurancePage++;
            hideLoading();
            loading = false;
          }
        })
      }
    }
  });
})

function collectAllFilter() {
  var authorIds = [];
  var tagIds = [];
  var order = 'view';

  $('.futurefilterbox__checkbox-input').each(function () {
    var idValue = (this.checked ? $(this).attr('id') : "");
    if (idValue.includes('author')) {
      authorIds.push(idValue.split('-')[1]);
    }
    if (idValue.includes('tag')) {
      tagIds.push(idValue.split('-')[1]);
    }
  });

  var orderValue = $('.futureinsurancetabs__filterbox-btn--active').attr('id');
  if (orderValue != undefined) {
    order = orderValue.split('-')[1];
  } else {
    order = []
  }

  return {
    authorIds: authorIds,
    tagIds: tagIds,
    order: order
  };
};