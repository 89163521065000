import React, { Component } from 'react'
import { Form, Col, Row } from 'react-bootstrap'
import styled from 'styled-components'
import { Field, ErrorMessage } from 'formik'
import Birthday from './Birthday'

const FormCheck = styled(Form.Check)`
  padding-left: 0rem;
`

const FormFeedback = styled.div`
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
`

function FormControl({ field, form, ...props }) {
  if (props.asType) {
    return (
      <Form.Control
        {...props}
        {...field}
        isInvalid={form.touched[field.name] && form.errors[field.name]}
        as="select"
      />
    )
  } else {
    return (
      <Form.Control
        {...props}
        {...field}
        {...(props._as ? { as: props._as } : null)}
        isInvalid={form.touched[field.name] && form.errors[field.name]}
      />
    )
  }
}

//多個input項
function MutiInput(props) {
  return (
    <>
      {props.inputs.map((input) => (
        <Form.Group
          as={Col}
          xs={12 / props.inputs.length}
          md={8 / props.inputs.length}
          key={input.name}
        >
          <Field
            name={input.name}
            type={props.type || 'text'}
            placeholder={input.placeholder}
            component={FormControl}
          />
          <ErrorMessage name={input.name} component={FormFeedback} />
        </Form.Group>
      ))}
    </>
  )
}

//單一一個input項
function Input(props) {
  return (
    <>
      <Form.Group as={Col} xs={12} md={9} controlId={props.name}>
        <Field
          name={props.name}
          type={props.type || 'text'}
          placeholder={props.placeholder}
          component={FormControl}
          {...props}
        />
        <ErrorMessage name={props.name} component={FormFeedback} />
      </Form.Group>
    </>
  )
}

//單選
function Radios(props) {
  return (
    <Form.Group as={Col} xs={12} md={9}>
      {props.radios.map((r, i) => (
        <Field
          key={i}
          name={props.name}
          as={FormCheck}
          id={`${props.name}_${i}`}
          value={r.value}
          inline={r.inline}
          label={r.label}
          type="radio"
        />
      ))}
      <ErrorMessage name={props.name} component={FormFeedback} />
    </Form.Group>
  )
}

//多選
function CheckBoxs(props) {
  if (props.checks) {
    return (
      <Form.Group as={Col} xs={12} md={9} controlId={props.name}>
        {props.checks.map((c, i) => (
          <Field
            as={Form.Check}
            key={i}
            name={props.name}
            value={c.name}
            inline={c.inline}
            label={c.label}
            id={`${props.name}_${i}`}
            type="checkbox"
          />
        ))}
        <ErrorMessage name={props.name} component={FormFeedback} />
      </Form.Group>
    )
  } else {
    return (
      <Form.Group as={Col} xs={12} md={9} controlId={props.name}>
        <Field as={Form.Check} {...props} id={props.name} type="checkbox" />
        <ErrorMessage name={props.name} component={FormFeedback} />
      </Form.Group>
    )
  }
}

//
function InputTextArea(props) {
  return (
    <Form.Group as={Col} xs={12} md={9}>
      <Field
        name={props.name}
        _as="textarea"
        rows={3}
        type="textarea"
        placeholder={props.placeholder}
        component={FormControl}
      />
      <ErrorMessage name={props.name} component={FormFeedback} />
    </Form.Group>
  )
}

function Selector(props) {
  return (
    <Form.Group as={Col} controlId={props.name} xs={12} md={9}>
      <Field
        as="select"
        name={props.name}
        component={FormControl}
        asType="select"
      >
        {props.defaultOption ? <option>{props.defaultOption}</option> : null}
        {props.options.map((o, i) => (
          <option value={o.value} key={i}>
            {o.name}
          </option>
        ))}
      </Field>
      <ErrorMessage component={FormFeedback} name={props.name} />
    </Form.Group>
  )
}

function BirthdaySelector(props) {
  return (
    <Form.Group as={Col} controlId={props.name} xs={12} md={9}>
      <Field component={Birthday} name={props.name} type="text" />
      <ErrorMessage name={props.name} component={FormFeedback} />
    </Form.Group>
  )
}

Selector.defaultProps = {
  options: [],
}

function WithLabel(Component, props) {
  return (
    <Form.Group as={Row}>
      <Form.Label column xs={12} md={3} className={props.className}>
        {props.label}
      </Form.Label>
      <Component {...props} />
    </Form.Group>
  )
}

let Inputs = {
  MutiInput: (props) => WithLabel(MutiInput, props),
  Input: (props) => WithLabel(Input, props),
  CheckBoxs: (props) => WithLabel(CheckBoxs, props),
  Radios: (props) => WithLabel(Radios, props),
  TextArea: (props) => WithLabel(InputTextArea, props),
  Selector: (props) => WithLabel(Selector, props),
  BirthdaySelector: (props) => WithLabel(BirthdaySelector, props),
}
export {
  MutiInput,
  Input,
  CheckBoxs,
  Radios,
  InputTextArea,
  Selector,
  FormFeedback,
  FormControl,
}
export default Inputs
