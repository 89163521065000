import React, { Component } from 'react'
import PolicyList from './PolicyList/Index'
import Title from './Title'
// import OtherBtn from './OtherBtn'
import SaraPoint from './SaraPoint'
import ShareWithFriend from './ShareWithFriend'
import { Container, Row, Col, Button } from 'react-bootstrap'
import MemberContext from '../../_context/MemberContext'
import { Icon, Flex } from '../common'
import axios from 'axios'

const memberSharePath = '/member/send_invitation_via_email'

export class Index extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ...props,
      onShareFriend: this.onShareFriend,
      emails: [],
    }
    this.state.onShareFriend = this.onShareFriend
    this.state.emails = []
    showLoading()
  }

  componentDidMount() {
    window.loadFB(document, 'script', 'facebook-jssdk')
    axios
      .get('/member.json', { headers: { 'Content-Type': 'application/json' } })
      .then((response) => {
        this.setState({ ...response.data })
        hideLoading()
      })
  }

  onShareFriend(params) {
    axios
      .post(
        memberSharePath,
        { utf8: '✓', email: params.join() },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
      .then(({ data }) => {
        alert(data.data)
      })
  }

  render() {
    return (
      <Container>
        <MemberContext.Provider value={this.state}>
          <Title />
          <Row className="mb-4">
            <Col xs="12" md="6">
              <Flex center="true" className="mb-3 mt-4 mx-2">
                <Icon name="i-my-policy"></Icon>
                <h4 className="ml-2 m-0">我的保單</h4>
              </Flex>
              <PolicyList policies={this.state.policies}></PolicyList>
              <Flex center="true" className="mb-3 mt-4 mx-2">
                <Icon name="i-member-share"></Icon>
                <h4 className="ml-2 m-0">推薦好友</h4>
              </Flex>
              <ShareWithFriend></ShareWithFriend>
            </Col>
            <Col xs="12" md="6" className="flex-column d-flex flex-fill">
              <Flex center="true" className="mb-3 mt-4 mx-2">
                <Icon name="i-saracoin"></Icon>
                <h4 className="ml-2 m-0">莎拉幣專區</h4>
                <div className="ml-auto d-md-none d-block">
                  <Button variant="outline-primary" size="sm" href="/sarapoint">
                    如何累積莎拉幣？
                  </Button>
                </div>
              </Flex>
              <SaraPoint point={this.state.point}></SaraPoint>
            </Col>
            {/* 這些按鈕尚未完成 先不顯示 */}
            {/* <Col xs="12">
              <OtherBtn />
            </Col> */}
          </Row>
        </MemberContext.Provider>
      </Container>
    )
  }
}

export default Index
