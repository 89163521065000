import React, { Component } from 'react'
import { MemberCard as Card } from '../common'
import { SvgButton } from '@/components/_common/SvgIcon'
import saracoin from '@/images/react/members/saracoin'
import { Button, Row, Col } from 'react-bootstrap'
import MenuButton from '../common/MenuButton'
import { Link } from 'react-router-dom'
import Flex from '@/components/_common/Flex'
import ReactTooltip from 'react-tooltip'
import styled from 'styled-components'

const QuestionIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    viewBox="0 0 15 15"
  >
    <path
      d="M13,15H2a2,2,0,0,1-2-2V2A2,2,0,0,1,2,0H13a2,2,0,0,1,2,2V13A2,2,0,0,1,13,15ZM6.954,9.546A1.213,1.213,0,1,0,8.168,10.76,1.215,1.215,0,0,0,6.954,9.546Zm.034-4.735c.569,0,1.205.373,1.205.873,0,.393-.326.6-.859.9l-.045.025c-.623.35-1.4.785-1.4,1.839v.106a.317.317,0,0,0,.317.317H7.688A.317.317,0,0,0,8,8.551V8.516c0-.284.311-.464.7-.692A2.347,2.347,0,0,0,10.2,5.7a2.417,2.417,0,0,0-.757-1.727A3.342,3.342,0,0,0,7.129,3L7,3A3.428,3.428,0,0,0,4.054,4.685a.316.316,0,0,0,.071.428l.915.7a.317.317,0,0,0,.436-.056C5.938,5.171,6.258,4.811,6.989,4.811Z"
      fill="#1c4151"
    />
  </svg>
)

const AcitveBanner = styled.div`
  background-image: url(${({ image }) => image});
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
  box-shadow: ${({ theme }) => theme.shadow.sm};
  border-radius: ${({ theme }) => theme.borderRadius};
  min-height: 200px;
`

export class SaraPoint extends Component {
  renderExpiredNoitce({ expired_date, expired_point }) {
    if (expired_point) {
      return (
        <p className="text-error small m-0">
          {expired_point}點莎拉幣將於{expired_date}後過期
        </p>
      )
    }
  }
  render() {
    return (
      <>
        <Card className="flex-fill">
          <Flex align="center">
            <Flex align="center">
              <h3 className="m-0 mr-2 text-primary">
                {this.props.point && this.props.point.count} 點
              </h3>
              <div>
                <p className="m-0">
                  可用的莎拉幣
                  <a data-tip data-for="tips" className="ml-1">
                    <QuestionIcon />
                  </a>
                </p>
                <ReactTooltip
                  id="tips"
                  place="right"
                  effect="solid"
                  backgroundColor={'#62cae3'}
                >
                  <p className="mb-0">
                    這是你目前擁有的莎拉幣，
                    <br />
                    累積越多可享好康越多。
                  </p>
                </ReactTooltip>
                {this.props.point && this.renderExpiredNoitce(this.props.point)}
              </div>
            </Flex>

            <div className="ml-auto d-none d-md-block">
              <Button variant="outline-primary" href="/sarapoint">
                如何累積莎拉幣？
              </Button>
            </div>
          </Flex>
          <Row
            className="mt-3"
            style={{ marginLeft: '-0.5rem', marginRight: '-0.5rem' }}
          >
            <Col>
              <MenuButton
                href="/lottery/chance_fate/"
                icon="#i-lottery"
                name="翻轉莎幣2選1"
              ></MenuButton>
            </Col>
            <Col>
              <MenuButton
                href="/member/bonus"
                icon="#i-exchange"
                name="莎拉領好康"
              ></MenuButton>
            </Col>
          </Row>
          <a
            href={this.props.point && this.props.point.banner_link}
            style={{ height: '100%' }}
          >
            <AcitveBanner
              image={this.props.point && this.props.point.banner_image}
            />
          </a>
        </Card>
      </>
    )
  }
}

export default SaraPoint
