import React, { Component, useContext, Fragment } from 'react'
import { Accordion } from 'react-bootstrap'
import styled from 'styled-components'
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle'
import { Context } from './index'

const AccordionCollapse = Accordion.Collapse
const _Trigger = ({
  num,
  eventKey,
  children,
  callback,
  className,
  currentEventKey,
}) => {
  const decoratedOnClick = useAccordionToggle(eventKey, () => {
    callback && callback(eventKey)
  })
  const isOpen = currentEventKey === eventKey

  return (
    <div onClick={decoratedOnClick} className={className}>
      {num ? <span>Q{num}</span> : null}
      {children}
      {isOpen ? (
        <i className="fa fa-angle-up" />
      ) : (
        <i className="fa fa-angle-down" />
      )}
    </div>
  )
}

export const Trigger = styled(_Trigger)`
  border-radius: 0 !important;
  padding: 15px 35px 15px 15px !important;
  color: #1c4151;
  line-height: 28px;
  position: relative;
  width: 100%;
  display: inline-flex;
  cursor: pointer;
  border-bottom: 1px solid rgba(0, 0, 0, 0.075);
  i {
    position: absolute;
    right: 10px !important;
    left: auto !important;
    top: 27px;
    color: ${({ theme }) => theme.grayColor.gray500};
    width: 22px;
    height: 22px;
    font-size: 22px;
    line-height: 30px;
    margin-top: -12px;
  }
  span {
    color: #ff762a;
    padding-right: 20px;
  }
`

export const Faq = (props) => {
  const { currentEventKey, handleFaqClick } = useContext(Context)
  return (
    <>
      {pug`
        Trigger(eventKey=props.eventKey num=props.num currentEventKey=currentEventKey callback=handleFaqClick)
          = props.title
        AccordionCollapse(eventKey=props.eventKey)
          .m-3(dangerouslySetInnerHTML={__html: props.ans})
      `}
    </>
  )
}

export default Faq
