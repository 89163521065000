import React, { Component } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { MemberCard as Card } from '../common'
import MenuButton from '../common/MenuButton'

export class OtherBtn extends Component {
  render() {
    return (
      <Row className="my-3">
        <Col xs="6" md="3">
          <MenuButton
            href="###"
            icon="#i-for-beginner"
            name="新手投保須知"
          ></MenuButton>
        </Col>
        <Col xs="6" md="3">
          <MenuButton
            href="###"
            icon="#i-infographics"
            name="保險懶人包"
          ></MenuButton>
        </Col>
        <Col xs="6" md="3">
          <MenuButton
            href="###"
            icon="#i-life-plan"
            name="人生規劃"
          ></MenuButton>
        </Col>
        <Col xs="6" md="3">
          <MenuButton href="###" icon="#i-save" name="小額儲蓄"></MenuButton>
        </Col>
      </Row>
    )
  }
}

export default OtherBtn
