import React, { Component } from 'react'
import styled from 'styled-components'

const P1 = styled.p``

export class Scrolling extends Component {
  render() {
    return (
      <div>
        <P></P>
      </div>
    )
  }
}

export default Scrolling
