import yup from './yup';
import { nameRegExp, phoneRegExp, addressRegExp } from './regexp';
import citizenIdTest from '@/components/_common/citizenIdTest';

const beneficiariesSchema = yup.array()
  .of(yup.object().shape({
    relation_id: yup.string().required(),
  }).when((value, schema) => (value.relation_id != 1
    ? schema.shape({
      name: yup.string().required('請輸入姓名').matches(nameRegExp, '請輸入您身分證上的姓名').nullable(),
      citizen_id: yup.string().required('請輸入身分證字號').test('citizenIdFormat', '身分證格式錯誤', citizenIdTest).nullable(),
      birthday: yup.date().required('請輸入生日 例：1990-01-01').typeError('格式錯誤 例：1990-01-01'),
      mobile_phone: yup.string().required('請輸入行動電話').matches(phoneRegExp, '電話格式錯誤').nullable(),
      address: yup.string()
        .required('地址格式錯誤')
        .matches(/^((?![0-9][F|f]).)+$/, '樓層請使用中文「樓」')
        .matches(/^((?![樓|號]之[0-9a-zA-Z]*[樓|室]).)+$/, '請使用逗號區隔地址，例如：15號之1，5樓之1，A室')
        .matches(/^((?!,).)+$/, '請使用全形逗號')
        .matches(addressRegExp, '地址格式錯誤')
        .nullable()
        .test(function () {
          // 不可使用arror function
          if (!this.parent.town_id) return this.createError({ message: '選擇縣市與行政區域' });
          return true;
        }),
    }) : schema)));

const insuredSchema = yup.object().shape({
  address: yup
    .string()
    .required('地址格式錯誤')
    .matches(/^((?![0-9][F|f]).)+$/, '樓層請使用中文「樓」')
    .matches(
      /^((?![樓|號]之[0-9a-zA-Z]*[樓|室]).)+$/,
      '請使用逗號區隔地址，例如：15號之1，5樓之1，A室',
    )
    .matches(/^((?!,).)+$/, '請使用全形逗號')
    .matches(addressRegExp, '地址格式錯誤'),
  occupation_code: yup.string().required('請輸入您的職業'),
  beneficiaries: beneficiariesSchema,
  citizen_id: yup.string().required('請輸入身分證字號').test('citizenIdFormat', '身分證格式錯誤', citizenIdTest),
  birthday: yup.date().required('請輸入生日 例：1990-01-01').typeError('格式錯誤 例：1990-01-01'),
  name: yup.string().required('請輸入姓名').matches(nameRegExp, '請輸入您身分證上的姓名'),
});

export default insuredSchema;
