import { Button, Col, Row, Select } from "react-bootstrap";
import React from "react";
import SearchOptionContext from "@/components/_context/SearchOptionContext";
import moment from "moment";
import SearchOption from "../SearchOption";
import { Formik, useFormikContext } from "formik";
import DateDuration from "../SearchOption/DateDuration";
import styled from "styled-components";
import CustomIcon from "../../../images/react/search/travel.svg";
import InfoIcon from "../../../images/react/search/info.svg";
import { defaultOptions } from "highcharts";

const SearchBox = styled.div`
  border: 1px solid #1c4151;
  margin-top: -1px;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  padding: 1rem;
`;
export const ScRow = styled(Row)`
  margin-left: -8px;
  margin-right: -8px;
`;
export const ScCol = styled(Col)`
  padding-left: 8px;
  padding-right: 8px;
`;
const AutoSubmitByPrams = () => {
  //如果values.commit 不等於初始值 表示從params有待資料進來
  const { values, submitForm } = useFormikContext();
  React.useEffect(() => {
    // Submit the form imperatively
    if (values.commit != "") {
      submitForm();
    }
  }, [values, submitForm]);
  return null;
};

class SearchFromTravel extends React.Component {
  render() {
    window.moment = moment;
    return (
      <SearchBox>
        <SearchOptionContext.Consumer>
          {({ searchForm }) => {
            let {
              productLine,
              currentCategory,
              currentOptionList,
              searchParams,
              onFormSubmit,
            } = searchForm;
            let {
              insuredTo = moment()
                .add(5, "days")
                .add(2, "hours")
                .startOf("hours")
                .toDate(),
              insuredFrom = moment().add(2, "hours").startOf("hours").toDate(),
              duration = 5,
              sortKind = "",
              mainCoverage = currentOptionList.options.defaultAmount?.domestic?.toString(),
              commit = "",
            } = searchParams;
            console.log(currentCategory);
            console.log(currentOptionList.options.defaultAmount);
            return (
              <div>
                <Formik
                  initialValues={{
                    category: currentCategory,
                    insuredTo: insuredTo,
                    insuredFrom: insuredFrom,
                    duration: duration,
                    sortKind: sortKind,
                    mainCoverage: mainCoverage,
                    commit: commit,
                  }}
                  onSubmit={(values, action) => {
                    values.category = currentCategory;
                    values.fetchAll = 1;
                    values.sortType = "desc";
                    onFormSubmit(values, action);
                  }}
                >
                  {({ values, handleSubmit, handleChange, setFieldValue }) => {
                    const option = currentOptionList.options.amounts
                      .filter((amounts) =>
                        currentCategory == 1
                          ? amounts.foreign
                          : amounts.domestic
                      )
                      .map((v) => v.amount.toString());

                    const defaultOption =
                      currentOptionList.options.defaultAmount;
                    if (!option?.includes(values.mainCoverage)) {
                      setFieldValue(
                        "mainCoverage",
                        currentCategory == 1
                          ? defaultOption.foreign.toString()
                          : defaultOption.domestic.toString()
                      );
                    }
                    return (
                      <>
                        <div className="d-flex align-items-center mb-2">
                          <CustomIcon
                            height="42px"
                            width="42px"
                            className="mr-1"
                          />
                          <h4 className="m-0">
                            投保
                            <span className="h1 text-secondary mx-1">
                              {values.duration}
                            </span>
                            天
                          </h4>
                        </div>
                        <form onSubmit={handleSubmit}>
                          <ScRow>
                            <DateDuration
                              value={({ insuredTo, insuredFrom } = values)}
                              onChange={handleChange}
                            />
                            <ScCol xs="6" lg="3">
                              <SearchOption
                                name="sortKind"
                                optionName={"在意的保障"}
                                options={currentOptionList.options.sortKind}
                                value={values.sortKind}
                                onChange={handleChange}
                                nullOption={"不指定"}
                              ></SearchOption>
                            </ScCol>
                            <ScCol xs="6" lg="3">
                              <SearchOption
                                name="mainCoverage"
                                optionName={"選擇保額"}
                                options={currentOptionList.options.amounts
                                  .filter((amounts) =>
                                    currentCategory == 1
                                      ? amounts.foreign
                                      : amounts.domestic
                                  )
                                  .map((a) => [`${a.amount}萬`, a.amount])}
                                value={values.mainCoverage}
                                onChange={handleChange}
                              ></SearchOption>
                            </ScCol>
                          </ScRow>
                          <p className="text-secondary small mb-0">
                            <InfoIcon className="mr-1" width="13px" />
                            旅平險以24小時計算單位，若回程時間不及24小時，系統將自動加計一天投保日。
                          </p>
                          <p className="text-secondary small">
                            <InfoIcon className="mr-1" width="13px" />
                            依法令規定，保險公司非有效契約客戶網路投保上限為1,000萬。(備註：有效契約客戶指投保時同時擁有該公司其他的保險契約)
                          </p>
                          <Row className="mt-2">
                            <Col xm="12" lg="4" className="ml-auto">
                              <Button variant="secondary" type="submit" block>
                                快搜試算
                              </Button>
                            </Col>
                          </Row>
                          <AutoSubmitByPrams />
                        </form>
                      </>
                    );
                  }}
                </Formik>
              </div>
            );
          }}
        </SearchOptionContext.Consumer>
      </SearchBox>
    );
  }
}

export default SearchFromTravel;
