import React from 'react'

function BuyBtn({ isActive, url, disabled, className, onClick }) {
  return (
    <a
      href={(isActive && !disabled && url) ? url : "#"}
      className={`btn btn-primary btn-block buy ${className || ""}`}
      disabled={disabled}
      onClick={onClick}
    >
      {isActive ? "立即購買" : '暫停銷售'}
    </a>
  )
}

export default BuyBtn
