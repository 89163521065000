import React, { Component } from 'react'
import styled from 'styled-components'
import { MyPopup } from '@/components/_common/StyledPopup'
import { Button } from 'react-bootstrap'
import { Flex } from '../common/index'
import MemberContext from '@/components/_context/MemberContext'
import { AwardBox } from './Award'

export class Sweepstakes extends Component {
  renderExchange(close) {
    return (
      <>
        <h4>抽獎贈品領取</h4>
        <p>是否領取 {this.context.currentExchange.exchangeObject.award_name}</p>
        <Flex justify="center">
          <Button variant="outline-info" onClick={close} className="mr-2">
            取消
          </Button>
          <Button
            variant="primary"
            onClick={() => this.context.currentExchange.onExchange()}
            className="ml-2"
          >
            確認
          </Button>
        </Flex>
      </>
    )
  }

  renderExchangeSuccess(close) {
    return (
      <>
        <h4>抽獎贈品領取</h4>
        <p>{this.context.currentExchange.msg}</p>
        <Flex justify="center">
          <Button
            variant="primary"
            onClick={() => this.context.exchangeSweepstakesCloseHandle(close)}
          >
            關閉
          </Button>
        </Flex>
      </>
    )
  }

  renderExchangeFailed(close) {
    return (
      <>
        <h4>抽獎贈品兌換</h4>
        <p className="m-0">
          很遺憾 兌換 {this.props.sweepstakes.award_name} 失敗
        </p>
        <p>請稍後再試</p>
        <Flex justify="center">
          <Button variant="primary" onClick={close}>
            關閉
          </Button>
        </Flex>
      </>
    )
  }

  render() {
    let { sweepstakes } = this.props
    return (
      <AwardBox image={sweepstakes.image.url}>
        <div className="img" />
        <div>
          <h6>{sweepstakes.award_name}</h6>
          <div>
            <MyPopup
              trigger={<Button variant="primary">立即領取</Button>}
              onOpen={() =>
                this.context.willExchangeHandler('sweepstakes', sweepstakes)
              }
              modal
            >
              {(close) =>
                !this.context.currentExchange.check
                  ? this.renderExchange(close)
                  : this.context.currentExchange.exchange
                  ? this.renderExchangeSuccess(close)
                  : this.renderExchangeFailed(close)
              }
            </MyPopup>
          </div>
        </div>
      </AwardBox>
    )
  }
}

Sweepstakes.contextType = MemberContext
export default Sweepstakes
