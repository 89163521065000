import React, { Component } from 'react'
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import { Formik, Field } from 'formik'
import * as Yup from 'yup'
import Inputs from '@/components/_common/Inputs'
import Spinner from '../../components/Spinner'
import { ConfirmationError } from '../../components/ErrorMessage'

const schema = Yup.object().shape({
  member: Yup.object().shape({
    email: Yup.string()
      .required(ConfirmationError.email.required)
      .email(ConfirmationError.email.email),
  }),
})

export class EmailField extends Component {
  render() {
    return (
      <div>
        <Formik
          validationSchema={schema}
          initialValues={{
            utf8: '✓',
            authenticity_token: document
              .querySelector("meta[name='csrf-token']")
              .getAttribute('content'),
            member: {
              email: '',
              receive_promotion: false,
            },
          }}
          onSubmit={this.props.submitHandler}
        >
          {({ values, handleSubmit, handleChange, errors, touched }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col xs={12}>
                    <Inputs.Input
                      label="E-mail"
                      placeholder="saracares@example.com.tw"
                      name="member[email]"
                      showError={true}
                    />
                  </Col>
                  <Col xs={12} md={9} className="ml-auto">
                    <Field
                      as={Form.Check}
                      name="member[kyc_read]"
                      label="我願意收到 SARAcares 的最新訊息"
                      id="member[kyc_read]"
                      type="checkbox"
                    />
                  </Col>

                  <Col xm={12} md={7} className="mx-auto my-4 mb-5">
                    <Button
                      variant="primary"
                      type="submit"
                      block
                      disabled={this.props.isLoading}
                    >
                      發送 E-mail 驗證信
                      {this.props.isLoading && <Spinner />}
                    </Button>
                  </Col>
                </Row>
              </Form>
            )
          }}
        </Formik>
      </div>
    )
  }
}

export default EmailField
