import React from 'react'
import { Row, Col } from 'react-bootstrap'
import styled, { ThemeProvider, keyframes } from 'styled-components'
import icon_1 from '@/images/devise/icon_1'
import icon_2 from '@/images/devise/icon_2'
import icon_3 from '@/images/devise/icon_3'

const _StepIcons = (props) => {
  return (
    <div className={props.className + ' ' + `step-${props.step}`}>
      <div className="cir">
        <img src={icon_1} />
      </div>
      <div className="cir">
        <img src={icon_2} />
      </div>
      <div className="cir">
        <img src={icon_3} />
      </div>
      <div className="line" />
      <div className="line line-primary" />
    </div>
  )
}

const StepIcons = styled(_StepIcons)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
  .line {
    height: 3px;
    width: 90%;
    top: calc(50% - 2px);
    left: 5%;
    background-color: ${({ theme }) => theme.grayColor.gray300};
    position: absolute;
    transition: width 0.8s ease-in;
  }
  .line-primary {
    background: linear-gradient(
      90deg,
      ${({ theme }) => theme.color.primary} 95%,
      #00000000 100%
    );
    width: 0%;
  }
  .cir {
    width: 20%;
    padding-top: 20%;
    background-color: ${({ theme }) => theme.grayColor.gray300};
    border-radius: 50%;
    z-index: 2;
    position: relative;
    transition: background-color 0.5s ease-in;
    transition-delay: 0.5s;
    &:first-child {
      background-color: ${({ theme }) => theme.color.primary};
    }
  }
  img {
    top: 20%;
    right: 20%;
    height: 60%;
    position: absolute;
  }
  &.step-2 {
    .line-primary {
      width: 50%;
    }
    .cir:nth-child(2) {
      background-color: ${({ theme }) => theme.color.primary};
    }
  }
  &.step-3 {
    .line-primary {
      width: 90%;
    }
    .cir {
      background-color: ${({ theme }) => theme.color.primary};
    }
  }
`

const lineAnimation = keyframes`

`

function Step(props) {
  if (props.step)
    return (
      <div>
        <h1 className="text-center">
          歡迎註冊
          <span className="text-primary">SARAcares</span>
        </h1>
        <Row className="mb-3">
          <Col xs={7} sm={5} md={4} lg={3} className="mx-auto">
            <StepIcons step={props.step} />
          </Col>
        </Row>
      </div>
    )
  else return <></>
}

export default Step
