import React, { Component } from 'react'
import { BrowserRouter, Switch, Route, Link } from 'react-router-dom'
import { Col, Row, Container } from 'react-bootstrap'
import MemberBox from '@/components/Member/common/MemberBox'
import themes from '@/components/Themes'
import { ThemeProvider } from 'styled-components'
import MemberList from '@/components/Member/common/MemberList'
import Index from '@/components/Member/Index'
import Edit from '@/components/Member/Edit'
import Bonus from '@/components/Member/Bonus'
import Notifications from '@/components/Member/Notifications'
import Share from '@/components/Member/Share'
import Policies from '@/components/Member/Policies/index'
import PolicyShow from '@/components/Member/Policies/PolicyShow'
import MemberContext from '@/components/_context/MemberContext'

const withProps = (Component, props) => {
  return (matchProps) => {
    return <Component {...props} {...matchProps}></Component>
  }
}

class MemberLayout extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ...props,
      setContext: (data) => {
        this.setContext(data)
      },
    }
  }

  setContext(data) {
    console.log({ data })
    this.setState({ ...data })
  }

  render() {
    return (
      <ThemeProvider theme={themes}>
        <MemberContext.Provider
          value={{ ...this.state, setContext: this.state.setContext }}
        >
          <BrowserRouter>
            <Switch>
              <Route path="/member/" exact component={Index}></Route>
              <Route path="/member/policies/:id" component={PolicyShow} />

              <Container className="pt-3 pt-md-5">
                <Row>
                  <Col sm={3} className="d-none d-md-block">
                    <MemberBox
                      memberName={this.props.currentMember.name}
                      gender={this.props.currentMember.gender}
                    />
                    <MemberList />
                  </Col>
                  <Col sm={12} md={9}>
                    <Switch>
                      <Route path="/member/policies" component={Policies} />
                      <Route path="/member/edit" component={Edit} />
                      <Route path="/members/edit" component={Edit} />
                      <Route path="/member/shares" component={Share} />
                      <Route
                        path="/member/notifications"
                        component={withProps(Notifications, {
                          tab: 'notifications',
                        })}
                      />
                      <Route
                        path="/member/customer_questions/:id"
                        component={withProps(Notifications, {
                          tab: 'question',
                        })}
                      />
                      <Route
                        path="/member/customer_questions"
                        component={withProps(Notifications, {
                          tab: 'question',
                        })}
                      />
                      <Route path="/member/bonus" component={Bonus} />
                    </Switch>
                  </Col>
                </Row>
              </Container>
            </Switch>
          </BrowserRouter>
        </MemberContext.Provider>
      </ThemeProvider>
    )
  }
}

export default MemberLayout
