import React, { useState, useEffect } from 'react'
import { Form, Col, Row, InputGroup } from 'react-bootstrap'
import InputMask from 'react-input-mask'
import Flex from './Flex'
import styled from 'styled-components'
import { string } from 'prop-types'
import _ from 'lodash'
import { FormFeedback } from '@/components/_common/Inputs'
import { ErrorMessage, Field } from 'formik'

export const Input = (props) => {
  let {
    label,
    value,
    name,
    type,
    onChange,
    placeholder,
    isInvalid,
    disabled,
    errors,
  } = props
  return (
    <Form.Group controlId={name}>
      <Form.Row>
        <Form.Label column md={3}>
          {label}
        </Form.Label>
        <Col md={9}>
          {props.mask ? (
            <InputMask
              beforeMaskedValueChange={props.beforeMaskedValueChange}
              mask={props.mask}
              value={value}
              onChange={onChange}
              maskPlaceholder={props.maskPlaceholder}
              maskChar={props.maskChar}
              alwaysShowMask={props.alwaysShowMask}
            >
              {() => (
                <Form.Control
                  name={name}
                  type={type}
                  placeholder={placeholder}
                  disabled={disabled}
                  isInvalid={!!errors}
                  style={{ imeMode: 'disabled' }}
                />
              )}
            </InputMask>
          ) : (
            <Form.Control
              value={value}
              onChange={onChange}
              name={name}
              type={type}
              placeholder={placeholder}
              disabled={disabled}
              isInvalid={!!errors}
            />
          )}

          {errors && (
            <Form.Control.Feedback type="invalid">
              {errors}
            </Form.Control.Feedback>
          )}
        </Col>
      </Form.Row>
    </Form.Group>
  )
}

const Group = styled.div`
  position: relative;
`
const InputButton = styled.a`
  position: absolute;
  height: 20px;
  width: 20px;
  top: calc(50% - 10px);
  right: 10px;
  background: none;
  border: none;
  padding: 0%;
  display: grid;
`
const Eye = () => {
  return (
    <svg
      width="20"
      height="20"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16.43238 10.471"
      style={{ width: '100%' }}
    >
      <g>
        <g>
          <g>
            <path
              d="M16.33018,4.916c-.147-.2-3.644-4.916-8.111-4.916S.25518,4.716.10418,4.916a.542.542,0,0,0,0,.639c.147.2,3.644,4.916,8.111,4.916s7.964-4.716,8.111-4.916A.542.542,0,0,0,16.33018,4.916Zm-8.111,4.472c-3.29,0-6.14-3.13-6.983-4.153.842-1.024,3.686-4.152,6.983-4.152s6.139,3.129,6.983,4.153c-.842,1.029-3.686,4.153-6.983,4.153Z"
              style={{ fill: '#adb5bd' }}
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M8.21918,1.986a3.25,3.25,0,1,0,3.25,3.25A3.25,3.25,0,0,0,8.21918,1.986Zm0,5.417a2.167,2.167,0,1,1,2.167-2.167A2.167,2.167,0,0,1,8.21918,7.403Z"
              style={{ fill: '#adb5bd' }}
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

const EyeClose = () => {
  return (
    <svg
      width="20"
      height="20"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 15.51431 7.0456"
      style={{ width: '100%' }}
    >
      <path
        d="M15.35888,4.21259a.437.437,0,0,1-.615-.053l-1.145-1.36a7.80908,7.80908,0,0,1-1.91,1.276l.653,1.582a.437.437,0,1,1-.80374.34334l-.00426-.01034-.653-1.583a9.53519,9.53519,0,0,1-2.8.51v1.691a.437.437,0,1,1-.874,0h0v-1.691a9.634,9.634,0,0,1-2.586-.439l-.624,1.512a.437.437,0,0,1-.808-.333l.614-1.488a7.829,7.829,0,0,1-1.976-1.246l-1.04,1.235a.437.437,0,0,1-.668-.563l1.079-1.282a6.50788,6.50788,0,0,1-1.147-1.67.437.437,0,1,1,.8-.345,5.82292,5.82292,0,0,0,1.185,1.63c.011.008.023.014.034.023a.437.437,0,0,1,.073.081,7.01484,7.01484,0,0,0,2.355,1.476.12071.12071,0,0,1,.041.012l.015.008a8.75884,8.75884,0,0,0,3.085.523c5.116,0,6.773-3.717,6.789-3.755a.437.437,0,1,1,.8.345,6.37624,6.37624,0,0,1-1.02,1.528l1.2,1.423a.437.437,0,0,1-.04477.61639Z"
        style={{ fill: '#adb5bd' }}
      />
    </svg>
  )
}
export const ConfirmInput = (props) => {
  let {
    label,
    value,
    name,
    type,
    onChange,
    placeholder,
    isInvalid,
    disabled,
    errors,
  } = props
  let [isShow, setIsShow] = useState(false)
  return (
    <Form.Group controlId={name}>
      <Form.Row>
        <Form.Label column md={props.fullSize ? 12 : 3}>
          {label}
        </Form.Label>
        <Col md={props.fullSize ? 12 : 9}>
          <Group>
            <Field
              as={Form.Control}
              onChange={onChange}
              name={name}
              type={isShow ? 'text' : type}
              placeholder={placeholder}
              disabled={disabled}
              style={{ imeMode: 'disabled' }}
            />
            <InputButton onClick={() => setIsShow(!isShow)}>
              {isShow ? <EyeClose /> : <Eye />}
            </InputButton>
          </Group>
          <ErrorMessage name={name} component={FormFeedback} />
        </Col>
      </Form.Row>
    </Form.Group>
  )
}

const PasswordValid = (props) => {
  return (
    <span className={props.valid ? 'text-primary mr-2' : 'text-gray-500 mr-2'}>
      <i className="fas fa-check-circle"></i>
      {props.children}
    </span>
  )
}
export const PasswordInput = (props) => {
  let {
    label,
    value,
    name,
    type,
    onChange,
    placeholder,
    isInvalid,
    disabled,
    errors,
  } = props
  let [isShow, setIsShow] = useState(false)
  const [passwordValid, setPasswordValid] = useState({
    upperCase: false,
    lowerCase: false,
    number: false,
    length: false,
  })
  useEffect(() => {
    let valid = {
      upperCase: false,
      lowerCase: false,
      number: false,
      length: false,
    }
    for (let i = 0; i <= value.length - 1; i++) {
      let char = value.charAt(i)
      // console.log(
      //   char,
      //   char.toUpperCase(),
      //   char.toLowerCase(),
      //   !isNaN(char * 1),
      // )
      console.log(char)
      if (isNaN(char * 1) && char.match(/[A-Z]/)) valid.upperCase = true
      if (isNaN(char * 1) && char.match(/[a-z]/)) valid.lowerCase = true
      if (!isNaN(char * 1)) valid.number = true
    }
    if (value.length >= 6) valid.length = true
    if (!_.isEqual(passwordValid, valid)) setPasswordValid(valid)
  })

  return (
    <Form.Group controlId={name}>
      <Form.Row>
        <Form.Label column md={props.fullSize ? 12 : 3}>
          {label}
        </Form.Label>
        <Col md={props.fullSize ? 12 : 9}>
          <Group>
            <Form.Control
              value={value}
              onChange={onChange}
              name={name}
              type={isShow ? 'text' : type}
              placeholder={placeholder}
              disabled={disabled}
              isInvalid={!!errors}
              autoComplete="new-password"
            />
            <InputButton onClick={() => setIsShow(!isShow)}>
              {isShow ? <EyeClose /> : <Eye />}
            </InputButton>
          </Group>

          <PasswordValid valid={passwordValid.upperCase}>
            英文大寫
          </PasswordValid>
          <PasswordValid valid={passwordValid.lowerCase}>
            英文小寫
          </PasswordValid>
          <PasswordValid valid={passwordValid.number}>包含數字</PasswordValid>
          <PasswordValid valid={passwordValid.length}>六個字以上</PasswordValid>
        </Col>
      </Form.Row>
    </Form.Group>
  )
}

export const MyInputGroup = (props) => {
  let { label, name, children } = props
  return (
    <Form.Group controlId={name}>
      <Form.Row>
        <Form.Label column md={3}>
          {label}
        </Form.Label>
        <Col md={9}>{children}</Col>
      </Form.Row>
    </Form.Group>
  )
}

export const Selector = (props) => {
  let { value, options, name, onChange, idKeyName = 'id', isInvalid } = props
  let filtedOptions = []
  return (
    <Form.Control
      value={value}
      name={name}
      onChange={onChange}
      as="select"
      isInvalid={isInvalid}
    >
      {options ?
        options.map((o) => (
          <option value={o[idKeyName]} key={o[idKeyName]}>
            {o.name}
          </option>
        )) : props.children}
    </Form.Control>
  )
}
