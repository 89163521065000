import React, { Component } from 'react'
import { MemberCard as Card, Flex, NavItem, Title } from '../../../common/index'
import { SvgIcon } from '@/components/_common/SvgIcon'
import { Form, Button } from 'react-bootstrap'
import styled from 'styled-components'
import MemberContext from '@/components/_context/MemberContext'
import axios from 'axios'
import Message from './Message'
import autosize from 'autosize'
import { over } from 'lodash'
import { Link } from 'react-router-dom'
import PulseLoader from 'react-spinners/PulseLoader'
import DefaultTheme from '@/components/Themes'
import { Scrollbars } from 'react-custom-scrollbars'

const Api = (id) => `/member/customer_questions/${id}.json`
const ReplyApi = (id) => `/member/customer_questions/${id}/question_replies`

const TitleWithLine = styled.p`
  border: 2px solid ${({ theme }) => theme.color.primary};
  border-style: none none none solid;
  line-height: 22px;
  padding: 0 0 0 ${({ theme }) => theme.spacers[2]};
`
const ChatBox = styled.div`
  border-radius: ${({ theme }) => theme.borderRadius};
  background-color: ${({ theme }) => theme.grayColor.gray100};
  padding: ${({ theme }) => theme.spacers[4]};
  @media (max-width: 576px) {
    padding: 0;
    background-color: white;
  }
`
const MsgWindow = styled.div`
  height: 500px;
  max-height: 100vh;
  /* overflow: scroll; */
  padding: ${({ theme }) => theme.spacers[1]};
  margin-bottom: ${({ theme }) => theme.spacers[2]};
`
class InputBox extends Component {
  constructor(props) {
    super(props)
    this.ref = React.createRef()
    this.style = {
      overflowY: 'scroll',
      resize: 'none',
      width: 'auto',
    }
  }

  componentDidUpdate() {
    autosize(this.ref.current)
  }

  render() {
    return (
      <Form.Control
        as="textarea"
        {...this.props}
        type="text"
        styled={this.style}
        rows={1}
        ref={this.ref}
      />
    )
  }
}

export class Chat extends Component {
  constructor(props) {
    super(props)
    this.state = {
      question_replies: [],
      customer_question: {
        type: '',
        sendMsg: '',
      },
      isLoading: true,
    }
    this.msgWindow = React.createRef()
  }
  componentDidMount() {
    let _this = this
    axios.get(Api(this.props.match.params.id)).then((response) => {
      console.log(response.data)
      _this.setState({ ...response.data, isLoading: false })
      _this.msgWindow.current.scrollToBottom()
    })
  }

  componentDidUpdate() { }

  inputChangeHandler(e) {
    this.setState({
      sendMsg: e.target.value,
    })
  }

  inputSubmitHandler(e) {
    if ((e.keyCode && e.keyCode != 13) || e.shiftKey) {
      return
    }
    e.preventDefault()
    this.setState({ isLoading: true })
    let formData = new FormData()

    formData.append('utf8', '✓')
    formData.append(
      'authenticity_token',
      document.querySelector("meta[name='csrf-token']").getAttribute('content'),
    )
    formData.append('question_reply[content]', this.state.sendMsg)
    let _this = this
    axios.post(ReplyApi(this.props.match.params.id), formData).then((response) => {
      _this.setState({
        question_replies: [
          ...this.state.question_replies,
          response.data.question_reply,
        ],
        isLoading: false,
      })
      _this.msgWindow.current.scrollToBottom()
    })

    this.setState({ sendMsg: '' })
  }

  render() {
    return (
      <Card>
        <div className="mt-3">
          <Flex className="my-3">
            <Link to="/member/customer_questions">
              <i className="fas fa-angle-left mr-2"></i>返回列表
            </Link>
            <p className="m-0 ml-auto">{`問題類別：${this.state.customer_question.type}`}</p>
          </Flex>
          <ChatBox>
            {/* <MsgWindow > */}
            <Scrollbars
              autoHide={true}
              style={{ height: '500px', maxHeight: '100vh' }}
              ref={this.msgWindow}
            >
              {this.state.question_replies.map((reply) => (
                <Message content={reply} />
              ))}
              {this.state.isLoading ? (
                <div className="text-center">
                  <PulseLoader color={DefaultTheme.color.primary} size={10} />
                </div>
              ) : (
                ''
              )}
            </Scrollbars>
            {/* </MsgWindow> */}
            <Form onSubmit={this.inputSubmitHandler.bind(this)} className="w-100">
              <Flex align="flex-end">
                <InputBox
                  value={this.state.sendMsg}
                  onChange={this.inputChangeHandler.bind(this)}
                  onKeyDown={this.inputSubmitHandler.bind(this)}
                />
                <Button
                  type="submit"
                  className="ml-2"
                  style={{ flex: 'none' }}
                  disabled={this.state.sendMsg == ''}
                >
                  送出
                </Button>
              </Flex>
            </Form>
          </ChatBox>
        </div>
      </Card>
    )
  }
}

export default Chat
