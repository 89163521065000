import React, { Component } from 'react'
import { Formik, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { FormFeedback, FormControl } from '@/components/_common/Inputs'
import { Row, Col, Form, Button, Spinner } from 'react-bootstrap'
import Axios from 'axios'
import Counter from '../../components/Counter'
import createFormData from '@/components/_common/createFormData'
import MobileForm from './MobileForm'
import { Provider, withContext } from './MobileContext'
import OTPForm from './OTPForm'
import Context from '../../Context'
import { MobileAuthError } from '../../components/ErrorMessage'

export class MobileAuthentication extends Component {
  constructor(props) {
    super(props)
    console.log(props)
    this.counter = React.createRef()
    this.state = {
      mobileAuthentication: {
        id: null,
        checkCode: null,
        isAuthenticated: false,
      },
      mobileSetting: {
        mobile_phone: '',
        isSetting: false,
      },
      isLoading: false,
      isBtnDisabled: false,
      mobileSubmitHandler: this.mobileSubmitHandler.bind(this),
      authenticationSubmitHandelr: this.authenticationSubmitHandelr.bind(this),
      counter: this.counter,
      goNextStep: this.goNextStep.bind(this),
    }
    //this.goNexStep = this.goNexStep.bind(this)
    window.textCounter = this
  }

  componentDidMount() {
    this.setState({
      ...this.state,
      mobileSetting: {
        mobile_phone: this.context.mobile_phone,
        isSetting: false,
      },
    })
  }

  mobileSubmitHandler(values, formikBag) {
    if (
      this.state.mobileSetting.isSetting &&
      !this.counter.current.isCountDownToZero()
    ) {
      alert(MobileAuthError.counter)
      return
    }

    let _this = this
    this.setState({ isLoading: true })
    let formData = createFormData(values)

    Axios.post('/member/mobile_authentications.json', formData, {
      header: { 'Content-Type': 'text/html; charset=utf-8' },
    }).then(({ data }) => {
      console.log(data)
      _this.setState({ isLoading: false })
      if (data.code == 0) {
        _this.setState({
          mobileAuthentication: {
            ..._this.state.mobileAuthentication,
            id: data.id,
            checkCode: data.prefix,
          },
          mobileSetting: {
            mobile_phone: values.member.mobile_phone,
            isSetting: true,
          },
          isBtnDisabled: true,
          isLoading: false,
        })
        _this.counter.current.resetTimer()
        _this.counter.current.startTimer()
      } else {
        formikBag.setFieldError('member[mobile_phone]', data.message)
      }
      console.log(data)
    })
  }

  authenticationSubmitHandelr(values, formikBag) {
    let _this = this
    let formData = createFormData(values)
    this.setState({ isLoading: true })
    Axios.put(
      `/member/mobile_authentications/${this.state.authenticationId}.json`,
      formData,
      {
        header: { 'Content-Type': 'text/html; charset=utf-8' },
      },
    ).then(({ data }) => {
      _this.setState({ isLoading: false })
      if (data.code == 0) {
        _this.setState({
          mobileAuthentication: {
            ...this.state.mobileAuthentication,
            isAuthenticated: true,
          },
          isBtnDisabled: false,
          verified: data.verified
        })
      } else {
        formikBag.setFieldError(
          'mobile_authentication[verify_code]',
          data.message,
        )
      }
    })
  }

  goNextStep() {
    if (this.state.verified) {
      return window.location.reload();
    }
    this.props.history.push('/members/confirmation/new')
  }

  render() {
    return (
      <>
        {this.state.mobileSetting.mobile_phone &&
          !this.state.mobileSetting.isSetting && (
            <p className="text-error text-center">
              {MobileAuthError.didNotCompleted}
            </p>
          )}
        <Row>
          <Col xs={12} sm={9} className="mx-auto">
            <Provider value={this.state}>
              <MobileForm />
              {this.state.mobileSetting.isSetting && <OTPForm />}
            </Provider>
          </Col>
        </Row>
      </>
    )
  }
}

MobileAuthentication.contextType = Context

export default MobileAuthentication
