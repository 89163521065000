import { useFormikContext } from 'formik';
import React from 'react'
import { Form, Button, Col, Row } from 'react-bootstrap'
import ErrorMessage from '../components/ErrorMessage';

function RequirementForm({ formik }) {
  return (
    <>
      <div className="p-4 mb-3 border">
        <Form.Group controlId="demand_services">
          <Form.Check
            id="demand_services-1"
            className="my-2"
            label="投保問題"
            type="checkbox"
            name="inquiry[demand_services]"
            value="insured_question"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {/* <Form.Check
            id="demand_services-2"
            className="my-2"
            label="保單健檢"
            type="checkbox"
            name="inquiry[demand_services]"
            value="policy_check"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          /> */}
          <Form.Check
            id="demand_services-3"
            className="my-2"
            label="理賠問題"
            type="checkbox"
            name="inquiry[demand_services]"
            value="claim_question"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <Form.Check
            id="demand_services-4"
            className="my-2"
            label="其他"
            type="checkbox"
            name="inquiry[demand_services]"
            value="asset_allocation"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <ErrorMessage formik={formik} name="demand_services" />
        </Form.Group>
      </div>
    </>
  )
}

export default RequirementForm