import yup from './yup';

const confirmSchema = yup.object().shape({
  policy: yup.object().shape({
    is_policy_content_read: yup.bool().nullable().oneOf([true]),
    is_kyc_read: yup.bool().nullable().oneOf([true]),
    is_contract_read: yup.bool().nullable().oneOf([true]),
    is_analysis_read: yup.bool().nullable().oneOf([true]),
    is_personal_capital_read: yup.bool().nullable().oneOf([true]),
  }),
});

export default confirmSchema;
