import React, { Component } from 'react'
import { Col, Row, Button } from 'react-bootstrap'
import styled from 'styled-components'
import { MyPopup } from '@/components/_common/StyledPopup'
import Pdf from '../../../../images/product_lines/pdf.svg'

let ColumnHead = styled.div`
  padding: 20px 10px 12px 10px;
  height: 100%;
  display: flex;
  p {
    margin: 0;
  }
`

let Column = styled.div`
  border-bottom: 1px dashed #dee2e6 !important;
  padding: 10px;
  height: 100%;
  p {
    margin: 0;
  }
  p.small {
    color: ${({ theme }) => theme.grayColor.gray500};
    font-weight: 100;
  }
`

const TitleWithLine = styled.h5`
  border: 4px solid ${({ theme }) => theme.color.primary};
  border-style: none none none solid;
  line-height: 28px;
  padding: 0 0 0 ${({ theme }) => theme.spacers[2]};
  margin: 0;
`

export const Box = styled.div`
  max-height: 350px;
  overflow: scroll;
  padding: 0 5px;
`

const Header = (props) => {
  return (
    <Col xs={12}>
      <ColumnHead>
        <TitleWithLine>{props.product.name}</TitleWithLine>
      </ColumnHead>
    </Col>
  )
}

const Product = (props) => {
  return (
    <Col xs={12} md={6}>
      <Column>
        <div>
          <div className="float-left">{props.product.name}</div>
          <div className="float-right">{props.product.amount}</div>
          <div className="clearfix" />
          <p className="small">{props.product.description}</p>
        </div>
      </Column>
    </Col>
  )
}

const _Term = styled.a`
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: ${({ theme }) => theme.borderRadius};
  background-color: ${({ theme }) => theme.grayColor.gray300};
  overflow: hidden;
  margin-bottom: ${({ theme }) => theme.spacers[3]};
  p {
    margin: 0;
    color: ${({ theme }) => theme.grayColor.gray600};
  }
  &:hover {
    p {
      color: ${({ theme }) => theme.grayColor.gray600};
    }
    box-shadow: ${({ theme }) => theme.shadow.sm};
    background-color: ${({ theme }) => theme.grayColor.gray400};
    & > div:last-child {
      background-color: ${({ theme }) => theme.grayColor.gray300};
    }
  }
  & > div:last-child {
    flex: auto;
    background-color: ${({ theme }) => theme.grayColor.gray200};
    justify-content: flex-start;
    div {
      width: 25px;
      margin-left: auto;
    }
    svg {
      width: 25px;
    }
  }
  & > div {
    display: flex;
    height: 100%;
    min-width: 80px;
    justify-content: center;
    padding: ${({ theme }) => theme.spacers[2]};
    align-items: center;
  }
`

export const Term = (props) => {
  let { term, index } = props
  return (
    <_Term href={term.file.url} target="_blank">
      <div>
        <p>條款{index + 1}</p>
      </div>
      <div>
        <p>{term.name}</p>
        <div>
          <Pdf />
        </div>
      </div>
    </_Term>
  )
}

export const Terms = (props) => {
  console.log(props)
  return (
    <MyPopup
      trigger={
        <Button variant="link">
          <u>條款、備註及注意事項</u>
          <i className="fa fa-download"></i>
        </Button>
      }
      onOpen={props.onOpen}
      modal
    >
      <h4 className="text-center">保單條款</h4>
      <Box>
        {props.terms.map((term, index) => (
          <Term term={term} index={index}></Term>
        ))}
      </Box>
    </MyPopup>
  )
}
Terms.defaultProps = { terms: [] }

export class Content extends Component {
  render() {
    return (
      <Row className="mx-0 mx-md-3">
        {this.props.products.map((product, i) => {
          if (product.amount) {
            return <Product key={i} product={product}></Product>
          } else {
            return <Header key={i} product={product}></Header>
          }
        })}
      </Row>
    )
  }
}

Content.defaultProps = {
  products: [],
}

export default Content
