import yup from './yup';
import insuredSchema from './insuredSchema';
import kycSchema from './kycSchema';
import getInformationSchema from './type';
import { debounce } from "lodash";
import moment from 'moment'

const validationSchema = (type) => yup.object().shape({
  policy: yup.object().shape({
    insured: insuredSchema,
    kyc: kycSchema,
    information: getInformationSchema(type),
    insured_from: yup.date().when('insured_from2', (insured_from2, schema) => {
      console.log(insured_from2)
      return !insured_from2
        ? schema.min(moment().startOf('day').toDate(), '不可選擇過去時間').required('請輸入日期').default(moment().toDate())
        : schema.nullable()
    }),
  }),
});


export default validationSchema;
