import React, { Component } from 'react'
import styled from 'styled-components'
import axios from 'axios'
import InfiniteScroll from 'react-infinite-scroller'
import Loading from '@/components/_common/Loading'

const Api = '/member/notifications.json'
const readApi = '/member/read_notification'

const Noti = styled.div`
  background-color: ${({ theme }) => theme.grayColor.gray100};
  margin: ${({ theme }) => theme.spacers[4]} ${({ theme }) => theme.spacers[3]};
  padding: ${({ theme }) => theme.spacers[3]} ${({ theme }) => theme.spacers[4]};
  position: relative;
  &.read {
    background-color: transparent;
  }
  &:before {
    content: '';
    border: 1px solid ${({ theme }) => theme.grayColor.gray300};
    border-style: solid none none none;
    width: 100%;
    bottom: calc(-${({ theme }) => theme.spacers[4]} / 2);
    position: absolute;
    left: 0;
  }
`

const NotiWithLink = styled.div`
  background-color: ${({ theme }) => theme.grayColor.gray100};
  color: ${({ theme }) => theme.grayColor.gray700};
  margin: ${({ theme }) => theme.spacers[4]} ${({ theme }) => theme.spacers[3]};
  padding: ${({ theme }) => theme.spacers[3]} ${({ theme }) => theme.spacers[5]}
    ${({ theme }) => theme.spacers[3]} ${({ theme }) => theme.spacers[4]};
  transition: all 0.5s ease;
  position: relative;
    cursor: pointer;
  &.read {
    background-color: transparent;
  }
  &:hover {
    background-color: ${({ theme }) => theme.grayColor.gray200};
    &:after {
      height: 15px;
      width: 15px;
      right: 18px;
      top: calc(50% - 7.5px);
    }
  }
  &:before {
    content: '';
    border: 1px solid ${({ theme }) => theme.grayColor.gray300};
    border-style: solid none none none;
    width: 100%;
    bottom: calc(-${({ theme }) => theme.spacers[4]} / 2);
    position: absolute;
    left: 0;
  }
  &:after {
    content: '';
    top: calc(50% - 5px);
    height: 10px;
    width: 10px;
    transform: rotate(45deg);
    border: 2px solid ${({ theme }) => theme.grayColor.gray600};
    border-style: solid solid none none;
    position: absolute;
    right: 20px;
    transition: all 0.5s ease;
  }
`

export class Notification extends Component {
  constructor(props) {
    super(props)

    this.state = {
      list: [],
      currentPage: 0,
      hasMore: true,
      loading: true,
    }
  }

  componentDidMount() {
    this.loadingNotification()
  }

  loadingNotification(aaa) {
    axios
      .get(Api, {
        params: { page: this.state.currentPage + 1 },
        headers: { 'Content-Type': 'application/json' },
      })
      .then((response) => {
        let { list, current_page, total_pages } = response.data
        this.setState({
          list: [...this.state.list, ...list],
          loading: false,
          currentPage: current_page,
          hasMore: current_page < total_pages,
          totalPage: total_pages,
        })
        this.sendReadNoti()
      })
  }

  sendReadNoti() {
    let id = []
    this.state.list.forEach((n) => id.push(n.id))
    axios
      .post(readApi, {
        ids: id,
        authenticity_token: document
          .querySelector("meta[name='csrf-token']")
          .getAttribute('content'),
      })
      .then((response) => {
        console.log(response)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  renderLoading() {
    return (
      <>
        <Noti>
          <Loading height={27} width={250} className="mb-2"></Loading>
          <Loading height={21} width={120}></Loading>
        </Noti>
        <Noti>
          <Loading height={27} width={220} className="mb-2"></Loading>
          <Loading height={21} width={120}></Loading>
        </Noti>
        <Noti>
          <Loading height={27} width={220} className="mb-2"></Loading>
          <Loading height={21} width={120}></Loading>
        </Noti>
        <Noti>
          <Loading height={27} width={220} className="mb-2"></Loading>
          <Loading height={21} width={120}></Loading>
        </Noti>
      </>
    )
  }

  render() {
    return (
      <>
        <InfiniteScroll
          pageStart={1}
          loadMore={this.loadingNotification.bind(this)}
          hasMore={this.state.hasMore}
          loader={this.renderLoading()}
          initialLoad={false}
        >
          {this.state.list.map((n, index) => {
            if (n.notification_template && n.notification_template.title_link) {
              return (
                <NotiWithLink
                  key={n.id}
                  className={n.is_read ? 'read' : ''}
                  onClick={() =>
                    (window.location = n.notification_template.title_link)
                  }
                >
                  <p className="mb-2">{n.content}</p>
                  <p className="small m-0">{n.created_at}</p>
                </NotiWithLink>
              )
            } else {
              return (
                <Noti key={n.id} className={n.is_read ? 'read' : ''}>
                  <p className="mb-2">{n.content}</p>
                  <p className="small m-0">{n.created_at}</p>
                </Noti>
              )
            }
          })}
        </InfiniteScroll>
      </>
    )
  }
}

export default Notification
