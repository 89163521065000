import React, { Component } from 'react'
const Point = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 253.1 45.64"
      width="200px"
      height="34px"
      className={props.className}
    >
      <g>
        <path
          d="M19.65,1.68H233.1c10.21,0,18.5,7.84,18.5,17.52h0c0,9.67-8.28,17.52-18.5,17.52H19.65Z"
          transform="translate(0 0)"
          style={{ fill: '#fff', stroke: '#62cae3', strokeWidth: '3px' }}
        />
        <g style={{ isolation: 'isolate' }}>
          <text
            transform="translate(47.47 26.8)"
            style={{
              isolation: 'isolate',
              fontSize: '18px',
              fill: '#62cae3',
              fontWeight: 400,
            }}
          >
            剩餘莎拉
            <tspan x="72" y="0" style={{ letterSpacing: '-0.25em' }}>
              幣：
            </tspan>
            <tspan x="99" y="0">
              {props.children}點
            </tspan>
          </text>
        </g>
        <ellipse
          cx="19.39"
          cy="19.2"
          rx="19.39"
          ry="19.2"
          style={{ fill: '#62cae3' }}
        />
        <ellipse
          cx="19.39"
          cy="19.2"
          rx="16.29"
          ry="16.12"
          style={{
            fill: '#62cae3',
            stroke: '#fff',
            strokeMiterlimit: '10',
            strokeWidth: '1.2480000257492065px',
          }}
        />
        <path
          d="M22.1,27.16a5,5,0,0,0,2.83-6.51h0a5,5,0,0,0-4.85-3.18,1.63,1.63,0,0,1-1.69-1.56v-.06A1.65,1.65,0,0,1,20,14.15a2.74,2.74,0,0,1,1.86.83,1.66,1.66,0,0,0,2.33-.15l0,0a1.68,1.68,0,0,0-.13-2.31A6.12,6.12,0,0,0,21.53,11a5.51,5.51,0,0,0-3.36.12,5,5,0,0,0-2.83,6.51h0a5,5,0,0,0,4.85,3.18,1.67,1.67,0,0,1,1.74,1.59h0v0a1.66,1.66,0,0,1-1.59,1.73h0A3,3,0,0,1,18.1,23a1.68,1.68,0,0,0-2.49,2.26h0a7.13,7.13,0,0,0,3.14,2.06A5.27,5.27,0,0,0,22.1,27.16Z"
          transform="translate(0 0)"
          style={{ fill: '#fff' }}
        />
        <path
          d="M19.66,6.22h-.1a1.67,1.67,0,0,0-1.62,1.71h0V8a1.66,1.66,0,0,0,1.71,1.61h.13a1.65,1.65,0,0,0,1.61-1.7v0a1.66,1.66,0,0,0-1.7-1.62Z"
          transform="translate(0 0)"
          style={{ fill: '#fff' }}
        />
        <path
          d="M20.46,28.57h-.1a1.66,1.66,0,0,0-1.62,1.71h0v0a1.67,1.67,0,0,0,1.71,1.62h.12a1.66,1.66,0,0,0,1.62-1.71h0v0a1.66,1.66,0,0,0-1.71-1.61h0Z"
          transform="translate(0 0)"
          style={{ fill: '#fff' }}
        />
        <circle cx="227.69" cy="19.2" r="10" style={{ fill: '#62cae3' }} />
        <path
          d="M226.81,20h-4.06V18.36h4.06V14h1.77v4.32h4.06V20h-4.06v4.32h-1.77Z"
          transform="translate(0 0)"
          style={{ fill: '#fff' }}
        />
      </g>
    </svg>
  )
}

export default Point
