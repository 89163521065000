import React from 'react'
import styled from 'styled-components'

export const SvgIcon = (props) => {
  let width,
    height = 40
  let viewBox = [0, 0, 48, 48]
  return (
    <svg
      width={props.width || props.height || width}
      height={props.height || props.width || height}
      viewBox={props.viewBox ? props.viewBox.join(' ') : viewBox.join(' ')}
      fill={props.fill || '#1c4151'}
    >
      <use href={props.href} />
    </svg>
  )
}

export const BaseSvgButton = (props) => {
  let width,
    height = 40
  let viewBox = [0, 0, 48, 48]
  return (
    <a
      href={props.href}
      target={props.target}
      rel={props.target ? 'noopener noreferrer' : ''}
      className={props.className}
      {...props}
    >
      <svg
        width={(props.width || width) + 'px'}
        height={(props.width || height) + 'px'}
        viewBox={props.viewBox ? props.viewBox.join(' ') : viewBox.join(' ')}
      >
        <use
          className="default"
          href={props.default || `${props.iconhref}-default`}
        />
        <use
          className="hover"
          href={props.hover || `${props.iconhref}-hover`}
        />
        <use
          className="active"
          href={props.active || `${props.iconhref}-active`}
        />
      </svg>
    </a>
  )
}

export const SvgButton = styled(BaseSvgButton)`
  display: flex;
  & .default {
    opacity: 1;
    transition: all 0.5s ease;
  }
  & .hover {
    opacity: 0;
    transition: all 0.5s ease;
  }
  & .active {
    opacity: 0;
    transition: all 0.5s ease;
  }
  &:hover .default {
    opacity: 0;
  }
  &:hover .hover {
    opacity: 1;
  }
  &:active .active {
    opacity: 1;
  }
`
