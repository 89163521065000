import React, { Component } from 'react'
import styled from 'styled-components'
import { MyPopup } from '@/components/_common/StyledPopup'
import { Button } from 'react-bootstrap'
import { Flex } from '../common/index'
import MemberContext from '@/components/_context/MemberContext'

export const AwardBox = styled.div`
  border-radius: ${({ theme }) => theme.borderRadius};
  background-color: ${({ theme }) => theme.grayColor.gray100};
  text-align: center;
  max-width: 80vw;
  min-width: 225px;
  margin-right: ${({ theme }) => theme.spacers[3]};
  padding: ${({ theme }) => theme.spacers[3]};
  display: flex;
  flex-direction: column;
  .img {
    width: 100%;
    background-image: url('${({ image }) => image}');
    height: 100px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  & > div {
    margin-top: auto;
    h6 {
      font-weight: ${({ theme }) => theme.fontWeight.normal};
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    & > div {
      display: flex;
      justify-content: center;
      align-items: center;
      & > p {
        color: ${({ theme }) => theme.color.secondary};
        margin: 0;
        margin-right: ${({ theme }) => theme.spacers[2]};
      }
    }
  }
  @media (max-width: 576px) {
    box-shadow: ${({ theme }) => theme.shadow.sm};
    margin-top: ${({ theme }) => theme.spacers[2]};
    margin-bottom: ${({ theme }) => theme.spacers[2]};
  }
`

export class Award extends Component {
  renderExchange(close) {
    return (
      <>
        <h4>莎拉幣兌換</h4>
        <p className="m-0">
          是否兌換 {this.context.currentExchange.exchangeObject.name}
        </p>
        <p>
          即將扣除莎拉幣{' '}
          <span className="text-secondary">
            {this.context.currentExchange.exchangeObject.price}
          </span>{' '}
          點
        </p>
        <Flex justify="center">
          <Button variant="outline-info" onClick={close} className="mr-2">
            取消
          </Button>
          <Button
            variant="primary"
            onClick={() => this.context.currentExchange.onExchange()}
            className="ml-2"
          >
            確認
          </Button>
        </Flex>
      </>
    )
  }

  renderExchangeSuccess(close) {
    return (
      <>
        <h4>莎拉幣兌換</h4>
        <p>
          已將 {this.context.currentExchange.exchangeObject.name}{' '}
          商品發送至您的手機
        </p>
        <Flex justify="center">
          <Button variant="primary" onClick={close}>
            關閉
          </Button>
        </Flex>
      </>
    )
  }

  renderExchangeFailed(close) {
    return (
      <>
        <h4>莎拉幣兌換</h4>
        <p className="m-0">
          很遺憾 兌換 {this.context.currentExchange.exchangeObject.name} 失敗
        </p>
        <p>請稍後再試</p>
        <Flex justify="center">
          <Button variant="primary" onClick={close}>
            關閉
          </Button>
        </Flex>
      </>
    )
  }

  render() {
    let { award } = this.props
    return (
      <AwardBox image={award.image.url}>
        <div className="img" />
        <div>
          <h6>{award.name}</h6>
          <div>
            <p>{award.price}元</p>
            {this.context.point >= award.price ? (
              <MyPopup
                trigger={<Button variant="primary">立即兌換</Button>}
                onOpen={() => this.context.willExchangeHandler('bouns', award)}
                modal
              >
                {(close) =>
                  !this.context.currentExchange.check
                    ? this.renderExchange(close)
                    : this.context.currentExchange.exchange
                    ? this.renderExchangeSuccess(close)
                    : this.renderExchangeFailed(close)
                }
              </MyPopup>
            ) : (
              <Button variant="primary" disabled>
                點數不足
              </Button>
            )}
          </div>
        </div>
      </AwardBox>
    )
  }
}
Award.contextType = MemberContext
export default Award
