import React from 'react'
import styled from 'styled-components'
import { Card, Nav } from 'react-bootstrap'

const Flex = styled.div`
  display: flex;
  ${({ center }) => (center ? 'align-items:center;' : '')};
  ${({ column }) => (column ? 'flex-direction: column;' : '')};
  ${({ justify }) => (justify ? `justify-content: ${justify};` : '')};
  ${({ align }) => (align ? `align-items: ${align}` : '')};
`

export default Flex
