import React from 'react'
import { Card, Button, Form, InputGroup } from 'react-bootstrap'
import SearchOptionContext from '@/components/_context/SearchOptionContext'
import styled from 'styled-components'
import { Formik } from 'formik'
import axios from 'axios'

const ComparePlan = styled.div`
  background-color: #fff;
  list-style: none;
  padding: 5px 35px 5px 8px;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 5px;
  position: relative;
  text-align: left;
`
const CloseBtn = styled.a`
  position: absolute;
  top: 8px;
  right: 12px;
  font-size: 20px;
  line-height: 20px;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
  font-family: 'Noto Sans TC', Arial, '微軟正黑體';
  font-weight: 400;
  :hover {
    transform: scale(1.2);
    opacity: 0.75;
  }
`
const Hr = styled.hr`
  border-color: #fff;
`

export const CompareBoxMobile = styled.a`
  ${(props) => (props.count == 0 ? 'display: none !important' : '')};
  background-color: #f6921f;
  box-shadow: none;
  border-radius: 0px;
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
  z-index: 99;
  text-align: center;
  height: 50px;
  font-size: 16pt;
  color: white !important;
  line-height: 50px;
  transition: all 0.5s sase;
`

class CompareBox extends React.Component {
  onCompareClick() {
    //document.getElementById('loading').style.display = 'block'
    let { plan_search_id } = this.context.planList.list[0]
    let { list } = this.context.compareList
    let compare = Object.keys(list).map((key) => {
      return { plan_id: parseInt(key), plan_search_id: plan_search_id }
    })
    let planParams = JSON.stringify(compare)
    let token = document
      .querySelector("meta[name='csrf-token']")
      .getAttribute('content')
    window.open(
      `/compare_plans?utf8=✓&authenticity_token=${token}&plans=${planParams}`,
      '_blank',
    )
  }
  render() {
    return (
      <SearchOptionContext.Consumer>
        {({ compareList }) => {
          let { list } = compareList
          return (
            <Card
              border="0"
              bg="primary-dark"
              text="white"
              className="text-center"
            >
              <Card.Body>
                <p className="m-0">{compareList.productName}共有</p>
                <p className="display-1 font-weight-bolder">
                  {compareList.count}
                </p>
                <p>推薦方案可選擇</p>
                <p className="small">
                  <i className="fas fa-check"></i>還可以選擇
                  {4 - Object.keys(list).length}種方案
                </p>
                <div>
                  {Object.keys(list).map((c) => {
                    console.log(list[c])
                    return (
                      <ComparePlan key={c}>
                        <CloseBtn
                          href="###"
                          onClick={() =>
                            compareList.onCompareClick({
                              target: { value: c },
                            })
                          }
                        >
                          <i className="fas fa-times"></i>
                        </CloseBtn>
                        <p className="text-primary-dark m-0">{list[c].name}</p>
                        <p className="text-secondary m-0">{list[c].subTitle}</p>
                      </ComparePlan>
                    )
                  })}
                  <Button
                    id="compareBtn"
                    variant="secondary"
                    block
                    disabled={Object.keys(list).length == 0}
                    onClick={(e) => this.onCompareClick()}
                  >
                    立即比較
                  </Button>
                </div>
                <Hr />
                <Formik
                  initialValues={{
                    email: '',
                  }}
                  onSubmit={(values, actions) => {
                    values.utf8 = '✓'
                    values['product_line_id'] = compareList.productLineId
                    values['event_name'] = '比較器'
                    values.authenticity_token = document
                      .querySelector("meta[name='csrf-token']")
                      .getAttribute('content')
                    console.log(values)
                    axios
                      .post('/subscribers', values, {
                        headers: {
                          'Content-Type': 'application/json',
                        },
                      })
                      .then((response) => {
                        console.log(response)
                        document.getElementById('subscribe_btn').click()
                      })
                      .catch(function (error) {
                        console.log(error)
                      })
                      .then(function () {
                        hideLoading()
                        actions.setSubmitting(false)
                      })
                  }}
                >
                  {(props) => (
                    <Form onSubmit={props.handleSubmit}>
                      <InputGroup>
                        <Form.Control
                          name="email"
                          type="email"
                          onChange={props.handleChange}
                          value={props.values.name}
                          placeholder="請輸入您的 E-mail"
                        ></Form.Control>
                        <InputGroup.Append>
                          <Button variant="primary" type="submit">
                            <i className="far fa-paper-plane"></i>
                          </Button>
                        </InputGroup.Append>
                      </InputGroup>
                    </Form>
                  )}
                </Formik>
              </Card.Body>
            </Card>
          )
        }}
      </SearchOptionContext.Consumer>
    )
  }
}
CompareBox.contextType = SearchOptionContext
export default CompareBox
