// 電子商務 曝光

var product_line

window.ga_search = (results) => {

    product_line = results.product_line.name;
    var plans = [];


    results.plans.forEach(function (_this) {
        plans.push({
            'name': _this.package.full_name,
            'id': _this.package_id,
            'price': Number(_this.total.replace(/[^0-9\.]+/g, "")),
            'brand': _this.insurance_company.name,
            'list': product_line
        })
    })


    dataLayer.push({
        'event': 'Search',
        'tag': product_line,
        'ecommerce': {
            'currencyCode': 'TWD', // 貨幣
            'impressions': plans
        }
    });

}

//按下方案詳情

window.ga_detail = ($productObj) => {


    dataLayer.push({
        'event': 'showDetail',
        'tag': $productObj.data('plan-name'),
        'ecommerce': {
            'detail': {
                'actionField': {
                    'list': product_line
                },
                'products': [{
                    'name': $productObj.data('plan-name'), // Name or ID is required.
                    'id': $productObj.data('plan-id'),
                    'price': Number($productObj.data('plan-price').replace(/[^0-9\.]+/g, "")),
                    'brand': $productObj.data('plan-brand'),
                }],

            },
        },
    })
    dataLayer.push({
        'event': 'Product Click',
        'tag': $productObj.data('plan-name'),
        'ecommerce': {
            'click': {
                'actionField': {
                    'list': product_line
                },
                'products': [{
                    'name': $productObj.data('plan-name'), // Name or ID is required.
                    'id': $productObj.data('plan-id'),
                    'price': Number($productObj.data('plan-price').replace(/[^0-9\.]+/g, "")),
                    'brand': $productObj.data('plan-brand'),
                }],
            }
        }
    });
}


///開始結帳

//1.按下買吧
var isClick = true;

window.ga_product_click = ($productObj) => {
    if (isClick) {
        isClick = false;

        dataLayer.push({
            'event': 'checkout',
            'tag': $productObj.data('plan-name'),
            'ecommerce': {
                'checkout': {
                    'actionField': {
                        'step': 1,
                        'option': '買吧第一頁'
                    },
                    'products': [{
                        'name': $productObj.data('plan-name'), // Name or ID is required.
                        'id': $productObj.data('plan-id'),
                        'price': Number($productObj.data('plan-price').replace(/[^0-9\.]+/g, "")),
                        'brand': $productObj.data('plan-brand')
                    }]
                },
            },
            'eventCallback': function () {
                document.location = $productObj.attr('href');
            }
        });

        setTimeout(function () {
            isClick = true;
        }, 1000); //一秒内不能重复点击
    }

}

//2.個資宣告
window.ga_check_personal_info = (product) => {

    dataLayer.push({
        'event': 'checkout',
        'tag': product.name,
        'ecommerce': {
            'checkout': {
                'actionField': {
                    'step': 2,
                    'option': '確認個資宣告'
                },
                'products': [{
                    'name': product.name,
                    'id': product.id,
                    'price': Number(product.price.replace(/[^0-9\.]+/g, "")),
                    'brand': product.brand,
                }]
            },
        },
    });
}

//3.填寫資料
window.ga_fill_info = (product) => {

    dataLayer.push({
        'event': 'checkout',
        'tag': product.name,
        'ecommerce': {
            'checkout': {
                'actionField': {
                    'step': 3,
                    'option': '填寫基本資料'
                },
                'products': [{
                    'name': product.name,
                    'id': product.id,
                    'price': Number(product.price.replace(/[^0-9\.]+/g, "")),
                    'brand': product.brand,
                }]
            },
        },
    });
}

//4.otp驗證
window.ga_check_insurance = (product) => {

    dataLayer.push({
        'event': 'checkout',
        'tag': product.name,
        'ecommerce': {
            'checkout': {
                'actionField': {
                    'step': 4,
                    'option': '確認保單'
                },
                'products': [{
                    'name': product.name,
                    'id': product.id,
                    'price': Number(product.price.replace(/[^0-9\.]+/g, "")),
                    'brand': product.brand,
                }]
            },
        },
    });
}

//5.otp驗證
window.ga_otp = (product) => {

    dataLayer.push({
        'event': 'checkout',
        'tag': product.name,
        'ecommerce': {
            'checkout': {
                'actionField': {
                    'step': 5,
                    'option': 'otp'
                },
                'products': [{
                    'name': product.name,
                    'id': product.id,
                    'price': Number(product.price.replace(/[^0-9\.]+/g, "")),
                    'brand': product.brand,
                }]
            },
        },
    });
}

window.ga_purchase = (data) => {
    dataLayer.push({
        'event': 'purchase',
        'tag': data.product.name,
        'ecommerce': {
            'purchase': {
                'actionField': {
                    'id': data.id, // Transaction ID. Required for purchases and refunds.
                    'revenue': Number(data.revenue), // Total transaction value (incl. tax and shipping)
                },
                'products': [{ // List of productFieldObjects.
                    'name': data.product.name,
                    'id': data.product.id,
                    'price': Number(data.product.price),
                    'brand': data.product.brand, // Optional fields may be omitted or set to empty string.
                    'quantity': 1,
                }]
            }
        }
    });
}

window.ga_promotion_view = (data) => {
    dataLayer.push({
        'event': 'promotionView',
        'ecommerce': {
            'promoView': {
                'promotions': data
            }
        }
    });
}