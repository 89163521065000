import yup from '../yup';

const travelSchema = yup.object().shape({
  activity_name: yup.string().default('-').nullable().required(),
  flight_date: yup.date().default(new Date()).typeError('格式錯誤 YYYY-MM-DD').required(),
  flight_number: yup.string().default('-').nullable().required(),
  mountain: yup.string().default('-').nullable().required(),
  need_english_insured_proof: yup.bool().default(false).nullable().required(),
  passport_english_name: yup.string().default('-').nullable().required('請提供護照上的英文姓名'),
  passport_number: yup.string().default('*********').length(9, '護照號碼應為九碼').nullable()
    .required(),
});

export default travelSchema;
