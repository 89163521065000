import React, { Component } from 'react'
import { BrowserRouter, Switch, withRouter } from 'react-router-dom'
import routes from './routes'
import { renderRoutes, matchRoutes } from 'react-router-config'
import { Provider } from './Context'
import { ThemeProvider } from 'styled-components'
import themes from '@/components/Themes'
import { Container, Row, Col } from 'react-bootstrap'
import Step from './components/Step'

const Title = (props) => {
  let branch = matchRoutes(routes, props.location.pathname)
  let { step, title, unconfirmTitle } = branch[0].route
  return (
    <>
      <Step step={step}></Step>
      <h3 className="text-center">
        {props.unconfirm ? unconfirmTitle : title}
      </h3>
    </>
  )
}
const TitleWithRouter = withRouter(Title)

export class Registration extends Component {
  render() {
    return (
      <Provider value={this.props}>
        <ThemeProvider theme={themes}>
          <BrowserRouter>
            <Container className="py-4">
              <TitleWithRouter unconfirm={this.props.unconfirm} />
              <Row>
                <Col xm={12} md={8} className="mx-auto">
                  {renderRoutes(routes)}
                </Col>
              </Row>
            </Container>
          </BrowserRouter>
        </ThemeProvider>
      </Provider>
    )
  }
}

export default Registration
