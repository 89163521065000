import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';

const scrollToElement = require('scroll-to-element');

const getFirstElementKey = (errors, key = '') => {
  if (typeof errors === 'object') {
    let allKey = key;
    const currentkey = Object.keys(errors)[0];
    if (!allKey) allKey = currentkey;
    else allKey += `[${currentkey}]`;
    return getFirstElementKey(errors[currentkey], allKey);
  }
  return key;
};

function FormikErrorFocus(props) {
  const { errors, submitCount, isValidating } = useFormikContext();
  const [count, setCount] = useState(null);
  useEffect(() => {
    if (!isValidating && count !== submitCount) {
      setCount(submitCount);
      const key = getFirstElementKey(errors);
      if (key !== '') {
        scrollToElement(`[name="${key}"]`, props);
      }
    }
  }, [isValidating, submitCount]);
  return null;
}

export default FormikErrorFocus;
