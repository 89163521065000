import React, { Component } from 'react'
import MemberContext from '@/components/_context/MemberContext'
import { Button } from 'react-bootstrap'
import styled from 'styled-components'
import { MemberTag } from '../common/index'
import { Link } from 'react-router-dom'

export class Title extends Component {
  render() {
    return (
      <div>
        <MemberContext.Consumer>
          {({ member }) => {
            return (
              <div className="d-flex align-items-center py-4">
                <svg height="60px" width="60px">
                  <use
                    href={
                      member && member.gender == '1'
                        ? '#member_male'
                        : '#member_female'
                    }
                  />
                </svg>
                <h3 className="ml-3 mb-0">{member && member.name}</h3>
                <MemberTag className="bg-primary-dark">一般會員</MemberTag>
                <Link to="/member/edit" className="ml-auto">
                  <Button variant="outline-primary">修改資料</Button>
                </Link>
              </div>
            )
          }}
        </MemberContext.Consumer>
      </div>
    )
  }
}

export default Title
