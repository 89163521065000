import React from 'react'
import styled from 'styled-components'

const OptionButton = styled.button`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 3px solid white;
  overflow: hidden;
  height: 60px;
  width: 100%;
  border: none;
  
  background-color: ${({ actived }) => actived ? '#62cae3' : '#DFF5FA'};
  color: ${({ actived }) => actived ? 'white !important' : '#495057 !important'};

  transition: all 0.2s ease-in-out;
  font-size: 1.125rem;
  
  ${({ borderRadius }) => {
    if (borderRadius == "right") {
      return `border-top-right-radius: 5px;
              border-bottom-right-radius: 5px;
              border-left: 3px solid white;`
    }
    if (borderRadius == "left") {
      return `border-top-left-radius: 5px;
              border-bottom-left-radius: 5px;`
    }
    return `border-left: 3px solid white;`
  }}
  &:hover{
    background-color: #62cae3;
    color: white !important;
  }
`



export default OptionButton
