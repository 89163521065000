import React, { Component } from 'react'
import Completed from './Completed'
import EmailField from './EmailField'
import Axios from 'axios'
import createFormData from '@/components/_common/createFormData'
import Context from '../../Context'
import { Row, Col } from 'react-bootstrap'

export class Confirmation extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isSent: false,
      isLoading: false,
      isResendOpen: false, //開啟重送
      email: '',
    }
    this.submitHandler = this.submitHandler.bind(this)
    this.resendHandler = this.resendHandler.bind(this)
  }

  componentDidMount() {
    if (this.context.unconfirm)
      this.setState({ email: this.context.email, unconfirm: true })
    else this.setState({ email: this.context.email })
  }

  resendHandler(e) {
    this.setState({ isResendOpen: !this.state.isResendOpen })
  }

  submitHandler(values, formikBag) {
    let _this = this
    let formData = createFormData(values)
    this.setState({ isLoading: true })
    Axios.post(`/members/confirmation.json`, formData, {
      header: { 'Content-Type': 'text/html; charset=utf-8' },
    }).then(({ data }) => {
      _this.setState({ isLoading: false })
      if (data.code == 0) {
        _this.setState({
          isSent: true,
          isLoading: false,
          isResendOpen: false,
          email: values.member.email,
        })
      } else {
        formikBag.setFieldError('member[email]', data.message)
      }
    })
  }
  render() {
    if (this.state.isSent || this.context.email) {
      return (
        <Row>
          <Col xs={12} sm={9} className="mx-auto">
            <Completed
              isLoading={this.state.isLoading}
              isResendOpen={this.state.isResendOpen}
              unconfirm={this.state.unconfirm}
              submitHandler={this.submitHandler}
              resendHandler={this.resendHandler}
              email={this.state.email}
            />
          </Col>
        </Row>
      )
    } else {
      return (
        <Row>
          <Col xs={12} sm={9} className="mx-auto">
            <EmailField
              isLoading={this.state.isLoading}
              submitHandler={this.submitHandler}
            />
          </Col>
        </Row>
      )
    }
  }
}
Confirmation.contextType = Context
export default Confirmation
