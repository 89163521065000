import React, { useContext } from 'react'
import BuyBtn from '../components/BuyBtn'
import Context from '../context'

const Head = ({ content }) => (
  <div className="col-12">
    <div className="content-column-head d-flex">
      <h5 className="mb-0 title-line name">
        {content.name}
      </h5>
      {/* <a href="#terms_lightbox" data-lightbox="inline"></a> */}
    </div>
  </div>
)

const Content = ({ content }) => (
  <div className="col-12 col-md-6">
    <div className="content-column">
      <div>
        <div className="float-left">
          <p className="name">{content.name}</p>
        </div>
        <div className="float-right">
          <div className="amount">{content.amount}</div>
        </div>
        <div className="clearfix" />
        <p className="small">{content.description}</p>
      </div>
    </div>
  </div>
)

function ProductPlan() {
  const { productInfo, planProducts, isLoading, handleBuyBtnClick } = useContext(Context)
  const { buyUrl, isActive, fee } = productInfo

  return (
    <>
      <div id="c-body">
        <div className="row" id="products"></div>
      </div>
      <div className="row" id="products">
        <div className="row">
          {planProducts?.map((p, index) => {
            if (p.amount) {
              return (<Content key={index} content={p} />)
            } else {
              return (<Head key={index} content={p} />)
            }
          })}
        </div>
      </div>
      <div className="mt-4 d-none d-md-block" id="c-footer">
        <div className="row">
          <div className="col-auto ml-auto justify-content-end">
          </div>
          <div className="col-3 align-self-center px-4">
            <BuyBtn
              disabled={!isActive || !(fee) || isLoading}
              url={buyUrl}
              isActive={isActive}
              onClick={handleBuyBtnClick}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default ProductPlan
