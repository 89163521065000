import moment from 'moment'
import { date } from 'yup'

export const getTravelPolicyInfo = (data) => {
  let list = []
  list.push({ title: '國家', value: data.countries_name || '國內' })
  if (data.information.mountain) list.push({ title: '登山地點', value: data.information.mountain })

  return list
}

export const getPetPolicyInfo = (data) => {
  let list = []
  list.push({ title: '寵物名稱', value: data.information.pet_name })
  list.push({ title: '寵物種類', value: data.information.pet_kind })
  list.push({ title: '寵物品種', value: data.information.pet_race })
  list.push({ title: '寵物晶片序號', value: data.information.etag_serial_number })
  return list
}

export const getVehiclePolicyInfo = (data) => {
  let list = []
  list.push({ title: '車牌號碼', value: data.information?.license_plate })
  list.push({ title: '車輛名稱', value: data.information?.car_name })
  list.push({ title: '引擎號碼', value: data.information?.engine_num })
  return list
}

export const getPolicyInfo = (data) => {
  let list = []
  console.log(data)
  list.push({ title: '投保日期', value: moment(data.created_at).format("YYYY/MM/DD") })
  list.push({ title: '保單號碼', value: data.policy_number })
  list.push({ title: '保單狀態', value: data.status_name })
  if (data.policy_type === 'car' || data.policy_type === 'motor') {
    data.insured_from && list.push({ title: '強制險有效期限', value: `${moment(data.insured_from).format("YYYY/MM/DD")} - ${moment(data.insured_till).format("YYYY/MM/DD")}` })
    data.insured_from2 && list.push({ title: '任意險有效期限', value: `${moment(data.insured_from2).format("YYYY/MM/DD")} - ${moment(data.insured_till2).format("YYYY/MM/DD")}` })
  } else if (data.policy_type === 'vaccine' || data.policy_type === 'pet') {
    list.push({ title: '有效期限', value: `${moment(data.insured_from).format("YYYY/MM/DD")} - ${moment(data.insured_till).format("YYYY/MM/DD")}` })
  } else {
    list.push({ title: '有效期限', value: `${moment(data.insured_from).format("YYYY/MM/DD HH:00")} - ${moment(data.insured_till).format("YYYY/MM/DD HH:00")} (台北標準時間)` })
  }
  list.push({ title: '保單寄送方式', value: data.delivery_method == "post" ? "紙本保單" : '電子保單' })
  list.push({ title: '保費', value: data.premium })
  return list
}

export const getInsuredList = (data) => {
  return [
    { title: '姓名', value: data?.name },
    { title: '身分證字號	', value: data?.citizen_id },
    { title: '出生年月日	', value: moment(data?.birthday).format("YYYY/MM/DD") },
  ]
}

export const getProposerList = (data) => {
  return [
    { title: '姓名', value: data?.xname },
    { title: '身分證字號	', value: data?.citizen_id },
    { title: '出生年月日	', value: moment(data?.birthday).format("YYYY/MM/DD") },
    { title: '行動電話	', value: data?.mobile_phone },
    { title: 'Email', value: data?.email },
    { title: '聯絡地址', value: data?.full_address }
  ]
}

export const getBeneficiaryList = (data, { allocation = null }) => {
  console.log(allocation)
  if (data.relationship == '法定繼承人' || data.relationship == '') {
    return [
      { title: '排序/百分比', value: data[allocation] },
      { title: '關係	', value: data.relationship },
    ]
  }
  return [
    { title: '排序/百分比', value: data[allocation] },
    { title: '性名	', value: data?.name },
    { title: '關係	', value: data.relationship },
    { title: '身分證字號	', value: data?.citizen_id },
    { title: '出生年月日	', value: moment(data?.birthday).format("YYYY/MM/DD") },
    { title: '行動電話	', value: data?.mobile_phone },
    { title: '聯絡地址', value: data?.address }
  ]


}