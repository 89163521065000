import React, { Component } from 'react'
import styled from 'styled-components'
import { ThemeProvider } from 'styled-components'
import MemberContext from '@/components/_context/MemberContext'
import { SvgIcon } from '@/components/_common/SvgIcon'
import TimeAgo from 'react-timeago'
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'
import Strings from 'react-timeago/lib/language-strings/zh-TW'
import { Flex } from '../../../common/index'

const formatString = {
  prefixAgo: null,
  prefixFromNow: null,
  suffixAgo: '前',
  suffixFromNow: '後',
  seconds: '幾秒',
  minute: '1分鐘',
  minutes: '%d分鐘',
  hour: '1小時',
  hours: '%d小時',
  day: '1天',
  days: '%d天',
  month: '1個月',
  months: '%d個月',
  year: '1年',
  years: '%d年',
  wordSeparator: '',
}

const formatter = buildFormatter(formatString)

const MessageContext = React.createContext()

const Name = styled.span`
  font-size: 12pt;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: ${({ theme }) => theme.spacers[1]};
`

const Avatar = styled.img`
  border-radius: 50%;
  width: 33px;
  height: 33px;
`

const ContentBox = styled.div`
  max-width: 80%;
  text-align: ${({ theme }) => (theme.isAdmin ? 'left' : 'right')};
`

const Content = styled.div`
  width: fit-content;
  background-color: ${({ theme }) =>
    theme.isAdmin ? theme.grayColor.gray200 : theme.color.primaryLight};
  padding: ${({ theme }) => theme.spacers[2]} ${({ theme }) => theme.spacers[3]};
  border-radius: 10px;
  position: relative;
  margin-left: 15px;
  margin-right: 15px;
  overflow-wrap: break-word;
  word-break: break-word;
  text-align: left;
  &:before {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 10px 0px 10px;
    border-color: ${({ theme }) =>
        theme.isAdmin ? theme.grayColor.gray200 : theme.color.primaryLight}
      transparent transparent transparent;
    position: absolute;
    ${({ theme }) => (theme.isAdmin ? 'left' : 'right')}: -10px;
    top: 0;
  }
  p {
    margin: 0;
  }
`

const Time = styled(TimeAgo)`
  /* margin-left: 15px;
  margin-right: 15px; */
  min-width: fit-content;
  margin-top: -5px;
  font-size: 12px;
  color: ${({ theme }) => theme.grayColor.gray500};
`
const MessageBox = styled.div`
  display: flex;
  padding-top: ${({ theme }) => theme.spacers[2]};
  padding-bottom: ${({ theme }) => theme.spacers[2]};
  flex-direction: ${({ theme }) => (theme.isAdmin ? 'row' : 'row-reverse')};
`

export class Message extends Component {
  render() {
    let { content } = this.props
    return (
      <div>
        <ThemeProvider theme={{ isAdmin: content.is_admin_reply }}>
          <MessageBox>
            <SvgIcon
              width={40}
              href={
                content.is_admin_reply
                  ? '#admin_icon'
                  : this.context.currentMember.gender == 'F'
                  ? '#member_female'
                  : '#member_male'
              }
            ></SvgIcon>
            <ContentBox>
              <Name>
                {content.is_admin_reply
                  ? 'SARAcares 客服'
                  : this.context && this.context.currentMember.name}
              </Name>
              <Flex
                align="flex-end"
                direction={content.is_admin_reply ? 'row' : 'row-reverse'}
              >
                <Content>
                  {content.content.split('\n').map((it, i) => (
                    <div key={'x' + i}>{it}</div>
                  ))}
                </Content>
                <Time date={content.created_at} formatter={formatter} />
              </Flex>
            </ContentBox>
          </MessageBox>
        </ThemeProvider>
      </div>
    )
  }
}
Message.contextType = MemberContext

export default Message
