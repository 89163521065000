import React, { useContext, useEffect, useState } from 'react'
import FooterContainer from '../FooterContainer'
import context from '../../context'
import OptionsOccupation from '../../components/OptionsOccupation'
import OptionsContainer from '../../components/OptionsContainer'

function Accident() {
  const { options, searchOptions, handleSearchOptionChange, isLoading, productInfo } = useContext(context)

  useEffect(() => {
    if (!isLoading && searchOptions.mainCoverage && searchOptions.occupation_code && !(productInfo.fee)) {
      alert("很抱歉，您所提供的資料不適合投保本商品。")
    }
  }, [productInfo])

  const handleMainCoverageChange = (e) => {
    handleSearchOptionChange({ mainCoverage: e.target.value });
  }

  const handleOccupationChange = (e) => {
    console.log(e)
    handleSearchOptionChange(e)
  }

  return (
    <>
      <div className="mt-3 mt-md-0">
        <div className="row">
          {/* left */}
          <div className="col-12 col-lg-6 py-0 pt-md-4">
            <h5 className="title-line-primary d-none d-md-block">選擇職業</h5>
            <h6 className="title-line-primary d-md-none d-block">選擇職業</h6>
            <OptionsOccupation
              onChange={handleOccupationChange}
            />
          </div>
          {/* right */}
          <div className="col-12 col-lg-6 py-0 pt-md-4">
            <h5 className="title-line-primary d-none d-md-block">選擇保額</h5>
            <h6 className="title-line-primary d-md-none d-block">選擇保額</h6>
            <OptionsContainer
              options={options.mainCoverage}
              value={searchOptions.mainCoverage}
              onChange={handleMainCoverageChange}
            />
          </div>
          <div className="col-12 ">
            <p className="text-gray-500 small">
              ◎ 法定網路投保須年滿18歲，並僅供本人投保<br />
              ◎ 非有效契約客戶網路投保上限為150萬，有效契約客戶上限為300萬。(備註：有效契約客戶指投保時同時擁有該保險公司其他的保險契約)<br />
              ◎ 本方案可承保1-2類的職業等級，如有多項工作者請選擇最高職業等級投保。
              <a href="/職業等級表.pdf" target="_blank" rel="noopener noreferrer">職業類別查詢</a><br />
              ◎ 請您務必親自據實回答，以免影響您的保單效力及合法權益。
            </p>
          </div>
        </div>
      </div>
      <FooterContainer />
    </>
  )
}

export default Accident
