import React, { Component } from 'react'
import { Flex } from './index'
import styled from 'styled-components'
import { Button } from 'react-bootstrap'
import ReactTooltip from 'react-tooltip'
import { withTheme } from 'styled-components'
import { Link } from 'react-router-dom'
import { TravelPlan, CarPlan, PetPlan, AccidentPlan, VaccinePlan } from './Plan'
import moment from 'moment'
import { MyPopup } from '@/components/_common/StyledPopup'

const MyReactTooltip = styled(ReactTooltip)`
  &.__react_component_tooltip {
    z-index: 298;
  }
`

const PolicyBox = styled.div`
  border: 1px solid ${({ theme }) => theme.grayColor.gray500};
  border-radius: ${({ theme }) => theme.borderRadius};
  margin-top: ${({ theme }) => theme.spacers[3]};
  margin-bottom: ${({ theme }) => theme.spacers[3]};
  padding: ${({ theme }) => theme.spacers[3]};
  padding-top: ${({ theme }) => theme.spacers[2]};
  padding-bottom: ${({ theme }) => theme.spacers[2]};
`
const Title = styled(Flex)`
  border-width: 0 0 1px 0;
  border-color: ${({ theme }) => theme.grayColor.gray500};
  border-style: solid;
  padding-bottom: ${({ theme }) => theme.spacers[2]};
  margin-bottom: ${({ theme }) => theme.spacers[2]};
  padding-left: ${({ theme }) => theme.spacers[1]};
  padding-right: ${({ theme }) => theme.spacers[1]};
  h5 {
    margin-bottom: 0;
    margin-right: ${({ theme }) => theme.spacers[2]};
  }
  p {
    margin-bottom: 0;
  }
`

const Footer = styled.div`
  border-width: 1px 0 0 0;
  border-color: ${({ theme }) => theme.grayColor.gray500};
  border-style: solid;
  padding-top: ${({ theme }) => theme.spacers[2]};
  margin-top: ${({ theme }) => theme.spacers[3]};
  display: flex;
  align-items: center;
  padding-left: ${({ theme }) => theme.spacers[1]};
  padding-right: ${({ theme }) => theme.spacers[1]};
`

const ToolButton = styled.button`
  color: white;
  width: 80px;
  padding: ${({ theme }) => theme.spacers[1]} 0;
  margin-top: ${({ theme }) => theme.spacers[1]};
  margin-bottom: ${({ theme }) => theme.spacers[1]};
  text-align: center;
  position: relative;
  border-radius: ${({ theme }) => theme.borderRadius};
  color: white;
  font-size: 14px;
  background: none;
  border: none;
  font-weight: 300;
  &:hover {
    background-color: ${({ theme }) => theme.grayColor.gray700};
  }
  &:after {
    content: '';
    border: 0px solid white;
    border-width: 0 0 0.5px 0;
    position: absolute;
    bottom: -${({ theme }) => theme.spacers[1]};
    right: 0;
    left: 0;
  }
  &:last-of-type:after {
    content: none;
  }
`

const INITIALIZED = "initialized"
const TRANSACTED = "transacted"
const SUCCEEDED = "succeeded"
const TERMINATED = "terminated"
const CANCEL = "cancel"

const statusName = (s) => {
  switch (s) {
    case INITIALIZED:
      return "未投保成功"
    case TRANSACTED:
      return "交易成功（保單出單中）"
    case SUCCEEDED:
      return "保單出單完成"
    case TERMINATED:
      return "交易取消"
    case CANCEL:
      return "交易取消"
  }
}

export class Policy extends Component {
  constructor(props) {
    super(props)
  }

  dispurchasableButtonClickHandler() { }

  renderState(status) {
    switch (status) {
      case TRANSACTED:
      case SUCCEEDED:
        if (this.props.policy.insured_till ? moment(this.props.policy.insured_till).isAfter(moment()) : moment(this.props.policy.insured_from).add(1, "y").isAfter(moment())) {
          return (
            <p className="text-primary ml-auto">
              <i className="fas fa-check-circle"></i>
              {statusName(status)}
            </p>
          )
        } else {
          return (
            <p className="text-gray-500 ml-auto">
              <i className="fas fa-minus-circle" />
              期間已過
            </p>
          )
        }
        break
      case INITIALIZED:
      case CANCEL:
        return (
          <p className="text-gray-500 ml-auto">
            <i className="fas fa-minus-circle"></i>
            {statusName(status)}
          </p>
        )
        break
      case TERMINATED:
        return (
          <p className="text-error ml-auto">
            <i className="fas fa-exclamation-circle"></i>
            {statusName(status)}
          </p>
        )
        break
      default:
        return (
          <p className="text-secondary ml-auto">
            <i className="fas fa-times-circle"></i>
            {statusName(status)}
          </p>
        )
        break
    }
  }

  renderPlan(policy) {
    switch (policy.policy_type) {
      case 'travel':
        return <TravelPlan policy={policy} />
      case 'pet':
        return <PetPlan policy={policy} />
      case 'car':
        return <CarPlan policy={policy} />
      case 'motor':
        return <CarPlan policy={policy} />
      case 'accident':
        return <AccidentPlan policy={policy} />
      case 'vaccine':
        return <VaccinePlan policy={policy} />
    }
  }
  renderAsk() {
    let { policy } = this.props
    if (policy.status != null) {
      console.log(policy.id, policy.status != null)
      return (
        <>
          <ToolButton
            onClick={() => this.props.onAskQuestion(4, policy, '我要理賠')}
          >
            我要理賠
          </ToolButton>
          <ToolButton
            onClick={() => this.props.onAskQuestion(2, policy, '保單變更')}
          >
            保單變更
          </ToolButton>
        </>
      )
    }
  }
  render() {
    let { policy } = this.props
    console.log(policy)
    return (
      <PolicyBox key={this.props.key}>
        <Title center>
          <h5>{policy.insurance_company.name}</h5>
          <p>{policy.insured_from && moment(policy.insured_from).format("YYYY/MM/DD")}</p>
          <a
            data-tip="menu"
            className="ml-auto"
            data-for={`clickme-${policy.id}`}
            data-event="click"
          >
            <i className="fas fa-ellipsis-v"></i>
          </a>
          <MyReactTooltip
            id={`clickme-${policy.id}`}
            place="bottom"
            effect="solid"
            clickable={true}
            backgroundColor={this.props.theme.color.bgMenu}
            globalEventOff="click"
          >
            <Flex column style={{ margin: '-3px -10px' }}>
              <ToolButton className="d-md-none d-block">
                <Link
                  to={`/member/policies/${policy.id}?type=${policy.policy_type}`}
                  className="text-white"
                >
                  保單內容
                </Link>
              </ToolButton>
              <ToolButton
                onClick={() => {
                  console.log('policy')
                  return this.props.onAskQuestion(1, policy, '我要詢問')
                }}
              >
                我要詢問
              </ToolButton>
              {this.renderAsk()}
            </Flex>
          </MyReactTooltip>
        </Title>
        <div className="px-1">
          <Flex>
            <h6 className="font-weight-normal mb-2">{policy.package?.name}</h6>
            {this.renderState(policy.status)}
          </Flex>
          {this.renderPlan(policy)}
        </div>
        <Footer>
          <p className="m-0">
            {policy.policy_number ? (
              <>
                <span className="d-none d-md-inline-block">保單號碼：</span>
                <span>{policy.policy_number}</span>
              </>
            ) : (
              ''
            )}
          </p>
          <div className="ml-auto">
            <Link to={`/member/policies/${policy.id}`}>
              <Button
                variant="outline-primary"
                className="d-none d-md-inline-block"
              >
                保單內容
              </Button>
            </Link>
            {policy.package['can_buy_now?'] ? (
              <a href={`/packages/${policy.package.id}`}>
                <Button variant="outline-primary ml-3">
                  <p className="m-0">再次購買</p>
                </Button>
              </a>
            ) : policy.feature ? (
              <MyPopup
                trigger={
                  <Button variant="outline-primary ml-3">
                    <p className="m-0">再次購買</p>
                  </Button>
                }
                modal
              >
                <div className="text-center">
                  <h4>本方案已停售</h4>
                  <p>請前往{policy.feature.name}，重新選擇方案。</p>
                  <a href={`/features/${policy.feature.slug}`}>
                    <Button variant="outline-primary ml-3">前往</Button>
                  </a>
                </div>
              </MyPopup>
            ) : (
              <Button variant="outline-primary ml-3" disabled>
                保單停售
              </Button>
            )}
          </div>
        </Footer>
      </PolicyBox>
    )
  }
}

export default withTheme(Policy)
