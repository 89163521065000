import React, { Component } from 'react'
import moment from 'moment'
import DateDurationView from './DateDurationView'

export class DateDuration extends Component {
  constructor(props) {
    super(props)
    let { onChange, value } = props
    let insuredFrom = value.insuredFrom
    let insuredTo = value.insuredTo
    this.state = {
      onChange: onChange,
      duration: 5,
      insuredFrom: {
        name: 'insuredFrom',
        default: insuredFrom,
        max: moment().add(60, 'days').startOf('hours').toDate(),
        min: moment().toDate(),
        changeHandler: (e) => this.insuredFromChangeHandler(e),
      },
      insuredTo: {
        name: 'insuredTo',
        default: insuredTo,
        max: moment().add(180, 'days').startOf('hours').toDate(),
        min: moment().add(1, 'days').startOf('hours').toDate(),
        changeHandler: (e) => this.insuredToChangeHandler(e),
      },
    }
  }

  insuredFromChangeHandler(e) {
    let value = e[0]
    let insuredFromDefault = moment(value).toISOString()
    let insuredToMax = moment(value).add(180, 'days').toDate()
    let insuredToDefault = this.state.insuredTo.default
    if (
      moment(insuredFromDefault).isSameOrAfter(this.state.insuredTo.default)
    ) {
      insuredToDefault = moment(insuredFromDefault).add(1, 'days').toISOString()
    }
    let duration = this.getDuration(insuredFromDefault, insuredToDefault)
    this.setState({
      ...this.state,
      insuredFrom: { ...this.state.insuredFrom, default: insuredFromDefault },
      insuredTo: {
        ...this.state.insuredTo,
        max: insuredToMax,
        default: insuredToDefault,
      },
      duration: duration,
    })
    this.state.onChange({
      target: { name: this.state.insuredFrom.name, value: value },
    })
    this.state.onChange({
      target: { name: 'duration', value: this.state.duration },
    })
  }

  insuredToChangeHandler(e) {
    let value = e[0]
    let insuredToDefault = moment(value).toISOString()
    let insuredFromDefault = this.state.insuredFrom.default
    let insuredToMax = this.state.insuredTo.max
    if (moment(insuredFromDefault).isSameOrAfter(insuredToDefault)) {
      insuredFromDefault = moment(insuredToDefault)
        .subtract(1, 'days')
        .toISOString()
      insuredToMax = moment(insuredFromDefault).add(180, 'days').toDate()
    }
    let duration = this.getDuration(insuredFromDefault, insuredToDefault)
    this.setState({
      ...this.state,
      insuredFrom: { ...this.state.insuredFrom, default: insuredFromDefault },
      insuredTo: {
        ...this.state.insuredTo,
        max: insuredToMax,
        default: insuredToDefault,
      },
      duration: duration,
    })
    this.state.onChange({
      target: { name: this.state.insuredTo.name, value: value },
    })
    this.state.onChange({
      target: { name: 'duration', value: this.state.duration },
    })
  }

  getDuration(insuredFrom, insuredTo) {
    return Math.ceil(moment(insuredTo).diff(moment(insuredFrom), 'hour') / 24)
  }

  render() {
    return (
      <>
        <DateDurationView
          insuredFrom={this.state.insuredFrom}
          insuredTo={this.state.insuredTo}
          onFromChange={(e) => this.insuredFromChangeHandler(e)}
          onToChange={(e) => this.insuredToChangeHandler(e)}
        />
      </>
    )
  }
}

export default DateDuration
