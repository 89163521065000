import React from 'react'
import styled from 'styled-components'
import { Swiper as _Swiper } from 'swiper/react'

const Swiper = styled(_Swiper)`
  padding: 10px;

  .swiper-button-next,
  .swiper-button-prev {
    position: absolute;
    top: calc(50%);
    height: 30px;
    width: 30px;
    z-index: 10;
    border-radius: 100%;
    background-color: white;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.267);
  }

  .swiper-button-next {
    right: 3px;
  }

  .swiper-button-prev {
    left: 3px;
  }

  .swiper-button-next:after,
  .swiper-button-prev:after {
    content: '';
    width: 10px;
    height: 10px;
    border-top: 2px solid #aaa;
    border-right: 2px solid #aaa;
    transform: rotate(45deg);
    position: absolute;
    left: 0;
    right: 3px;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  .swiper-button-next:after {
    transform: rotate(45deg);
    left: 0;
    right: 3px;
  }

  .swiper-button-prev:after {
    transform: rotate(-135deg);
    left: 3px;
    right: 0px;
  }
`

export default Swiper
