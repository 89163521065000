import React from 'react'


function ProductPlanContainer({ children }) {
  return (
    <section>
      <div className="container">
        <div className="card shadow-sm border-0 my-5 p-3 md-4 px-md-5" data-animation="fadeInUp">
          <div className="d-flex align-items-center">
            <h4 className="mb-0">
              <div className="d-flex align-items-center">
                <svg height="38px" width="38px" viewBox="0 0 48 48" className="svg-icon-primary-dark mr-2">
                  <use href="#i-my-policy"></use>
                </svg>
                <span>保單內容</span>
              </div>
            </h4>
            <a href="#terms_lightbox" className="ml-2" data-lightbox="inline">
              <u> 條款、備註及注意事項</u>
              <i className="fa fa-download ml-1" />
            </a>
          </div>

          {children}

        </div>
      </div>
    </section>
  )
}

export default ProductPlanContainer
