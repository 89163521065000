const GrayColor = {
  gray100: '#f8f9fa',
  gray200: '#e9ecef',
  gray300: '#dee2e6',
  gray400: '#ced4da',
  gray500: '#adb5bd',
  gray600: '#6c757d',
  gray700: '#495057',
  gray800: '#343a40',
}

const Color = {
  danger: '#de7761',
  primary: '#62cae3',
  primaryLight: '#DFF5FA',
  secondary: '#ff762a',
  primaryDark: '#1c4151',
  darkBlue: '#121b2b',
  link: '#62cae3',
  linkHover: GrayColor.gray400,
  tableStripedColor: '#f2f7fa',
  landingColor: '#1C4151',
  landingPink: '#EC94AF',
  landingBlue: '#5DA3DA',
  landingOrange: '#F99157',
  landingPurple: '#8F97DE',
  bgMenu: '#6C757D',
}

const borderRadius = '.25rem'
const spacer = 1
const spacers = [spacer * 0.25, spacer * 0.5, spacer, spacer * 1.5, spacer * 3]
let Spacers = {}
spacers.forEach((space, index) => (Spacers[`${index + 1}`] = space + 'rem'))
const fontWeight = {
  lighter: 'lighter',
  light: 300,
  normal: 400,
  bold: 500,
  bolder: 'bolder',
}

const shadow = {
  sm: '0 .125rem .25rem rgba(0,0,0, .25)',
  md: '0 .5rem 1rem rgba(0,0,0, .15)',
  lg: '0 1rem 3rem rgba(0,0,0, .175)',
}

const DefaultTheme = {
  color: Color,
  grayColor: GrayColor,
  borderRadius: borderRadius,
  spacers: Spacers,
  fontWeight: fontWeight,
  shadow: shadow,
}

export default DefaultTheme
