import React from 'react'
import Travel from './Travel'
import Sport from './Sport'
import Car from './Car'
import Motor from './Motor'
import Accident from './Accident'
import Pet from './Pet'

const renderSearchBar = (type) => {
  switch (type) {
    case "travel":
      return (<Travel />)
    case "sport":
      return (<Sport />)
    case "car":
      return (<Car />)
    case "motor":
      return (<Motor />)
    case "accident":
      return (<Accident />)
    case "pet":
      return (<Pet />)
    default:
      return
  }
}

function SearchBar({ type }) {
  if (type == "vaccine") return null
  return (
    <section>
      <div className="container">
        <div className="card shadow-sm border-0 my-5 p-3 p-md-4 px-md-5" data-animation="fadeInUp">
          <div className="d-flex align-items-center">
            <h4 className="mb-0 text-inline mt-2">
              <div className="d-flex align-items-cinter">
                <svg height="38px" width="38px" viewBox="0 0 48 48" className="svg-icon-primary-dark mr-2"> <use href="#i-caculator"></use> </svg>
                <span>保費試算</span>
              </div>
            </h4>
          </div>
          {renderSearchBar(type)}
        </div>
      </div>
    </section>
  )
}

export default SearchBar
