import React, { Component } from 'react'
import Counter from '../components/Counter'
import Context from '../Context'

export class Completed extends Component {
  callback() {
    window.location = this.context.redirect_url
  }
  render() {
    return (
      <Context.Consumer>
        {(value) => {
          if (value.code == 0) {
            return (
              <div className="text-center py-3">
                <h1 className="text-primary" style={{ fontSize: '100px' }}>
                  <i className="fas fa-check-circle"></i>
                </h1>
                <h1 className="mb-0">您已完成會員註冊</h1>
                <p>登入會員，即可立即進行投保</p>
                <p></p>
                <p>
                  系統將於
                  <Counter
                    time={5}
                    className="text-primary mx-1"
                    autoStart
                    callback={this.callback.bind(this)}
                  />
                  秒後自動為您導回網站
                </p>
              </div>
            )
          } else {
            return (
              <div className="text-center py-3">
                <h1 className="text-error" style={{ fontSize: '100px' }}>
                  <i className="fas fa-exclamation-triangle"></i>
                </h1>
                <h1 className="mb-0">{value.message}</h1>
                <p>
                  系統將於
                  <Counter
                    time={5}
                    className="text-primary mx-1"
                    autoStart
                    callback={this.callback.bind(this)}
                  />
                  秒後自動為您導回登入頁
                </p>
              </div>
            )
          }
        }}
      </Context.Consumer>
    )
  }
}
Completed.contextType = Context

export default Completed
