import React, { Component } from 'react'
import SwiperCore, { Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper.scss'
import 'swiper/components/pagination/pagination.scss'
import StyledPopup from '@/components/_common/StyledPopup'
import { Button, Row, Col, InputGroup, FormControl } from 'react-bootstrap'
import { CopyToClipboard } from 'react-copy-to-clipboard'

SwiperCore.use([Pagination])

const style = {
  maxHeight: '500px',
  overflow: 'scroll',
}
export class SaraShared extends Component {
  constructor(props) {
    super(props)
    this.state = {
      url: props.url,
      copied: false,
      isMobilePhone: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent,
      ),
    }
  }

  componentDidMount() {
    window.loadFB(document, 'script', 'facebook-jssdk')
  }

  handleClick(e) {
    e.preventDefault()
    const { link, appId } = this.props
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent,
      )
    ) {
      window.open(
        'fb-messenger://share?link=' +
          encodeURIComponent(link) +
          '&app_id=' +
          encodeURIComponent(appId),
      )
    } else {
      FB.ui({
        method: 'send',
        link: link,
      })
    }
  }

  renderPopup(commission) {
    return (
      <SwiperSlide key={commission.id}>
        <StyledPopup
          modal={true}
          trigger={<img src={commission.image.url} className="w-100" />}
        >
          {(close) => (
            <>
              <a className="close-btn h1" onClick={close} href="###">
                <i className="fas fa-times-circle"></i>
              </a>
              <div className="p-3 m-0 p-md-4 m-md-4">
                <h4 className="text-center">{commission.name}</h4>
                <div
                  dangerouslySetInnerHTML={{ __html: commission.content }}
                  style={style}
                />
              </div>
            </>
          )}
        </StyledPopup>
      </SwiperSlide>
    )
  }

  render() {
    if (this.state.isMobilePhone) {
      return (
        <div className="d-flex px-0 px-md-3 px-lg-5">
          <Row>
            <Col xs="12">
              <Button
                variant="messenger"
                className="mb-3"
                onClick={(e) => this.handleClick(e)}
                block
              >
                與 Facebook 好友分享
              </Button>
              <a
                href={`https://lineit.line.me/share/ui?url=${
                  this.props.url
                }&text=${encodeURIComponent(
                  '推薦給您【錠嵂保經網路投保平台-SARAcares莎拉保險網】提供各家保險方案試算、比較、快速投保服務， 還會不定期分享簡單好懂保險知識！',
                )}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button block variant="line">
                  與 LINE 好友分享
                </Button>
              </a>
            </Col>

            <Col xs="12">
              <InputGroup className="my-3">
                <FormControl
                  as="input"
                  value={this.state.url}
                  className="border-primary"
                  onFocus={(e) => e.target.select()}
                  onChange={() => {}}
                />
                <InputGroup.Append>
                  <CopyToClipboard
                    text={this.state.url}
                    onCopy={() => alert('已複製至剪貼簿')}
                  >
                    <Button variant="primary" id="share_qrcode_url_btn">
                      複製
                    </Button>
                  </CopyToClipboard>
                </InputGroup.Append>
              </InputGroup>
            </Col>
          </Row>
        </div>
      )
    } else {
      return (
        <div className="d-flex px-0 px-md-3 px-lg-5">
          <div className="my-3">
            <Button
              size="lg"
              variant="messenger"
              className="mr-3"
              onClick={(e) => this.handleClick(e)}
            >
              與 Facebook 好友分享
            </Button>
            <a
              href={`https://lineit.line.me/share/ui?url=${
                this.props.url
              }&text=${encodeURIComponent(
                '推薦給您【錠嵂保經網路投保平台-SARAcares莎拉保險網】提供各家保險方案試算、比較、快速投保服務， 還會不定期分享簡單好懂保險知識！',
              )}`}
              target="_blank"
              rel="noopener noreferrer"
              className="mx-3"
            >
              <Button size="lg" variant="line">
                與 LINE 好友分享
              </Button>
            </a>
          </div>

          <Col>
            <InputGroup size="lg" className="my-3">
              <FormControl
                as="input"
                value={this.state.url}
                className="border-primary"
                onFocus={(e) => e.target.select()}
                onChange={() => {}}
              />
              <InputGroup.Append>
                <CopyToClipboard
                  text={this.state.url}
                  onCopy={() => alert('已複製至剪貼簿')}
                >
                  <Button variant="primary" id="share_qrcode_url_btn">
                    複製
                  </Button>
                </CopyToClipboard>
              </InputGroup.Append>
            </InputGroup>
          </Col>
        </div>
      )
    }
  }
}

export default SaraShared
