import React, { Component } from 'react'
import styled from 'styled-components'
import { Container, Button } from 'react-bootstrap'
//import Banner from '../../images/promotions/banner.svg'
import { Banner, BannerM } from './Banner'
//import BannerM from '../../images/promotions/banner-m.svg'
import Lottery from './Lottery'
import themes from '@/components/Themes'
import { ThemeProvider } from 'styled-components'
import Flex from '@/components/_common/Flex'
import LotteryContext from '@/components/_context/LotteryContext'
import CardPopup from './CardPopup'
import Point from './Point'
import Terms from './Terms'
import { MyPopup } from '../_common/StyledPopup'
import Rules from './Rules'
import axios from 'axios'

const lotteryApi = '/lottery/chance_fate/lottery.json'

const Background = styled.div`
  background-image: url(${require('../../images/promotions/background.svg')});
  background-size: 1400px;
  background-repeat: repeat;
`

const I = styled.i`
  font-size: 1.75rem;
  line-height: 1;
  margin-left: 1rem;
  color: ${({ theme }) => theme.color.secondary};
`
export class Promotions extends Component {
  constructor(props) {
    super(props)
    this.wrapper = React.createRef()
    this.state = {
      isStart: false,
      isRerender: false,
      isDraw: false,
      fateCardIndex: Math.floor(Math.random() * 5),
      chanceCardIndex: Math.floor(Math.random() * 5),
      selectedCard: null,
      isSelected: false,
      point: this.props.currentMember.point || 0,
      prize: null,
      buttonClickHnadler: this.buttonClickHnadler.bind(this),
      animateEndHandler: this.animateEndHandler.bind(this),
      selectedCardHandler: this.selectedCardHandler.bind(this),
      drawHandler: this.drawHandler.bind(this),
      resetHandler: this.resetHandler.bind(this),
    }
  }

  resetHandler() {
    this.setState({
      selectedCard: null,
      isSelected: false,
      isRerender: false,
      isDraw: false,
      isStart: false,
    })
  }

  drawHandler(e) {
    const _this = this
    showLoading()

    axios
      .get(lotteryApi, {
        headers: {
          'X-CSRF-Token': document
            .querySelector("meta[name='csrf-token']")
            .getAttribute('content'),
        },
      })
      .then((resoult) => {
        if (!isNaN(parseInt(resoult.data.gotcha))) {
          console.log(resoult)
          hideLoading()
          _this.setState({
            isDraw: true,
            prize: resoult.data.gotcha,
            prizeName: resoult.data.award_name,
            point: this.state.point - 5,
          })
        } else {
          hideLoading()
          alert(resoult.data.error)

          if (resoult.data.code == 11) {
            window.location = '/members/sign_in'
          } else {
            _this.resetHandler()
          }
        }
      })
  }
  setIndex(currentIndex) {
    let _index = Math.floor(Math.random() * 5)
    if (currentIndex == _index) {
      return this.setIndex()
    }
    return _index
  }

  buttonClickHnadler() {
    if (this.state.isStart) {
      this.setState({
        isRerender: true,
        fateCardIndex: this.setIndex(this.state.fateCardIndex),
        chanceCardIndex: this.setIndex(this.state.chanceCardIndex),
      })
    } else {
      this.setState({
        isStart: true,
      })
    }
  }

  animateEndHandler() {
    this.setState({
      isRerender: false,
    })
  }

  selectedCardHandler(card) {
    this.setState({
      selectedCard: card,
      isSelected: true,
    })
  }

  // closeSelectedCard() {
  //   this.setState({
  //     selectedCard: null,
  //     isSelected: false
  //   })
  // }

  render() {
    return (
      <div>
        <ThemeProvider theme={themes}>
          <LotteryContext.Provider value={this.state}>
            <Background>
              <Container className="mb-4">
                <Flex className="mt-4">
                  <a
                    href="/sarapoint"
                    className="mr-auto mr-md-0 ml-0 ml-md-auto"
                  >
                    <Point>{this.state.point}</Point>
                  </a>
                  <MyPopup
                    trigger={
                      <a href="###">
                        <I className="fas fa-question-circle"></I>
                      </a>
                    }
                    modal
                  >
                    {(close) => (
                      <>
                        <h2 className="text-center">遊戲規則</h2>
                        <Rules />
                        <Flex justify="center">
                          <Button variant="primary" onClick={close}>
                            關閉
                          </Button>
                        </Flex>
                      </>
                    )}
                  </MyPopup>
                </Flex>
                <Banner
                  className="d-none d-md-block mb-4"
                  awards={this.props.data.awards}
                ></Banner>
                <BannerM
                  className="d-md-none d-block mb-4 mt-3"
                  awards={this.props.data.awards}
                ></BannerM>
                <Flex justify="center" ref={this.wrapper}>
                  <Lottery />
                </Flex>
              </Container>
            </Background>
            <CardPopup />
            <Terms />
          </LotteryContext.Provider>
        </ThemeProvider>
      </div>
    )
  }
}

export default Promotions
