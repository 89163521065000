import React from 'react'
import SearchOptionContext from '@/components/_context/SearchOptionContext'
import axios from 'axios'
import humps from 'humps'
import PlanList from './PlanList'
import { Col, Row } from 'react-bootstrap'
import styled from 'styled-components'
import SearchTabs from './SearchTabs'
import Sticky from 'react-stickynode'
import CompareBox, { CompareBoxMobile } from './CompareBox'
import arraySort from 'array-sort'

import TravelSearchForm from './SearchFrom/TravelSearchFrom'
import MotorSearchForm from './SearchFrom/MotorSearchFrom'
import CarSearchForm from './SearchFrom/CarSearchFrom'
import StyledPopup from '@/components/_common/StyledPopup'

import TagManager from 'react-gtm-module'
import { gtmId } from '@/components/_common/index'

const setTagManagerArgs = (plans) => {
  console.log('TagManager', plans)
  let plan = plans.map((plan) => {
    return {
      name: plan['package'].name,
      id: plan['package'].id,
      brand: plan.insurance_company.name,
      category: plan.type,
      list: `search ${plan.type}`,
      price: plan.fee,
    }
  })
  let dataLayer = {
    event: 'Search',
    tag: plans[0].type,
    ecommerce: {
      currencyCode: 'NTD',
      impressions: plan,
    },
  }

  let tagManagerArgs = {
    gtmId,
    dataLayer,
  }
  TagManager.initialize(tagManagerArgs)
}

const Title = styled.h3`
  text-align: center;
  @media (min-width: 768px) {
    text-align: left;
  }
`

class ProductLine extends React.Component {
  constructor(props) {
    super(props)
    console.log(props)
    this.onCompareClick = this.onCompareClick.bind(this)
    this.onFormSubmit = this.onFormSubmit.bind(this)
    this.onSortChange = this.onSortChange.bind(this)
    this.state = {
      searchForm: {
        productLine: props.product_line,
        categoryList: props.search_bar,
        currentCategory: props.search_bar[0].category_id,
        optionList: props.search_bar,
        currentOptionList: props.search_bar[0],
        searchParams: humps.camelizeKeys(props.search_params),
        onFormSubmit: this.onFormSubmit,
        onCategoryChange: this.onCategoryChange,
      },
      planList: {
        list: [],
        response: {},
        sort: { by: 'fee', func: 'ASC' },
        onSortChange: this.onSortChange,
      },
      compareList: {
        productLineId: this.props.product_line_id,
        count: this.props.search_bar[0].package_count,
        list: [],
        productName: props.product_name,
        onCompareClick: this.onCompareClick,
      },
      openNoPackageLightBox: false,
    }
  }
  componentDidUpdate(prevProps, prevState) {
    console.log(prevState)
    if (prevState.planList.sort !== this.state.planList.sort) {
      this.sortPlanList()
    }
  }
  onCategoryChange = (category) => {
    let currentOptionList = this.state.searchForm.optionList.find(
      (options) => options.category_id == category,
    )
    this.setState({
      searchForm: {
        ...this.state.searchForm,
        currentCategory: category,
        currentOptionList,
      },
      planList: {
        ...this.state.planList,
        list: [],
      },
      compareList: {
        ...this.state.compareList,
        count: currentOptionList.package_count,
      },
    })
  }
  setCategory = (category) => {
    this.setState({ category })
    let currentCategoryOptions = []
    this.state.categoryData.forEach((c) => {
      if (c.category_id == category) {
        currentCategoryOptions = c.options
      }
    })
    this.setState({
      currentCategoryOptions,
      otherSearchOptions: { ...this.state.otherSearchOptions, sortKind: '' },
    })
  }
  onSortChange(by) {
    console.log(by)
    let { sort } = this.state.planList
    let func = 'ASC'
    if (sort.by == by || sort.by[0] == by[0]) {
      if (sort.func == func) {
        func = 'DESC'
      }
    }
    console.log({ sort: { func, by } })
    this.setState({ planList: { ...this.state.planList, sort: { func, by } } })
    console.log(this.state.planList.sort)
    //this.sortPlanList()
  }
  sortPlanList() {
    let { sort, list } = this.state.planList
    let p = arraySort(list, sort.by, {
      reverse: sort.func == 'ASC',
    })
    this.setState({ planList: { ...this.state.planList, list } })
  }

  onFormSubmit(values, action) {
    showLoading()
    console.log(this)
    this.setState({
      searchForm: {
        ...this.state.searchForm,
        searchParams: humps.decamelizeKeys(values),
      },
      planList: { ...this.state.planList, list: [] },
      compareList: { ...this.state.compareList, list: [] },
    })
    axios
      .get(
        `/product_lines/${this.state.searchForm.productLine}/plan_searches.json`,
        {
          params: humps.decamelizeKeys(values),
        },
      )
      .then((response) => {
        console.log('fjsklf', response.length)
        if (
          response.data.plans == undefined ||
          response.data.plans.length == 0
        ) {
          this.setState({
            openNoPackageLightBox: true,
          })
          return
        }
        setTagManagerArgs(response.data.plans)
        let list = response.data.plans.map((p) => {
          return { ...p, compare: false }
        })
        this.setState({
          planList: {
            ...this.state.planList,
            response: response.data,
            list: list,
          },
        })
        this.sortPlanList()
      })
      .catch(function (error) {
        //console.log(error)
      })
      .then(function () {
        hideLoading()
        action.setSubmitting(false)
      })
  }

  showLoading() {
    document.getElementById('loading').style.display = 'block'
  }
  hideLoading() {
    document.getElementById('loading').style.display = 'none'
  }
  onCompareClick(e) {
    console.log(e)
    let id = e.target.value
    let { list } = this.state.planList
    let { list: compareList } = this.state.compareList
    let plan = list.find((p) => p.id == id)
    let compareListCount = (compareList = Object.keys(compareList).length)

    if (!plan.compare) {
      if (compareListCount < 4) {
        plan.compare = !plan.compare
        this.setState({ planList: { ...this.state.planList, list } })

        let { list: compareList } = this.state.compareList
        let planName = ''
        if (this.state.searchForm.productLine != 'travel') {
          planName = plan.insurance_company.name + ' - ' + plan['package'].name
        } else {
          planName = plan.full_plan_title
        }
        compareList[id] = {
          name: planName,
          subTitle: `$${plan.cost}`,
        }
        this.setState({
          compareList: { ...this.state.compareList, list: compareList },
        })
      } else {
        alert('很抱歉！僅能選擇四種方案進行比較')
      }
    } else {
      plan.compare = !plan.compare
      this.setState({ planList: { ...this.state.planList, list } })
      let { list: compareList } = this.state.compareList
      delete compareList[id]
      this.setState({
        compareList: { ...this.state.compareList, list: compareList },
      })
    }
  }

  onNoPackageLightBoxClose(e) {
    e ? e.preventDefault() : ''
    this.setState({
      openNoPackageLightBox: false,
    })
  }

  renderSearchFrom() {
    switch (this.props.product_line) {
      case 'travel':
        return <TravelSearchForm />

      case 'motorcycle':
        return <MotorSearchForm />

      case 'car':
        return <CarSearchForm />
    }
  }

  render() {
    return (
      <SearchOptionContext.Provider value={this.state}>
        {/* <SearchBar plans={this.state.planList} /> */}
        <Row id="search-box">
          <Col xm={12} md={8} lg={9}>
            <Title>各家方案比較</Title>

            <SearchTabs />
            {this.renderSearchFrom()}
            <PlanList />
          </Col>
          <Col
            sm={12}
            md={4}
            lg={3}
            className="d-none d-md-block"
            id="compare-container"
          >
            <Sticky top={100} bottomBoundary={'#compare-container'}>
              <CompareBox />
            </Sticky>
          </Col>
          <CompareBoxMobile
            className="d-block d-md-none"
            count={Object.keys(this.state.compareList.list).length}
            onClick={(e) => {
              document.getElementById('compareBtn').click()
            }}
          >
            立即比較 （{Object.keys(this.state.compareList.list).length}）
          </CompareBoxMobile>
        </Row>
        <StyledPopup
          modal={true}
          open={this.state.openNoPackageLightBox}
          onClose={() => this.onNoPackageLightBoxClose()}
        >
          <a
            className="close-btn h1"
            onClick={(e) => this.onNoPackageLightBoxClose(e)}
            href="###"
          >
            <i className="fas fa-times-circle"></i>
          </a>
          <div className="p-3 m-0 p-md-4 m-md-4 position-relative">
            <h4 className="mb-0 text-center">
              <i className="fas fa-exclamation-triangle mr-1"></i>
              無符合搜尋條件之方案
              <br />
              請重新選擇
              <span className="text-secondary">保障項目</span>或
              <span className="text-secondary">保額</span>
            </h4>
          </div>
        </StyledPopup>
      </SearchOptionContext.Provider>
    )
  }
}

export default ProductLine
