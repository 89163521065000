import React, { useState } from 'react'
import { useFormik, FormikProvider } from 'formik'
import schema from './schema'
import { Button } from 'react-bootstrap'
import styled from 'styled-components'
import { MyPopup } from '@/components/_common/StyledPopup'
import RequirementForm from './subForm/RequirementForm'
import ContactInfoForm from './subForm/ContactInfoForm'
import InsuranceForm from './subForm/InsuranceForm'
import OtherServiceForm from './subForm/OtherServiceForm'
import ThankyouPage from './ThankyouPage'
import queryString from 'query-string'
import ImagesForm from './subForm/ImagesForm'
import FormikErrorFocus from 'components/_common/FormikErrorFocus';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import ReCaptcha from './components/ReCaptcha'
import { v4 as uuidGenerator } from 'uuid';

const uuid = uuidGenerator()

window.dataLayer.push({ uuid })

const inquiryInitialValue = {
  demand_services: [],
  uuid,
  name: "",
  gender: "",
  city_id: "",
  phone: "",
  email: "",
  line_id: "",
  available_times: [],
  other_available_time: "",
  requirement: "",
  birthday: null,
  budget: "",
  physical_conditions: [],
  physical_condition_description: "",
  images: [],
}

function New({ options, term, recaptchaKey }) {
  const { token, be } = queryString.parse(location.search);
  const [succeeded, setSucceeded] = useState(false);
  const [readyRecaptcha, setReadyRecaptcha] = useState(false);
  const [submitErrorMessage, setSubmitErrorMessage] = useState(false);
  const formik = useFormik({
    initialValues: {
      utf8: '✓',
      authenticity_token: document
        .querySelector("meta[name='csrf-token']")
        .getAttribute('content'),
      terms_agreement: false,
      inquiry: { token: token || '', source: be || '', ...inquiryInitialValue }
    },
    validationSchema: schema,
    onSubmit: async (values) => {
      showLoading()

      // generate formData
      const formData = new FormData();
      for (let key in values) {
        if (typeof values[key] == 'object') {
          for (let key2 in values[key]) {
            if (Array.isArray(values[key][key2])) {
              values[key][key2].forEach(v => {
                formData.append(`${key}[${key2}][]`, v)
              })
            } else formData.append(`${key}[${key2}]`, values[key][key2])
          }
        } else {
          formData.append(key, values[key])
        }
      }

      // Get reCAPTCHA Token
      if (!readyRecaptcha) return setSubmitErrorMessage("請再試一次")
      let reCaptchaToken = null;
      reCaptchaToken = await window.grecaptcha.execute(recaptchaKey, { action: 'new_inquiry' })

      if (!reCaptchaToken) {
        console.log(reCaptchaToken)
        setSubmitErrorMessage("Opts! 好像有什麼東西壞了 請稍後再試")
        hideLoading()
        return
      }

      formData.append('g-recaptcha-response-data[new_inquiry]', reCaptchaToken)

      fetch("/inquiries", { method: 'POST', headers: {}, body: formData }).then(res => res.json()).then(res => {
        hideLoading()
        if (res.responseCode == "00") {
          window.history.pushState('', '', '/form/inquiry/thankyou');
          window.scrollTo(0, 0)
          return setSucceeded(true);
        }
        if (res.errorMessage) {
          return setSubmitErrorMessage(res.errorMessage)
        }
        setSubmitErrorMessage("網路錯誤 請稍後再試")
      }).catch((e) => {
        window.history.pushState('', '', '/form/inquiry/thankyou');
        hideLoading()
        setSubmitErrorMessage("Opts! 好像有什麼東西壞了 請稍後再試")
        console.log(e)
      })
    }
  })

  const handleReadyReCaptcha = () => setReadyRecaptcha(true)




  if (succeeded) return (<ThankyouPage />)
  return (
    <section className="container mb-3">
      <p className="my-3 text-center">接下來服務由錠嵂保險經紀人為您服務</p>
      <GoogleReCaptchaProvider
        reCaptchaKey={recaptchaKey}
        language='zh-TW'
      >
        <FormikProvider value={formik} >
          <form onSubmit={formik.handleSubmit}>
            <ReCaptcha action="new_inquiry" onReady={handleReadyReCaptcha} />
            <div className="m-auto col-lg-9 col-12">
              <h4><NumberIcon className="bg-primary">1</NumberIcon>需求服務</h4>
              <RequirementForm formik={formik} />
              <h4><NumberIcon className="bg-primary">2</NumberIcon>聯絡資料</h4>
              <ContactInfoForm formik={formik} options={options} />
              <h4><NumberIcon className="bg-primary">3</NumberIcon>資料/照片上傳（限 .jpg .jpeg .png 格式）</h4>
              <ImagesForm formik={formik} />
              {/* <h4><NumberIcon className="bg-primary">4</NumberIcon>投保相關問題</h4> */}
              {/* <InsuranceForm formik={formik} /> */}
              <h4><NumberIcon className="bg-primary">4</NumberIcon>個資宣告</h4>
              <OtherServiceForm formik={formik} term={term} />
              <div className="pt-4 m-auto col-4">
                <Button variant="primary" type="submit" size="lg" block>
                  送出
                </Button>
              </div>
              {submitErrorMessage && <p className="mt-1 text-center text-error">{submitErrorMessage}</p>}
              <p className="text-center small text-gray-500 text-weight-light mb-4 mt-1">
                This site is protected by reCAPTCHA and the Google
                {" "}
                <a className="font-weight-normal text-gray-500" href="https://policies.google.com/privacy" rel="noopener noreferrer" target="_blank">Privacy Policy</a>
                {" "}
                and
                {" "}
                <a className="font-weight-normal text-gray-500" href="https://policies.google.com/terms" rel="noopener noreferrer" target="_blank">Terms of Service</a>
                {" "}
                apply.
              </p>
            </div>
            <FormikErrorFocus
              offset={-80}
              align="top"
              focusDelay={200}
              ease="linear"
              duration={500}
            />
          </form>
        </FormikProvider>
      </GoogleReCaptchaProvider>
    </section >
  )
}

const NumberIcon = styled.span`
    display: inline-block;
    height: 1.5rem;
    width: 1.5rem;
    font-size: 1rem;
    text-align: center;
    border-radius: 50%;
    margin-right: 5px;
    line-height: 1.5;
    color: white;
`


export default New