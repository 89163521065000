import React from 'react'
import { Form, Button, Col, Row } from 'react-bootstrap'
import ErrorMessage from '../components/ErrorMessage'
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/material_green.css';

function InsuranceForm({ formik }) {
  return (
    <>
      <div className="p-4 mb-3 border">
        <Form.Group as={Row} controlId="budget">
          <Form.Label column xs={12} md={3}>
            預計每年保費
          </Form.Label>
          <Col xs={12} md={9}>
            <Form.Control as="select"
              name="inquiry[budget]"
              value={formik.values.inquiry.budget || ""}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              <option value="">請選擇</option>
              <option value="below_10_thousand">1萬以下</option>
              <option value="ten_to_30_thousand">1萬～3萬</option>
              <option value="over_30_thousand">3萬(含)以上</option>
            </Form.Control>

            <ErrorMessage formik={formik} name="budget" />
          </Col>
        </Form.Group>

        <Flatpickr
          options={{
            enableTime: false,
            dateFormat: 'Y/m/d',
            maxDate: new Date()
          }}
          onChange={(value) => {
            formik.handleChange({ target: { value: value[0], name: "inquiry[birthday]" } });
          }}
          onClose={() => formik.handleBlur({ target: { name: "inquiry[birthday]" } })}
          render={
            ({ defaultValue }, ref) => (
              <Form.Group as={Row} controlId="birthday" className="my-3">
                <Form.Label column xs={12} md={3}>
                  被保人生日
                </Form.Label>
                <Col xs={12} md={9}>
                  <Form.Control
                    value={defaultValue || ''}
                    name={"inquiry[birthday]"}
                    type="text"
                    // onChange={formik.handleChange}
                    ref={ref}
                  />
                  <ErrorMessage formik={formik} name="birthday" />
                </Col>
              </Form.Group>
            )
          }
        />

        <Form.Group as={Row} controlId="physical_conditions">
          <Form.Label column xs={12} md={3}>
            是否有體況
          </Form.Label>
          <Col xs={12} md={9}>
            <Form.Check
              id="physical_conditions-1"
              className="my-2"
              label="沒有以下狀況"
              type="checkbox"
              name="inquiry[physical_conditions]"
              value="nothing"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <Form.Check
              id="physical_conditions-2"
              className="my-2"
              label="過去2年內，曾受健康檢查，有異常情形而被建議接受其他檢查或治療"
              type="checkbox"
              name="inquiry[physical_conditions]"
              value="have_been_recommended_to_examinations_or_treatment"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <Form.Check
              id="physical_conditions-3"
              className="my-2"
              label="5年內，曾患有下列疾病而接受治療（心血管疾病／腦血管疾病／肺部疾病／肝臟疾病／腎臟疾病／甲狀腺疾病／精神疾病）"
              type="checkbox"
              name="inquiry[physical_conditions]"
              value="have_been_treated"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <Form.Check
              id="physical_conditions-4"
              className="my-2"
              label="其他（請在下一題簡述）"
              type="checkbox"
              name="inquiry[physical_conditions]"
              value="others"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <ErrorMessage formik={formik} name="physical_conditions" />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="physical_condition_description">
          <Form.Label column xs={12} md={3}>
            請簡述健康狀況
          </Form.Label>
          <Col xs={12} md={9}>
            <Form.Control
              as="textarea"
              rows={5}
              name="inquiry[physical_condition_description]"
              value={formik.values.inquiry.physical_condition_description}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />

            <ErrorMessage formik={formik} name="physical_condition_description" />
          </Col>
        </Form.Group>
      </div>
    </>
  )
}

export default InsuranceForm