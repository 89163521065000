import React, { useState, useEffect } from 'react'

const initRequest = {
  planProducts: null,
  productInfo: {
    isActive: false,
    buyUrl: "",
    fee: "--",
    otherInfo: {}
  }
};

const useSearching = (api, initOptions, packages, createOtherInfo) => {
  const [searchOptions, setSearchOptions] = useState(initOptions);
  const [isLoading, setIsloading] = useState(false);
  const [requrestState, setRequestState] = useState(
    initRequest
  )


  useEffect(() => {
    let url = api + '?' + (new URLSearchParams({
      ...searchOptions,
      package: packages
    })).toString();

    setIsloading(true);
    showLoading();
    fetch(url, {
      ...searchOptions,
      packages
    }).then(function (response) {
      hideLoading();
      return response.json();
    })
      .then((request) => {
        hideLoading();
        let { is_active, plan_products, buy_path, fee, ...otherInfo } = request;
        setIsloading(false);
        if (!is_active) {
          return setRequestState({
            productInfo: {
              isActive: false,
              buyUrl: "",
              fee: "--",
              otherInfo
            },
            planProducts: plan_products,
          });
        }
        setRequestState({
          productInfo: {
            isActive: is_active,
            buyUrl: buy_path,
            fee,
            otherInfo
          },
          planProducts: plan_products
        });
      })
      .catch((e) => {
        console.log(e)
        hideLoading();
        alert("系統異常！ 請稍後再試")
      });
  }, [searchOptions]);

  return [requrestState.productInfo, requrestState.planProducts, searchOptions, setSearchOptions, isLoading];
};


export default useSearching
// data: Object.assign({
//   package: this.packageId
// }, $this.option.getOptions()),
// success: function (data) {
//   $this.option.updateView(data)
// },
// error: function (data) {
//   alert('系統錯誤！請稍後再試或通知客服。');
// }