import React, { Component } from 'react'
import styled, { keyframes } from 'styled-components'
import Popup from 'reactjs-popup'
import LotteryContext from '@/components/_context/LotteryContext'
import { Cards, NothingCards, AwardCards } from './Cards'
import Flex from '@/components/_common/Flex'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const StyledPopup = styled(Popup)`
  &-overlay {
  }
  &-content {
    position: relative !important;
    width: 90% !important;
    margin: auto !important;
    border-radius: 5px !important;
    border: none !important;
    padding: 5px !important;
    max-width: 600px !important;
    animation: ${({ draw }) => (draw ? disappear : 'none')} 3s normal forwards
      ease-in-out;
  }
  &-content .close-btn {
    position: absolute;
    top: -9px;
    right: -9px;
    color: #62cae3;
    transition: all 0.5s ease;
    line-height: 1;
    font-size: 2.5rem;
    border-radius: 1rem;
    animation: ${({ draw }) => (draw ? disappearButton : 'none')} 3s normal
      forwards ease-in-out;
    &:hover {
      transform: rotateZ(180deg);
    }
  }
`

const withStyle = (Component) => {
  return styled(Component)`
    width: 220px;
    position: absolute;
    left: 0;
    box-shadow: ${({ theme }) => theme.shadow.sm};
    backface-visibility: hidden;
    z-index: 5;
    animation: ${({ draw }) => (draw ? turn : 'none')} 3s normal forwards
      ease-in-out;
  `
}

const withResultStyle = (Component) => {
  return styled(Component)`
    width: 220px;
    left: 0;
    position: absolute;
    box-shadow: ${({ theme }) => theme.shadow.sm};
    animation: ${({ draw }) => (draw ? resultTurn : 'none')} 3s normal forwards
      ease-in-out;
  `
}

const withAwardName = (Component) => (props) => {
  let line1 = props.awardName.split('&')[0]
  let line2 = props.awardName.split('&')[1]
  return <Component className={props.className} line1={line1} line2={line2} />
}

const disappear = keyframes`
  
  5% {
    box-shadow: 0 0.125rem 0.75rem rgba(0,0,0,0) !important;
    background-color: rgba(0, 0, 0, 0)
  }
  95% {
    box-shadow: 0 0.125rem 0.75rem rgba(0,0,0,0) !important;
    background-color: rgba(0, 0, 0, 0)
  }
`

const disappearButton = keyframes`
  
  5% {
    opacity: 0;
  }
  95% {
    opacity: 0;
  }
`

const turn = keyframes`
  5% {
    transform: scale(1); 
  }
  15% {
    transform: scale(1.2) rotateY(0deg); 
  }
  20%{
    transform: scale(1.2) rotateY(0deg);
  }
  80% {
    transform: scale(1.2) rotateY(180deg);
  }
  100%{
    transform: scale(1) rotateY(180deg); 
  }
`
const resultTurn = keyframes`
  0% {
    transform: rotateY(-180deg);
  }
  5% {
    transform: scale(1) rotateY(-180deg); 
  }
  15% {
    transform: scale(1.2) rotateY(-180deg); 
  }
  20%{
    transform: scale(1.2) rotateY(-180deg);
  }
  80% {
    transform: scale(1.2) rotateY(0deg); 
  }
  100%{
    transform: scale(1) rotateY(0deg);
  }
`
const disappearText = keyframes`
  0%{
    opacity: 0;
  }
  95%{
    opacity:0;
  }
  
`

const CardContainer = styled.div`
  width: 100%;
  perspective: 2500px;
  display: flex;
  div {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    width: 220px;
    height: 298.5px;
  }
`
const TextContainer = styled.div`
  animation: ${({ draw }) => (draw ? disappearText : 'none')} 3s normal forwards
    ease-in-out;
`

export class CardPopup extends Component {
  renderInit() {
    return (
      <LotteryContext.Consumer>
        {({ selectedCard, isDraw, drawHandler, prize, resetHandler }) => {
          if (isDraw) {
            if (prize != 0) {
              return (
                <>
                  <Button
                    variant="outline-gray-500"
                    className="mr-2 mt-3"
                    onClick={resetHandler}
                  >
                    再玩一次
                  </Button>
                  <a href="/member/bonus">
                    <Button variant="primary" className="ml-2 mt-3">
                      前往領獎
                    </Button>
                  </a>
                </>
              )
            } else {
              return (
                <Button
                  variant="outline-gray-500"
                  className="mr-2 mt-3"
                  onClick={resetHandler}
                >
                  再玩一次
                </Button>
              )
            }
          } else {
            return (
              <>
                <p className="mt-3">
                  {' '}
                  確認使用5點莎拉幣選擇【
                  {selectedCard && selectedCard.type == 'fate'
                    ? '命運'
                    : '機會'}
                  】嗎？
                </p>
                <Button
                  variant="outline-gray-500"
                  className="mr-2"
                  onClick={resetHandler}
                >
                  取消
                </Button>
                <Button
                  variant="primary"
                  className="ml-2"
                  onClick={drawHandler}
                >
                  確認
                </Button>
              </>
            )
          }
        }}
      </LotteryContext.Consumer>
    )
  }
  renderPrize(prize, prizeName, type, isDraw) {
    if (prize == 0) {
      let NotiongCard = withResultStyle(NothingCards[type])
      return <NotiongCard draw={isDraw} />
    } else {
      let AwardCardWithStyle = withResultStyle(withAwardName(AwardCards[type]))
      return (
        <AwardCardWithStyle draw={isDraw} awardName={prizeName} type={type} />
      )
    }
  }
  render() {
    return (
      <LotteryContext.Consumer>
        {({
          isSelected,
          selectedCard,
          isDraw,
          prize,
          prizeName,
          resetHandler,
        }) => {
          let SelectedCard =
            selectedCard &&
            withStyle(Cards[selectedCard.type][selectedCard.index])

          return (
            <StyledPopup
              open={typeof isSelected == 'boolean' ? isSelected : false}
              draw={isDraw}
            >
              <>
                <a className="close-btn h1" onClick={resetHandler} href="###">
                  <i className="fas fa-times-circle"></i>
                </a>
                <div className="p-3 m-0 p-md-4 m-md-4 position-relative text-center">
                  <CardContainer>
                    <div>
                      {isSelected ? <SelectedCard draw={isDraw} /> : null}
                      {isDraw
                        ? this.renderPrize(
                            prize,
                            prizeName,
                            selectedCard.type,
                            isDraw,
                          )
                        : null}
                    </div>
                  </CardContainer>
                  <TextContainer draw={isDraw}>
                    {this.renderInit()}
                  </TextContainer>
                </div>
              </>
            </StyledPopup>
          )
        }}
      </LotteryContext.Consumer>
    )
  }
}
CardPopup.contextType = LotteryContext
export default CardPopup
