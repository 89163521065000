import React, { useState, useEffect } from 'react'
import { MemberCard as Card, Flex, NavItem, Title } from '../common/index'
import { SvgIcon } from '@/components/_common/SvgIcon'
import { Nav, Container, Tab } from 'react-bootstrap'
import styled from 'styled-components'
import { Col, Row, Button } from 'react-bootstrap'
import Notification from './Notification'
import Question from './Question'
import MemberContext from '@/components/_context/MemberContext'
import axios from 'axios'

const Unread = styled.span`
  height: 13px;
  font-size: 10px;
  line-height: 1;
  background: red;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 6px;
  color: white;
  margin-left: 5px;

  &::before {
    content: none;
    position: absolute;
    background-color: #fb4d53;
    border-radius: 50%;
    height: 10px;
    width: 10px;
    top: 1px;
    right: -3px;
    z-index: 5;
  }
`

const Api = '/member/customer_questions.json'

function Notifications({ tab = "notifications", ...props }) {

  const [unreadCount, setUnreadCount] = useState(0)

  useState(() => {
    axios
      .get(Api, { headers: { 'Content-Type': 'application/json' } })
      .then((response) => {
        let { unread_count } = response.data
        setUnreadCount(unread_count)
      })
  })
  return (
    <div>
      <Card>
        <Title>
          <SvgIcon width={30} href="#i-message" />
          <h3>個人訊息</h3>
        </Title>
        <Tab.Container defaultActiveKey={tab}>
          <Container>
            <Nav justify variant="tabs" style={{ marginBottom: 0 }}>
              <NavItem>
                <Nav.Link eventKey="notifications">平台通知</Nav.Link>
              </NavItem>
              <NavItem>
                <Nav.Link eventKey="question">
                  客服紀錄
                  {unreadCount ? (
                    <Unread>{unreadCount}</Unread>
                  ) : (
                    ''
                  )}
                </Nav.Link>
              </NavItem>
            </Nav>
          </Container>
          <Tab.Content>
            <Tab.Pane eventKey="notifications" className="border-0">
              <Notification />
            </Tab.Pane>
            <Tab.Pane eventKey="question" className="border-0">
              <Question {...props.match.params} />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </Card>
    </div>
  )
}

export default Notifications
