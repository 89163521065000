import React from 'react'
import { Nav } from 'react-bootstrap'
import styled from 'styled-components'
import SearchOptionContext from '@/components/_context/SearchOptionContext'

const NavItem = styled(Nav.Item)`
  width: 50%;
  max-width: 300px;
  text-align: center;
`

const CommonQuesLink = styled.a`
  top: 5px;
  right: 0;
  position: absolute;
  @media (max-width: 991px) {
    top: -45px;
    right: 0;
  }
`

class SearchTabs extends React.Component {
  onFaqClick() {
    document.getElementById('faq').firstChild.click()
    setTimeout(() => (location.href = '#faq-list'), 500)
  }
  render() {
    return (
      <div className="position-relative">
        <CommonQuesLink
          href="#faq-list"
          onClick={this.onFaqClick}
          className="text-primary-dark"
        >
          <u className="small">常見問題</u>
        </CommonQuesLink>

        <SearchOptionContext.Consumer>
          {({ searchForm }) => {
            console.log('searchFrom', searchForm)
            let { categoryList, onCategoryChange, currentCategory } = searchForm

            return (
              <Nav variant="tabs" role="tablist" activeKey={currentCategory}>
                {categoryList.map((c) => {
                  return (
                    <NavItem key={c.category_id}>
                      <Nav.Link
                        eventKey={c.category_id}
                        className="style-2"
                        data-toggle="tab"
                        href="###"
                        aria-selected="true"
                        name={c.name}
                        onSelect={(e) => onCategoryChange(e)}
                      >
                        {c.name}
                      </Nav.Link>
                    </NavItem>
                  )
                })}
              </Nav>
            )
          }}
        </SearchOptionContext.Consumer>
      </div>
    )
  }
}

export default SearchTabs
