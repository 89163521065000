import * as yup from 'yup'

const phoneRegExp = /^09[0-9]{8}$/
const emailRegExp = /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/

const schema = yup.object().shape({
  inquiry: yup.object().shape({
    demand_services: yup.array().min(1, "請填寫這個欄位").required("請填寫這個欄位"),
    name: yup.string().required("請填寫這個欄位"),
    gender: yup.mixed().oneOf(['male', 'female'], "請填入正確的性別").required("請填寫這個欄位"),
    city_id: yup.string().required("請填寫這個欄位"),
    phone: yup.string().matches(phoneRegExp, "恩~這看起來不是手機號碼耶").required("請填寫這個欄位"),
    email: yup.string().matches(emailRegExp, "這好像不是E-mail格式喔").required("請填寫這個欄位"),
    line_id: yup.string().max(20, "不能超過20個字喔").nullable(),
    available_times: yup.array().min(1, "請填寫這個欄位").required("請填寫這個欄位"),
    other_available_time: yup.string().when('available_times', (availableTimes, schema) =>
      availableTimes && availableTimes.includes('other_times') ? schema.max(20, "中英文字元不能超過20個字喔").required("請填寫其他可聯絡時段") : schema.nullable()
    ),
    requirement: yup.string().max(500, "中英文字元不能超過500個字喔"),
    // birthday: yup.date().typeError('請填寫這個欄位').when("demand_services", (demandServices, schema) =>
    //   demandServices && demandServices.includes("insured_question") ? schema.max(new Date()).required("請填寫這個欄位") : schema.nullable()
    // ),
    // budget: yup.string().when("demand_services", (demandServices, schema) =>
    //   demandServices && demandServices.includes("insured_question") ? schema.required("請填寫這個欄位") : schema.nullable()
    // ),
    // physical_conditions: yup.array().when("demand_services", (demandServices, schema) =>
    //   demandServices && demandServices.includes("insured_question") ? schema.min(1, "請填寫這個欄位") : schema.nullable()
    // ),
    // physical_condition_description: yup.string().max(100, "中英文字元不能超過100個字喔").when('physical_conditions', (physical_conditions, schema) =>
    //   physical_conditions && physical_conditions.includes('others') ? schema.required("請填寫這個欄位") : schema.nullable()
    // ),
    images: yup.array().max(20, '照片最多只能 20 張喔').test('fileSize', "照片請勿超過 3MB", (value) => !value.find(file => file.size > 3 * 1024 * 1024)).nullable()
  }),
  terms_agreement: yup.bool().oneOf([true], "此欄必須勾選同意喔"),
})

export default schema