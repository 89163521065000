import React, { Component } from 'react'

export class Counter extends Component {
  constructor(props) {
    super(props)
    this.state = {
      time: props.time,
    }
    this.timer = null
    this.startTimer = this.startTimer.bind(this)
    this.resetTimer = this.resetTimer.bind(this)
    this.countDown = this.countDown.bind(this)
    this.isCountDownToZero = this.isCountDownToZero.bind(this)
    if (props.autoStart) {
      this.startTimer()
    }
  }

  isCountDownToZero() {
    return this.state.time <= 0
  }

  startTimer() {
    this.timer = setInterval(this.countDown, 1000)
  }
  resetTimer() {
    this.setState({ time: this.props.time })
    this.stopTimer()
  }

  stopTimer() {
    clearInterval(this.timer)
  }

  countDown() {
    this.setState((state) => ({ time: this.state.time - 1 }))
    if (this.state.time == 0) {
      clearInterval(this.timer)
      if (this.props.callback) {
        this.props.callback()
      }
    }
  }

  componentWillUnmount() {
    this.stopTimer()
  }

  render() {
    return <span className={this.props.className}>{this.state.time}</span>
  }
}

export default Counter
