import React, { useContext } from 'react'
import OptionsContainer from '../../components/OptionsContainer';
import Footer from './Footer';
import FooterContainer from '../FooterContainer'
import context from '../../context'
import FromToPicker from '../../components/FromToPicker';

function Travel() {
  const { options, searchOptions, handleSearchOptionChange } = useContext(context)

  const handleMainCoverageChange = (e) => {
    handleSearchOptionChange({ mainCoverage: e.target.value });
  }

  const handleDurationChange = (e) => {
    handleSearchOptionChange(e);
  }

  return (
    <>
      <div className="mt-3 mt-md-0">
        <div className="row">
          {/* left */}
          <div className="col-12 col-lg-6 py-0 pt-md-4">
            <h5 className="title-line-primary d-none d-md-block">選擇投保期間</h5>
            <h6 className="title-line-primary d-md-none d-block">選擇投保期間</h6>
            <div className="row px-3 mb-4">
              <FromToPicker
                from={searchOptions.insuredFrom}
                to={searchOptions.insuredTo}
                duration={searchOptions.duration}
                onChange={handleDurationChange}
              />
            </div>
          </div>
          {/* right */}
          <div className="col-12 col-lg-6 py-0 pt-md-4">
            <h5 className="title-line-primary d-none d-md-block">選擇保額</h5>
            <h6 className="title-line-primary d-md-none d-block">選擇保額</h6>
            <OptionsContainer
              options={options.mainCoverage}
              value={searchOptions.mainCoverage}
              onChange={handleMainCoverageChange}
            />
          </div>
          <div className="col-12 ">
            <p className="text-gray-500 small">
              ◎ 旅平險以24小時計算單位，若回程時間不及24小時，系統將自動加計一天投保日。<br />
              ◎ 依法令規定，保險公司非有效契約客戶網路投保上限為1,000萬。(備註：有效契約客戶指投保時同時擁有該公司其他的保險契約)<br />
              ◎ 法定網路投保須年滿18，並僅供本人投保；如有團體投保需求請電洽 0800-055-970 #1 (週一~週五9:00~17:00)
            </p>
          </div>
        </div>
      </div>
      <FooterContainer>
        <Footer></Footer>
      </FooterContainer>
    </>
  )
}

export default Travel


