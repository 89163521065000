import Layout from './Layout'
import Edit from '@/components/Member/Edit'
import Bonus from '@/components/Member/Bonus'
import Notifications from '@/components/Member/Notifications'
import Share from '@/components/Member/Share'
import Policies from '@/components/Member/Policies/index'
import PolicyShow from '@/components/Member/Policies/PolicyShow'
import Index from '@/components/Member/Index/index'
import Question from './Notifications/Question'
import Chat from './Notifications/Question/Chat'

const routes = [
  {
    path: '/member/policies/:id',
    component: PolicyShow,
  },
  {
    path: '/member',
    component: Index,
    exact: true,
  },
  {
    path: '/sarapoint',
    component: null,
  },
  {
    path: '/lottery/chance_fate',
    component: null,
  },
  {
    path: '/member',
    component: Layout,
    routes: [
      {
        path: '/member/edit',
        component: Edit,
      },
      {
        path: '/member/policies',
        component: Policies,
      },
      {
        path: '/member/customer_questions/:id',
        component: Chat,
      },
      {
        path: '/member/customer_questions/',
        component: Notifications,
        props: {
          tab: 'question',
        }
      },
      {
        path: '/member/notifications',
        component: Notifications,
      },
      {
        path: '/member/bonus',
        component: Bonus,
      },
      {
        path: '/member/shares',
        component: Share,
      },

    ]
  },

]

export default routes;