import React from 'react'
import { Form, Button, Col, Row } from 'react-bootstrap'
import { Scrollbars } from 'react-custom-scrollbars';

function OtherServiceForm({ formik, term }) {
  return (
    <>
      <div className="p-4 mb-3 border">
        <Scrollbars autoHeight autoHide autoHeightMin="200px">
          <div dangerouslySetInnerHTML={{ __html: term }} />
        </Scrollbars>
      </div>
      <Form.Check
        id="terms_agreement"
        type="checkbox"
        label="我已詳細閱讀並同意上述個人資料告知與同意事項說明書"
        value={formik.values.terms_agreement}
        name="terms_agreement"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      />
      {formik?.touched?.terms_agreement && formik?.errors?.terms_agreement &&
        <Form.Control.Feedback type={'invalid'} className="d-block">
          {formik.errors?.terms_agreement}
        </Form.Control.Feedback>
      }

    </>
  )
}

export default OtherServiceForm