import React, { Component } from 'react'
import { Formik, Field, ErrorMessage } from 'formik'
import { Container, Form, Button, Col, Row } from 'react-bootstrap'
import Axios from 'axios'
import * as yup from 'yup'
import { MyPopup } from '@/components/_common/StyledPopup'
import styled from 'styled-components'
import Inputs, { FormFeedback } from '@/components/_common/Inputs'

// 諮詢者基本資訊
const formData1_1 = [
  {
    //姓氏
    Type: Inputs.MutiInput,
    props: {
      label: '如何稱呼您',
      inputs: [
        {
          name: 'last_name',
          placeholder: '姓氏',
          showError: true,
        },
        {
          name: 'first_name',
          placeholder: '名字',
          showError: true,
        },
      ],
    },
  },
  {
    Type: Inputs.Radios,
    props: {
      label: '性別',
      name: 'gender',
      radios: [
        {
          inline: true,
          label: '男',
          value: '1',
        },
        {
          inline: true,
          label: '女',
          value: '0',
        },
      ],
    },
  },
]
const formData1_2 = [
  {
    Type: Inputs.Input,
    props: {
      label: '諮詢主要目的',
      name: 'subject',
      placeholder: '請輸入主要目的',
      showError: true,
    },
  },
  {
    Type: Inputs.TextArea,
    props: {
      label: '問題描述',
      name: 'description',
      placeholder: '(請盡量填寫，以便為您準備充足資料)',
      showError: 'true',
    },
  },
]

//被保險人基本資料
const formData2 = [
  {
    Type: Inputs.Radios,
    props: {
      name: 'is_insured',
      label: '是否為被保險人',
      radios: [
        {
          inline: true,
          label: '是，我自己的需求',
          value: 'true',
        },
        {
          inline: true,
          label: '否，代家人詢問',
          value: 'false',
        },
      ],
    },
  },
  {
    Type: Inputs.Input,
    props: {
      label: '被保險人生日',
      name: 'insured_birthday',
      placeholder: '1900/00/00',
      showError: true,
    },
  },
  {
    Type: Inputs.CheckBoxs,
    props: {
      label: '被保險人健康狀況',
      name: 'insured_healthy',
      checks: [
        {
          inline: false,
          label: '沒有以下狀況',
          name: 'insured_healthy_1',
        },
        {
          inline: false,
          label:
            '過去２年內，曾受健康檢查，有異常情形而被建議接受其他檢查或治療。',
          name: 'insured_healthy_2',
        },
        {
          inline: false,
          label:
            '過去５年內，曾患有下列疾病而接受治療（心血管疾病／腦血管疾病／肺部疾病／肝臟疾病／腎臟疾病／甲狀腺疾病／精神疾病）',
          name: 'insured_healthy_3',
        },
        {
          inline: false,
          label: '其他',
          name: 'insured_healthy_4',
        },
      ],
    },
  },
  {
    Type: Inputs.TextArea,
    props: {
      label: '承上題，請簡述健康狀況',
      name: 'insured_healthy_description',
      placeholder: '（請盡量填寫，以便為您準備充足資料）',
      showError: 'true',
    },
  },
  {
    Type: Inputs.Radios,
    props: {
      label: '每年可接受保費',
      name: 'acceptable_price',
      radios: [
        {
          inline: true,
          label: '1萬以下',
          value: '1',
        },
        {
          inline: true,
          label: '1萬-3萬',
          value: '2',
        },
        {
          inline: true,
          label: '3萬(含)以上',
          value: '3',
        },
      ],
    },
  },
]

//聯絡資訊
const formData3 = [
  {
    Type: Inputs.Input,
    props: {
      label: '聯絡電話',
      name: 'phone_number',
      placeholder: '0900000000 或 02-22345678',
      showError: true,
    },
  },
  {
    Type: Inputs.Input,
    props: {
      label: (
        <>
          LINE 帳號 <span className="text-gray-600">(選填)</span>
        </>
      ),
      name: 'line_id',
      placeholder: '線上對談更方便',
      showError: false,
    },
  },
  {
    Type: Inputs.CheckBoxs,
    props: {
      label: '方便聯絡時段',
      name: 'contact_time',
      checks: [
        {
          inline: true,
          label: '09:00-12:00',
          name: 'contact_time_1',
        },
        {
          inline: true,
          label: '14:00-18:00',
          name: 'contact_time_2',
        },
        {
          inline: true,
          label: '18:00-22:00',
          name: 'contact_time_3',
        },
      ],
    },
  },
  {
    Type: Inputs.Radios,
    props: {
      label: '是否需要優先服務',
      name: 'is_first_priority',
      radios: [
        {
          inline: true,
          label: '是，緊急需求，請3日內聯繫',
          value: 'true',
        },
        {
          inline: true,
          label: '否，可接受7日內聯繫',
          value: 'false',
        },
      ],
    },
  },
]

const schema = yup.object().shape({
  last_name: yup.string().required('請輸入姓氏'),
  first_name: yup.string().required('請輸入名稱'),
  gender: yup.string().required('請輸入性別'),
  location: yup.string().required('請選擇地區'),
  subject: yup.string().required('請輸入主旨'),
  description: yup.string().required('請輸入內容'),
  is_insured: yup.string().required('請選擇是否為被保險人'),
  contact_time: yup.array().required('請選擇時間'),
  insured_birthday: yup
    .string()
    .required('請輸入生日')
    .matches(
      /^(19|20)[0-9]{2}\/(0[1-9]|1[0-2])\/(0[1-9]|[1-2][0-9]|3[0-1])$/,
      '日期未完成輸入或格式錯誤 範例: 1990/01/01',
    ),
  insured_healthy: yup.array().required('請至少選擇一項'),
  insured_healthy_description: yup.string().required('請輸入內容'),
  acceptable_price: yup.string().required('請選擇可接受價格'),
  phone_number: yup.string().required('請輸入聯絡電話'),
  contact_time: yup.array().required('請至少選一個時段'),
  is_first_priority: yup.string().required('請選擇'),
  is_accept_terms: yup.bool().oneOf([true], '您必須同意該項目'),
})
export class New extends Component {
  constructor(props) {
    super(props)
    this.onSubmit = this.onSubmit.bind(this)
    console.log(props)
    this.state = { isSended: false }
  }

  onSubmit(values) {
    values = {
      utf8: '✓',
      authenticity_token: document
        .querySelector("meta[name='csrf-token']")
        .getAttribute('content'),
      online_to_offline_request: values,
    }
    let formData = new FormData()
    for (let key in values) {
      if (typeof values[key] == 'object') {
        for (let key2 in values[key]) {
          if (key2 != 'file_group') {
            formData.append(`${key}[${key2}]`, values[key][key2])
          }
        }
      } else {
        formData.append(key, values[key])
      }
    }
    Axios.post('/o2o_requests.json', formData).then((res) => {
      console.log(res)
      this.setState({ isSended: true })
    })
  }
  render() {
    return (
      <>
        <Formik
          validationSchema={schema}
          initialValues={{
            gender: '1',
            is_insured: 'true',
            first_name: 'test',
            last_name: '',
            location: '',
            last_name: '',
            first_name: '',
            gender: '',
            location: '',
            subject: '',
            description: '',
            is_insured: '',
            insured_birthday: '',
            insured_healthy: [],
            insured_healthy_description: '',
            acceptable_price: '',
            phone_number: '',
            contact_time: [],
            is_first_priority: '',
            is_accept_terms: false,
          }}
          onSubmit={this.onSubmit}
        >
          {({ values, handleSubmit, handleChange, errors, touched }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <h4 className="border-bottom border-primary text-primary font-weight-normal">
                  諮詢者基本資料
                </h4>
                {formData1_1.map((form, i) => (
                  <form.Type {...form.props} key={i} />
                ))}

                <Inputs.Selector
                  label="所在地點"
                  options={this.props.cities}
                  defaultOption="請選擇地點"
                  name="location"
                />

                {formData1_2.map((form, i) => (
                  <form.Type {...form.props} key={i} />
                ))}

                <h4 className="border-bottom border-primary text-primary font-weight-normal">
                  被保險人基本資料
                </h4>
                {formData2.map((form, i) => (
                  <form.Type {...form.props} key={i} />
                ))}

                <h4 className="border-bottom border-primary text-primary font-weight-normal">
                  聯絡資訊
                </h4>
                {formData3.map((form, i) => (
                  <form.Type {...form.props} key={i} />
                ))}
                <p className="text-center">
                  我們重視您的隱私安全，請參閱我們的
                  <a
                    href="https://events.lawbroker.com.tw/main/privacyterm-82?_ga=2.143254001.572532554.1586346522-1127652902.1586346522"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    個資保護方式
                  </a>
                </p>
                <Form.Group controlId="is_accept_terms" as={Row}>
                  <Form.Check
                    label="我已詳細閱讀並同意"
                    id="is_accept_terms"
                    type="checkbox"
                    onChange={handleChange}
                    errors={errors}
                    className="mx-auto"
                  />
                  <ErrorMessage
                    component={FormFeedback}
                    name="is_accept_terms"
                    className="text-center"
                  />
                </Form.Group>
                <Row>
                  <Col xs={12} md={6} className="mx-auto mt-4">
                    <Button
                      block
                      variant="primary"
                      type="submit"
                      className="mx-auto"
                    >
                      送出
                    </Button>
                  </Col>
                </Row>
              </Form>
            )
          }}
        </Formik>
        <MyPopup open={this.state.isSended} modal={true} noCloseBtn>
          <p className="text-center">
            您的問卷已提交
            <br />
            專人將以
            <span className="text-primary">0918-872-826</span>或
            <span className="text-primary">02-27190389</span>
            與你聯繫
            <br />
            請再留意~
          </p>
          <Row>
            <Col className="mx-auto" xs="7">
              <Button
                variant="primary"
                block
                onClick={() => window.history.back()}
              >
                返回上一頁
              </Button>
            </Col>
          </Row>
        </MyPopup>
      </>
    )
  }
}

export default New
