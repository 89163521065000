import React, { Component } from 'react'
import { Row, Col, Form, Button } from 'react-bootstrap'
import { Formik } from 'formik'
import Axios from 'axios'
import { Link } from 'react-router-dom'
import Flex from '@/components/_common/Flex'

const typesWithSuccess = [
  '商品內容諮詢',
  '保單內容變更',
  '契約終止',
  '理賠問題',
  '其他',
]
const typesWithoutSuccess = ['商品內容諮詢', '其他']

export class Ask extends Component {
  constructor(props) {
    super(props)
    if (props.policy.status != null) this.types = typesWithSuccess
    else this.types = typesWithoutSuccess
    this.state = {
      send: false,
    }
  }
  renderForm() {
    let { policy } = this.props
    return (
      <>
        <p>保單名稱：{policy && policy.package.name}</p>
        <Formik
          initialValues={{
            utf8: '✓',
            authenticity_token: document
              .querySelector("meta[name='csrf-token']")
              .getAttribute('content'),
            customer_question: {
              policy_id:
                policy && policy.id || '',
              package_id: (policy && policy.package.id) || '',
              question_type_id: this.props.type || 1,
              title: '',
              content: '',
            },
            commit: '送出',
          }}
          onSubmit={(values, actions) => {
            showLoading()
            let _this = this
            let formData = new FormData()
            for (let key in values) {
              if (typeof values[key] == 'object') {
                for (let key2 in values[key]) {
                  formData.append(`${key}[${key2}]`, values[key][key2])
                }
              } else {
                formData.append(key, values[key])
              }
            }
            Axios.post('/member/customer_questions.json', formData, {
              headers: {
                'Content-Type': 'application/json',
              },
            }).then(({ data }) => {
              hideLoading()
              if (data.success) {
                _this.setState({
                  send: true,
                })
              } else {
                alert(data.error)
                _this.props.onClose()
              }
            })
          }}
        >
          {({ values, handleChange, handleSubmit }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <p className="mb-0">問題類別</p>
                {this.types.map((type, i) => (
                  <Form.Check
                    key={i}
                    inline
                    type="radio"
                    label={type}
                    value={i + 1}
                    checked={values.customer_question.question_type_id == i + 1}
                    onChange={handleChange}
                    name="customer_question[question_type_id]"
                  ></Form.Check>
                ))}
                <Form.Group className="mt-3">
                  <Form.Label>問題詳述</Form.Label>
                  <Form.Control
                    as="textarea"
                    value={values.customer_question.content}
                    name="customer_question[content]"
                    onChange={handleChange}
                  />
                </Form.Group>
                <Row>
                  <Col className="mx-auto" xs="12" md="6">
                    <Button variant="primary" type="submit" size="lg" block>
                      送出
                    </Button>
                  </Col>
                </Row>
              </Form>
            )
          }}
        </Formik>
      </>
    )
  }
  renderSend() {
    return (
      <div className="text-center">
        <p className="m-0">您的問題已送出，請稍待客服人員回覆；</p>
        <p>欲查詢相關客服紀錄請前往[個人訊息]。</p>
        <Flex justify="center">
          <Button
            variant="outline-gray-500"
            onClick={() => this.props.onClose()}
            className="mr-3"
          >
            取消
          </Button>
          <Link to="/member/customer_questions">
            <Button variant="primary">前往</Button>
          </Link>
        </Flex>
      </div>
    )
  }
  render() {
    return (
      <div>
        <h2 className="text-center">{this.props.title}</h2>
        {this.state.send ? this.renderSend() : this.renderForm()}
      </div>
    )
  }
}

export default Ask
