import React, { Component } from 'react'
import { MemberCard as Card, Flex, MemberTag } from './index'
import { SvgIcon } from '@/components/_common/SvgIcon'
import { Col, Row } from 'react-bootstrap'
import styled from 'styled-components'
import { NavLink, Link } from 'react-router-dom'

const Avatar = styled.div`
  overflow: hidden;
  border-radius: 50%;
  width: ${({ width, height }) => (width || height || '50') + 'px'};
  height: ${({ height, width }) => (height || width || '50') + 'px'};
`
const Button = styled(NavLink)`
  cursor: pointer;
  border-radius: 5px;
  margin: 5px;
  padding: 10px 5px;
  width: 100%;
  text-align: center;
  p {
    margin-bottom: 0;
    border-width: 0 0 2px 0;
    border-style: solid;
    border-color: ${({ active, theme }) =>
      active ? theme.color.primary : 'transparent'};
    font-weight: ${({ active, theme }) =>
      active ? theme.fontWeight.normal : theme.fontWeight.lighter};
    color: ${({ theme }) => theme.color.primaryDark};
    width: fit-content;
    margin-right: auto;
    margin-left: auto;
    transition: all 0.3s ease;
  }
  svg {
    transition: all 0.3s ease;
  }
  &.actived {
    p {
      border-color: ${({ theme }) => theme.color.primary};
      font-weight: ${({ theme }) => theme.fontWeight.normal};
    }
  }
  &:hover {
    svg {
      fill: ${({ theme }) => theme.color.primary};
    }
    background-color: ${({ theme }) => theme.grayColor.gray100};
    p {
      border-color: ${({ theme }) => theme.color.primary};
      /* font-weight: ${({ theme }) => theme.fontWeight.normal}; */
      color: ${({ theme }) => theme.color.primary};
    }
  }
`

export class MemberBox extends Component {
  render() {
    return (
      <div>
        <Card>
          <Flex center column>
            <Link to="/member">
              <Avatar height={64}>
                <SvgIcon
                  height={64}
                  href={
                    this.props.gender == 'M' ? '#member_male' : '#member_female'
                  }
                />
              </Avatar>
            </Link>
            <h4 className="m-0">{this.props.memberName}</h4>
            <MemberTag className="bg-primary-dark">一般會員</MemberTag>
          </Flex>
          <Flex className="mt-2">
            <Button to="/member/policies" activeClassName="actived">
              <SvgIcon width={25} href="#i-my-policy" />
              <p>我的保單</p>
            </Button>
            <Button to="/member/edit" activeClassName="actived">
              <SvgIcon width={25} href="#i-profile" />
              <p>資料修改</p>
            </Button>
          </Flex>
        </Card>
      </div>
    )
  }
}

export default MemberBox
