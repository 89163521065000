import React, { useEffect, useState } from 'react'
import { ThemeProvider } from 'styled-components'
import { Col, Row, Container, Button } from 'react-bootstrap'
import themes from '@/components/Themes'
import axios from 'axios'
import PolicyLoading from './PolicyLoading'
import PolicyTitle from './PolicyTitle'
import { MemberCard as Card, Flex, MemberTag, Title } from '../../common/index'
import moment from "moment"
import { SvgIcon } from '@/components/_common/SvgIcon'
import Contents, { Terms } from './Contents'
import PolicyTable, { TitleWithLine } from './PolicyTable'
import PolicyTables from './PolicyTableWithoutTitle'
import {
  getPolicyInfo,
  getInsuredList,
  getBeneficiaryList,
  getProposerList,
  getTravelPolicyInfo,
  getVehiclePolicyInfo,
  getPetPolicyInfo
} from './getInfo'

const termsApi = '/packages/terms/'



function PolicyShow({ match }) {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState(null)
  const [terms, setTerms] = useState(null)

  const fetchData = () => {
    const api = `/member/policies/${match.params.id}.json`
    axios.get(api).then((response) => {
      setData(response.data)
      setLoading(false)
    })
  }

  const handleFetchTerm = () => {
    axios
      .get(termsApi, { params: { id: data.package.id } })
      .then((response) => {
        setTerms(response.data)
        console.log(response)
      })
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <ThemeProvider theme={themes}>
      <Container className="pt-3 pt-md-5">
        {loading && <PolicyLoading />}
        {data && (
          <>
            <Card>
              <PolicyTitle
                image={data.insurance_company.image.url}
                policyName={data.package.name}
                companyName={
                  data.insurance_company.name
                }
                createdAt={moment(data.created_at).format("YYYY/MM/DD HH:mm")}
              ></PolicyTitle>
              <div className="m-0 mx-md-5">
                <PolicyTable
                  data={getPolicyInfo(data)}
                  title="保單基本項目"
                />

                {["motor", "car"].includes(data.policy_type) && (
                  <PolicyTable
                    data={getVehiclePolicyInfo(data)}
                    title="車籍資料"
                  />
                )}

                {data.policy_type === "travel" && (
                  <PolicyTable
                    data={getTravelPolicyInfo(data)}
                    title="旅遊資訊"
                  />
                )}

                {data.policy_type === "pet" && (
                  <PolicyTable
                    data={getPetPolicyInfo(data)}
                    title="被保寵物"
                  />
                )}
              </div>

              <div className="m-0 mx-md-5">
                <PolicyTable
                  data={getProposerList(data.member)}
                  title="要保人"
                />
                {["travel", "vaccine"].includes(data.policy_type) && (
                  <PolicyTable
                    data={getInsuredList(data.insured)}
                    title="被保人"
                  />
                )}

                {data.beneficiaries.length > 0 && <TitleWithLine>受益人</TitleWithLine>}
                {data.beneficiaries.map(bene => (
                  <PolicyTables
                    data={getBeneficiaryList(bene, { allocation: data.insured.allocation })}
                  />
                ))}
              </div>
              <Row className="my-3 mt-4">
                <div className="justify-content-end ml-auto col-auto" />
              </Row>
            </Card>
            <Card>
              <div className="m-0 mx-md-3 mt-md-4">
                <Title>
                  <SvgIcon width={38} href="#i-my-policy" />
                  <h3 className="m-0">保單內容</h3>
                  <Terms
                    onOpen={handleFetchTerm}
                    terms={terms || []}
                  ></Terms>
                </Title>
                {<Contents products={data.plan_products}></Contents>}
                <Row className="my-3 mt-4">
                  <div className="justify-content-end ml-auto col-auto" />
                  <Col xs={6} md={3} className="align-self-conter px-4">
                    {data.package["can_buy_now?"] ? (
                      <Button
                        variant="primary"
                        block
                        onClick={() =>
                          (window.location = `/packages/${data.package.id}`)
                        }
                      >
                        再次購買
                      </Button>
                    ) : (
                      <Button
                        variant="primary"
                        disabled
                        size="lg"
                        className="ml-auto"
                      >
                        暫停銷售
                      </Button>
                    )}
                  </Col>
                </Row>
              </div>
            </Card>
          </>
        )}
      </Container>
    </ThemeProvider>
  )
}

export default PolicyShow