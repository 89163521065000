import yup from "./yup";

const kycSchema = yup.object().shape({
  is_career_type_about_laundering_money: yup
    .bool()
    .default(false)
    .oneOf([false]),

  is_buy_other_plan: yup.bool().default(false).required(),
  is_pregnant: yup.bool().default(false).oneOf([false]),
  is_guardian_declare: yup.bool().default(false).oneOf([false]),
  is_live_abroad: yup.bool().default(false).oneOf([false]),
  has_political_job: yup.bool().default(false).oneOf([false]),
  personal_purpose_and_demand: yup.array().default([0]).min(1),
  does_not_care_plan_product: yup.bool().default(false).oneOf([false]),
  source_of_insurance_expenses: yup.array().default([0]).min(1),
  cases_of_contract_change_loan_borrow_in_three_months: yup
    .bool()
    .default(false)
    .oneOf([false]),
  has_other_country_id_or_not_born_in_roc: yup
    .bool()
    .default(false)
    .oneOf([false]),
  ctbc_source_of_insurance_expenses: yup
    .string()
    .default("-")
    .required()
    .test(
      "invalid_option",
      "保險公司無法受理您的投保，敬請見諒。如有疑問請於上班日營業時間洽客服人員，謝謝。",
      (value) => value !== "loan" && value !== "insurance_cancellation"
    ),
  understanding_insured_fee: yup.bool().default(false).oneOf([false]),
  mega_no_other_travel_policy: yup.bool().default(true).oneOf([true]),
  is_buy_other_travel_plan: yup.bool().default(false).oneOf([false]),
  personal_income: yup.string().default("-").required(),
  whole_family_income: yup.string().default("-").required(),
  family_main_income_amount: yup.string().default("-").required(),
  family_main_income_provider: yup.string().default("-").required(),
  // career_level:
  // career_options
  // job_title_options
  self_manage: yup.bool().default(false).oneOf([false]),
  renew_insurance: yup.bool().default(false).default(false).required(),
  other_infectious_policy: yup.bool().default(false).required(),
  // other_infectious_policy_remark: yup.string().default("-").required(),
  other_vaccine_policy: yup.bool().default(false).required(),
  // other_vaccine_policy_remark: yup.string().default("-").required(),
  physical_disabilities: yup.bool().default(false).oneOf([false]),
  already_vaccinated_in_one_year: yup.bool().default(false).required(),
  treatment_received_in_two_months: yup.bool().default(false).oneOf([false]),
  treatment_received_in_three_months: yup.bool().default(false).oneOf([false]),
  pet_physical_disabilities: yup.bool().default(false).oneOf([false]),
  other_pet_policy: yup.bool().default(false).oneOf([false]),
  medical_exam_abnormal: yup.bool().default(false).oneOf([false]),
  pet_physical_disabilities2: yup.bool().default(false).oneOf([false]),
  is_electric: yup.bool().default(false).oneOf([false]),
});

export default kycSchema;
