import React, { useContext, useEffect, useRef, useState } from 'react'
import context from '../context'
import useScrollDirection from '../useScrollDirection'
import { isMobile } from 'react-device-detect'
import Travel from './Travel'
import Sport from './Sport'
import Accident from './Accident'
import Motor from './Motor'
import Car from './Car'
import BuyBtn from '../components/BuyBtn'
import CountUp from 'react-countup'


const SubTitle = ({ type, otherInfo, options }) => {
  console.log(type)
  switch (type) {
    case "travel":
      return (<Travel otherInfo={otherInfo} />)
    case "Sport":
      return (<Sport otherInfo={otherInfo} />)
    case "car":
      return (<Car otherInfo={otherInfo} options={options} />)
    case "motor":
      return (<Motor otherInfo={otherInfo} options={options} />)
    case "accident":
      return (<Accident />)
    default:
      return null
  }
}

function BottomBar() {
  const { productInfo, type, options, isLoading, handleBuyBtnClick } = useContext(context)
  const { package_name, insurence_company, ...otherInfo } = productInfo.otherInfo
  const { fee, buyUrl, isActive } = productInfo
  const scrollDir = useScrollDirection("down", null, isMobile)

  const [feeCounter, setFeeCounter] = useState(0)
  const prevFeeCounterRef = useRef();
  useEffect(() => {
    prevFeeCounterRef.current = feeCounter
  })
  const prevFeeCounter = prevFeeCounterRef.current

  useEffect(() => {
    setFeeCounter(fee)
  }, [fee])


  return (
    <div className={`bottom ${!isMobile && "hideUp"} ${scrollDir == "up" && 'hideUp'}`}>
      <div className="row">
        <div className="col ml-2">
          <h6 className="title-secondary mb-1">
            {package_name}
          </h6>
          <p className="text-gray small mb-1">{insurence_company}</p>
        </div>
        <div className="col-auto text-right">
          <h5 className="text-secondary mb-0">
            {feeCounter ?
              <CountUp prefix="$" suffix=" 元" duration={1.5} start={Number(prevFeeCounter) || 0} end={Number(feeCounter)}></CountUp> :
              "$ -- 元"
            }
          </h5>
          <SubTitle type={type} otherInfo={otherInfo} options={options} />
        </div>
      </div>
      <BuyBtn
        url={buyUrl}
        disabled={!isActive || !fee || isLoading}
        isActive={isActive}
        className="btn-block"
        onClick={handleBuyBtnClick}
      />
    </div>
  )
}

export default BottomBar