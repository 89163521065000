import React, { Component, useState } from 'react'
import styled from 'styled-components'
import { Container, Button } from 'react-bootstrap'
import Flex from '@/components/_common/Flex'

const Ol = styled.ol`
  list-style: none;
  counter-reset: li;
  padding-left: ${({ theme }) => theme.spacers[4]};
`
const Li = styled.li`
  padding-left: ${({ theme }) => theme.spacers[3]};
  margin-bottom: ${({ theme }) => theme.spacers[3]};
  &::before {
    counter-increment: li;
    content: '(' counter(li) ') ';
    display: inline-block;
    width: ${({ theme }) => theme.spacers[5]};
    margin-left: -${({ theme }) => theme.spacers[5]};
    padding-right: ${({ theme }) => theme.spacers[3]};
    text-align: right;
  }
`

const terms = [
  '參加本活動並留下個人基本資料者，即表示同意於法令允許範圍內以電子檔或紙本形式於我國境內蒐集、處理及利用其個人資料。前述個人資料可用於廣告行銷活動資訊通知、中獎訊息通知、寄送獎品、申報稅務資料、活動統計等與本活動相關之處理或利用。參加本活動者可自由選擇是否提供完整個人資訊，惟若不願提供或提供資訊不完整者，將無法參加本活動或喪失得獎資格。可電洽本公司（02-25455970*355），針對其個人資料行使請求查詢、提供閱覽、製給複本、更正、補充、停止蒐集、處理、利用或刪除之權利。',
  '參加者於參加本活動之同時，即同意接受本活動辦法、本活動網站使用規範及其他有關規定；若有違反，本公司得取消其參加資格，如為得獎人，則取消得獎資格。',
  '嚴禁參加者參加本活動時，使用不當程式或方式違反活動公平性，本公司有權取消參加或得獎資格並保留法律追訴權的權利。',
  '參加者聲明並承諾所有填寫或提出之資料均為真實、正確且未冒用或盜用任何第三人之資料；若有冒用或盜用他人資料，或個人資料有不實、不齊全或不正確之情事，將被取消參加或得獎資格。如因此致本公司無法通知其得獎訊息時，本公司不負任何責任，如因而致本公司或其他任何第三人受有任何損害，參加者應負一切相關法律責任。',
]
const terms2 = [
  '如有任何因行動裝置、電腦、網路、電話、技術或不可歸責於本公司之事由，而使參加者所登錄之資料有遲延、遺失、錯誤、無法辨識或毀損等情況，本公司不負任何法律責任，參加者亦不得因此異議或請求賠償。',
  '本網站若因任何非本公司可掌握之因素(包含但不限於以下原因)，如程式錯誤...等，而造成本活動未能如預期之規劃執行，致侵害或影響其他消費者權益、活動公平性及正當性者，本公司將保留取消、終止、或修改及暫停本活動之權利，並有權取消任何影響他人得獎權益之得獎人的得獎資格。',
  '本活動僅限在台灣地區（包含金門、馬祖、澎湖）設有住居所之本國人參加。獎品之寄送亦僅限前述地區，如獎品寄送地點為前述地區以外之地區，本公司得取消得獎資格。',
  '本活動所提供之獎品不得折換現金、兌換其他商品或優惠，亦不得將本活動得獎資格轉讓或轉售予他人，本公司保有更換同值獎品之權利。獎品相關使用辦法請依照兌換券內容說明為準。一旦得獎人領取獎品後，若有遺失或被竊，本公司不發給任何證明或補償。',
  '投保活動之獎項屬於機會中獎，依中華民國所得稅法及各類所得扣繳率標準規定，機會中獎金額超過新台幣20,000元者，得獎者需先行繳納10%機會中獎所得稅款(不足1元部分，則四捨五入至元)，詳細規定，請參閱財政部稅務入口網。無論扣繳與否，均需列入活動得獎人當年度之個人機會中獎所得，並由本公司開立扣繳憑單予活動得獎人，活動得獎人需依規定填寫並繳交相關資料方可領獎。如不願意配合者，則視為自動放棄得獎資格。',
  '以上事項請詳細閱讀，參加活動即視同參加者接受上述事項之約束。',
]

const Terms = (props) => {
  let [isOpen, setIsOpen] = useState(false)
  return (
    <div style={{ backgroundColor: '#fff' }}>
      <Container className="py-4 py-md-5">
        <h4>注意事項</h4>
        <Ol>
          {terms.map((term, i) => (
            <Li key={i}>{term}</Li>
          ))}
          {isOpen ? terms2.map((term, i) => <Li key={i + 5}>{term}</Li>) : null}
        </Ol>
        {!isOpen ? (
          <div>
            <hr className="mb-2" />
            <Flex>
              <Button
                variant="link"
                className="mx-auto"
                onClick={() => setIsOpen(!isOpen)}
              >
                <i className="fas fa-angle-down mr-2"></i>
                展開看更多
              </Button>
            </Flex>
          </div>
        ) : (
          <div>
            <hr className="mb-2" />
            <Flex>
              <Button
                variant="link"
                className="mx-auto"
                onClick={() => setIsOpen(!isOpen)}
              >
                <i className="fas fa-angle-up mr-2"></i>
                收合
              </Button>
            </Flex>
          </div>
        )}
      </Container>
    </div>
  )
}

export default Terms
