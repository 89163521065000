import React from 'react'
import { Container } from 'react-bootstrap'

function Completed() {
  return (
    <Container>
      <h1 className="text-center">新密碼設定完成</h1>
      <p>請使用新密碼重新登入</p>
    </Container>
  )
}

export default Completed
