const { ref } = require('yup')

import React from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'
import { Context } from '.'

function NoAnswer(props) {
  return (
    <Context.Consumer>
      {({ handleContactUsClcik }) => (
        <section className={props.bgGray ? 'bg-gray-100' : ''}>
          <Container className="py-5">
            <div className="mb-5">
              <h3 className="text-center">找不到解答?</h3>
              <Row>
                <Col xs={12} md={6} className="mx-auto">
                  <Button block onClick={handleContactUsClcik} size="lg">
                    諮詢 SARAcares
                  </Button>
                </Col>
              </Row>
            </div>
          </Container>
        </section>
      )}
    </Context.Consumer>
  )
}

export default NoAnswer
