import React, { Component } from 'react'
import { MyPopup } from '@/components/_common/StyledPopup'
import { Button, Table } from 'react-bootstrap'
import { Flex } from '../common/index'
import MemberContext from '@/components/_context/MemberContext'
import { Scroll, Thead, Tbody, Th } from './Tables'
import { Scrollbars } from 'react-custom-scrollbars'
import { styled } from 'styled-components'

export class AwardsRecord extends Component {
  renderRedPointTable() {
    return (
      <Scrollbars autoHeight autoHide autoHeightMin="400px">
        <Table borderless>
          <Thead>
            <tr>
              <Th>活動名稱</Th>
              <Th>贈品名稱</Th>
              <Th>使用點數</Th>
              <Th>使用日期</Th>
            </tr>
          </Thead>
          <Tbody>
            {this.props.awardHistory &&
              this.props.awardHistory.map((p, i) => {
                return (
                  <tr key={i}>
                    <td>{p.activity_name}</td>
                    <td>{p.award_name}</td>
                    <td>{p.cost}</td>
                    <td>{p.received_at}</td>
                  </tr>
                )
              })}
          </Tbody>
        </Table>
      </Scrollbars>
    )
  }
  renderSweep() {
    return (
      <Scrollbars autoHeight autoHide autoHeightMin="400px">
        <Table borderless>
          <Thead>
            <tr>
              <Th>活動名稱</Th>
              <Th>贈品名稱</Th>
              <Th>抽獎日期</Th>
              <Th>領取日期</Th>
            </tr>
          </Thead>
          <Tbody>
            {this.props.awardHistory &&
              this.props.awardHistory.map((p, i) => {
                return (
                  <tr key={i}>
                    <td>{p.activity_name}</td>
                    <td>{p.award_name}</td>
                    <td>{p.created_at}</td>
                    <td>{p.received_at}</td>
                  </tr>
                )
              })}
          </Tbody>
        </Table>
      </Scrollbars>
    )
  }
  render() {
    console.log(this.props.awardHistory)
    return (
      <MemberContext.Consumer>
        {() => (
          <MyPopup
            trigger={
              <Button variant="outline-primary ml-auto">
                {this.props.buttonTitle}
              </Button>
            }
            onOpen={() => this.props.getAwardsHistory(this.props.type)}
            modal
          >
            <div className="text-center">
              <h4>{this.props.title}</h4>
              {this.props.awardHistory &&
              this.props.awardHistory.length == 0 ? (
                <Flex center justify="center" className="w-100">
                  <h3 className="text-gray-500 text-center py-5">
                    尚無任何紀錄
                  </h3>
                </Flex>
              ) : this.props.type == 'red_points' ? (
                this.renderRedPointTable()
              ) : (
                this.renderSweep()
              )}
            </div>
          </MyPopup>
        )}
      </MemberContext.Consumer>
    )
  }
}

export default AwardsRecord
