import React, { Component } from 'react'
import { ThemeProvider } from 'styled-components'
import { Container } from 'react-bootstrap'
import DefaultTheme from '@/components/Themes'
import SearchBar from './SearchBar'
import SearchResult from './SearchResult'
import FaqList from './FaqList'
import Axios from 'axios'
import FaqType from './FaqType'
import NoAnswer from './NoAnswer'
import ContactUs from './ContactUs'

export const Context = React.createContext()

export class Faq extends Component {
  constructor(props) {
    super(props)
    this.state = {
      handleFaqClick: this.handleFaqClick.bind(this),
      handleSearching: this.handleSearching.bind(this),
      handleSearchBarChange: this.handleSearchBarChange.bind(this),
      handleBackBtnClick: this.handleBackBtnClick.bind(this),
      handleFaqTypeClcik: this.handleFaqTypeClcik.bind(this),
      handleContactUsClcik: this.handleContactUsClcik.bind(this),
      currentEventKey: null,
      currentFaqType: 'default',
      currentFaqContent: {
        title: '',
        faqs: [],
      },
      defaultFaqContent: {
        title: '',
        faqs: [],
      },
      faqType: [],
      keyword: '',
      currentKeyword: '',
      searchResult: [],
      searchCount: 0,
      isOnSearch: false,
      isContactUs: props.contact_us,
    }
  }
  handleFaqTypeClcik(type) {
    showLoading()
    this.getFaqListContent(type)
  }

  componentDidMount() {
    let _this = this
    Axios.get(`/customer-center/${this.state.currentFaqType}.json`).then(
      ({ data }) => {
        _this.setState({ defaultFaqContent: data, currentFaqContent: data })
      },
    )
    this.getFaqType()
    require('smoothscroll-polyfill').polyfill()
  }

  handleContactUsClcik() {
    this.setState({ isContactUs: true })
  }

  getFaqListContent(type) {
    console.log(type)
    let _this = this
    Axios.get(`/customer-center/${type}.json`).then(({ data }) => {
      hideLoading()
      _this.setState({ currentFaqContent: data, currentFaqType: type })
      if (type != 'default') _this.smoothScroll()
    })
  }

  getFaqType() {
    let _this = this
    Axios.get('/customer-center-types').then(({ data }) => {
      _this.setState({ faqType: data })
    })
  }

  handleFaqClick(currentEventKey) {
    if (this.state.currentEventKey == currentEventKey) {
      this.setState({ currentEventKey: null })
      return
    }
    this.setState({ currentEventKey })
  }

  handleSearchBarChange(e) {
    this.setState({ keyword: e.target.value })
  }

  handleSearching(keyword) {
    this.setState({ keyword })
    showLoading()
    let _this = this
    Axios.get(`/customer-center/search?q=${keyword}`).then(({ data }) => {
      hideLoading()
      _this.setState({
        searchResult: data,
        isOnSearch: true,
        currentKeyword: keyword,
      })
    })
  }

  handleBackBtnClick() {
    this.setState({
      isOnSearch: false,
      isContactUs: false,
      keyword: '',
      currentFaqType: 'default',
      currentFaqContent: this.state.defaultFaqContent,
    })
  }

  smoothScroll() {
    let offset = () => 0
    const $anchor = document.getElementById('anchor')
    const offsetTop =
      $anchor.getBoundingClientRect().top + window.pageYOffset - 80
    console.log($anchor, offsetTop + 160)
    window.scroll({
      top: offsetTop - offset(),
      behavior: 'smooth',
    })
  }

  render() {
    return (
      <Context.Provider value={this.state}>
        <ThemeProvider theme={DefaultTheme}>
          {this.state.isContactUs ? (
            <ContactUs />
          ) : (
            <>
              <SearchBar></SearchBar>
              {this.state.isOnSearch ? <SearchResult /> : <FaqList />}
              {this.state.currentFaqType != 'default' ||
              this.state.isOnSearch ? (
                <NoAnswer />
              ) : (
                <FaqType />
              )}
            </>
          )}
        </ThemeProvider>
      </Context.Provider>
    )
  }
}

export default Faq
