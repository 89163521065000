import React from 'react'
import { formik } from 'formik'
import { Formik, Field, ErrorMessage } from 'formik'
import { FormFeedback, FormControl } from '@/components/_common/Inputs'
import {
  Row,
  Col,
  Form,
  Button,
  Spinner,
  Badge,
  InputGroup,
} from 'react-bootstrap'
import SubmitBtn from '../../components/SubmitBtn'
import { withContext } from './MobileContext'
import withToken from '@/components/_common/withToken'

const authenticationValues = {
  mobile_authentication: {
    verify_code: '',
  },
}

function OTPForm({ context }) {
  return (
    <Formik
      initialValues={withToken(authenticationValues)}
      onSubmit={context.authenticationSubmitHandelr}
    >
      {({ values, handleSubmit }) => {
        return (
          <>
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col xs={12} md={9} className="ml-auto">
                  <Form.Group as={Row}>
                    <Col xs={12} className="d-flex align-items-center">
                      
                      <InputGroup>
                        <InputGroup.Prepend>
                          <InputGroup.Text>{`${context.mobileAuthentication.checkCode} - `}</InputGroup.Text>
                        </InputGroup.Prepend>

                        <Field
                          type="text"
                          name="mobile_authentication[verify_code]"
                          placeholder="手機驗證碼"
                          component={FormControl}
                        />

                        <InputGroup.Append>
                          {context.mobileAuthentication.isAuthenticated ? (
                            <Badge
                              variant="primary-dark"
                              className="p-2"
                              style={{
                                borderTopLeftRadius: 0,
                                borderBottomLeftRadius: 0,
                              }}
                            >
                              <p className="mb-0">
                                <i className="fas fa-check" />
                                已認證
                              </p>
                            </Badge>
                          ) : (
                            <Button
                              type="submit"
                              variant="primary"
                              disabled={context.isLoading}
                            >
                              送出
                              {context.isLoading && (
                                <Spinner
                                  as="span"
                                  animation="border"
                                  role="status"
                                  size="sm"
                                  aria-hidden="true"
                                  className="mb-1 ml-2"
                                />
                              )}
                            </Button>
                          )}
                        </InputGroup.Append>
                      </InputGroup>
                    </Col>
                    <Col>
                      <ErrorMessage
                        name="mobile_authentication[verify_code]"
                        component={FormFeedback}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Form>
            <SubmitBtn
              isLoading={context.isLoading}
              disabled={context.isBtnDisabled}
              onClick={context.goNextStep}
            >
              下一步
            </SubmitBtn>
          </>
        )
      }}
    </Formik>
  )
}

export default withContext(OTPForm)
