import React, { Component } from 'react'
import { Flex } from './index'
import styled from 'styled-components'
import Loading from '@/components/_common/Loading'

const PolicyBox = styled.div`
  border: 1px solid ${({ theme }) => theme.grayColor.gray500};
  border-radius: ${({ theme }) => theme.borderRadius};
  margin-top: ${({ theme }) => theme.spacers[3]};
  margin-bottom: ${({ theme }) => theme.spacers[3]};
  padding: ${({ theme }) => theme.spacers[3]};
  padding-top: ${({ theme }) => theme.spacers[2]};
  padding-bottom: ${({ theme }) => theme.spacers[2]};
`
const Title = styled(Flex)`
  border-width: 0 0 1px 0;
  border-color: ${({ theme }) => theme.grayColor.gray500};
  border-style: solid;
  padding-bottom: ${({ theme }) => theme.spacers[2]};
  margin-bottom: ${({ theme }) => theme.spacers[2]};
  padding-left: ${({ theme }) => theme.spacers[1]};
  padding-right: ${({ theme }) => theme.spacers[1]};
`

const Footer = styled.div`
  border-width: 1px 0 0 0;
  border-color: ${({ theme }) => theme.grayColor.gray500};
  border-style: solid;
  padding-top: ${({ theme }) => theme.spacers[2]};
  margin-top: ${({ theme }) => theme.spacers[3]};
  display: flex;
  align-items: center;
  padding-left: ${({ theme }) => theme.spacers[1]};
  padding-right: ${({ theme }) => theme.spacers[1]};
`

export class PolicyLoading extends Component {
  render() {
    return (
      <PolicyBox>
        <Title center>
          <Loading height={25} width={170}></Loading>
        </Title>
        <div className="px-1">
          <Flex>
            <Loading height={20} width={110}></Loading>
            <Loading height={20} width={90} className="ml-auto"></Loading>
          </Flex>
          <div className="mt-2 d-flex">
            <Loading height={100} width={120} className="mr-3"></Loading>
            <Loading height={100} width={120} className="mr-3"></Loading>
            <Loading height={100} width={120} className="mr-3"></Loading>
          </div>
        </div>
        <Footer>
          <Loading height={20} width={130}></Loading>
          <div className="ml-auto d-flex">
            <Loading
              height={38}
              width={90}
              className="mr-3 d-none d-md-flex"
            ></Loading>
            <Loading height={38} width={90} className=""></Loading>
          </div>
        </Footer>
      </PolicyBox>
    )
  }
}

export default PolicyLoading
