import React, { Component } from 'react'
import LotteryCards from './LotteryCards'
import styled from 'styled-components'
import LotteryContext from '@/components/_context/LotteryContext'

const Wrapper = styled.div`
  width: ${({ width }) => width || 'auto'};
  transition: all 0.25s ease-out;
`

export class Lottery extends Component {
  constructor(props) {
    super(props)
    this.state = {
      width: 0,
      button: 0,
      cardLeft: 0,
      cardRight: 0,
      BoxHeight: 350,
      BtnWidth: 100,
      CardWidth: 100,
    }
    this.box = React.createRef()
  }

  updateSize() {
    this.setState({
      width: this.box.current.clientWidth,
      height:
        this.box.current.clientWidth < 576
          ? this.box.current.clientWidth * 0.5
          : this.box.current.clientWidth * 0.27,
    })
  }

  componentDidMount() {
    this.setState({
      width: this.box.current.clientWidth,
      height:
        this.box.current.clientWidth < 576
          ? this.box.current.clientWidth * 0.5
          : this.box.current.clientWidth * 0.27,
    })
    window.addEventListener('resize', this.updateSize.bind(this))
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateSize)
  }

  render() {
    return (
      <LotteryContext.Consumer>
        {({ isStart, isRerender, buttonClickHnadler, animateEndHandler }) => (
          <LotteryCards.Box ref={this.box} height={this.state.height}>
            <LotteryCards.Button
              isStart={isStart}
              isRerender={isRerender}
              onClick={buttonClickHnadler}
              _width={this.state.width}
            />
            <LotteryCards.ChanceCard
              isStart={isStart}
              isRerender={isRerender}
              onAnimationEnd={animateEndHandler}
              _width={this.state.width}
            />
            <LotteryCards.FateCard
              isStart={isStart}
              isRerender={isRerender}
              onAnimationEnd={animateEndHandler}
              _width={this.state.width}
            />
          </LotteryCards.Box>
        )}
      </LotteryContext.Consumer>
    )
  }
}

//Lottery.contextType = LotteryContext
export default Lottery
