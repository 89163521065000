import React, { Component } from 'react'
import { Formik, Field } from 'formik'
import { Form, Button, Col, Row } from 'react-bootstrap'
import styled, { ThemeProvider } from 'styled-components'
import DefaultTheme from '@/components/Themes'
import axios from 'axios'
import * as yup from 'yup'
import { MyPopup } from '@/components/_common/StyledPopup'

const FormFile = styled(Form.File)`
  input:focus ~ .custom-file-label {
    box-shadow: none !important;
    border-color: ${({ theme }) => theme.color.primary};
  }
  label {
    color: ${({ theme }) => theme.grayColor.gray500};

    &:after {
      background-color: ${({ theme }) => theme.color.primary};
      border-color: ${({ theme }) => theme.color.primary};
      color: white;
    }
  }
`

const phoneRegExp = /09[0-9]{8}/
const emailRegExp = /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/

const schema = yup.object().shape({
  cooperation: yup.object().shape({
    company_title: yup.string().required('請輸入公司名稱'),
    contact_person: yup.string().required('請輸入姓名'),
    contact_number: yup
      .string()
      .required('請輸入電話')
      .matches(phoneRegExp, '電話格式錯誤'),
    contact_email: yup.string().required('請輸入Email').email('email格式錯誤'),
    category: yup.string().required('請選擇類別'),
    subject: yup.string().required('請輸入主旨'),
    file_group: yup
      .mixed()
      .test('fileSize', '檔案過大，請重新選擇', (value) => {
        return value.length == 0 || value[0].size <= 1024 * 1024 * 3
      }),
  }),
})

export class Cooperations extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isSending: false,
      isSended: false,
      message: '',
    }
    this.initialValues = {
      utf8: '✓',
      authenticity_token: document
        .querySelector("meta[name='csrf-token']")
        .getAttribute('content'),
      cooperation: {
        company_title: '',
        contact_person: '',
        contact_number: '',
        contact_email: '',
        category: '',
        subject: '',
        file_group: [],
      },
    }
  }

  onSubmit = (values, actions) => {
    let _this = this
    showLoading()
    let formData = new FormData()
    for (let key in values) {
      if (typeof values[key] == 'object') {
        for (let key2 in values[key]) {
          if (key2 != 'file_group') {
            formData.append(`${key}[${key2}]`, values[key][key2])
          }
        }
      } else {
        formData.append(key, values[key])
      }
    }
    formData.append(
      'cooperation[file_group][]',
      values.cooperation.file_group[0],
    )

    axios
      .post('/cooperations', formData, {
        header: { 'Content-Type': 'text/html; charset=utf-8' },
      })
      .then((response) => {
        hideLoading()
        _this.setState({ isSended: true, message: response.data.message })
      })
  }

  onClose() {
    this.setState({
      isSended: false,
    })
  }

  renderPopup() {
    return (
      <MyPopup
        open={this.state.isSended}
        modal={true}
        close={this.onClose.bind(this)}
      >
        <h3 className="text-center my-5">
          <i className="fas fa-check-circle mr-2"></i>
          {this.state.message}
        </h3>
      </MyPopup>
    )
  }

  render() {
    return (
      <ThemeProvider theme={DefaultTheme}>
        {this.renderPopup()}
        <Formik
          initialValues={this.initialValues}
          onSubmit={this.onSubmit.bind(this)}
          validationSchema={schema}
        >
          {({ values, handleChange, errors, handleSubmit, initialValues }) => {
            let _errors = errors.cooperation
            return (
              <Form className="py-4" onSubmit={handleSubmit}>
                <Form.Group as={Row} controlId="formBasicEmail">
                  <Form.Label column xs={12} md={3}>
                    公司名稱
                  </Form.Label>
                  <Col xs={12} md={9}>
                    <Form.Control
                      name="cooperation[company_title]"
                      value={values.cooperation.company_title}
                      onChange={handleChange}
                      type="name"
                      placeholder=""
                    />
                    <Form.Control.Feedback type={'invalid'} className="d-block">
                      {_errors && _errors.company_title}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="formBasicEmail">
                  <Form.Label column xs={12} md={3}>
                    聯絡人
                  </Form.Label>
                  <Col xs={12} md={9}>
                    <Form.Control
                      name="cooperation[contact_person]"
                      value={values.cooperation.contact_person}
                      onChange={handleChange}
                      type="name"
                      placeholder=""
                    />
                    <Form.Control.Feedback type={'invalid'} className="d-block">
                      {_errors && _errors.contact_person}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="formBasicEmail">
                  <Form.Label column xs={12} md={3}>
                    聯絡人電話
                  </Form.Label>
                  <Col xs={12} md={9}>
                    <Form.Control
                      name="cooperation[contact_number]"
                      value={values.cooperation.contact_number}
                      onChange={handleChange}
                      type="phone"
                      placeholder=""
                    />
                    <Form.Control.Feedback type={'invalid'} className="d-block">
                      {_errors && _errors.contact_number}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="formBasicEmail">
                  <Form.Label column xs={12} md={3}>
                    聯絡人 E-mail
                  </Form.Label>
                  <Col xs={12} md={9}>
                    <Form.Control
                      name="cooperation[contact_email]"
                      value={values.cooperation.contact_email}
                      onChange={handleChange}
                      type="email"
                      placeholder=""
                    />
                    <Form.Control.Feedback type={'invalid'} className="d-block">
                      {_errors && _errors.contact_email}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="formBasicEmail">
                  <Form.Label column xs={12} md={3}>
                    合作提案類型
                  </Form.Label>
                  <Col xs={12} md={9}>
                    <Form.Check
                      inline
                      id="category-1"
                      label="媒體採訪"
                      type="radio"
                      name="cooperation[category]"
                      value="interview"
                      onChange={handleChange}
                    />
                    <Form.Check
                      inline
                      id="category-2"
                      label="廣告業務行銷拜訪"
                      type="radio"
                      name="cooperation[category]"
                      value="advertisement"
                      onChange={handleChange}
                    />
                    <Form.Check
                      inline
                      id="category-3"
                      label="平台業務合作洽談"
                      type="radio"
                      name="cooperation[category]"
                      value="platform"
                      onChange={handleChange}
                    />
                    <Form.Check
                      inline
                      id="category-4"
                      label="異業聯合行銷洽談"
                      type="radio"
                      name="cooperation[category]"
                      value="alliance"
                      onChange={handleChange}
                    />
                    <Form.Control.Feedback type={'invalid'} className="d-block">
                      {_errors && _errors.category}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="formBasicEmail">
                  <Form.Label column xs={12} md={3}>
                    洽談主旨
                  </Form.Label>
                  <Col xs={12} md={9}>
                    <Form.Control
                      as="textarea"
                      row="6"
                      name="cooperation[subject]"
                      value={values.cooperation.subject}
                      onChange={handleChange}
                      placeholder="(字數上限300字)"
                    />
                    <Form.Control.Feedback type={'invalid'} className="d-block">
                      {_errors && _errors.subject}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="formBasicEmail">
                  <Form.Label column xs={12} md={3}>
                    附件資料
                  </Form.Label>
                  <Col xs={12} md={9}>
                    <Field
                      id="custom-file"
                      label="選擇附件檔案...(檔案請勿超過3MB)"
                      custom
                      lang="zh-tw"
                      data-browse="瀏覽"
                      name="cooperation[file_group]"
                      component={FileInput}
                    />
                    <Form.Control.Feedback type={'invalid'} className="d-block">
                      {_errors && _errors.file_group}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Row className="my-4">
                  <Col className="mx-auto" xs={12} md={6}>
                    <Button variant="primary" type="submit" block>
                      送出
                    </Button>
                  </Col>
                </Row>
              </Form>
            )
          }}
        </Formik>
      </ThemeProvider>
    )
  }
}

const FileInput = ({ field, form, ...props }) => {
  const onChangeHandler = (e) => {
    let ele = e.target
    if (ele.files.length == 0) {
      form.setFieldValue(field.name, [])
      return
    }
    form.setFieldValue(field.name, [ele.files[0]])
  }
  return (
    <>
      <FormFile
        {...props}
        onChange={onChangeHandler}
        name={field.name}
        label={field.value.length > 0 ? field.value[0].name : props.label}
      />
    </>
  )
}

export default Cooperations
