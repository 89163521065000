import styled, { keyframes } from 'styled-components'

const LoadingAni = keyframes`
0%{
  background:#f8f9fa;
}
50%{
  background:#dee2e6;
}
100%{
  background:#f8f9fa;
}
`

const Loading = styled.div`
  width: ${({ width }) => (isNaN(width) ? width : width + 'px')};
  height: ${({ height }) => (isNaN(height) ? height : height + 'px')};
  background: ${({ theme }) => theme.grayColor.gray200};
  animation-name: ${LoadingAni};
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  border-radius: ${({ theme }) => theme.borderRadius};
`

export default Loading
