import yup from '../yup';
import citizenIdTest from '@/components/_common/citizenIdTest';
import { carLicensePlateExp } from '../regexp';

const carSchema = yup.object().shape({
  insured_citizen_id: yup.string(),
  citizen_id: yup.string().required('請輸入身分證字號')
    .when('insured_citizen_id', (citizenId, schema) => {
      console.log({ citizenId })
      return schema.test('citizenIdFormat', '身分證錯誤', citizenIdTest).test('citizenIdCompare', '抱歉！因網路投保核保規定，車主與要保人須為同一人，保險公司將無法受理您的投保，敬請見諒。',
        value => citizenId === value
      )
    }),
  license_plate_last: yup.string().required('請輸入車牌').test(function (value) {
    const license = `${this.parent.license_plate_first}-${value}`;
    if (carLicensePlateExp.test(license)) return true
    return this.createError({ message: '請輸入正確的車牌號碼' });
  }),
  car_kind: yup.string().required('請選擇車輛類型'),
  car_model: yup.string().required('請輸入車型'),
  car_exhaust: yup.number().required('請輸入排氣量').typeError("請輸入數字"),
  engine_num: yup.string().required('請輸入引擎號碼'),
  passengers_number: yup.number().required('請選擇乘載人數'),
  date_car_made: yup.string().required('請輸入出廠時間'),
});

export default carSchema;
