import React, { useContext } from 'react'
import context from '../context'

function Car(otherInfo) {
  const { duration, main_coverage } = otherInfo
  return (
    <p className="small mb-0">
      <span>一年期</span>
    </p>
  )
}

export default Car
