import React from 'react'
import { Field, ErrorMessage } from 'formik'
import { Row, Col, Form, Button } from 'react-bootstrap'
import Inputs, { FormFeedback } from '@/components/_common/Inputs'
import { PasswordInput, ConfirmInput } from '@/components/_common/FormInput'
import Terms from './Terms'
import TermsMobile from './TermsMobile'
import { BrowserView, MobileView } from 'react-device-detect'
import ErrorFocus from '@/components/_common/ErrorFocus'

function RegistrationForm({
  values,
  handleSubmit,
  handleChange,
  errors,
  touched,
}) {
  return (
    <Form onSubmit={handleSubmit}>
      <ErrorFocus />
      <Row>
        <Col xs={12} sm={9} className="mx-auto">
          <Inputs.Input
            label="帳號"
            placeholder="請輸入身分證字號"
            name="member[citizen_id]"
            maxLength="10"
            autocomplete="new-password"
            showError
          />
          <Inputs.Input
            label="姓名"
            placeholder="請輸入身分證上的真實姓名"
            name="member[xname]"
            autocomplete="new-password"
            showError
          />
          <Inputs.BirthdaySelector
            label="生日"
            name="member[birthday]"
            showError
          />
          <PasswordInput
            label="密碼"
            type="password"
            value={values.member.password}
            name="member[password]"
            autocomplete="new-password"
            onChange={handleChange}
          />
          <ConfirmInput
            label="密碼確認"
            type="password"
            value={values.member.password_confirmation}
            name="member[password_confirmation]"
            autocomplete="new-password"
            onChange={handleChange}
          />
        </Col>
      </Row>
      <BrowserView>
        <Terms className="my-3" />
      </BrowserView>
      <MobileView>
        <TermsMobile className="my-3" />
      </MobileView>
      <Field
        as={Form.Check}
        name="member[kyc_read]"
        label="我已詳細閱讀並同意上述個資宣告、網路投保服務聲明等事項。"
        id="member[kyc_read]"
        type="checkbox"
      />
      <ErrorMessage component={FormFeedback} name="member[kyc_read]" />
      <Row className="my-3 mb-5">
        <Col xm={12} md={5} className="mx-auto">
          <Button variant="primary" type="submit" block>
            送出
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

export default RegistrationForm
