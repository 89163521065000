import React, { useEffect, useState } from 'react'
import OptionButton from './OptionButton'
import styled from 'styled-components'
import OptionsSelect from './OptionsSelect'

const Container = styled.div`
  & div{
    select {
      border-radius: 0 !important;
      border-left: 3px solid white;

    }
  }
  & div:first-child{
    select {
      border-top-left-radius: 5px !important;
      border-bottom-left-radius: 5px !important;
      border: none;
    }
  }
  & div:last-child {
    select {

    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
    }
  }
  @media (max-width: 768px){
    & div select{
      border: none;
    }
    & div:first-child select{
      border-radius: 0;
      border-top-left-radius: 5px !important;
      border-top-right-radius: 5px !important;
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
    & div:last-child select{
      border-radius: 0;
      border-bottom-left-radius: 5px !important;
      border-bottom-right-radius: 5px !important;
      border-top-left-radius: 0 !important;
      border-top-right-radius: 0 !important;
      border: none;
    }            
  }
`

function OptionOccupation({ onChange }) {

  const [code, setCode] = useState("")
  const [value, setValue] = useState({ type: "", class: "", occupation: "" })
  const [options, setOptions] = useState({ types: [], classes: [], occupations: [] })
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    fetch("/occupations.json?" + (code && `code=${code}`), {
      method: "GET",
    }).then((res) => res.json()).then((json) => {
      setIsLoading(false)
      if (code == "") {
        return setOptions({ ...options, types: json })
      }
      if (code.length == 2) {
        return setOptions(prev => ({ ...prev, classes: json }))
      }
      if (code.length == 4) {
        let occOptions = json.map(j => [j.description, j.code])
        return setOptions(prev => ({ ...prev, occupations: occOptions }))
      }
    })
  }, [code])

  const handleCodeChange = (e) => {
    let value = e.target.value
    if (value.length == 2) {
      if (value.type == value) return
      setValue({ type: value, class: "", occupation: "" })
    } else if (value.length == 4) {
      if (value.class == value) return
      setValue(prev => ({ ...prev, class: value, occupation: "" }))
    } else {
      if (value.occupation == value) return
      setValue(prev => ({ ...prev, occupation: value }))
      onChange({ occupation_code: value })
    }
    setCode(value)
  }


  return (
    <Container className="row px-3 mb-4">
      <div className="col-md col-12 mb-1 mb-md-0 p-0">
        <OptionsSelect options={options.types} value={value.type} blank={isLoading ? "載入中..." : "行業別"} onChange={handleCodeChange}></OptionsSelect>
      </div>
      <div className="col-md col-12 mb-1 mb-md-0 p-0">
        <OptionsSelect options={options.classes} value={value.class} blank={isLoading ? "載入中..." : "職業別"} onChange={handleCodeChange}></OptionsSelect>
      </div>
      <div className="col-md col-12 mb-1 mb-md-0 p-0">
        <OptionsSelect options={options.occupations} value={value.occupation} blank={isLoading ? "載入中..." : "職業類型"} onChange={handleCodeChange}></OptionsSelect>
      </div>
    </Container>
  )
}

export default OptionOccupation
