import * as React from 'react'
import isEmailFn from './isEmail'
import { ThemeProvider } from 'styled-components'
import DefaultTheme from '@/components/Themes'
import {
  MultiBox,
  Placeholder,
  Input,
  SubmitButton,
  Label,
} from './MulitEmailView'

class MultiEmail extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      focused: false,
      emails: [],
      inputValue: '',
      Theme: DefaultTheme,
    }
    this.findEmailAddress = (value, isEnter) => {
      const { validateEmail } = this.props
      let validEmails = []
      let inputValue = ''
      const re = /[ ,;]/g
      const isEmail = validateEmail || isEmailFn
      const addEmails = (email) => {
        const emails = this.state.emails
        for (let i = 0, l = emails.length; i < l; i++) {
          if (emails[i] === email) {
            return false
          }
        }
        validEmails.push(email)
        return true
      }
      if (value !== '') {
        if (re.test(value)) {
          let arr = value.split(re).filter((n) => {
            return n !== '' && n !== undefined && n !== null
          })
          do {
            if (isEmail('' + arr[0])) {
              addEmails('' + arr.shift())
            } else {
              if (arr.length === 1) {
                /// 마지막 아이템이면 inputValue로 남겨두기
                inputValue = '' + arr.shift()
              } else {
                arr.shift()
              }
            }
          } while (arr.length)
        } else {
          if (isEnter) {
            if (isEmail(value)) {
              addEmails(value)
            } else {
              inputValue = value
            }
          } else {
            inputValue = value
          }
        }
      }
      this.setState({
        emails: [...this.state.emails, ...validEmails],
        inputValue: inputValue,
      })
      if (validEmails.length && this.props.onChange) {
        this.props.onChange([...this.state.emails, ...validEmails])
      }
    }
    this.onChangeInputValue = (value) => {
      this.findEmailAddress(value)
    }
    this.removeEmail = (index) => {
      this.setState(
        (prevState) => {
          return {
            emails: [
              ...prevState.emails.slice(0, index),
              ...prevState.emails.slice(index + 1),
            ],
          }
        },
        () => {
          if (this.props.onChange) {
            this.props.onChange(this.state.emails)
          }
        },
      )
    }
    this.handleOnKeydown = (e) => {
      switch (e.which) {
        case 13:
        case 9:
          e.preventDefault()
          break
        case 8:
          if (!e.currentTarget.value) {
            this.removeEmail(this.state.emails.length - 1)
          }
          break
        default:
      }
    }
    this.handleOnKeyup = (e) => {
      switch (e.which) {
        case 13:
        case 9:
          this.findEmailAddress(e.currentTarget.value, true)
          break
        default:
      }
    }
    this.handleOnChange = (e) => this.onChangeInputValue(e.currentTarget.value)
    this.handleOnBlur = (e) => {
      this.setState({ focused: false })
      this.findEmailAddress(e.currentTarget.value, true)
    }
    this.handleOnFocus = () =>
      this.setState({
        focused: true,
      })
    this.emailInputRef = React.createRef()
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.propsEmails !== nextProps.emails) {
      return {
        propsEmails: nextProps.emails || [],
        emails: nextProps.emails || [],
        inputValue: '',
        focused: false,
      }
    }
    return null
  }
  componentDidUpdate() {
    this.container.scrollLeft = 1000
    console.log(this.container)
  }
  render() {
    const { focused, emails, inputValue, containerWidth } = this.state
    const {
      style,
      getLabel,
      className = '',
      noClass,
      placeholder,
      buttonText,
    } = this.props
    // removeEmail
    return (
      <div className="input-group w-100">
        <ThemeProvider theme={this.state.Theme}>
          <MultiBox
            ref={(el) => (this.container = el)}
            focused={focused}
            onClick={() => {
              if (this.emailInputRef.current) this.emailInputRef.current.focus()
            }}
          >
            {placeholder ? (
              <Placeholder
                focused={focused}
                empty={emails.length == 0}
                value={inputValue}
              >
                {placeholder}
              </Placeholder>
            ) : null}

            {emails.map((email, index) => {
              return (
                <Label key={index}>
                  {email}
                  <span data-tag-handle onClick={() => this.removeEmail(index)}>
                    ×
                  </span>
                </Label>
              )
            })}
            <Input
              ref={this.emailInputRef}
              focused={focused}
              type="text"
              value={inputValue}
              onFocus={this.handleOnFocus}
              onBlur={this.handleOnBlur}
              onChange={this.handleOnChange}
              onKeyDown={this.handleOnKeydown}
              onKeyUp={this.handleOnKeyup}
            />
          </MultiBox>
          <div>
            <SubmitButton
              variant="primary"
              onClick={() => this.props.onSubmit(this.state.emails)}
            >
              {buttonText}
            </SubmitButton>
          </div>
        </ThemeProvider>
      </div>
    )
  }
}
export default MultiEmail
