import React from 'react'
import { Formik, Field, ErrorMessage } from 'formik'
import { Container, Row, Col, Form, Button, InputGroup } from 'react-bootstrap'
import Flex from '@/components/_common/Flex'
import { withContext } from '../Context'
import { FormFeedback } from '@/components/_common/Inputs'

const OTPForm = ({ ...props }) => {
  let context = props.context

  let initialValues = {
    forget_password: true,
    check_code: '',
  }

  return (
    <Container>
      <h1 className="text-center">忘記密碼</h1>
      <p>
        我們已發送驗證碼至您留下的聯絡方式
        <br />
        手機號碼 {context.mobile_phone.substr(0, 4)}-***-
        {context.mobile_phone.substr(-3)}
        <br />
        {context.email && '電子郵件 ' + context.email}
      </p>
      <Formik
        initialValues={initialValues}
        onSubmit={context.submitFunction.onOTPFormSubmit}
      >
        {({ values, handleSubmit, handleChange, errors, touched }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Row xs="auto">
                <Col xs={12} className="mb-3">
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text>{context.prefix}-</InputGroup.Text>
                    </InputGroup.Prepend>
                    <Field
                      as={Form.Control}
                      type="text"
                      name="check_code"
                      placeholder="請輸入驗證碼"
                    />
                  </InputGroup>
                  {/* <Flex center className="mx-1">
                    <span className="mr-3">{context.prefix}-</span>
                    <Field
                      as={Form.Control}
                      type="text"
                      name="check_code"
                      placeholder="請輸入驗證碼"
                    />
                  </Flex> */}
                  <ErrorMessage
                    component={FormFeedback}
                    name="check_code"
                    className="text-left"
                  />
                </Col>
                <Col xs={12}>
                  <Button variant="primary" type="submit" block>
                    下一步
                  </Button>
                </Col>
              </Row>
            </Form>
          )
        }}
      </Formik>
      <p className="mt-3">
        若未收到簡訊請按
        <a href="###" onClick={context.submitFunction.sendOtp}>
          重新傳送
        </a>
      </p>
    </Container>
  )
}

export default withContext(OTPForm)
