import React from 'react'
import styled from 'styled-components'
import { Scrollbars } from 'react-custom-scrollbars'

const Ul = styled.ol`
  list-style: none;
  counter-reset: li;
  padding-left: ${({ theme }) => theme.spacers[4]};
`
const Li = styled.li`
  padding-left: ${({ theme }) => theme.spacers[3]};
  margin-bottom: ${({ theme }) => theme.spacers[3]};
  &::before {
    content: '\f0c8';
    color: ${({ theme }) => theme.color.primary};
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-feature-settings: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    width: ${({ theme }) => theme.spacers[5]};
    margin-left: -${({ theme }) => theme.spacers[5]};
    padding-right: ${({ theme }) => theme.spacers[3]};
    text-align: right;
  }
`
function Rules() {
  return (
    <Scrollbars autoHeight autoHeightMax="450px" autohide>
      <Ul>
        <Li>
          SARAcares會員所累計之莎拉幣，即可參與本活動，每人每日不限參加次數。
        </Li>
        <Li>
          每參與一次本活動，即需扣除5點莎拉幣，莎拉幣經確認完成扣除後，請恕無法退還點數，點數亦不補發。
        </Li>
        <Li>
          參與本活動前，請確認您莎拉幣是否充足；莎拉幣不足者，將無法成功完成扣點，則無法參與本活動。
        </Li>
        <Li>如需查看個人得獎紀錄，可至「莎拉領好康」抽獎贈品領取專區。</Li>
        <Li>
          SARAcares莎拉保險網保留更換本活動內容之權利。若發現惡意舞弊、違反活動辦法或盜用他人身份資料參與活動等情事，主辦單位保有取消中獎資格及參與本活動之權利，並對任何破壞本活動行為保留法律追訴權，上述未盡事宜，請詳閱下方完整注意事項。
        </Li>
      </Ul>
    </Scrollbars>
  )
}

export default Rules
