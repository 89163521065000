import React, { useContext } from 'react'
import context from '../context'

function Travel({ otherInfo }) {
  const { duration, main_coverage } = otherInfo
  return (
    <p className="small mb-0">
      <span>{main_coverage}</span>
            萬・
      <span>{duration}</span>
            天
    </p>
  )
}

export default Travel
