import React from 'react'
import { Spinner as _Spinner } from 'react-bootstrap'

function Spinner() {
  return (
    <_Spinner
      as="span"
      animation="border"
      role="status"
      size="sm"
      aria-hidden="true"
      className="mb-1 ml-2"
    />
  )
}

export default Spinner
