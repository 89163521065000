import React, { Component } from 'react'
import { MemberCard as Card } from '.'
import styled from 'styled-components'
import { SvgIcon } from '@/components/_common/SvgIcon'
const CardButton = styled(Card)`
  transition: all 0.3s ease;
  box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.1) !important;

  &:hover {
    box-shadow: 0 0.125rem 1rem rgba(0, 0, 0, 0.1) !important;
  }
  @media (max-width: 576px) {
    margin-left: 0px;
    margin-right: 0px;
  }
`
export class MenuButton extends Component {
  render() {
    return (
      <a href={this.props.href}>
        <CardButton style={{ padding: '1rem 0.5rem' }}>
          <div className="d-flex justify-content-center">
            <SvgIcon
              width="48"
              href={this.props.icon}
              className="mx-auto"
              fill={this.props.fill}
            />
          </div>
          <h5 className="text-center font-weight-normal m-0">
            {this.props.name}{' '}
          </h5>
        </CardButton>
      </a>
    )
  }
}

export default MenuButton
