import yup from '../yup';

const petSchema = yup.object().shape({
  pet_name: yup.string().default('-'),
  pet_kind: yup.string().oneOf(['cat', 'dog'], '???').default('dog'),
  pet_birthday: yup.string(),
  pet_race: yup.string().default('-'),
  pet_gender: yup.string().oneOf(['male', 'female']).default('male'),
  pet_weight: yup.number().default(1),
  pet_color: yup.string().default('-'),
  certified_document: yup.mixed().oneOf(['etag', 'blood', 'exam', 'injection'], '此為必選欄位').default("etag"),
  etag_serial_number: yup.string().when('certified_document', {
    is: (v) => (v == undefined || v == 'etag' || v == null),
    then: yup.string().required().test('length', '序號長度錯誤 應為10或15碼', (value) => value.toString().length === 10 || value.toString().length === 15),
    otherwise: yup.string().nullable()
  }).default(1111111111),
  document_file: yup.mixed().when('certified_document', {
    is: (v) => ['blood', 'exam', 'injection'].includes(v),
    then: yup.mixed().test('fileSize', '檔案請勿超過 10MB', (value) => fileSizeLimitByBase64(value)),
    otherwise: yup.mixed().nullable()
  }),
  picture_file: yup.mixed().test('fileSize', '檔案請勿超過 10MB', (value) => fileSizeLimitByBase64(value)).required().default('x'),
});

const fileSizeLimitByBase64 = (value, size = 10) => {
  if (value == null) return true
  const buffer = Buffer.from(value.substring(value.indexOf(',') + 1));
  if (!buffer) return true;
  return getSizeFromBase64(buffer) <= 1_048_576 * size;
}

const getSizeFromBase64 = (buffer) => (buffer.length * 3) / 4 - 2

export default petSchema;
