import React, { Component } from 'react'
import { Form } from 'react-bootstrap'
import styled from 'styled-components'
import { ChangeEvent } from 'react'
import moment from 'moment'

const padLeft = (str) => {
  if (str.length == 2) return str
  if (str.length == 1) return '0' + str
}

const Select = styled.select`
  border: none;
  background: none;
  flex: auto;
  margin-left: 10px;
  outline: none;
  appearance: none;
  &::-ms-expand {
    display: none;
  }
  &:focus {
    font-weight: 700;
  }
`
const Span = styled.h6`
  margin: 0 1rem 0 0;
`

const Split = styled.div`
  border: 1px solid #ced4da;
  border-width: 0 1px 0 0;
`
//options
const years = [...Array(new Date().getFullYear() - 1919).keys()].map(
  (y) => y + 1920,
)
const months = [...Array(12).keys()].map((m) => m + 1)
const days = [...Array(31).keys()].map((y) => y + 1)

export class Birthday_ extends Component {
  constructor(props) {
    super(props)
    //setting Y M D value
    this.state = {
      value: props.field.value || moment(new Date()).format('YYYY-MM-DD'),
      year: `${new Date(props.field.value).getFullYear() || new Date().getFullYear()
        }`,
      month: `${new Date(props.field.value).getMonth() + 1 || new Date().getMonth() + 1}`,
      day: `${new Date(props.field.value).getDate() || new Date().getDate()}`
    }
  }

  changeHandler(type, event) {
    this.props.form.setFieldTouched(this.props.field.name, true)
    event.persist()
    let dateValue = this.state.value
    switch (type) {
      case 'y':
        dateValue = `${event.target.value}-${padLeft(
          this.state.month,
        )}-${padLeft(this.state.day)}`
        this.setState((state) => ({
          year: event.target.value,
          value: dateValue,
        }))
        break
      case 'm':
        dateValue = `${this.state.year}-${padLeft(
          event.target.value,
        )}-${padLeft(this.state.day)}`
        this.setState((state) => ({
          month: event.target.value,
          value: dateValue,
        }))
        break
      case 'd':
        dateValue = `${this.state.year}-${padLeft(this.state.month)}-${padLeft(
          event.target.value,
        )}`
        this.setState((state) => ({
          day: event.target.value,
          value: dateValue,
        }))
        break
      default:
        break
    }
    this.props.form.setFieldValue(this.props.field.name, dateValue)
  }

  render() {
    return (
      <div className={this.props.className}>
        <Select
          as="select"
          value={this.state.year}
          onChange={(e) => this.changeHandler('y', e)}
        >
          {years.map((y) => (
            <option value={y} key={y}>
              {y}
            </option>
          ))}
        </Select>
        <Span>年</Span>
        <Split />
        <Select
          as="select"
          value={this.state.month}
          onChange={(e) => this.changeHandler('m', e)}
        >
          {months.map((m) => (
            <option value={m} key={m}>
              {m}
            </option>
          ))}
        </Select>
        <Span>月</Span>
        <Split />
        <Select
          as="select"
          value={this.state.day}
          onChange={(e) => this.changeHandler('d', e)}
        >
          {days.map((d) => (
            <option value={d} key={d}>
              {d}
            </option>
          ))}
        </Select>
        <Span>日</Span>
      </div>
    )
  }
}

const Birthday = styled(Birthday_)`
  display: flex;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
`

export default Birthday
