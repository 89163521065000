import React, { useContext } from 'react'
import OptionsContainer from '../../components/OptionsContainer';
import Footer from './Footer';
import FooterContainer from '../FooterContainer'
import context from '../../context'
import FromToPicker from '../../components/FromToPicker';

function Sport() {
  const { options, searchOptions, handleSearchOptionChange } = useContext(context)

  const handleAgeSearchOptionChange = (e) => {
    handleSearchOptionChange({ age: e.target.value })
  }

  const handleGenderSearchOptionChange = (e) => {
    handleSearchOptionChange({ gender: e.target.value })
  }

  return (
    <>
      <div className="mt-3 mt-md-0">
        <div className="row">
          {/* left */}
          <div className="col-12 col-lg-6 py-0 pt-md-4">
            <h5 className="title-line-primary d-none d-md-block">性別</h5>
            <h6 className="title-line-primary d-md-none d-block">性別</h6>
            <OptionsContainer
              options={options.gender}
              value={searchOptions.gender}
              onChange={handleGenderSearchOptionChange}
            />
          </div>
          {/* right */}
          <div className="col-12 col-lg-6 py-0 pt-md-4">
            <h5 className="title-line-primary d-none d-md-block">年齡</h5>
            <h6 className="title-line-primary d-md-none d-block">年齡</h6>
            <OptionsContainer
              options={options.age}
              value={searchOptions.age}
              onChange={handleAgeSearchOptionChange}
            />
          </div>
          <div className="col-12 ">
            <p className="text-gray-500 small">
              ◎ 車主本人需年滿18，限使用車主本人信用卡繳費。<br />
              ◎ 網路投保車險為一年期，保險期間僅承接60日內之保期。<br />
              ◎ 本站方案皆以關貿等級4(無投保紀錄)，車價69.8萬(如有車體險)費率估算，您的實際保費將連線監理單位檢核， 依照投保人與車輛係數計算後呈現。
            </p>
          </div>
        </div>
      </div>
      <FooterContainer>
        <Footer />
      </FooterContainer>
    </>
  )
}

export default Sport


