import React from 'react'
import { Form } from 'react-bootstrap'

function ErrorMessage({ formik, name }) {
  if (!(formik.touched?.inquiry && formik.touched?.inquiry[name]) || !(formik.errors?.inquiry && formik.errors.inquiry[name])) return null
  return (
    <Form.Control.Feedback type={'invalid'} className="d-block">
      {formik.errors?.inquiry[name]}
    </Form.Control.Feedback>
  )
}

export default ErrorMessage