import React from 'react'
import { Button } from 'react-bootstrap'
import styled from 'styled-components'
import SearchOptionContext from '@/components/_context/SearchOptionContext'
import StyledPopup from '@/components/_common/StyledPopup'
import IConQues from '../../../images/react/search/question.svg'

const SortBtn = styled(Button)`
  position: relative;
  padding-right: 1.375rem;
  margin-right: 1rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  &:before {
    content: '\f0d8';
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    position: absolute;
    top: 0rem;
    right: 0.375rem;
    opacity: ${(props) => (props.sort == 'DESC' ? 1 : 0.4)};
  }
  &:after {
    content: '\f0d7';
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    position: absolute;
    bottom: 0rem;
    right: 0.375rem;
    opacity: ${(props) => (props.sort == 'ASC' ? 1 : 0.4)};
  }
`

class Sort extends React.Component {
  onSortFeeClick(by) {
    this.context.planList.onSortChange('fee')
  }

  onSortPlanClick(by) {
    this.context.planList.onSortChange([
      'package.tags_count',
      'package.for_sale',
      'package.hot',
      'package.featured',
      'package.car_level_4',
      'package.car_level_3',
      'package.car_level_2',
      'package.car_level_1',
    ])
  }

  render() {
    return (
      <div className="py-3 pt-4 d-flex">
        <SearchOptionContext.Consumer>
          {({ planList, searchForm }) => {
            let productLine = searchForm.productLine
            let { sort } = planList
            let fee,
              plan = null
            if (sort.by == 'fee') {
              fee = sort.func
            } else {
              plan = sort.func
            }
            return (
              <>
                <SortBtn
                  variant="outline-primary"
                  sort={fee}
                  onClick={() => this.onSortFeeClick(fee)}
                >
                  保費
                </SortBtn>
                <SortBtn
                  variant="outline-primary"
                  sort={plan}
                  onClick={() => this.onSortPlanClick(plan)}
                >
                  推薦方案
                </SortBtn>
                <FeeCountPopup type={productLine} />
              </>
            )
          }}
        </SearchOptionContext.Consumer>
      </div>
    )
  }
}

Sort.contextType = SearchOptionContext

const FeeCountPopup = (props) => {
  if (props.type == 'car' || props.type == 'motorcycle') {
    return (
      <StyledPopup
        modal={true}
        trigger={
          <a
            className="text-primary-dark ml-auto align-self-center small"
            href="###"
          >
            這會是我買到的保費嗎
            <i>
              <IConQues className="ml-1 mb-1" width="13px" />
            </i>
          </a>
        }
      >
        {(close) => (
          <>
            <a className="close-btn h1" onClick={close} href="###">
              <i className="fas fa-times-circle"></i>
            </a>
            <div className="p-3 m-0 p-md-4 m-md-4 position-relative text-center">
              <h4 className="text-center">預估保費計算方式 </h4>
              <p className="m-0">
                本站方案皆以關貿等級4(無投保紀錄)
                {props.type == 'car' ? '，車價69.8萬(如有車體險)費率估算' : ''}
                您的實際保費將連線監理單位檢核，
                依照投保人與車輛係數計算後呈現。
              </p>
            </div>
          </>
        )}
      </StyledPopup>
    )
  } else {
    return null
  }
}

export default Sort
