import React, { Component } from 'react'
import { Context } from './index'
import Faq from './Faq'
import { Accordion, Button, Container } from 'react-bootstrap'
import styled from 'styled-components'

const Back = styled.a`
  color: ${({ theme }) => theme.grayColor.gray500};
  font-weight: ${({ theme }) => theme.fontWeight.normal};
  &:hover {
    color: ${({ theme }) => theme.color.primary};
  }
`

export class SearchResult extends Component {
  render() {
    return (
      <section>
        <Container className="mb-5 mt-3">
          <Context.Consumer>
            {({ searchResult, currentKeyword, handleBackBtnClick }) => {
              let count = searchResult.reduce((a, c) => {
                return a + c.faqs.length
              }, 0)
              return (
                <>
                  <Back variant="link" onClick={handleBackBtnClick} href="###">
                    <i className="fas fa-angle-left mr-1" />
                    返回客服中心
                  </Back>
                  <h3 className="text-center mt-4">
                    查找到 {count} 個結果關於 "{currentKeyword}"
                  </h3>
                  {searchResult.map((result, i) => (
                    <div key={i} className="mb-5">
                      <h4 className="text-primary mb-1">{result.title}</h4>
                      <Accordion>
                        {result.faqs.map((faq) => (
                          <Faq
                            title={faq.question}
                            ans={faq.full_answer}
                            key={faq.id}
                            eventKey={faq.id}
                          ></Faq>
                        ))}
                      </Accordion>
                    </div>
                  ))}
                </>
              )
            }}
          </Context.Consumer>
        </Container>
      </section>
    )
  }
}

export default SearchResult
