import React from 'react'
import ErrorMessage from '../components/ErrorMessage'
import Upload from '../components/Upload'

function ImagesForm({ formik }) {
  return (
    <div className="p-4 mb-3 border">
      <Upload name="inquiry[images]" onChange={formik.handleChange} onBlur={formik.handleBlur} multiple accept="image/png, image/jpg, image/jpeg" />
      <ErrorMessage formik={formik} name="images" />
    </div>
  )
}

export default ImagesForm