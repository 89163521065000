import React, { useContext } from 'react'
import context from '../context'

function Motor({ otherInfo, options }) {
  let allOptions = options ? [...options.electric_displacement_options, ...options.gas_displacement_options] : []
  let optionName = otherInfo && allOptions.find(o => o[1] == otherInfo.displacement)
  return (
    <p className="small mb-0">
      <span>{optionName && optionName[0]}</span>
      <span>/一年期</span>
    </p>
  )
}

export default Motor
