import React, { useContext, useState } from 'react';

import OptionsContainer from '../../components/OptionsContainer';
import OptionsDate from '../../components/OptionsDate';
import context from '../../context';
import FooterContainer from '../FooterContainer';

function Pet() {
  const { options, searchOptions, handleSearchOptionChange } = useContext(context)
  const { pet_kind: petKinds } = options
  const { pet_kind: petKind, pet_birth_date: petBirthDay } = searchOptions

  const handlePetKindChange = (e => {
    handleSearchOptionChange({ pet_kind: e.target.value })
  })

  const handleBirthdayChange = (e => {
    handleSearchOptionChange({ pet_birth_date: e })
  })

  return (
    <>
      <div className="mt-3 mt-md-0">
        <div className="row">
          {/* left */}
          <div className="col-12 col-lg-6 py-0 pt-md-4">
            <h5 className="title-line-primary d-none d-md-block">寵物種類</h5>
            <h6 className="title-line-primary d-md-none d-block">寵物種類</h6>
            <OptionsContainer
              options={petKinds}
              value={petKind}
              onChange={handlePetKindChange}
            />
          </div>
          {/* right */}
          <div className="col-12 col-lg-6 py-0 pt-md-4">
            <h5 className="title-line-primary d-none d-md-block">選擇寵物出生日期</h5>
            <h6 className="title-line-primary d-md-none d-block">選擇寵物出生日期</h6>
            <OptionsDate
              value={petBirthDay}
              onChange={handleBirthdayChange}
            >

            </OptionsDate>
          </div>
          <div className="col-12 ">
            <p className="text-gray-500 small">
              ◎ 根據法令規定，網路投保限年滿18的飼主本人投保。<br />
              ◎ 寵物年齡需年滿六個月且未滿八歲六個月。已滿八歲六個月視為九歲。<br />
              ◎ 本保險為非保證續保商品，且依寵物續保年齡之不同，按當時主管機關核可的保險費率計算保費。
            </p>
          </div>
        </div>
      </div>
      <FooterContainer>
        <h4 className="m-0 mt-3">
          <span>一年期</span>
        </h4>
      </FooterContainer>
    </>
  )
}

export default Pet


