import React, { Component } from 'react'
import { InputGroup, Button, FormControl } from 'react-bootstrap'
import MultiEmail from './MultiEmail'
import { Label } from './MulitEmailView'
import isEmail from './isEmail'
import axios from 'axios'

export class EmailsShare extends Component {
  constructor(props) {
    super(props)
    this.state = { emails: [] }
  }

  onSubmit(params) {
    axios
      .post(
        '/member/send_invitation_via_email',
        { utf8: '✓', email: this.state.emails },
        {
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': document
              .querySelector("meta[name='csrf-token']")
              .getAttribute('content'),
          },
        },
      )
      .then(({ data }) => {
        alert(data.data)
      })
  }
  render() {
    return (
      // <>
      //     <MultiEmail
      //         placeholder="Email Address"
      //         emails={this.state.emails}
      //         onSubmit={(values) => { this.onSubmit(values) }}
      //         onChange={(_emails) => {
      //             this.setState({ emails: _emails });
      //         }}
      //         validateEmail={email => {
      //             return isEmail(email); // return boolean
      //         }}
      //         buttonText={<i className="far fa-paper-plane"></i>}
      //     />
      // </>
      <div>
        <InputGroup>
          <FormControl
            onChange={(e) => {
              this.setState({ emails: e.target.value })
            }}
            placeholder="Email 給好友分享"
          />
          <InputGroup.Append>
            <Button
              variant="primary"
              onClick={() => {
                this.onSubmit()
              }}
            >
              <i className="far fa-paper-plane"></i>
            </Button>
          </InputGroup.Append>
        </InputGroup>
      </div>
    )
  }
}

export default EmailsShare
