const ForgetPasswordErrorMessage = {
  IdForm: {
    citizen_id: {
      required: '請輸入身分證字號',
      test: '身分證字號錯誤',
      code: (code) => {
        switch (code) {
          case '98':
            return '您於註冊時並未輸入手機號碼，請於營業時間與客服人員聯繫，謝謝！'
          case '-1':
            return '此身份證字號並未註冊過會員，請先註冊會員'
          case '99':
            return '您的帳號已被鎖定，請於營業時間與客服人員聯繫，謝謝！'
          default:
            return '系統錯誤 請稍後再試一次'
        }
      },
    },
  },
}

export default ForgetPasswordErrorMessage
