import { Options } from 'smooth-scrollbar/options';

const toArray = (obj) => {
  if (!obj || Object.keys(obj).length === 0) return [];
  if (Array.isArray(obj)) {
    if (obj.length > 0 && Array.isArray(obj[0])) return obj;
    if (obj.length > 0) return obj.map((ele) => [ele, ele]);
    return obj;
  }
  if (typeof obj[Object.keys(obj)[0]] == 'object') {
    const cloneObj = obj;
    Object.keys(obj).forEach((o) => { cloneObj[o] = toArray(obj[o]); });
    return cloneObj;
  }
  return Object.keys(obj).map((key) => [obj[key], key]);
};


export default toArray;
