class AnimateByScroll {

    constructor() {
        this.$window = $(window)
        this.win_height_padded = this.$window.height() * 1.1
        this.scrolled = this.$window.scrollTop();
        this.revealOnScroll = this.revealOnScroll.bind(this)
        this.$window.on('scroll', this.revealOnScroll);
        console.info("[Animate By Scroll] Is init")
        this.init();
    }

    init() {
        const _this = this
        const scrolled = $(window).scrollTop()
        $(document).ready(() => {
            $("[data-animation]:not(.animate__animated)").each(function (id, elem) {
                var $elem = $(elem)
                var offsetTop = $elem.offset().top;
                if (window.innerHeight > offsetTop) {
                    _this.animate($elem)
                }
            })
            this.init()
        })
    }

    revealOnScroll(e) {
        const _this = this
        const scrolled = $(window).scrollTop()
        //var win_height_padded = $(_window).height() * 1.1
        $("[data-animation]:not(.animate__animated)").each(function (id, elem) {
            var $elem = $(elem)
            var offsetTop = $elem.offset().top;

            if ($elem.data('offset')) {
                _this.win_height_padded = $(window).height() * $elem.data('offset')
            }

            if (scrolled + _this.win_height_padded / 1.2 > offsetTop) {
                _this.animate($elem)
            }
        });
    }

    animate($elem) {

        if ($elem.data('timeout')) {

            setTimeout(function () {
                $elem.addClass('animate__animated animate__' + $elem.data('animation'));
            }, parseInt($elem.data('timeout'), 10));
        } else {
            $elem.addClass('animate__animated animate__' + $elem.data('animation'));
        }
    }
}

export default AnimateByScroll

// function AnimateByScroll(window) {
//     $window = window
//     console.log($window)
//     win_height_padded = $window.height() * 1.1

//     revealOnScroll()
//     $window.on('scroll', revealOnScroll);
//     console.log("yes")
// }