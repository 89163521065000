import React, { Component } from 'react'
import { Formik } from 'formik'
import { Container, Form, Button, Col, Row } from 'react-bootstrap'
import Axios from 'axios'
import * as yup from 'yup'
import { MyPopup } from '@/components/_common/StyledPopup'
import styled from 'styled-components'
import { Context } from './index'

const Api = '/contact_us'

const Back = styled.a`
  color: ${({ theme }) => theme.grayColor.gray500};
  font-weight: ${({ theme }) => theme.fontWeight.normal};
  position: absolute;
  top: 20px;
  left: 15px;
  &:hover {
    color: ${({ theme }) => theme.color.primary};
  }
`

const schema = yup.object().shape({
  name1: yup.string().required('請輸入姓氏'),
  name2: yup.string().required('請輸入名稱'),
  gender: yup.string().required('請輸入性別'),
  is_member: yup.string().required('請輸入是否為會員'),
  subject: yup.string().required('請輸入主旨'),
  email: yup.string().required('請輸入Email').email('email格式錯誤'),
  message: yup.string().required('請描述您的問題').max(300, '字數上限300字'),
  is_read: yup.string().required('請同意個資保護方式'),
})

export class ContactUs extends Component {
  constructor(props) {
    super(props)
    this.state = { isSended: false }
    this.initialValues = {
      utf8: '✓',
      authenticity_token: document
        .querySelector("meta[name='csrf-token']")
        .getAttribute('content'),
      is_member: '',
      gender: '',
      is_read: '',
    }
    this.onSubmit = this.onSubmit.bind(this)
  }

  onSubmit(values) {
    showLoading()
    let _this = this
    let formData = new FormData()
    for (var key in values) {
      formData.append(key, values[key])
    }
    Axios.post(Api, formData).then((response) => {
      console.log(response.data)
      _this.setState({ isSended: true })
      hideLoading()
    })
  }

  renderPopup() {
    return (
      <MyPopup open={this.state.isSended} modal={true}>
        <p className="text-center">
          您的諮詢單已送出，
          <br />
          我們收到後會指定專人為您服務
        </p>
        <Row>
          <Col className="mx-auto" xs="5">
            <Button variant="primary" block href="/customer-center">
              返回客服中心
            </Button>
          </Col>
        </Row>
      </MyPopup>
    )
  }

  renderForm() {
    return (
      <Formik
        initialValues={this.initialValues}
        onSubmit={this.onSubmit}
        validationSchema={schema}
      >
        {({ values, handleChange, handleSubmit, errors }) => {
          return (
            <Row>
              <Col xs={12} md={8} className="mx-auto">
                <Form onSubmit={handleSubmit}>
                  <Form.Group as={Row}>
                    <Form.Label column xs={12} md={4}>
                      姓名
                    </Form.Label>
                    <Col xs={6} md={4}>
                      <Form.Control
                        name="name1"
                        onChange={handleChange}
                        type="text"
                        placeholder="姓氏"
                        isInvalid={!!errors.name1}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.name1}
                      </Form.Control.Feedback>
                    </Col>
                    <Col xs={6} md={4}>
                      <Form.Control
                        name="name2"
                        onChange={handleChange}
                        type="text"
                        placeholder="名字"
                        isInvalid={!!errors.name2}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.name2}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row}>
                    <Form.Label column xs={12} md={4}>
                      性別
                    </Form.Label>
                    <Col xs={12} md={8}>
                      <Form.Check
                        inline
                        label="男"
                        id="gender_male"
                        type="radio"
                        name="gender"
                        value="男"
                        onChange={handleChange}
                        isInvalid={!!errors.gender}
                      />
                      <Form.Check
                        inline
                        label="女"
                        id="gender_female"
                        type="radio"
                        name="gender"
                        value="女"
                        onChange={handleChange}
                        isInvalid={!!errors.gender}
                      />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row}>
                    <Form.Label column xs={12} md={4}>
                      是否為網站會員
                    </Form.Label>
                    <Col xs={12} md={8}>
                      <Form.Check
                        inline
                        id="member_yes"
                        label="是，為 SARAcares 網站會員"
                        type="radio"
                        name="is_member"
                        value="是"
                        onChange={handleChange}
                        isInvalid={!!errors.is_member}
                      />
                      <Form.Check
                        inline
                        label="否"
                        id="member_no"
                        type="radio"
                        name="is_member"
                        value="否"
                        onChange={handleChange}
                        isInvalid={!!errors.is_member}
                      />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} controlId="formBasicEmail">
                    <Form.Label column xs={12} md={4}>
                      聯絡人 E-mail
                    </Form.Label>
                    <Col xs={12} md={8}>
                      <Form.Control
                        name="email"
                        onChange={handleChange}
                        type="email"
                        placeholder=""
                        isInvalid={!!errors.email}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.email}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} controlId="formBasicEmail">
                    <Form.Label column xs={12} md={4}>
                      主旨
                    </Form.Label>
                    <Col xs={12} md={8}>
                      <Form.Control
                        name="subject"
                        onChange={handleChange}
                        type="text"
                        placeholder=""
                        isInvalid={!!errors.subject}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.subject}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} controlId="formBasicEmail">
                    <Form.Label column xs={12} md={4}>
                      問題描述
                    </Form.Label>
                    <Col xs={12} md={8}>
                      <Form.Control
                        as="textarea"
                        row="6"
                        name="message"
                        onChange={handleChange}
                        placeholder="(字數上限300字)"
                        isInvalid={!!errors.message}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.message}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>

                  <div className="text-center">
                    <p>
                      我們重視您的隱私安全，請參閱我們的
                      <a
                        href="https://events.lawbroker.com.tw/main/privacyterm-82?_ga=2.143254001.572532554.1586346522-1127652902.1586346522"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        個資保護方式
                      </a>
                    </p>
                    <Form.Group as={Row}>
                      <Form.Check className="mx-auto" inline id="isRead">
                        <Form.Check.Input
                          type="checkbox"
                          name="is_read"
                          isInvalid={!!errors.is_read}
                          onChange={handleChange}
                        />
                        <Form.Check.Label>我已詳細閱讀並同意</Form.Check.Label>
                        <Form.Control.Feedback type="invalid">
                          {errors.is_read}
                        </Form.Control.Feedback>
                      </Form.Check>
                    </Form.Group>
                  </div>
                  <Row>
                    <Col xs={12} md={6} className="mx-auto">
                      <Button block type="submit" size="lg">
                        送出
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          )
        }}
      </Formik>
    )
  }

  render() {
    return (
      <Context.Consumer>
        {({ handleBackBtnClick }) => (
          <section className="bg-gray-100">
            <Container className="py-5 position-relative">
              <Back variant="link" onClick={handleBackBtnClick} href="###">
                <i className="fas fa-angle-left mr-1" />
                返回客服中心
              </Back>
              <h1 className="text-center">諮詢SARAcares</h1>
              {this.renderForm()}
              {this.renderPopup()}
            </Container>
          </section>
        )}
      </Context.Consumer>
    )
  }
}

export default ContactUs
