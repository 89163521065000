import React, { Component } from 'react'
import styled from 'styled-components'
import { MyPopup } from '@/components/_common/StyledPopup'
import { Button } from 'react-bootstrap'
import { Flex } from '../common/index'
import axios from 'axios'
import Loading from '@/components/_common/Loading'

const AwardBox = styled.div`
  border-radius: ${({ theme }) => theme.borderRadius};
  background-color: ${({ theme }) => theme.grayColor.gray100};
  text-align: center;
  max-width: 80vw;
  min-width: 225px;
  margin-right: ${({ theme }) => theme.spacers[3]};
  padding: ${({ theme }) => theme.spacers[3]};
  display: flex;
  flex-direction: column;
  .img {
    width: 100%;
    background-image: url('${({ image }) => image}');
    height: 100px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  & > div {
    margin-top: auto;
    h6 {
      font-weight: ${({ theme }) => theme.fontWeight.normal};
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    & > div {
      display: flex;
      justify-content: center;
      align-items: center;
      & > p {
        color: ${({ theme }) => theme.color.secondary};
        margin: 0;
        margin-right: ${({ theme }) => theme.spacers[2]};
      }
    }
  }
  @media (max-width: 576px) {
    box-shadow: ${({ theme }) => theme.shadow.sm};
    margin-top: ${({ theme }) => theme.spacers[2]};
    margin-bottom: ${({ theme }) => theme.spacers[2]};
  }
`

export class AwardLoading extends Component {
  render() {
    let { award } = this.props
    return (
      <>
        <AwardBox>
          <Loading height={100} width={193}></Loading>
          <div>
            <Flex>
              <Loading height={27} width={100} className="my-2"></Loading>
            </Flex>
            <div>
              <Loading height={20} width={35}></Loading>
              <Loading height={38} width={90} className="ml-2"></Loading>
            </div>
          </div>
        </AwardBox>
        <AwardBox>
          <Loading height={100} width={193}></Loading>
          <div>
            <Flex>
              <Loading height={27} width={100} className="my-2"></Loading>
            </Flex>
            <div>
              <Loading height={20} width={35}></Loading>
              <Loading height={38} width={90} className="ml-2"></Loading>
            </div>
          </div>
        </AwardBox>
        <AwardBox>
          <Loading height={100} width={193}></Loading>
          <div>
            <Flex>
              <Loading height={27} width={100} className="my-2"></Loading>
            </Flex>
            <div>
              <Loading height={20} width={35}></Loading>
              <Loading height={38} width={90} className="ml-2"></Loading>
            </div>
          </div>
        </AwardBox>
      </>
    )
  }
}

export default AwardLoading
