import React, { useEffect, useState } from 'react'
import OptionButton from './OptionButton'
import styled from 'styled-components'
import OptionsSelect from './OptionsSelect'
import moment from 'moment'

const Container = styled.div`
  & div{
    position: relative;
    &:hover::after{
      color: white;
    }
    &:after{
      content: "月";
      pointer-events: none;
      position: absolute;
      right: 20px;
      line-height: 60px;
      top: 0;
      font-size: 1rem;
      font-weight: 400;
      color: #495057;
      transition: all 0.2s ease-in-out;
    }
    select {
      border-radius: 0 !important;
      border-left: 3px solid white;
      appearance: none;
      padding-left: 30px;
      text-align-last: left !important;
    }
  }
  & div:first-child{
    &:after{
      content: "年";
      padding-left: 22px;
    }
    select {
      border-top-left-radius: 5px !important;
      border-bottom-left-radius: 5px !important;
      border: none;
    }
  }
  & div:last-child {
    &:after{
      content: "日";
    }
    select {
      border-top-right-radius: 5px !important;
      border-bottom-right-radius: 5px !important;
    }
  }
  @media (max-width: 768px){
    & div{
    &:after{
      right: 15px;
    }
    select {
      padding-left: 15px;
    }
  }
  }
`

// options
const YEAR = Array.from({ length: 20 }, (v, i) => [i + (new Date().getFullYear() - 19), i + (new Date().getFullYear() - 19)]);
const MONTH = Array.from({ length: 12 }, (v, i) => [i + 1, i]);
const DAY = Array.from({ length: 31 }, (v, i) => [i + 1, i + 1]);

function OptionsDate({ value, onChange }) {
  const defaultValue = value && moment(value)
  const [date, setDate] = useState((defaultValue && [defaultValue.year(), defaultValue.month(), defaultValue.day()]) || [new Date().getFullYear() - 5, 0, 1])

  useEffect(() => {
    onChange(moment(date).format())
  }, [date])

  const handleYearChange = (e) => {
    let temp = [...date]
    temp[0] = parseInt(e.target.value)
    setDate(temp)
  }

  const handleMonthChange = (e) => {
    let temp = [...date]
    temp[1] = parseInt(e.target.value)
    setDate(temp)
  }

  const handleDayChange = (e) => {
    let temp = [...date]
    temp[2] = parseInt(e.target.value)
    setDate(temp)
  }

  return (
    <Container className="row px-3 mb-4">
      <div className="col p-0">
        <OptionsSelect options={YEAR} value={date[0]} onChange={handleYearChange}></OptionsSelect>
      </div>
      <div className="col p-0">
        <OptionsSelect options={MONTH} value={date[1]} onChange={handleMonthChange}></OptionsSelect>
      </div>
      <div className="col p-0">
        <OptionsSelect options={DAY} value={date[2]} onChange={handleDayChange}></OptionsSelect>
      </div>
    </Container>
  )
}

export default OptionsDate
