import React from 'react'
import styled from 'styled-components'
import Flatpickr from "react-flatpickr";

const StyledFlatpickr = styled(Flatpickr)`
  background-color: transparent;
  border: none;
  display: block;
  padding: 30px 30px 5px 14px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  color: #495057;
  &:focus{
    outline: none;
  }
`

const StyledContainer = styled.div`
  width: 100%;
  margin: 0;
  position: relative;
  ${({ borderRadius }) => {
    if (borderRadius == "right") {
      return `border-top-right-radius: 5px;
              border-bottom-right-radius: 5px;
              border-left: 3px solid white;`
    }
    if (borderRadius == "left") {
      return `border-top-left-radius: 5px;
              border-bottom-left-radius: 5px;`
    }
    return `border-left: 3px solid white;`
  }}
  
  overflow: hidden;
  height: 60px;
  width: auto;
  background-color: #DFF5FA;
  transition: all 0.2s ease-in-out;
  color: #495057 !important;
  &:hover{
    background-color: #62cae3;
    color: white !important;
    p {
      color: white !important;
    }
    input {
      color: white;
    }
  }
  p {
    text-align: left;
    margin-left: 14px;
    margin-top: 5px;
    color: #495057;
    font-weight: 500 !important;
  }
`

function MyFlatpickr({ borderRadius, label, ...props }) {

  return (
    <StyledContainer borderRadius={borderRadius}>
      <p>{label}</p>
      <StyledFlatpickr
        {...props}
      />
    </StyledContainer>
  )
}

export default MyFlatpickr
