import React, { Component } from 'react'
import { Accordion, Container } from 'react-bootstrap'
import Faq from './Faq'
import { Context } from './index'
import FaqLoading from './FaqLoading'
import styled from 'styled-components'

const Back = styled.a`
  color: ${({ theme }) => theme.grayColor.gray500};
  font-weight: ${({ theme }) => theme.fontWeight.normal};
  margin-top: ${({ theme }) => '-' + theme.spacers[3]};
  position: absolute;
  top: -20px;
  left: 15px;
  &:hover {
    color: ${({ theme }) => theme.color.primary};
  }
`
export class FaqList extends Component {
  render() {
    return (
      <section>
        <Container className="my-5 position-relative">
          <Context.Consumer>
            {({ currentFaqType, currentFaqContent, handleBackBtnClick }) => (
              <>
                {currentFaqType == 'default' ? null : (
                  <Back
                    variant="link"
                    onClick={handleBackBtnClick}
                    href="###"
                    id="anchor"
                  >
                    <i className="fas fa-angle-left mr-1" />
                    返回客服中心
                  </Back>
                )}
                <h3 className="text-center">
                  {currentFaqType == 'default'
                    ? '常見問題'
                    : currentFaqContent.title}
                </h3>
                {currentFaqContent.faqs.length ? null : <FaqLoading />}
                <Accordion>
                  {currentFaqContent.faqs.map((faq) => (
                    <Faq
                      title={faq.question}
                      num={faq.serial_number}
                      ans={faq.full_answer}
                      key={faq.id}
                      eventKey={faq.id}
                    ></Faq>
                  ))}
                </Accordion>
              </>
            )}
          </Context.Consumer>
        </Container>
      </section>
    )
  }
}

export default FaqList
