import React, { Component } from 'react'
import { MemberCard as Card, TextLink } from '../../common'
import Policy from './Policy.js'
import styled from 'styled-components'

const NullBox = styled.div`
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export class MemberPolicyList extends Component {
  render() {
    return (
      <Card>
        {this.props.policies &&
          this.props.policies.map((policy) => (
            <Policy policy={policy} key={policy.id}></Policy>
          ))}
        {this.props.policies && this.props.policies.length < 1 ? (
          <NullBox>
            <h6 className="text-gray-500">您尚未擁有任何保單</h6>
          </NullBox>
        ) : (
          <TextLink to="/member/policies" className="text-right mt-2">
            查看更多...
          </TextLink>
        )}
      </Card>
    )
  }
}

export default MemberPolicyList
