import React, { useState, useEffect, useRef } from 'react'
import Flatpickr from '../components/Flatpickr'
import moment from 'moment'
import { timers } from 'jquery';

// set Max and Min
const initSetting = ({ from, to }) => {
  let now = moment().minutes(0).seconds(0);
  return {
    from: {
      min: moment(now).add(2, "hours").subtract(1, "seconds").toDate(),
      max: moment(now).add(180, "days").toDate()
    },
    to: {
      min: moment(now).add(1, "days").subtract(1, "seconds").toDate(),
      max: from.add(180, "days").toDate()
    }
  };
};


function FromToPicker({ onChange, ...props }) {
  const [{ duration, from, to }, setState] = useState({
    duration: props.duration,
    from: moment(props.from).startOf('hour'),
    to: moment(props.to).startOf('hour')
  });

  // flatpickr setting options
  const [setting, setSetting] = useState(
    initSetting({
      duration: props.duration,
      from: moment(props.from),
      to: moment(props.to)
    })
  );

  useEffect(() => {
    setSetting({
      ...setting,
      to: {
        min: setting.to.min,
        max: moment(from).add(180, "days").toDate()
      }
    });
    onChange({
      duration: duration,
      insuredFrom: from.format(),
      insuredTo: to.format()
    })
  }, [duration, from, to]);

  const handleFromChange = (selectedDates) => {
    let newFrom = adjustToOClick(moment(selectedDates[0]));
    let newTo = to;

    if (to.diff(newFrom, "hours") < 24) {
      newTo = moment(newFrom).add(1, "days");
    }
    calculateDuration(newFrom, newTo)
  };

  const handleToChange = (selectedDates) => {
    let newTo = adjustToOClick(moment(selectedDates[0]));
    let newFrom = from;

    if (newTo.diff(from, "hours") < 24) {
      newFrom = moment(newTo).subtract(1, "days");
    }

    calculateDuration(newFrom, newTo)
  };

  //fix seconds will be 59
  const adjustToOClick = (time) => {
    if (time.second() == 59) {
      return time.add(1, "seconds")
    }
    return time
  }


  // calculate Duration
  const calculateDuration = (from, to) => {
    return setState({
      from,
      to,
      duration: Math.ceil((to.diff(from, 'hour')) / 24)
    });
  }

  return (
    <>
      <div className="col col-md-6 col-12 mb-2 p-0">
        <Flatpickr
          borderRadius="left"
          label="開始時間"
          data-enable-time
          value={from.format()}
          onChange={handleFromChange}
          options={{
            minuteIncrement: 60,
            minDate: setting && setting.from.min,
            maxDate: setting && setting.from.max
          }}
        />
      </div>
      <div className="col col-md-6 col-12 p-0">

        <Flatpickr
          borderRadius="right"
          label="結束時間"
          value={to.format()}
          data-enable-time
          onChange={handleToChange}
          options={{
            minuteIncrement: 60,
            minDate: setting && setting.to.min,
            maxDate: setting && setting.to.max
          }}
        />
      </div>
    </>
  );
}

export default FromToPicker;
