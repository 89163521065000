import * as yup from 'yup';

yup.setLocale({
  mixed: {
    oneOf: '保險公司無法受理您的投保，敬請見諒。如有疑問請於上班日營業時間洽客服人員，謝謝。',
    required: '此為必填欄位',
    notType: '此為必填欄位',
  },
  array: {
    min: '此為必填欄位',
  },
});

export default yup;
