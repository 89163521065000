import { Button, Col, Row, Select } from 'react-bootstrap'
import React from 'react'
import SearchOptionContext from '@/components/_context/SearchOptionContext'
import moment from 'moment'
import SearchOption from '../SearchOption'
import { Formik, useFormikContext } from 'formik'
import DateDuration from '../SearchOption/DateDuration'
import styled from 'styled-components'
import CustomIcon from '../../../images/react/search/travel.svg'
import InfoIcon from '../../../images/react/search/info.svg'

const SearchBox = styled.div`
  border: 1px solid #1c4151;
  margin-top: -1px;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  padding: 1rem;
`
export const ScRow = styled(Row)`
  margin-left: -8px;
  margin-right: -8px;
`
export const ScCol = styled(Col)`
  padding-left: 8px;
  padding-right: 8px;
`
const AutoSubmitByPrams = () => {
  //如果values.commit 不等於初始值 表示從params有待資料進來
  const { values, submitForm } = useFormikContext()
  React.useEffect(() => {
    // Submit the form imperatively
    if (values.commit != '') {
      submitForm()
    }
  }, [values, submitForm])
  return null
}
class CarSearchForm extends React.Component {
  render() {
    window.moment = moment
    return (
      <SearchBox>
        <SearchOptionContext.Consumer>
          {({ searchForm }) => {
            let {
              productLine,
              currentCategory,
              currentOptionList,
              searchParams,
              onFormSubmit,
            } = searchForm
            let {
              sortKind = '',
              age = '3',
              gender = '0',
              budget = '3',
              commit = '',
            } = searchParams
            return (
              <div>
                <Formik
                  initialValues={{
                    category: currentCategory,
                    sortKind: sortKind,
                    age: age,
                    gender: gender,
                    budget: budget,
                    commit: commit,
                  }}
                  onSubmit={(values, action) => {
                    values.category = currentCategory
                    values.fetchAll = 1
                    values.sortType = 'desc'
                    onFormSubmit(values, action)
                  }}
                >
                  {({ values, handleSubmit, handleChange }) => (
                    <>
                      <form onSubmit={handleSubmit}>
                        <ScRow>
                          <ScCol xs="6" lg="3">
                            <SearchOption
                              name="gender"
                              optionName={'性別'}
                              options={currentOptionList.options.genderOptions}
                              value={values.gender}
                              onChange={handleChange}
                            ></SearchOption>
                          </ScCol>
                          <ScCol xs="6" lg="3">
                            <SearchOption
                              name="age"
                              optionName={'年齡'}
                              options={currentOptionList.options.ageOptions}
                              value={values.age}
                              onChange={handleChange}
                            ></SearchOption>
                          </ScCol>
                          <ScCol xs="6" lg="3">
                            <SearchOption
                              name="sortKind"
                              optionName={'在意的保障'}
                              options={currentOptionList.options.sortKind}
                              value={values.sortKind}
                              onChange={handleChange}
                              nullOption={'不指定'}
                            ></SearchOption>
                          </ScCol>
                          <ScCol xs="6" lg="3">
                            <SearchOption
                              name="budget"
                              optionName={'保費預算'}
                              options={currentOptionList.options.budgetOptions}
                              value={values.budget}
                              onChange={handleChange}
                            ></SearchOption>
                          </ScCol>
                        </ScRow>
                        <p className="text-secondary small mb-0">
                          <InfoIcon className="mr-1" width="13px" />
                          網路投保僅供車主本人投保，尚未開放公司車。
                        </p>
                        <Row className="mt-2">
                          <Col xm="12" lg="4" className="ml-auto">
                            <Button variant="secondary" type="submit" block>
                              快搜試算
                            </Button>
                          </Col>
                        </Row>
                        <AutoSubmitByPrams />
                      </form>
                    </>
                  )}
                </Formik>
              </div>
            )
          }}
        </SearchOptionContext.Consumer>
      </SearchBox>
    )
  }
}

export default CarSearchForm
