import React, { Component } from 'react'
import { Context } from './index'
import { MemberCard as Card } from '@/components/Member/common'
import { Container, Row, Col } from 'react-bootstrap'
import Flex from '@/components/_common/Flex'
import styled from 'styled-components'
import NoAnswer from './NoAnswer'

const CardButton = styled(Card)`
  transition: all 0.3s ease;
  box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.1) !important;
  cursor: pointer;
  justify-content: center;
  align-items: center;

  &:hover {
    box-shadow: 0 0.125rem 1rem rgba(0, 0, 0, 0.125) !important;
  }
  @media (max-width: 576px) {
    margin-left: 0px;
    margin-right: 0px;
  }
`

export class FaqType extends Component {
  render() {
    return (
      <Context.Consumer>
        {({ faqType, handleFaqTypeClcik }) => (
          <>
            <section className="bg-gray-100">
              <Container className="py-5">
                <h3 className="text-center">問題類別</h3>
                <Row>
                  {faqType &&
                    faqType.map((type) => (
                      <Col
                        xs={6}
                        md={4}
                        lg={3}
                        key={type.slug}
                        className="my-3"
                      >
                        <CardButton
                          onClick={() => handleFaqTypeClcik(type.slug)}
                          className="h-100"
                        >
                          <img
                            src={type.image.url}
                            style={{ width: '72px' }}
                          ></img>
                          <h6 className="text-center mb-0">{type.title}</h6>
                        </CardButton>
                      </Col>
                    ))}
                </Row>
              </Container>
            </section>
            <NoAnswer bgGray></NoAnswer>
          </>
        )}
      </Context.Consumer>
    )
  }
}

export default FaqType
