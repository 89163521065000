import React, { useContext, useState } from 'react'
import OptionsContainer from '../../components/OptionsContainer';
import Footer from './Footer';
import FooterContainer from '../FooterContainer'
import context from '../../context'
import FromToPicker from '../../components/FromToPicker';

function Motor() {
  const { options, searchOptions, handleSearchOptionChange } = useContext(context)

  const motorType = [["燃油機車", 0], ["電動機車", 1]];
  const gasDisplacementOptions = options.gas_displacement_options
  const electricDisplacementOptions = options.electric_displacement_options

  const [currentMotorType, setCurrentMotorType] = useState(searchOptions.displacement > 2 ? 1 : 0)
  const [currentOptions, setCurrentOptions] = useState(searchOptions.displacement > 2 ? electricDisplacementOptions : gasDisplacementOptions)

  const handleMotorTypeChange = (e) => {
    let value = e.target.value
    let currentOption
    if (value == 0) {
      currentOption = gasDisplacementOptions[0][1]
    } else {
      currentOption = electricDisplacementOptions[0][1]
    }
    setCurrentMotorType(value)
    setCurrentOptions(value == 0 ? gasDisplacementOptions : electricDisplacementOptions)
    handleSearchOptionChange({ displacement: currentOption })
  }

  const handleDisplacementChange = (e) => {
    handleSearchOptionChange({ displacement: e.target.value })
  }


  return (
    <>
      <div className="mt-3 mt-md-0">
        <div className="row">
          {/* left */}
          <div className="col-12 col-lg-6 py-0 pt-md-4">
            <h5 className="title-line-primary d-none d-md-block">您的機車是</h5>
            <h6 className="title-line-primary d-md-none d-block">您的機車是</h6>
            <OptionsContainer
              options={motorType}
              value={currentMotorType}
              onChange={handleMotorTypeChange}
            />
          </div>
          {/* right */}
          <div className="col-12 col-lg-6 py-0 pt-md-4">
            <h5 className="title-line-primary d-none d-md-block">您的排氣量</h5>
            <h6 className="title-line-primary d-md-none d-block">您的排氣量</h6>
            <OptionsContainer
              options={currentOptions}
              value={searchOptions.displacement}
              onChange={handleDisplacementChange}
            />
          </div>
          <div className="col-12 ">
            <p className="text-gray-500 small">
              ◎ 車主本人需年滿18，限使用車主本人信用卡繳費。<br />
              ◎ 網路投保機車險為一年期，保險期間僅承接60日內之保期。<br />
              ◎ 本站方案皆以關貿等級4(無投保紀錄)，您的實際保費將連線監理單位檢核，依照投保人與車輛係數計算後呈現。
            </p>
          </div>
        </div>
      </div>
      <FooterContainer>
        <Footer />
      </FooterContainer>
    </>
  )
}

export default Motor


