import React, { useContext } from 'react'
import context from '../../context'

function Footer() {
  const { productInfo, options } = useContext(context)
  const { displacement } = productInfo.otherInfo

  let optionsFletten = [...options.gas_displacement_options, ...options.electric_displacement_options]
  let [optionName] = optionsFletten.filter((option) => option[1] == displacement)
  console.log(optionName, optionsFletten)
  return (
    <>
      <h4 className="m-0 mt-3">
        {optionName && optionName[0]}
        /一年期</h4>
    </>
  )
}

export default Footer;

