import { ReactComponent as ChanceA } from '../../images/promotions/cards/chance-1.svg'
import { ReactComponent as ChanceB } from '../../images/promotions/cards/chance-2.svg'
import { ReactComponent as ChanceC } from '../../images/promotions/cards/chance-3.svg'
import { ReactComponent as ChanceD } from '../../images/promotions/cards/chance-4.svg'
import { ReactComponent as ChanceE } from '../../images/promotions/cards/chance-5.svg'
import { ReactComponent as FateA } from '../../images/promotions/cards/fate-1.svg'
import { ReactComponent as FateB } from '../../images/promotions/cards/fate-2.svg'
import { ReactComponent as FateC } from '../../images/promotions/cards/fate-3.svg'
import { ReactComponent as FateD } from '../../images/promotions/cards/fate-4.svg'
import { ReactComponent as FateE } from '../../images/promotions/cards/fate-5.svg'
import { ReactComponent as FateNothing } from '../../images/promotions/cards/fate-nothing.svg'
import { ReactComponent as ChanceNothing } from '../../images/promotions/cards/chance-nothing.svg'
import AwardChanceCard from './AwardChanceCard'
import AwardFateCard from './AwardFateCard'

export const ChanceCards = [ChanceA, ChanceB, ChanceC, ChanceD, ChanceE]
export const FateCards = [FateA, FateB, FateC, FateD, FateE]
export const Cards = { chance: ChanceCards, fate: FateCards }
export const NothingCards = { chance: ChanceNothing, fate: FateNothing }
export const AwardCards = { chance: AwardChanceCard, fate: AwardFateCard }
