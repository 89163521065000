import React from 'react'
import { Row, Col, Button, Spinner } from 'react-bootstrap'

function SubmitBtn({ children, isLoading, disabled, onClick }) {
  return (
    <Row>
      <Col xm={12} md={7} className="mx-auto mb-5">
        <Button
          variant="primary"
          type="submit"
          block
          disabled={isLoading || disabled}
          onClick={onClick}
        >
          {children}
          {isLoading && (
            <Spinner
              as="span"
              animation="border"
              role="status"
              size="sm"
              aria-hidden="true"
              className="mb-1 ml-2"
            />
          )}
        </Button>
      </Col>
    </Row>
  )
}

export default SubmitBtn
