import React from 'react'
import styled from 'styled-components'

const NullBox = styled.div`
  height: 250px;
  border-style: dashed;
  border-width: 1px;
  border-radius: 0.25rem;
  border-color: #adb5bd
  margin-bottom: 1rem !important;
  margin-top: 1rem !important;
  align-items: center !important;
  justify-content: center !important;
  display: flex !important;
  border-color: #adb5bd
`

function NullView() {
  return (
    <div>
      <NullBox>
        <h5 className="m-0 text-gray-500 font-weight-normal">
          使用快搜試算，即可立即比較各家方案
        </h5>
      </NullBox>
    </div>
  )
}

export default NullView
