import React, { Component } from 'react'
import { Flex } from '../../common/index'

export class PolicyTitle extends Component {
  render() {
    return (
      <div className="mb-2 mb-md-4">
        <Flex center className="mx-0 mx-md-3">
          <img
            width="100px"
            src={this.props.image}
            className="d-none d-md-block mr-3"
          />
          <div>
            <h4 className="m-0">{this.props.companyName}</h4>
            <h3 className="m-0">{this.props.policyName}</h3>
          </div>
          <p className="align-self-end ml-auto d-none d-md-block">
            購買時間 {this.props.createdAt}
          </p>
        </Flex>
        <hr />
      </div>
    )
  }
}

export default PolicyTitle
