import React, { Component } from 'react'
import { Formik, Field, ErrorMessage } from 'formik'
import { FormFeedback } from '@/components/_common/Inputs'
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import * as yup from 'yup'
import { withContext } from '../Context'
import Err from '../ErrorMessage'
import citizenIdTest from '@/components/_common/citizenIdTest'

const schema = yup.object().shape({
  member: yup.object().shape({
    citizen_id: yup
      .string()
      .required(Err.IdForm.citizen_id.required)
      .test('citizenIdFormat', Err.IdForm.citizen_id.test, citizenIdTest),
  }),
})

const initialValues = {
  utf8: '✓',
  authenticity_token: document
    .querySelector("meta[name='csrf-token']")
    .getAttribute('content'),
  member: { citizen_id: '' },
}

const IdForm = ({ ...props }) => {
  let context = props.context
  return (
    <Container>
      <h1 className="text-center">忘記密碼</h1>
      <p>請輸入您的身分證字號</p>
      <Formik
        validationSchema={schema}
        initialValues={initialValues}
        onSubmit={context.submitFunction.onIdFormSubmit}
      >
        {({ values, handleSubmit, handleChange, errors, touched }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col xs={12} className="mb-3">
                  <Field
                    as={Form.Control}
                    type="text"
                    name="member[citizen_id]"
                    placeholder="請輸入身分證字號"
                  />
                  <ErrorMessage
                    component={FormFeedback}
                    name="member[citizen_id]"
                    className="text-left"
                  />
                </Col>
                <Col xs={12}>
                  <Button variant="primary" type="submit" block>
                    發送驗證碼
                  </Button>
                </Col>
              </Row>
            </Form>
          )
        }}
      </Formik>
    </Container>
  )
}

export default withContext(IdForm)
