import React, { Component } from 'react'
import { Tabs as _Tabs, Tab } from 'react-bootstrap'
import styled, { ThemeProvider } from 'styled-components'
import themes from '@/components/Themes'
import { Scrollbars } from 'react-custom-scrollbars'
import { withContext } from '../../Context'
const Tabs = styled(_Tabs)`
  a:first-child {
    border-radius: 10px 0 0 0;
  }
  a:last-child {
    border-radius: 0 10px 0 0;
  }
  a:hover {
    border-color: ${({ theme }) => theme.color.primary} !important;
  }
  .active {
    background-color: ${({ theme }) => theme.color.primary} !important;
    border-color: ${({ theme }) => theme.color.primary} !important;
  }
  .nav-item.nav-link {
    padding-right: 10px;
    padding-left: 10px;
  }
  .tab-content > .active {
    border-radius: 0 10px 0 10px;
  }
`

const Content = styled.div`
  margin: ${({ theme }) => theme.spacers[3]};
  max-height: 300px;
  height: 80vh;
  width: auto;
`

export class Terms extends Component {
  render() {
    return (
      <div className={this.props.className}>
        <Tabs justify variant="tabs" defaultActiveKey="term-0">
          {this.props.context.terms.map((term, i) => (
            <Tab eventKey={'term-' + i} title={term.title} key={i}>
              <Content>
                <Scrollbars style={{ height: '100%' }}>
                  <p
                    className="mr-2"
                    dangerouslySetInnerHTML={{ __html: term.term }}
                  />
                </Scrollbars>
              </Content>
            </Tab>
          ))}
        </Tabs>
      </div>
    )
  }
}

export default withContext(Terms)
