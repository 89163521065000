import React, { Component } from 'react'
import { MyPopup } from '@/components/_common/StyledPopup'
import { Formik, Field, ErrorMessage } from 'formik'
import { Form, Row, Col, Button } from 'react-bootstrap'
import Spinner from '../../components/Spinner'
import * as Yup from 'yup'
import { ConfirmationError } from '../../components/ErrorMessage'

const schema = Yup.object().shape({
  member: Yup.object().shape({
    email: Yup.string()
      .required(ConfirmationError.email.required)
      .email(ConfirmationError.email.email),
  }),
})

const Error = function (props) {
  return <p className="text-error"></p>
}

export class Completed extends Component {
  renderForm() {
    return (
      <Formik
        validationSchema={schema}
        initialValues={{
          utf8: '✓',
          authenticity_token: document
            .querySelector("meta[name='csrf-token']")
            .getAttribute('content'),
          member: {
            email: this.props.email,
          },
        }}
        onSubmit={this.props.submitHandler}
      >
        {({ values, handleSubmit, handleChange, errors, touched }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Field
                as={Form.Control}
                name="member[email]"
                label="我願意收到 SARAcares 的最新訊息"
                id="member[email]"
                type="text"
              />
              <ErrorMessage
                name="member[email]"
                render={(msg) => <p className="text-error">{msg}</p>}
              />
              <Button
                variant="primary"
                type="submit"
                block
                disabled={this.props.isLoading}
                className="mt-3"
              >
                送出
                {this.props.isLoading && <Spinner />}
              </Button>
            </Form>
          )
        }}
      </Formik>
    )
  }

  render() {
    return (
      <div className="text-center">
        <p>
          系統已{this.props.unconfirm && '重新'}發送驗證信至您所填寫的信箱，
          <br />
          請前往收信，並完成 E-mail 驗證，即可完成註冊。
        </p>
        <p>
          若未收到驗證信件，請按
          <a
            href="###"
            className="font-weight-bold"
            onClick={this.props.resendHandler}
          >
            重寄 E-mail 驗證信
          </a>
          <MyPopup
            open={this.props.isResendOpen}
            modal
            close={this.props.resendHandler}
          >
            <>
              <h2 className="text-center">重寄 E-mail 驗證信</h2>
              <Row>
                <Col xs={12} md={8} className="mx-auto">
                  {this.renderForm()}
                </Col>
              </Row>
            </>
          </MyPopup>
        </p>
      </div>
    )
  }
}

export default Completed
