const citizenIdTest = (value) => {
  if (!value) return true
  let citizenid = value.toUpperCase()
  let local_table = [
    10,
    11,
    12,
    13,
    14,
    15,
    16,
    17,
    34,
    18,
    19,
    20,
    21,
    22,
    35,
    23,
    24,
    25,
    26,
    27,
    28,
    29,
    32,
    30,
    31,
    33,
  ]
  /* A, B, C, D, E, F, G, H, I, J, K, L, M,
        N, O, P, Q, R, S, T, U, V, W, X, Y, Z */

  let local_digit = local_table[citizenid.charCodeAt(0) - 'A'.charCodeAt(0)]
  let checksum = 0
  checksum += Math.floor(local_digit / 10)
  checksum += (local_digit % 10) * 9
  if (
    parseInt(citizenid.charAt(1)) != 1 &&
    parseInt(citizenid.charAt(1)) != 2
  ) {
    return false
  }

  for (var i = 1, p = 8; i <= 8; i++, p--) {
    checksum += parseInt(citizenid.charAt(i)) * p
  }

  checksum += parseInt(citizenid.charAt(9)) /* add the last number */
  return !(checksum % 10) && !isNaN(checksum)
}

export default citizenIdTest