import React from 'react'
import { Formik } from 'formik'
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import { PasswordInput, ConfirmInput } from '@/components/_common/FormInput'
import { withContext } from '../Context'
import * as Yup from 'yup'
import { SignUpError } from '@/components/Registration/components/ErrorMessage'

const passwrodRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d!@#$%^&*?\.\,]{6,}$/

const schema = Yup.object().shape({
  member: Yup.object().shape({
    password: Yup.string().matches(passwrodRegExp),
    password_confirmation: Yup.string().when('password', (password, schema) => {
      return password
        ? schema
          .oneOf([password], '輸入密碼不一致').required('密碼輸入不一致')
          .required(SignUpError.password_confirmation.required)
        : schema
    }),
  }),
})

const ResetPasswordForm = ({ ...props }) => {
  let context = props.context

  let initialValues = {
    utf8: '✓',
    authenticity_token: document
      .querySelector("meta[name='csrf-token']")
      .getAttribute('content'),
    member: {
      reset_password_token: context.reset_password_token,
      password: '',
      password_confirmation: '',
    },
  }

  return (
    <Container>
      <h1 className="text-center">重新設定新密碼</h1>
      <Formik
        initialValues={initialValues}
        onSubmit={context.submitFunction.onResetPasswordFormSubmit}
        validationSchema={schema}
      >
        {({ values, handleSubmit, handleChange, errors, touched }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Row className="text-left">
                <Col xs={12} className="mb-3">
                  <PasswordInput
                    label="新密碼"
                    type="password"
                    value={values.member.password}
                    fullSize
                    name="member[password]"
                    onChange={handleChange}
                  />
                </Col>
                <Col>
                  <ConfirmInput
                    label="密碼確認"
                    type="password"
                    value={values.member.password_confirmation}
                    fullSize
                    name="member[password_confirmation]"
                    onChange={handleChange}
                  />
                </Col>
                <Col xs={12}>
                  <Button variant="primary" type="submit" block>
                    下一步
                  </Button>
                </Col>
              </Row>
            </Form>
          )
        }}
      </Formik>
    </Container>
  )
}

export default withContext(ResetPasswordForm)
