import React, { Component } from 'react'
import NullView from './NullView'
import Sort from './Sort'
import Plan from './Plan'
import SearchOptionContext from '@/components/_context/SearchOptionContext'
import StyledPopup from '@/components/_common/StyledPopup'
import IConQues from '../../../images/react/search/question.svg'

export class PlanList extends Component {
  constructor(props) {
    super(props)
  }
  planListRender(list) {
    return list.map((plan) => {
      return (
        <Plan
          key={plan.id}
          plan={plan}
          onCompareClick={(e) => {
            this.context.compareList.onCompareClick(e)
          }}
        />
      )
    })
  }

  render() {
    console.log(this.context)
    let { planList } = this.context
    return (
      <div>
        <Sort onClick={this.sortPlan} />
        {planList.list.length > 0 ? (
          this.planListRender(planList.list)
        ) : (
          <NullView />
        )}
      </div>
    )
  }
}

PlanList.contextType = SearchOptionContext

export default PlanList
