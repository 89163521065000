import React, { Component } from 'react'
import MemberContext from '@/components/_context/MemberContext'
import {
  Input,
  InputGroup,
  PasswordInput,
  ConfirmInput,
} from '@/components/_common/FormInput'
import { Formik } from 'formik'
import { Form, Col, Row, Button } from 'react-bootstrap'
import axios from 'axios'
import * as Yup from 'yup'
import { MyPopup } from '@/components/_common/StyledPopup'

const passwrodRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{6,}$/

const schema = Yup.object().shape({
  member: Yup.object().shape({
    current_password: Yup.string().required('請輸入您原本的密碼'),
    password: Yup.string().matches(passwrodRegExp),
    password_confirmation: Yup.string().when('password', (password, schema) => {
      return password
        ? schema.oneOf([password], '輸入密碼不一致').required('密碼輸入不一致')
        : schema
    }),
  }),
})

export class Password extends Component {
  constructor(props) {
    super(props)
    this.state = {
      popupTrigger: false,
      response: [],
      success: false,
      showPassword: false,
      showConfirm: false,
    }
  }

  onShowClick(password) {
    if (password) {
      this.setState({
        showPassword: !this.state.showPassword,
      })
    } else {
      this.setState({
        showConfirm: !this.state.showConfirm,
      })
    }
  }

  onClosePopup() {
    if (this.state.success) {
      window.location.href = '/welcome/member'
    }
    this.setState({ popupTrigger: false, response: [] })
  }
  render() {
    let { current_member } = this.context
    return (
      <div className="mt-3">
        <Formik
          validationSchema={schema}
          initialValues={{
            utf8: '✓',
            _method: 'path',
            authenticity_token: document
              .querySelector("meta[name='csrf-token']")
              .getAttribute('content'),
            member: {
              current_password: '',
              password: '',
              password_confirmation: '',
            },
          }}
          onSubmit={(values, actions) => {
            let _this = this
            console.log(values)
            showLoading()
            let formData = new FormData()
            for (let key in values) {
              if (typeof values[key] == 'object') {
                for (let key2 in values[key]) {
                  formData.append(`${key}[${key2}]`, values[key][key2])
                }
              } else {
                formData.append(key, values[key])
              }
            }
            axios
              .patch('/member/update_password.json', formData, {
                headers: {
                  'Content-Type': 'application/json',
                },
              })
              .then((response) => {
                console.log(response)
                if (response.data.success) {
                  console.log(response.data.success)
                  _this.setState({
                    popupTrigger: true,
                    response: ['密碼變更完成'],
                    success: true,
                  })
                }
                if (response.data.error) {
                  _this.setState({
                    popupTrigger: true,
                    response: response.data.error,
                    success: false,
                  })
                }
              })
              .catch(function (error) {
                console.log(error)
              })
              .then(function () {
                hideLoading()
                actions.setSubmitting(false)
              })
          }}
        >
          {({
            values,
            touched,
            handleChange,
            errors,
            handleSubmit,
            initialValues,
            resetForm,
          }) => {
            return (
              <Form className="mx-0 mx-md-3 mt-4" onSubmit={handleSubmit}>
                <Input
                  label="舊密碼"
                  value={values.member.current_member}
                  type="password"
                  name="member[current_password]"
                  onChange={handleChange}
                ></Input>

                <PasswordInput
                  label="新密碼"
                  value={values.member.password}
                  type="password"
                  name="member[password]"
                  onChange={handleChange}
                />

                <ConfirmInput
                  label="確認密碼"
                  value={values.member.password_confirmation}
                  type="password"
                  name="member[password_confirmation]"
                  onChange={handleChange}
                ></ConfirmInput>

                <Row className="mt-5 mb-4">
                  <Col xs={6} md={2} className="mx-auto">
                    <Button variant="primary" type="submit" block>
                      {' '}
                      完成{' '}
                    </Button>
                  </Col>
                </Row>
              </Form>
            )
          }}
        </Formik>
        <MyPopup
          open={this.state.popupTrigger}
          modal={true}
          close={this.onClosePopup.bind(this)}
        >
          {this.state.response.map((r, index) => (
            <h3 className="text-center" key={index}>
              {r}
            </h3>
          ))}
        </MyPopup>
        {/* <MobileVerifies id={this.state.varifyId} phone={this.state.varifyPhone} trigger={this.state.varifyPopupTrigger} onClose={(e) => this.varifyPopupCloseHandler(e)}></MobileVerifies> */}
      </div>
    )
  }
}
Password.contextType = MemberContext

export default Password
