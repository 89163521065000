import styled from 'styled-components/macro'
import { Button as Btn } from 'react-bootstrap'
export const MultiBox = styled.div`
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin: 0;
  outline: 0;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  line-height: 1.21428571em;
  padding: 0.25rem 0.25rem;
  background: #fff;
  border: 1px solid ${(props) => props.theme.grayColor.gray300};
  border-width: 1px 0 1px 1px;
  border-radius: 0.25rem 0 0 0.25rem;
  transition: box-shadow 0.1s ease,
    ${(props) => props.theme.grayColor.gray300} 0.1s ease;
  font-size: 13px;
  display: flex;
  flex-wrap: nowrap;
  overflow: scroll;
  align-items: center;
  align-content: flex-start;
  ${({ focused, theme }) => {
    return focused
      ? `border-color: ${theme.color.primary};background: #fff;`
      : ``
  }}
  &::-webkit-scrollbar {
    display: none;
  }
`

export const Placeholder = styled.span`
  position: absolute;
  left: 0.5em;
  top: calc(50% - 7px);
  line-height: 14px;
  color: rgba(0, 0, 0, 0.4);
  display: ${({ focused, empty, value }) =>
    empty ? (focused ? 'none' : value == '' ? 'inline' : 'none') : 'none'};
`
export const Label = styled.div`
  line-height: 1;
  vertical-align: baseline;
  margin: 0.14285714em;
  background-color: ${(props) => props.theme.grayColor.gray200};
  background-image: none;
  padding: 0.5833em 0.833em;
  color: ${(props) => props.theme.grayColor.gray600};
  text-transform: none;
  font-weight: 600;
  border-radius: 1rem;
  transition: background 0.1s ease;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  & [data-tag-item] {
    overflow: hidden;
  }
  & [data-tag]:first-child {
    margin-left: 0;
  }
  & span {
    margin-left: 0.833em;
    cursor: pointer;
  }
`

export const Input = styled.input`
  outline: none !important;
  border: 0 none !important;
  display: inline-block !important;
  line-height: 1;
  color: ${(props) => props.theme.grayColor.gray600};
  vertical-align: baseline !important;
  padding: 0.4em 0.1em !important;
  width: auto;
`

export const SubmitButton = styled(Btn)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  flex: 0 0;
  height: 100%;
`
