import React, { Component } from 'react'
import styled from 'styled-components'
import { Button, Table } from 'react-bootstrap'
import { Flex } from '../../common/index'
import _ from 'lodash'
import moment from 'moment'




function PolicyTables({ data }) {
  return (
    <div className="mb-4">
      <Table bordered hover className='mb-3'>
        <Tbody>
          {data.map(row => (
            <tr key={row.title}>
              <td>{row.title}</td>
              <td>{row.value}</td>
            </tr>
          ))}
        </Tbody>
      </Table>
    </div>
  )
}

export const Tbody = styled.tbody`
  tr:nth-of-type(odd) {
    background-color: ${({ theme }) => theme.grayColor.gray100};
  }
  tr:hover {
    background-color: ${({ theme }) => theme.color.primaryLight} !important;
  }
  td:first-child {
    width: 40%;
  }
  td {
    width: 60%;
  }
`

export default PolicyTables
