import React, { Component, useEffect, useState, useContext } from 'react'
import LotteryContext from '@/components/_context/LotteryContext'
import { ReactComponent as CardButton } from '../../images/promotions/start-btn.svg'
import { ChanceCards, FateCards } from './Cards'

import styled, { keyframes } from 'styled-components'
import { render } from 'react-dom'

const buttonAnimation = keyframes`
  from {
    left: 40%;
  }
  to {
    left: 5%;
  }
`
const ButtonAnimationMobile = keyframes`
  from {
    left: 30%;
  }
  to {
    left: 1%;
    transform: scale(0.53)
  }
`

const fateCardShuffleAnimation = keyframes`
  0% {
    left: 75%;
  }
  15% {
    left: 7%;
  }
  25%{
    left: 15%;
  }
  35%{
    left: 7%;
  }
  65%{
    left: 15%;
  }
  75%{
    left: 7%;
  }
  80% {
    left: 7%;
  }
  100% {
    left: 75%;
  }
`

const fateCardShuffleAnimationMobile = (width) => keyframes`
  
  30% {
    left: 7%;
    transform: scale(0.48)
  }

  70% {
    left: 7%;
    
    transform: scale(0.48)
  }
  
`

const chanceCardShuffleAnimation = keyframes`
  0% {
    left: 41%;
  }
  15%{
    left: 7%;
  }
  45%{
    left: 15%;
  }
  55% {
    left: 7%;
  }
  80% {
    left: 15%;
  }
  100% {
    left: 41%;
  }
`

const chanceCardShuffleAnimationMobile = (width) => keyframes`
  10% {
    left: 7%;
    transform: scale(0.48) 
  }

  90% {
    left: 7%;
    transform: scale(0.48)
  }
`

const FateCard = (props) => {
  let { fateCardIndex } = useContext(LotteryContext)
  let { selectedCardHandler } = useContext(LotteryContext)
  console.log(fateCardIndex)
  let Component = FateCards[fateCardIndex] || FateCards[0]
  return (
    <Component
      {...props}
      onClick={() =>
        selectedCardHandler({ type: 'fate', index: fateCardIndex })
      }
    ></Component>
  )
}
const ChanceCard = (props) => {
  let { chanceCardIndex } = useContext(LotteryContext)
  let { selectedCardHandler } = useContext(LotteryContext)
  let Component = ChanceCards[chanceCardIndex] || ChanceCards[0]
  return (
    <Component
      {...props}
      onClick={() =>
        selectedCardHandler({ type: 'chance', index: chanceCardIndex })
      }
    ></Component>
  )
}

const withStyled = (Component, type) => {
  const left = {
    chance: {
      init: '41%',
      start: '41%',
    },
    fate: {
      init: '41%',
      start: '75%',
    },
  }
  const leftMobile = {
    chance: {
      init: '36%',
      start: '27%',
    },
    fate: {
      init: '36%',
      start: '64%',
    },
  }

  const animation = {
    chance: chanceCardShuffleAnimation,
    fate: fateCardShuffleAnimation,
  }

  const animationMobile = {
    chance: chanceCardShuffleAnimationMobile,
    fate: fateCardShuffleAnimationMobile,
  }

  return styled(Component)`
    transition: all 0.25s ease-out;
    box-shadow: ${({ theme }) => theme.shadow.sm};
    height: 94%;
    position: absolute;
    top: 0;
    margin: 0.7%;
    z-index: 1;
    left: ${({ isStart }) => (isStart ? left[type].start : left[type].init)};
    transform-origin: 0px 0px;
    cursor: pointer;
    animation: 2s ${({ isRerender }) => (isRerender ? animation[type] : 'none')}
      ease-out forwards;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: optimize-contrast;
    backface-visibility: hidden;

    &:hover {
      transform-style: preserve-3d;
      transform: rotate3d(1, 0, 0, 20deg) translateZ(60px);
      box-shadow: ${({ theme }) => theme.shadow.md};
    }
    @media (max-width: 768px) {
      left: ${({ isStart }) =>
        isStart ? leftMobile[type].start : leftMobile[type].init};
      animation: 0.75s
        ${({ isRerender, _width }) =>
          isRerender ? animationMobile[type](_width) : 'none'}
        ease-out forwards;
      &:hover {
        transform: rotate3d(1, 0, 0, 20deg) translateZ(20px);
      }
    }
  `
}

const Box = styled.div`
  display: block;
  height: ${({ height }) => height + 'px' || '100%'};
  padding: 5px;
  position: relative;
  width: 100%;
  justify-content: space-between;
  transition: all 0.35s ease-out;
  perspective: 2500px;
`

const Button = styled(CardButton).attrs((props) => ({
  className: props.isStart ? 'actived' : '',
}))`
  height: 100%;
  position: absolute;
  cursor: pointer;
  top: 0;
  bottom: 0;
  left: 40%;
  z-index: 3;
  transform-origin: 0px 0px;
  animation: 0.25s ${({ isStart }) => (isStart ? buttonAnimation : 'none')}
    ease-out forwards;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: optimize-contrast;
  backface-visibility: hidden;
  & * {
    transition: all 0.25s ease-out;
  }

  &:hover {
    .st-btn {
      opacity: 0;
    }
    .rest-btn {
      opacity: 0;
    }
    .oc-1 {
      transform: translateX(10px);
    }
    .bc-2 {
      transform: translateX(20px);
    }
    .oc-2 {
      transform: translateX(30px);
    }
  }
  .st-hover,
  .st-btn {
    ${({ isStart }) => (isStart ? 'display:none' : '')}
  }

  @media (max-width: 768px) {
    left: 30%;
    animation: 0.25s
      ${({ isStart }) => (isStart ? ButtonAnimationMobile : 'none')} ease-out
      forwards;
  }
`

export default {
  Button,
  Box,
  FateCard: withStyled(FateCard, 'fate'),
  ChanceCard: withStyled(ChanceCard, 'chance'),
}
