import React, { useEffect, useState } from 'react'
import useSearching from './useSearching'

import ProductPlan from './ProductPlan'
import ProductPlanContainer from './ProductPlan/ProductPlanContainer'
import Context from './context'
import SearchBar from './SearchBar'
import BottomBar from './BottomBar'

const searchApi = (type) => {
  if (type == "travel")
    return "/api/v2/travel_searching_default"
  if (type == "sport")
    return "/api/v2/travel_searching_default"
  return `/api/v2/${type}_searching`
}


function LandingPage(props) {
  const { packageId, defaultOptions, apiUrl, type } = props


  const [productInfo, planProducts, searchOptions, setSearchOptions, isLoading] = useSearching(`/api/v2/searching/${type}`, defaultOptions, packageId, () => { })
  const handleSearchOptionChange = (option) => {
    setSearchOptions((prev) => ({
      ...prev, ...option
    }))
  }

  const handleBuyBtnClick = (e) => {
    e.preventDefault()
    dataLayer.push({
      'event': 'checkout',
      'tag': productInfo.otherInfo.package_name,
      'ecommerce': {
        'checkout': {
          'actionField': {
            'step': 1,
            'option': '買吧第一頁'
          },
          'products': [{
            'name': productInfo.otherInfo.package_name,
            'id': productInfo.otherInfo.package_id,
            'price': productInfo.fee,
            'brand': productInfo.otherInfo.insurence_company
          }]
        },
      },
      'eventCallback': () => {
        document.location = productInfo.buyUrl;
      }
    });
  }

  return (
    <Context.Provider value={{ ...props, searchOptions, handleSearchOptionChange, handleBuyBtnClick, planProducts, productInfo, isLoading }}>
      <SearchBar type={type} />
      <ProductPlanContainer>
        <ProductPlan  {...{ planProducts, productInfo }} />
      </ProductPlanContainer>
      <BottomBar />
    </Context.Provider>
  )
}

export default LandingPage
