import React, { useEffect, useState } from 'react'
import { MyPopup } from '@/components/_common/StyledPopup'
import MemberContext from '@/components/_context/MemberContext'
import { useFormik } from 'formik'
import { Form, Button, InputGroup } from 'react-bootstrap'
import axios from 'axios'
import jwtHeader from '@/components/_common/jwtHeader'

function MobileVerifies({ phone, id, prefix, verifyValue, onVerified = () => { }, onClose = () => { }, trigger, params }) {
  const [verify, setVerify] = useState(false);
  const [mobileInfo, setMobileInfo] = useState({ id, prefix })

  useEffect(() => {
    setMobileInfo({ id, prefix })
  }, [id, prefix])

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: mobileInfo.id,
      check_code: '',
    },
    onSubmit: (values, actions) => {
      console.log(values)
      showLoading()
      fetch(`/member/mobile_verifies/${values.id}/edit?mobile_verify[check_code]=${values.check_code}`, { headers: jwtHeader() })
        .then(res => res.json())
        .then((res) => {
          console.log(res)
          if (!res.success) { throw res.error || '系統異常，請稍後再試！' }
          const formData = new FormData();
          formData.append('mobile_verify[mobile_phone]', phone);
          formData.append('mobile_verify[check_code]', values.check_code);
          for (let key in params) {
            formData.append(`mobile_verify[${key}]`, params[key])
          }
          return fetch(`/member/mobile_verifies/${values.id}`, { method: 'PATCH', body: formData, headers: jwtHeader({}) })
        })
        .then(res => res.json())
        .then((res) => {
          console.log(res)
          if (!res.success) { throw res.error || '系統異常，請稍後再試！' }
          setVerify(true)
          onVerified(true)
          alert("資料已更新");
          hideLoading()
          onClose()
        })
        .catch(e => {
          alert(e.toString())
          hideLoading()
          actions.resetForm()
        })
    },
  })

  const onResend = () => {
    showLoading()
    const formData = new FormData()
    formData.append('mobile_verify[phone_number]', phone);
    fetch('/member/mobile_verifies', { method: 'POST', headers: jwtHeader(), body: JSON.stringify({ mobile_verify: { phone_number: phone } }) })
      .then(res => res.json())
      .then(res => {
        hideLoading();
        if (!res.success) throw res.error
        setMobileInfo({ id: res.id, prefix: res.prefix });
      })
      .catch(e => {
        alert(e.toString());
      })
  }

  return (
    <MyPopup
      open={trigger}
      modal={true}
      close={onClose}
    >
      <h3>
        驗證碼將寄至{phone}
        <br />
        請輸入
        <span className="text-primary">手機簡訊</span>
        收到的驗證碼
      </h3>
      <a href="###" onClick={onResend}>
        重新傳送驗證碼
      </a>
      <Form onSubmit={formik.handleSubmit}>
        <InputGroup>
          <InputGroup.Prepend>
            <InputGroup.Text>{mobileInfo.prefix}</InputGroup.Text>
          </InputGroup.Prepend>
          <input type="hidden" id="id" name="id" value={formik.values.id} />
          <Form.Control
            type="text"
            onChange={formik.handleChange}
            name="check_code"
            value={formik.values.check_code}
          ></Form.Control>
          <InputGroup.Append>
            <Button type="submit">送出</Button>
          </InputGroup.Append>
        </InputGroup>
      </Form>
    </MyPopup>
  )
}

// export class MobileVerifies extends Component {
//   constructor(props) {
//     super(props)
//     console.log(this.props.params)
//     this.state = {
//       verifiry: false,
//       trigger: false,
//       phone: this.props.phone || '',
//       id: this.props.id || '',
//       prefix: this.props.prefix + '-',
//     }
//   }

//   componentDidUpdate(prevProps) {
//     if (this.props.trigger !== prevProps.trigger) {
//       this.setState({
//         trigger: this.props.trigger,
//         phone: this.props.phone,
//         id: this.props.id,
//       })
//     }
//   }

//   onClose() {
//     if (this.props.onVerified) {
//       this.props.onVerified(this.state.verifiry)
//     }
//     this.props.onClose()
//   }

//   onSubmit(data, resetForm, values) {
//     if (data.success) {
//       let _this = this
//       let params = new FormData()
//       params.append('mobile_verify[mobile_phone]', this.state.phone)
//       params.append(`mobile_verify[check_code]`, values.check_code)
//       for (let key in this.props.params) {
//         params.append(`mobile_verify[${key}]`, this.props.params[key])
//       }
//       axios
//         .patch(`/member/mobile_verifies/${values.id}`, params, {
//           headers: {
//             'Content-Type': 'application/json',
//             'X-CSRF-Token': document
//               .querySelector("meta[name='csrf-token']")
//               .getAttribute('content'),
//           },
//         })
//         .then((response) => {
//           if (response.data.error) {
//             alert(response.data.error)
//             hideLoading()
//             if (this.props.onVerified) {
//               this.props.onVerified(false)
//             }
//             resetForm()
//           } else {
//             _this.setState({
//               verifiry: true,
//             })
//             if (_this.props.onVerified) {
//               _this.props.onVerified(true)
//             }
//             alert(data.success)
//             hideLoading()
//             _this.onClose()
//           }
//         })
//     } else {
//       hideLoading()
//       alert(data.error)
//       if (this.props.onVerified) {
//         this.props.onVerified(false)
//       }
//       resetForm()
//     }
//   }

//   onResend() {
//     showLoading()
//     let _this = this
//     let params = new FormData()
//     params.append('mobile_verify[phone_number]', this.state.phone)
//     axios
//       .post('/member/mobile_verifies', params, {
//         headers: {
//           'Content-Type': 'application/json',
//           'X-CSRF-Token': document
//             .querySelector("meta[name='csrf-token']")
//             .getAttribute('content'),
//         },
//       })
//       .then((response) => {
//         hideLoading()
//         if (response.data.success) {
//           _this.setState({
//             id: response.data.id,
//             prefix: response.data.prefix,
//           })
//         } else {
//           alert(response.data.error)
//         }
//       })
//   }

//   render() {
//     return (
//       <MemberContext.Consumer>
//         {() => (
//           <MyPopup
//             open={this.state.trigger}
//             modal={true}
//             close={(e) => this.props.onClose(e)}
//           >
//             <h3>
//               驗證碼將寄至{this.state.phone}
//               <br />
//               請輸入
//               <span className="text-primary">手機簡訊</span>
//               收到的驗證碼
//             </h3>
//             <a href="###" onClick={this.onResend.bind(this)}>
//               重新傳送驗證碼
//             </a>
//             <Formik
//               initialValues={{
//                 id: this.props.id,
//                 check_code: '',
//               }}
//               onSubmit={(values, { resetForm }) => {
//                 showLoading()
//                 let _this = this
//                 axios
//                   .get(
//                     `/member/mobile_verifies/${values.id}/edit?mobile_verify[check_code]=${values.check_code}`,
//                     {
//                       headers: {
//                         'Content-Type': 'application/json',
//                         'X-CSRF-Token': document
//                           .querySelector("meta[name='csrf-token']")
//                           .getAttribute('content'),
//                       },
//                     },
//                   )
//                   .then((response) => {
//                     _this.onSubmit(response.data, resetForm, values)
//                   })
//               }}
//             >
//               {({ values, handleChange, handleSubmit }) => {
//                 return (
//                   <Form onSubmit={handleSubmit}>
//                     <InputGroup>
//                       <InputGroup.Prepend>
//                         <InputGroup.Text>{this.props.prefix}</InputGroup.Text>
//                       </InputGroup.Prepend>
//                       <Form.Control
//                         type="text"
//                         onChange={handleChange}
//                         name="check_code"
//                         value={values.check_code}
//                       ></Form.Control>
//                       <InputGroup.Append>
//                         <Button type="submit">送出</Button>
//                       </InputGroup.Append>
//                     </InputGroup>
//                   </Form>
//                 )
//               }}
//             </Formik>
//           </MyPopup>
//         )}
//       </MemberContext.Consumer>
//     )
//   }
// }

export default MobileVerifies
