import React, { useContext, useEffect, useRef, useState } from 'react'
import BuyBtn from '../components/BuyBtn'
import context from '../context'
import CountUp from 'react-countup'


function Footer({ children }) {
  const { productInfo, type, isLoading, handleBuyBtnClick } = useContext(context)
  const { fee, buyUrl, isActive } = productInfo

  const [feeCounter, setFeeCounter] = useState(0)
  const prevFeeCounterRef = useRef();
  useEffect(() => {
    prevFeeCounterRef.current = feeCounter
  })
  const prevFeeCounter = prevFeeCounterRef.current

  useEffect(() => {
    setFeeCounter(fee)
  }, [fee])

  useEffect(() => {
    if (fee == null && isActive && type == 'pet') {
      alert('您的寵物年齡不符合此商品投保條件，敬請見諒；如有疑問請於上班日營業時間洽客服人員，謝謝。')
    }
  }, [fee])

  return (
    <div className="d-none d-md-block">
      <div className="d-flex py-3">
        <div className="col left">
          {
            children
          }
        </div>

        <div className="col-auto right justify-content-end">
          {(type == "car" || type == "motor") && <h4 className="d-inline m-0 mr-3 ml-auto">網投優惠試算價</h4>}
          {(type == "accident") && <h4 className="d-inline m-0 mr-3 ml-auto">網投優惠價</h4>}
          <h1 className="d-inline text-secondary mx-2">
            {feeCounter > 0 ?
              <CountUp prefix="$" suffix=" 元" duration={1.5} start={Number(prevFeeCounter) || 0} end={Number(feeCounter)}></CountUp> :
              "$ -- 元"
            }
          </h1>
        </div>
        <div className="col-3 pr-4 align-self-center">
          <BuyBtn
            disabled={!isActive || !(fee) || isLoading}
            url={buyUrl}
            isActive={isActive}
            onClick={handleBuyBtnClick}
          />
        </div>
      </div>
    </div>
  )
}

export default Footer
