import React, { useRef, useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { Scrollbars } from 'react-custom-scrollbars';
import styled from 'styled-components';
import { useField, useFormikContext } from 'formik';
import { values } from 'lodash';

function Upload({
  resetKey,
  children,
  onChange,
  accept,
  multiple,
  name,
  ...props
}) {
  // preview url
  const [fileUrl, setFileUrl] = useState([])

  // formik filed
  const [field, { touched, value }, { setError, setValue, setTouched }] = useField("inquiry[images]")

  // handle acceptedFiles
  const onDrop = useCallback(acceptedFiles => {
    setValue(acceptedFiles.concat(value))
    if (!touched) setTouched(true)
  }, [value, touched])

  // handle delete file
  const handleDeleteFile = (index) => {
    const removeFileClone = [...value]
    removeFileClone.splice(index, 1)
    setValue(removeFileClone)
  }

  // dropzone setting
  const { fileRejections, getRootProps, getInputProps, open, isDragActive } = useDropzone({
    accept,
    multiple,
    onDrop,
    noClick: true,
    noKeyboard: true
  });

  // handle file type error
  useEffect(() => {
    if (fileRejections.length > 0) {
      if (!touched) setTouched(true)
      setError("照片格式錯誤（非 jpg jpeg png)")
    } else {
      setError()
    }
  }, [fileRejections])

  // create preview url
  useEffect(() => {
    if (value.length !== fileUrl.length) setFileUrl(value.map(v => URL.createObjectURL(v)))
  }, [value])

  return (
    <DropZone {...getRootProps({ isDragActive })}>
      <Scrollbars autoWidth autoHide autoWidthMin="100%" autoHeight>
        <ImageContainer>
          <AddBox onClick={open} />
          <input
            {...getInputProps({ name })}
          />
          {fileUrl.map((url, index) => <ImageBox key={url} src={url} onDelete={() => handleDeleteFile(index)} />)}
        </ImageContainer>
      </Scrollbars>
    </DropZone>
  );
};

// Upload.propTypes = {
//   resetKey: PropTypes.number,
//   accept: PropTypes.string,
//   multiple: PropTypes.bool,
//   onChange: PropTypes.func,
//   children: PropTypes.element.isRequired,
// };

Upload.defaultProps = {
  resetKey: 0,
  accept: undefined,
  multiple: false,
  onChange: () => { },
};

const DropZone = styled.div`
  width: 100%;
  position: relative;
  padding: 1rem;
  &::after{
    ${({ isDragActive }) => isDragActive && 'content: "請將檔案放置於此";'}
    position: absolute;
    text-align: center;
    line-height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 2px dashed;
    background-color: #aaaaaa33;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    font-weight: bold;
  }
`

const ImageContainer = styled.div`
  display: flex;
  width: fit-content;
  padding-bottom: 12px;
  flex-wrap: nowrap;
  & > * {
    margin-right: 1rem;
  }
`

const ImageBase = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 150px;
  position: relative;
  border-width: 1px;
  border-style: dashed;
  border-radius: 10px;
  transition: all .25s ease;
  overflow: hidden;
  &:hover {
    filter: brightness(.9);
  }
  img {
    max-height: 100%;
    max-width: 100%;
  }
`

const AddImageButton = styled(ImageBase)`
  cursor: pointer;
  &:hover {
    button {
      transform: rotate(180deg)
    }
  }
`

const IconButton = styled.button`
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  padding: 0;
  border-radius: 50%;
  transition: all .5s ease;
  cursor: pointer;
  &:hover {
    transform: rotate(180deg)
  }
`

const DeleteButton = styled(IconButton)`
  position: absolute;
  right: 10px;
  top: 10px;
`

function AddBox({ onClick }) {
  return (
    <AddImageButton className="border-0 bg-primary text-white" onClick={onClick}>
      <div className="text-center">
        <IconButton className="bg-white text-primary m-auto" type="button">
          <i className="fas fa-plus" />
        </IconButton>
        <p className="mt-2 mb-0">選擇檔案</p>
      </div>
    </AddImageButton>
  )
}

function ImageBox({ src, onDelete = () => { } }) {
  return (
    <ImageBase>
      <DeleteButton onClick={onDelete} className="bg-secondary text-white" type="button">
        <i className="fas fa-times" />
      </DeleteButton >
      <img src={src}></img>
    </ImageBase >
  )
}

export default Upload;