import React, { useState } from 'react'
import styled from 'styled-components'
import Popup from 'reactjs-popup'
const StyledPopup = styled(Popup)`
  &-overlay {
  }
  &-content {
    position: relative !important;
    width: 90% !important;
    margin: auto !important;
    border-radius: 5px !important;
    border: none !important;
    padding: 5px !important;
    max-width: ${({ size }) => size == 'lg' ? '900px' : '600px'} !important;
    box-shadow: 0 0.125rem 0.75rem rgba(0, 0, 0, 0.125) !important;
  }
  &-content .close-button {
    position: absolute;
    top: -9px;
    right: -9px;
    color: #62cae3;
    transition: all 0.5s ease;
    line-height: 1;
    font-size: 2.5rem !important;
    border-radius: 1rem;
    &:hover {
      transform: rotateZ(180deg);
    }
  }
`

// const MyPopup = (props) => {
//   return (
//     <StyledPopup  {...props}>
//       {close => (
//         <>
//           <a className="close-btn h1" onClick={(e) => props.onClose ? props.onClose(e) : close(e)} href="###">
//             <i className="fas fa-times-circle"></i>
//           </a>
//           <div className="p-3 m-0 p-md-4 m-md-4 position-relative">
//             {props.children(close)}
//           </div>
//         </>
//       )}
//     </StyledPopup>
//   )
// }

class MyPopup extends React.Component {
  render() {
    return (
      <StyledPopup {...this.props}>
        {(close) => (
          <>
            {this.props.noCloseBtn ? null : (
              <a
                className="close-button h1"
                onClick={(e) =>
                  this.props.close ? this.props.close(e) : close(e)
                }
                href="###"
                style={{ zIndex: '100' }}
              >
                <i className="fas fa-times-circle"></i>
              </a>
            )}
            <div className="p-3 m-0 p-md-4 m-md-4 position-relative">
              {typeof this.props.children == 'function'
                ? this.props.children(close)
                : this.props.children}
            </div>
          </>
        )}
      </StyledPopup>
    )
  }
}

export default StyledPopup
export { MyPopup }
