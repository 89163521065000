import React, { Component } from 'react'
import { Formik, Field, ErrorMessage, connect, getIn } from 'formik'
import * as Yup from 'yup'
import moment from 'moment'
import Axios from 'axios'
import createFormData from '@/components/_common/createFormData'
import citizenIdTest from '@/components/_common/citizenIdTest'
import RegistrationForm from './RegistrationForm'
import withToken from '@/components/_common/withToken'
import { SignUpError } from '../../components/ErrorMessage'

const passwrodRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{6,}$/
const nameRegExp = /^(?=.*[\u4e00-\u9fa5\u3400-\u4DBF\uF900-\uFAFF])[\u4e00-\u9fa5\u3400-\u4DBF\uF900-\uFAFF_a-zA-Z,\s-]{2,}$/

//check is citizenID already been registered
const checkCanBeRegistered = (value) => {
  return Axios.get(
    `/member/check_can_be_registered.json?citizen_id=${value || ''}`,
  ).then((res) => res.data.result)
}

const schema = Yup.object().shape({
  member: Yup.object().shape({
    citizen_id: Yup.string()
      .test(
        'checkCanBeRegistered',
        SignUpError.citizenId.checkCanBeRegistered,
        checkCanBeRegistered,
      )
      .test(
        'citizenIdFormat',
        SignUpError.citizenId.citizenIdFormat,
        citizenIdTest,
      )
      .required(SignUpError.citizenId.required),
    xname: Yup.string()
      .matches(nameRegExp, SignUpError.xname.matches)
      .required(SignUpError.xname.required),
    birthday: Yup.date()
      .typeError(SignUpError.birthday.typeError)
      .max(moment().subtract(18, 'years').toDate(), SignUpError.birthday.max)
      .required(SignUpError.birthday.required),
    password: Yup.string().matches(passwrodRegExp),
    password_confirmation: Yup.string().when('password', (password, schema) => {
      return password
        ? schema
          .oneOf([password], SignUpError.password_confirmation.password)
          .required(SignUpError.password_confirmation.required)
        : schema
    }),
    kyc_read: Yup.bool().oneOf([true], SignUpError.kyc_read),
  }),
})

class Capitalize extends Component {
  componentDidUpdate(prevProps) {
    const { values, setFieldValue } = this.props.formik
    const citizenId = getIn(values, 'member[citizen_id]')
    console.log(citizenId)
    if (citizenId && citizenId[0] != citizenId[0].toUpperCase()) {
      console.log(citizenId)
      setFieldValue('member[citizen_id]', citizenId.toUpperCase())
      // document.querySelector(
      //   '[name="member[citizen_id]"]',
      // ).value = values.member.citizen_id.toUpperCase()
    }
  }
  render() {
    return null
  }
}

const ConnectCapitalize = connect(Capitalize)
export class SignUp extends Component {
  constructor(props) {
    super(props)
    this.submitHandler = this.submitHandler.bind(this)
  }

  initialValues = {
    member: {
      birthday: moment().subtract(18, 'years').format('YYYY-MM-DD'),
      citizen_id: '',
      xname: '',
      password: '',
      password_confirmation: '',
      kyc_read: false,
    },
  }

  submitHandler(values, actions) {
    let _this = this
    showLoading()
    let formData = createFormData(values)

    Axios.post('/members.json', formData, {
      header: { 'Content-Type': 'text/html; charset=utf-8' },
    })
      .then(({ data }) => {
        hideLoading()
        if (data.status == 200) {
          console.log(_this.props)
          console.log(data)
          _this.props.history.push(data.url)
          window.scrollTo(0, 0)
        } else {
          _this.setState({ errorMsg: data.message })
        }
      })
      .catch((error) => {
        hideLoading()
        console.log(error)
        alert('Sorry! 我們出現了一些小問題，請稍後再試')
      })
  }

  render() {
    return (
      <Formik
        validationSchema={schema}
        initialValues={withToken(this.initialValues)}
        onSubmit={this.submitHandler}
      >
        {(formProps) => (
          <>
            <RegistrationForm {...formProps} />
            <ConnectCapitalize />
          </>
        )}
      </Formik>
    )
  }
}

export default SignUp
