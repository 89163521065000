import React from 'react'
import { Trigger } from './Faq'
import Loading from '@/components/_common/Loading'
import styled from 'styled-components'

const FaqLoading = () => {
  return (
    <>
      <Trigger>
        <span>
          <Loading width={40} height={28} />
        </span>
        <Loading width="60%" height={28} />
        <i style={{ zIndex: '1' }}>
          <Loading width={28} height={28} />
        </i>
      </Trigger>
      <Trigger>
        <span>
          <Loading width={40} height={28} />
        </span>
        <Loading width="60%" height={28} />
        <i style={{ zIndex: '1' }}>
          <Loading width={28} height={28} />
        </i>
      </Trigger>
      <Trigger>
        <span>
          <Loading width={40} height={28} />
        </span>
        <Loading width="60%" height={28} />
        <i style={{ zIndex: '1' }}>
          <Loading width={28} height={28} />
        </i>
      </Trigger>
      <Trigger>
        <span>
          <Loading width={40} height={28} />
        </span>
        <Loading width="60%" height={28} />
        <i style={{ zIndex: '1' }}>
          <Loading width={28} height={28} />
        </i>
      </Trigger>
    </>
  )
}

export default FaqLoading
