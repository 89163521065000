import React, { Component } from 'react'
import { MyPopup } from '@/components/_common/StyledPopup'
import err from './ErrorMessage'
import createFormData from '@/components/_common/createFormData'
import Axios from 'axios'
import { Provider } from './Context'
import { Completed, IdForm, OTPForm, ResetPasswordForm } from './pages/index'

const Status = {
  NOT_USER: 0,
  OTP: 1,
  CHANGE_PWD: 2,
  COMPLETED: 3,
}

export class ForgetPassword extends Component {
  constructor(props) {
    super(props)
    this.state = {
      status: '',
      submitFunction: {
        onIdFormSubmit: this.onIdFormSubmit.bind(this),
        onOTPFormSubmit: this.onOTPFormSubmit.bind(this),
        backPrevStatus: this.backPrevStatus.bind(this),
        sendOtp: this.sendOtp.bind(this),
        onResetPasswordFormSubmit: this.onResetPasswordFormSubmit.bind(this),
      },
    }
  }

  //驗證身分證
  onIdFormSubmit(value, action) {
    let formData = createFormData(value)
    Axios.post('/members/password.json', formData, {}).then(({ data }) => {
      console.log(data)
      if (data.code == '00') {
        this.setState({ sendOtpValue: data }, () => this.sendOtp(action))
      } else {
        action.setErrors({
          member: { citizen_id: err.IdForm.citizen_id.code(data.code) },
        })
      }
    })
  }

  //發送OTP
  sendOtp(action = null) {
    let prevData = Object.assign(
      {
        utf8: '✓',
        authenticity_token: document
          .querySelector("meta[name='csrf-token']")
          .getAttribute('content'),
      },
      this.state.sendOtpValue,
    )
    console.log(prevData)
    let verifiyFormData = createFormData(prevData)
    Axios.post('/member/mobile_verifies.json', verifiyFormData).then(
      ({ data }) => {
        console.log(data)
        if (data.code == '00') {
          this.setState({
            status: Status.OTP,
            mobile_phone: prevData.mobile_verify.phone_number,
            email: prevData.email,
            prefix: data.prefix,
            otpId: data.id,
            member_id: prevData.member_id,
          })
        } else {
          action &&
            action.setErrors({ member: err.IdForm.citizen_id.code(data.code) })
          alert(data.error)
        }
      },
    )
  }

  //驗證OTP
  onOTPFormSubmit(value, action) {
    console.log(value)
    Axios.get(
      `/member/mobile_verifies/${this.state.otpId}/edit?forget_password=true&mobile_verify[check_code]=${value.check_code}&member_id=${this.state.member_id}`,
    ).then(({ data }) => {
      console.log(data)
      if (data.error) {
        action.setErrors({ check_code: data.error })
      } else {
        this.setState({
          status: Status.CHANGE_PWD,
          reset_password_token: data.reset_password_token,
        })
      }
    })
  }

  //修改密碼
  onResetPasswordFormSubmit(value, action) {
    Axios.put('/members/password.json', createFormData(value)).then(
      ({ data }) => {
        console.log(data)
        if (data.code == '00') {
          this.setState({ status: Status.COMPLETED })
        } else {
          action.setErrors({
            member: { password_confirmation: data.message },
          })
        }
      },
    )
  }

  backPrevStatus() {
    if (this.state.status == 1) return this.setState({ status: null })
    return this.setState({ status: this.state.status - 1 })
  }

  renderState() {
    switch (this.state.status) {
      case Status.NOT_USER:
        break
      case Status.OTP:
        return <OTPForm />
        break
      case Status.CHANGE_PWD:
        return <ResetPasswordForm />
        break
      case Status.COMPLETED:
        return <Completed />
        break
      default:
        return <IdForm />
        break
    }
  }
  render() {
    return (
      <Provider value={this.state}>
        <MyPopup
          trigger={
            <a href="###" className="font-weight-bold">
              忘記密碼？
            </a>
          }
          modal
        >
          {this.renderState()}
        </MyPopup>
      </Provider>
    )
  }
}

export default ForgetPassword
