import React from 'react'
import { Form, Button, Col, Row } from 'react-bootstrap'
import styled from 'styled-components';
import ErrorMessage from '../components/ErrorMessage';

const Input = styled.input`
  border-bottom-width: 1px;
  border-width: 0;
  border-bottom-width: 1px;
  outline: none;
  border-color: #ced4da;
  width: 300px;
`

function ContactInfoForm({ formik, options }) {
  return (
    <>
      <div className="p-4 mb-3 border">

        <Form.Group as={Row} controlId="name">
          <Form.Label column xs={12} md={3}>
            姓名*
          </Form.Label>
          <Col xs={12} md={9}>
            <Form.Control
              name="inquiry[name]"
              value={formik.values.inquiry.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              maxlength="20"
              type="name"
              placeholder="張三"
            />
            <ErrorMessage formik={formik} name="name" />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="gender">
          <Form.Label column xs={12} md={3}>
            性別*
          </Form.Label>
          <Col xs={12} md={9}>
            <Form.Check
              id="gender-male"
              inline
              label="男性"
              type="radio"
              name="inquiry[gender]"
              value="male"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <Form.Check
              id="gender-female"
              inline
              label="女性"
              type="radio"
              name="inquiry[gender]"
              value="female"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <ErrorMessage formik={formik} name="gender" />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="city_id">
          <Form.Label column xs={12} md={3}>
            所在地區*
          </Form.Label>
          <Col xs={12} md={9}>
            <Form.Control as="select"
              name="inquiry[city_id]"
              value={formik.values.inquiry.city_id || ""}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              <option value="">請選擇</option>
              {options && options.cities.map(city => (
                <option key={city[1]} value={city[1]}>{city[0]}</option>
              ))}
            </Form.Control>
            <ErrorMessage formik={formik} name="city_id" />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="phone">
          <Form.Label column xs={12} md={3}>
            手機號碼*
          </Form.Label>
          <Col xs={12} md={9}>
            <Form.Control
              name="inquiry[phone]"
              value={formik.values.inquiry.phone}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              maxlength="10"
              type="phone"
              placeholder="0987654321"
            />
            <ErrorMessage formik={formik} name="phone" />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="email">
          <Form.Label column xs={12} md={3}>
            E-mail*
          </Form.Label>
          <Col xs={12} md={9}>
            <Form.Control
              name="inquiry[email]"
              value={formik.values.inquiry.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              type="email"
              placeholder="service@saracares.com.tw"
            />
            <ErrorMessage formik={formik} name="email" />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="line_id">
          <Form.Label column xs={12} md={3}>
            Line ID
          </Form.Label>
          <Col xs={12} md={9}>
            <Form.Control
              name="inquiry[line_id]"
              value={formik.values.inquiry.line_id}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              type="text"
            />
            <ErrorMessage formik={formik} name="line_id" />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="available_times">
          <Form.Label column xs={12} md={3}>
            可聯絡時段*
          </Form.Label>
          <Col xs={12} md={9}>
            <Form.Check
              id="available_times-1"
              className="my-1"
              label="09:00~12:00"
              type="checkbox"
              name="inquiry[available_times]"
              value="morning"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <Form.Check
              id="available_times-2"
              className="my-1"
              label="14:00~18:00"
              type="checkbox"
              name="inquiry[available_times]"
              value="afternoon"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <Form.Check
              id="available_times-3"
              className="my-1"
              label="18:00~22:00"
              type="checkbox"
              name="inquiry[available_times]"
              value="night"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <Form.Check
              id="available_times-4"
              className="my-1"
              label="全天都可以"
              type="checkbox"
              name="inquiry[available_times]"
              value="all_days"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <Form.Check
              id="available_times-5"
              className="my-1"
              label={<>
                其他
                {" "}
                <Input
                  type="text"
                  value={formik.values.inquiry.other_available_time}
                  name="inquiry[other_available_time]"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </>}
              type="checkbox"
              name="inquiry[available_times]"
              value="other_times"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <ErrorMessage formik={formik} name="available_times" />
            <ErrorMessage formik={formik} name="other_available_time" />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="requirement">
          <Form.Label column xs={12} md={3}>
            歡迎簡述您的需求
          </Form.Label>
          <Col xs={12} md={9}>
            <Form.Control
              as="textarea"
              rows={4}
              name="inquiry[requirement]"
              value={formik.values.inquiry.requirement}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <ErrorMessage formik={formik} name="requirement" />
          </Col>
        </Form.Group>



      </div>
    </>
  )
}

export default ContactInfoForm