import React, { Component } from 'react'
import styled from 'styled-components'
import axios from 'axios'
import InfiniteScroll from 'react-infinite-scroller'
import Loading from '@/components/_common/Loading'
import { Link } from 'react-router-dom'
import Chat from './Chat/index'

const Noti = styled.div`
  background-color: ${({ theme, read }) =>
    read ? 'white' : theme.grayColor.gray100};
  color: ${({ theme }) => theme.grayColor.gray700};
  margin: ${({ theme }) => theme.spacers[4]} ${({ theme }) => theme.spacers[3]};
  padding: ${({ theme }) => theme.spacers[3]} ${({ theme }) => theme.spacers[5]}
    ${({ theme }) => theme.spacers[3]} ${({ theme }) => theme.spacers[4]};
  transition: all 0.5s ease;
  position: relative;
    cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.grayColor.gray200};
    &:after {
      height: 15px;
      width: 15px;
      right: 18px;
      top: calc(50% - 7.5px);
    }
  }
  &:before {
    content: '';
    border: 1px solid ${({ theme }) => theme.grayColor.gray300};
    border-style: solid none none none;
    width: 100%;
    bottom: calc(-${({ theme }) => theme.spacers[4]} / 2);
    position: absolute;
    left: 0;
  }
  &:after {
    content: '';
    top: calc(50% - 5px);
    height: 10px;
    width: 10px;
    transform: rotate(45deg);
    border: 2px solid ${({ theme }) => theme.grayColor.gray600};
    border-style: solid solid none none;
    position: absolute;
    right: 20px;
    transition: all 0.5s ease;
  }
`
const Content = styled.p`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`
const Api = '/member/customer_questions.json'
export class Question extends Component {
  constructor(props) {
    super(props)

    this.state = {
      list: [],
      currentPage: 0,
      hasMore: true,
      loading: true,
      isChat: this.props.id,
      currentChat: this.props.id || 0,
    }
  }

  componentDidMount() {
    this.loadingQuestion()
  }

  loadingQuestion() {
    console.log('XXX')
    axios
      .get(Api, {
        params: { page: this.state.currentPage + 1 },
        headers: { 'Content-Type': 'application/json' },
      })
      .then((response) => {
        let { list, current_page, total_pages } = response.data
        this.setState({
          list: [...this.state.list, ...list],
          loading: false,
          currentPage: current_page,
          hasMore: current_page < total_pages,
          totalPage: total_pages,
        })
      })
  }

  renderLoading() {
    return (
      <>
        <Noti>
          <Loading height={27} width={210} className="mb-2"></Loading>
          <Loading height={27} width={250} className="mb-2"></Loading>
          <Loading height={21} width={120}></Loading>
        </Noti>
        <Noti>
          <Loading height={27} width={210} className="mb-2"></Loading>
          <Loading height={27} width={220} className="mb-2"></Loading>
          <Loading height={21} width={120}></Loading>
        </Noti>
        <Noti>
          <Loading height={27} width={210} className="mb-2"></Loading>
          <Loading height={27} width={220} className="mb-2"></Loading>
          <Loading height={21} width={120}></Loading>
        </Noti>
        <Noti>
          <Loading height={27} width={210} className="mb-2"></Loading>
          <Loading height={27} width={220} className="mb-2"></Loading>
          <Loading height={21} width={120}></Loading>
        </Noti>
      </>
    )
  }
  render() {
    return (
      <div>
        {this.state.isChat ? (
          <Chat id={this.state.currentChat} />
        ) : (
          <InfiniteScroll
            pageStart={1}
            loadMore={this.loadingQuestion.bind(this)}
            hasMore={this.state.hasMore}
            loader={this.renderLoading()}
            initialLoad={false}
          >
            {this.state.list.map((con, index) => {
              return (
                <Link key={con.id} to={`/member/customer_questions/${con.id}`}>
                  <Noti read={con.is_read}>
                    <p className="mb-2">{`問題類別：${con.type}`}</p>
                    <Content className="mb-2">{con.content}</Content>
                    <p className="small m-0">{con.created_at}</p>
                  </Noti>
                </Link>
              )
            })}
          </InfiniteScroll>
        )}
      </div>
    )
  }
}

export default Question
