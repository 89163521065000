import React, { Component } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { Link } from 'react-router-dom'

const ListItem = styled.div`
  border-bottom: 1px solid #e9ecef;
  padding: 0 10px 10px 10px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`

const Time = styled.small`
  line-height: 1.2;
  color: ${({ theme }) => theme.grayColor.gray500};
`

const Name = styled.h5`
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 3px;
`
const Company = styled.p`
  line-height: 1;
  margin-bottom: 5px;
  font-size: 80%;
`

const INITIALIZED = "initialized"
const TRANSACTED = "transacted"
const SUCCEEDED = "succeeded"
const TERMINATED = "terminated"
const CANCEL = "cancel"

const statusName = (s) => {
  switch (s) {
    case INITIALIZED:
      return "未投保成功"
    case TRANSACTED:
      return "交易成功（保單出單中）"
    case SUCCEEDED:
      return "保單出單完成"
    case TERMINATED:
      return "交易取消"
    case CANCEL:
      return "交易取消"
  }
}
export class Policy extends Component {
  constructor(props) {
    super(props)
    this.policy = props.policy
    console.log(this.policy)
  }
  renderStatus(status) {
    if (
      status &&
      (status == TRANSACTED || status == SUCCEEDED)
    ) {
      return (
        <p className="mb-0 text-primary">
          <i className="fas fa-check-circle mr-1"></i>
          {statusName(status)}
        </p>
      )
    } else {
      return (
        <p className="text-secondary mb-0">
          <i className="fas fa-times-circle"></i>
          {statusName(status)}
        </p>
      )
    }
  }
  render() {
    console.log(this.policy.insurance_company)
    return (
      <Link to={`/member/policies/${this.policy.id}?type=${this.policy.policy_type}`}>
        <ListItem>
          <img src={this.policy.img} width="55px" className="mr-3" />
          <div>
            <Time>
              {moment(this.policy.create_at).format('YYYY/MM/DD  HH:mm')}
            </Time>
            <Name>{this.props.policy.name}</Name>
            <Company className="text-primary-dark">
              {this.policy.insurance_company.name}
            </Company>
          </div>
          <div className="ml-auto">{this.renderStatus(this.policy.status)}</div>
        </ListItem>
      </Link>
    )
  }
}

export default Policy
