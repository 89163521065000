import React, { Component } from 'react'
import { MemberCard as Card, Flex, NavItem, Title } from '../common/index'
import { SvgIcon } from '@/components/_common/SvgIcon'
import styled from 'styled-components'
import { Nav, Container, Table } from 'react-bootstrap'
import axios from 'axios'
import Award from './Award'
import AwardsRecord from './AwardsRecord'
import MemberContext from '@/components/_context/MemberContext'
import { MyPopup } from '@/components/_common/StyledPopup'
import AwardLoading from './AwardLoading'
import { Thead, Tbody } from './Tables'
import Sweepstakes from './Sweepstakes'
import _ from 'lodash'
import { Scrollbars as _Scrollbars } from 'react-custom-scrollbars'

const Scrollbars = styled(_Scrollbars)`
  & > div {
    &[style] {
      -webkit-overflow-scrolling: auto !important;
    }
  }
`

const Scroll = styled.div`
  width: 100%;
  overflow: scroll;
  display: flex;
`

const exangeBonusApi = '/red_point_awards/'
const exangeSweepstakesApi = '/member/bonus/exchange_award/'

export class Bonus extends Component {
  constructor(props) {
    super(props)
    this.state = {
      point: 0,
      expired_point: '',
      expired_date: '',
      awards: [],
      loading: true,
      unsend_award_sweepstakes: [],
      currentExchange: {
        type: '',
        exchangeObject: {},
        exchange: false,
        check: false,
        msg: '',
        onExchange: () => {},
      },
      willExchangeHandler: this.willExchangeHandler.bind(this),
      exchangeSweepstakesCloseHandle: this.exchangeSweepstakesCloseHandle.bind(
        this,
      ),
    }
  }

  willExchangeHandler(type, object) {
    let exchangeHandler
    if (type == 'bouns') {
      exchangeHandler = this.exchangeBonusHandler.bind(this)
    } else {
      exchangeHandler = this.exchangeSweepstakesHandler.bind(this)
    }
    this.setState({
      currentExchange: {
        type: type,
        exchangeObject: object,
        onExchange: exchangeHandler,
        check: false,
        exchange: false,
      },
    })
  }

  componentDidMount() {
    let _this = this
    axios
      .get('/member/bonus.json', {
        headers: { 'Content-Type': 'application/json' },
      })
      .then((response) => {
        console.log(response.data)
        let data = response.data
        data.awards.map(
          (award) =>
            (award.exchange_url = `${exangeBonusApi}${award.id}/exchange`),
        )
        _this.setState({ ...data, loading: false })
      })
  }

  exchangeBonusHandler() {
    let _this = this
    let award = this.state.currentExchange.exchangeObject
    showLoading()
    axios
      .post(
        award.exchange_url,
        {},
        {
          headers: {
            'X-CSRF-Token': document
              .querySelector("meta[name='csrf-token']")
              .getAttribute('content'),
          },
        },
      )
      .then(({ data }) => {
        hideLoading()
        let point = _this.state.point
        let exchange = false
        console.log(point, data)

        if (!data.error_msg) {
          exchange = true
          point -= award.price
          console.log('haha fuck')
        }
        _this.setState({
          point,
          currentExchange: {
            ..._this.state.currentExchange,
            exchange,
            exchangeObject: award,
            check: true,
          },
        })
      })
  }

  exchangeSweepstakesHandler() {
    let _this = this
    let sweepstakes = this.state.currentExchange.exchangeObject
    axios
      .post(
        exangeSweepstakesApi,
        { sweepstake_id: sweepstakes.id },
        {
          headers: {
            'X-CSRF-Token': document
              .querySelector("meta[name='csrf-token']")
              .getAttribute('content'),
          },
        },
      )
      .then(({ data }) => {
        console.log(data)
        let exchange = false
        if (data.success) {
          exchange = true
        } else {
          exchange = false
        }
        _this.setState({
          point: data.current_red_point,
          currentExchange: {
            ...this.state.currentExchange,
            exchange,
            check: true,
            msg: data.msg,
          },
        })
      })
  }

  exchangeSweepstakesCloseHandle(close) {
    if (this.state.currentExchange.exchange == true) {
      let unsend = [...this.state.unsend_award_sweepstakes]
      _.remove(unsend, { id: this.state.currentExchange.exchangeObject.id })
      this.setState({
        unsend_award_sweepstakes: unsend,
        unsend_award_size: this.state.unsend_award_size - 1,
      })
    }
    close()
  }

  getAwardsHistory = (type) => {
    let _this = this
    axios
      .get(`/member/bonus/show_awards_histories.json?type=${type}`, {
        headers: { 'Content-Type': 'application/json' },
      })
      .then((response) => {
        let data = response.data
        console.log(response)
        if (type == 'red_points') {
          _this.setState({ awardHistory: data })
        } else {
          _this.setState({ sweepstakesHistory: data })
        }
      })
  }

  renderExpiredNoitce({ expired_date, expired_point }) {
    if (expired_point > 0) {
      return (
        <React.Fragment>
          ，<span className="h5 text-secondary">{expired_point}</span> 點數將於
          <span className="text-secondary"> {expired_date} </span> 到期。
        </React.Fragment>
      )
    }
  }

  render() {
    return (
      <MemberContext.Provider value={{ ...this.context, ...this.state }}>
        <Card>
          <Title>
            <SvgIcon width={30} href="#i-exchange" />
            <h3>莎拉領好康</h3>
          </Title>
          <Container className="p-0 pb-md-4">
            <h6 className="font-weight-normal">
              你目前擁有莎拉幣{' '}
              <span className="h5 text-secondary">{this.state.point}</span> 點
              {this.state && this.renderExpiredNoitce(this.state)}
            </h6>
            <p className="small">
              《溫馨提醒
              》當你申請兌換後，系統所發送之領取連結即開始計算兌換期限，各禮品(包含電子票券類)之有效期限以各家廠商提供之截止日為主，相關優惠權益與兌換條件請詳閱廠商公告。
            </p>

            <Flex center>
              <h4 className="text-primary mb-0">莎拉幣兌換專區 </h4>
              <AwardsRecord
                getAwardsHistory={this.getAwardsHistory.bind(this)}
                buttonTitle="使用紀錄"
                type="red_points"
                awardHistory={this.state.awardHistory}
                title="莎拉幣使用紀錄"
              ></AwardsRecord>
            </Flex>
            <h6>
              可兌換點數
              <span className="h5 text-secondary"> {this.state.point} </span>點
            </h6>
            <Scrollbars autoHeight autoHide autoHeightMax="auto">
              <Flex>
                {this.state.loading && <AwardLoading />}
                {this.state.awards.map((award) => (
                  <Award key={award.id} award={award} />
                ))}
              </Flex>
            </Scrollbars>
            <hr />
            <Flex center>
              <h4 className="text-primary mb-0">抽獎贈品領取專區</h4>
              <AwardsRecord
                getAwardsHistory={this.getAwardsHistory.bind(this)}
                buttonTitle="領取紀錄"
                type="sweepstakes"
                awardHistory={this.state.sweepstakesHistory}
                title="抽獎贈品領取紀錄"
              ></AwardsRecord>
            </Flex>
            <h6>
              有
              <span className="h5 text-secondary">
                {' '}
                {this.state.unsend_award_size}{' '}
              </span>
              筆贈品可領取
            </h6>
            <Scrollbars autoHeight autoHide autoHeightMax="auto">
              <Flex>
                {this.state.loading && <AwardLoading />}
                {this.state.unsend_award_sweepstakes.map((sweepstakes) => (
                  <Sweepstakes
                    key={sweepstakes.id}
                    sweepstakes={sweepstakes}
                  ></Sweepstakes>
                ))}
              </Flex>
            </Scrollbars>
            {/* <Table className="d-none d-md-table">
              <Thead className="text-center bg-gray-100">
                <tr>
                  <th>活動名稱</th>
                  <th>贈品名稱</th>
                  <th>日期</th>
                  <th width="150px" className="text-right"></th>
                </tr>
              </Thead>

                <Tbody className="text-center">
                  {this.state.unsend_award_sweepstakes.map(sweepstakes => <Sweepstakes key={sweepstakes.id} sweepstakes={sweepstakes}></Sweepstakes>)}
                </Tbody>
              
            </Table> */}
          </Container>
        </Card>
      </MemberContext.Provider>
    )
  }
}
Bonus.contextType = MemberContext
export default Bonus
