import React, { Component } from 'react'
import Loading from '@/components/_common/Loading'
import { Table } from 'react-bootstrap'
import { Tbody } from '../PolicyShow/PolicyTable'
import { MemberCard as Card } from '../../common/index'
import { Flex } from '../../common/index'

export const PolicyLoading = (props) => {
  return (
    <Card>
      <div className="mb-2 mb-md-4">
        <Flex center className="mx-0 mx-md-3">
          <Loading
            height={100}
            width={100}
            className="d-none d-md-block mr-3"
          />
          <div>
            <Loading height={28} width={180} className="my-3" />
            <Loading height={34} width={220} className="my-3" />
          </div>
        </Flex>
        <hr />
      </div>
      <div className="mb-4 mx-md-5">
        <Loading height={26} width={200} className="mb-3 " />
        <Table className="mb-3" bordered hover className="mb-3">
          <Tbody>
            <tr>
              <td>
                <Loading height={20} width={80} />
              </td>
              <td>
                <Loading height={20} width={120} />
              </td>
            </tr>
            <tr>
              <td>
                <Loading height={20} width={100} />
              </td>
              <td>
                <Loading height={20} width={140} />
              </td>
            </tr>
            <tr>
              <td>
                <Loading height={20} width={80} />
              </td>
              <td>
                <Loading height={20} width={120} />
              </td>
            </tr>
            <tr>
              <td>
                <Loading height={20} width={100} />
              </td>
              <td>
                <Loading height={20} width={140} />
              </td>
            </tr>
            <tr>
              <td>
                <Loading height={20} width={80} />
              </td>
              <td>
                <Loading height={20} width={120} />
              </td>
            </tr>
            <tr>
              <td>
                <Loading height={20} width={100} />
              </td>
              <td>
                <Loading height={20} width={140} />
              </td>
            </tr>
            <tr>
              <td>
                <Loading height={20} width={80} />
              </td>
              <td>
                <Loading height={20} width={120} />
              </td>
            </tr>
            <tr>
              <td>
                <Loading height={20} width={100} />
              </td>
              <td>
                <Loading height={20} width={140} />
              </td>
            </tr>
            <tr>
              <td>
                <Loading height={20} width={80} />
              </td>
              <td>
                <Loading height={20} width={120} />
              </td>
            </tr>
            <tr>
              <td>
                <Loading height={20} width={100} />
              </td>
              <td>
                <Loading height={20} width={140} />
              </td>
            </tr>
            <tr>
              <td>
                <Loading height={20} width={80} />
              </td>
              <td>
                <Loading height={20} width={120} />
              </td>
            </tr>
            <tr>
              <td>
                <Loading height={20} width={100} />
              </td>
              <td>
                <Loading height={20} width={140} />
              </td>
            </tr>
          </Tbody>
        </Table>
      </div>
    </Card>
  )
}

export default PolicyLoading
