import React, { Component } from 'react'
import { MemberCard as Card, Flex, NavItem, Title } from '../common/index'
import { SvgIcon } from '@/components/_common/SvgIcon'
import styled from 'styled-components'
import _Illustration from '../../../images/react/members/share/memberShare.svg'
import { Col, Row, Button } from 'react-bootstrap'
import EmailsShare from '../Index/ShareWithFriend/EmailsShare'
import MemberContext from '@/components/_context/MemberContext'

const Dialog = styled.div`
  border: 2px solid ${({ theme }) => theme.color.primary};
  min-height: 65px;
  position: relative;
  width: calc(100% - 13px);
  border-top-right-radius: ${({ theme }) => theme.borderRadius};
  border-bottom-left-radius: ${({ theme }) => theme.borderRadius};
  border-bottom-right-radius: ${({ theme }) => theme.borderRadius};
  padding: ${({ theme }) => theme.spacers[2]} ${({ theme }) => theme.spacers[3]};
  margin-left: 13px;
  margin-top: ${({ theme }) => theme.spacers[3]};
  margin-bottom: ${({ theme }) => theme.spacers[3]};
  text-align: center;

  &:before {
    border-color: transparent ${({ theme }) => theme.color.primary} transparent
      transparent;
    border-style: solid;
    border-width: 0px 13px 12px 13px;
    content: '';
    height: 0px;
    position: absolute;
    width: 0px;
    left: -26px;
    top: -2px;
  }

  &:after {
    border-color: transparent #fff transparent transparent;
    border-style: solid solid solid solid;
    border-width: 0px 9.9px 9.1px 9.9px;

    content: '';
    height: 0px;

    position: absolute;
    width: 0px;
    left: -17.8px;
    top: 0;
  }

  .sub {
    font-size: 14px;
  }

  @media (max-width: 576px) {
    padding: ${({ theme }) => theme.spacers[2]}
      ${({ theme }) => theme.spacers[1]};
  }
`

const Illustration = styled(_Illustration)`
  width: 195px;
  @media (max-width: 576px) {
    width: 179px;
  }
`

const CenterLine = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  height: 40px;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    border: 1px solid ${({ theme }) => theme.grayColor.gray500};
    border-style: none solid none none;
    height: 40px;
  }
  &:after {
    content: ${(props) => `"` + props.value + `"`};
    position: absolute;
    font-size: 16px;
    color: ${({ theme }) => theme.grayColor.gray500};
    background-color: white;
    line-height: 14px;
    top: 11px;
    bottom: 11px;
  }
  @media (max-width: 991px) {
    height: 30px;
    padding-left: ${({ theme }) => theme.spacers[2]};
    padding-right: ${({ theme }) => theme.spacers[2]};

    &:before {
      border-style: solid none none none;
      height: 0;
      top: 14px;
      bottom: 15px;
      width: calc(100% - ${({ theme }) => theme.spacers[2]} * 2);
    }
    &:after {
      top: 4px;
      bottom: 4px;
      line-height: 16px;
      padding-left: 5px;
      padding-right: 5px;
    }
  }
`

const withShareMethod = (WrappedComponent) => {
  return class extends Component {
    constructor(props) {
      super(props)
      this.state = {
        appId: 791238524710589,
        message: encodeURIComponent(
          '我找到一個提供各家保險方案試算、比較、快速投保的網路平台， 還會不定期分享簡單好懂保險知識，推薦給你！',
        ),
        lineMobie: 'line://msg/text/',
        line: 'https://lineit.line.me/share/ui?',
        messageMobie: 'fb-messenger://share/?',
        link: 'https://www.saracares.com.tw',
        shareLink: this.shareLink.bind(this),
        onFbShare: (v) => this.onFbShare(v),
        onLineShare: this.onLineShare.bind(this),
      }
    }

    componentDidMount() {
      //window.loadFB()
    }

    shareLink(recommendCode) {
      return `${this.state.link}/?recommend_code=${encodeURIComponent(
        recommendCode,
      )}`
    }

    onFbShare(recommendCode) {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent,
        )
      ) {
        window.open(
          `${this.state.messageMobie}link=${encodeURIComponent(
            this.state.shareLink(recommendCode),
          )}&app_id=${this.state.appId}`,
          '_blank',
        )
      } else {
        FB.ui(
          {
            method: 'send',
            link: this.state.shareLink(recommendCode),
          },
          (response) => {
            console.log(response)
          },
        )
      }
    }

    onLineShare(recommendCode) {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent,
        )
      ) {
        window.open(
          `${this.state.lineMobie}${
            this.state.message
          }%0D%0A${this.state.shareLink(recommendCode)}`,
        )
      } else {
        window.open(
          `${this.state.line}url=${this.state.shareLink(recommendCode)}&text=${
            this.state.message
          }`,
        )
      }
    }

    render() {
      return <WrappedComponent {...this.props} {...this.state} />
    }
  }
}

class Share extends Component {
  constructor(props) {
    super(props)
    console.log(this.onLineShare)
  }

  renderShareLine(block = false, recommendCode) {
    return (
      <Button
        variant="line"
        block={block}
        onClick={() => this.props.onLineShare(recommendCode)}
        className="m-0 mx-lg-2"
        style={{ fontSize: '14px' }}
      >
        LINE好友分享
      </Button>
    )
  }

  renderShareFb(block = false, recommendCode) {
    return (
      <Button
        variant="messenger"
        onClick={() => this.props.onFbShare(recommendCode)}
        block={block}
        className="m-0 mx-lg-2"
        style={{ fontSize: '14px' }}
      >
        FACEBOOK好友分享
      </Button>
    )
  }

  render() {
    return (
      <Card>
        <div className="p-0 px-md-3 py-md-2">
          <Title>
            <SvgIcon width={30} href="#i-member-share" />
            <h3>推薦好友</h3>
          </Title>
          <p>
            推薦好友在SARAcares完成投保，不僅幫助好友輕鬆解決投保問題，你和好友都能一起獲得好康!
          </p>
          <div className="px-lg-5 px-0">
            <Flex className="px-md-5 px-0 my-2" center>
              <Illustration></Illustration>
              <div className="col p-0">
                <Dialog>
                  <p className="m-0 text-primary font-weight-bold">
                    推薦好友投保
                  </p>
                  <p className="m-0 sub">
                    每推薦一名好友完成投保，即可獲得
                    <span className="text-primary">莎拉幣30點</span>。
                  </p>
                </Dialog>
                <Dialog>
                  <p className="m-0 text-primary font-weight-bold">
                    被推薦也有好康
                  </p>
                  <p className="m-0 sub">
                    被推薦好友完成首次投保，可獲得2倍莎拉幣–
                    <span className="text-primary">共計60點。</span>
                  </p>
                </Dialog>
              </div>
            </Flex>
          </div>
          <hr />
          <h4 className="m-0 text-primary">立即分享 樂攢好康</h4>
          <p>5分鐘就能完成的網路投保，這麼簡單的事情怎麼能只有自己知道！</p>
          <MemberContext.Consumer>
            {({ data }) => (
              <h5 className="mb-2 font-weight-normal">
                <i className="far fa-thumbs-up mr-2"></i>
                你已成功推薦
                <span className="text-secondary h3">
                  {' '}
                  {data && data.member.recommendedCountThisYear}{' '}
                </span>
                位好友
              </h5>
            )}
          </MemberContext.Consumer>
          <Row>
            <Col xs={12} lg={5}>
              <EmailsShare />
              {/* <p className="text-gray-500 m-0 small"><i className="fas fa-exclamation-circle"></i> 多組 Email 請使用空白鍵分開</p> */}
            </Col>
            <Col xs={12} lg={1} className="p-0">
              <CenterLine value="or" />
            </Col>
            <Col xs={12} lg={6}>
              <MemberContext.Consumer>
                {({ currentMember }) => (
                  <>
                    <Row className="d-none d-lg-block">
                      {this.renderShareFb(false, currentMember.recommendCode)}
                      {this.renderShareLine(false, currentMember.recommendCode)}
                    </Row>
                    <Row className="d-lg-none d-flex mb-3">
                      <Col xs={6}>
                        {this.renderShareFb(true, currentMember.recommendCode)}
                      </Col>
                      <Col xs={6}>
                        {this.renderShareLine(
                          true,
                          currentMember.recommendCode,
                        )}
                      </Col>
                    </Row>
                  </>
                )}
              </MemberContext.Consumer>
            </Col>
          </Row>
        </div>
      </Card>
    )
  }
}
export { withShareMethod }
export default withShareMethod(Share)
