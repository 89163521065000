const withToken = (values) => {
  return {
    utf8: '✓',
    authenticity_token: document
      .querySelector("meta[name='csrf-token']")
      .getAttribute('content'),
    ...values,
  }
}

export default withToken
