import React, { useContext } from 'react'
import context from '../context'

function Accident() {
  return (
    <p className="small mb-0">
      <span>一年期</span>
    </p>
  )
}

export default Accident
