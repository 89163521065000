import React from 'react'
import styled from 'styled-components'

const Select = styled.select`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 3px solid white;
  overflow: hidden;
  text-align-last: center;
  height: 60px;
  width: 100%;
  border: none;
  background-color: #DFF5FA;
  transition: all 0.2s ease-in-out;
  font-size: 1.125rem;
  color: #495057 !important;
  border-radius: 5px;
  border:none;
  outline: none;
  &:hover{
    background-color: #62cae3;
    color: white !important;
  }
`


function OptionsSelect({ options, value, blank, onChange, className }) {
  return (
    <Select value={value} onChange={onChange} className={className}>
      {blank && <option disabled value="" style={{ display: "none" }}>{blank}</option>}
      {options.map(option => {
        return (
          <option key={option[1]} value={option[1]}>{option[0]}</option>
        )
      })}
    </Select>
  )
}

export default OptionsSelect
