import React, { Component } from 'react'
import { MemberCard as Card, Flex, NavItem, Title } from '../common/index'
import Policy from '../common/Policy'
import PolicyLoading from '../common/PolicyLoading'
import { SvgIcon } from '@/components/_common/SvgIcon'
import styled from 'styled-components'
import { Nav, Container } from 'react-bootstrap'
import axios from 'axios'
import InfiniteScroll from 'react-infinite-scroller'
import Ask from './Ask'
import { MyPopup } from '@/components/_common/StyledPopup'

const NullPolicy = styled.div`
  border: 2px solid ${({ theme }) => theme.grayColor.gray300};

  border-radius: ${({ theme }) => theme.borderRadius};
  margin: ${({ theme }) => theme.spacers[3]};
  display: flex;
  height: 300px;
  h3 {
    color: ${({ theme }) => theme.grayColor.gray400};
    align-self: center;
    flex: auto;
    text-align: center;
  }
`

export class Policies extends Component {
  constructor(props) {
    super(props)
    this.loadingPolicies = this.loadingPolicies.bind(this)
    this.tabSelectHandler = this.tabSelectHandler.bind(this)
    this.askQuestion = this.askQuestion.bind(this)
    this.closeAskQuestion = this.closeAskQuestion.bind(this)
    this.state = {
      activeTab: 'all',
      policies: [],
      currentPage: 0,
      hasMore: true,
      loading: true,
      askQuestion: {
        type: 0,
        policy: null,
      },
    }
    console.log(this)
  }

  loadingPolicies() {
    console.log('connect Api')
    axios
      .get('/member/policies.json', {
        params: {
          page: this.state.currentPage + 1,
          type: this.state.activeTab,
        },
        headers: { 'Content-Type': 'application/json' },
      })
      .then((response) => {
        console.log(this.state)
        let data = response.data
        let _hasMore = data.total_pages > data.current_page
        this.setState({
          totalPages: data.total_pages,
          currentPage: data.current_page,
          policies: [...this.state.policies, ...data.list],
          hasMore: _hasMore,
          loading: false,
        })
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  tabSelectHandler(selectedKey) {
    this.setState({
      activeTab: selectedKey,
      policies: [],
      currentPage: 0,
      hasMore: true,
      loading: true,
      totalPages: null,
    })
  }

  askQuestion(type, policy, title) {
    this.setState({
      askQuestion: {
        policy,
        type,
        title,
      },
    })
  }

  closeAskQuestion() {
    this.setState({
      askQuestion: {
        policy: null,
        type: 0,
        title: '',
      },
    })
  }

  componentDidMount() {
    //this.loadingPolicies();
  }

  renderPolicy() {
    let list = this.state.policies

    if (list.length) {
      return list.map((p) => (
        <>
          {p && <Policy key={p.id} policy={p} onAskQuestion={this.askQuestion} />}
        </>
      ))
    } else if (!this.state.loading) {
      return (
        <NullPolicy key="none">
          <h3>您尚未擁有任何保單</h3>
        </NullPolicy>
      )
    } else {
      return <></>
    }
  }

  renderLoading() {
    return (
      <div key={0}>
        <PolicyLoading />
        <PolicyLoading />
      </div>
    )
  }

  renderAsk() {
    return (
      <MyPopup
        open={this.state.askQuestion.policy != null}
        close={this.closeAskQuestion}
      >
        <Ask {...this.state.askQuestion} onClose={this.closeAskQuestion} />
      </MyPopup>
    )
  }

  render() {
    return (
      <Card>
        <Title>
          <SvgIcon width={30} href="#i-my-policy" />
          <h3>我的保單</h3>
        </Title>
        <p className="m-0">
          成交紀錄顯示兩年內的歷史資料，若要查閱更早以前的資料，請與客服聯繫。
        </p>
        <Container>
          <Nav
            justify
            variant="tabs"
            defaultActiveKey="all"
            onSelect={this.tabSelectHandler}
            style={{ marginBottom: 0 }}
          >
            <NavItem>
              <Nav.Link eventKey="all">全部</Nav.Link>
            </NavItem>
            <NavItem>
              <Nav.Link eventKey="succeeded_and_unexpired">投保成功</Nav.Link>
            </NavItem>
            <NavItem>
              <Nav.Link eventKey="expired">期間已過</Nav.Link>
            </NavItem>
          </Nav>
        </Container>
        <InfiniteScroll
          pageStart={1}
          loadMore={this.loadingPolicies}
          hasMore={this.state.hasMore}
          loader={this.renderLoading()}
        >
          {this.renderPolicy()}
        </InfiniteScroll>
        {this.renderAsk()}
      </Card>
    )
  }
}

export default Policies
