import React, { Component } from 'react'
import {
  Container,
  InputGroup,
  Button,
  FormControl,
  Row,
  Col,
} from 'react-bootstrap'
import styled from 'styled-components'
import { Context } from './index'
import Flex from '@/components/_common/Flex'

const Section = styled.section`
  background-color: ${({ theme }) => theme.color.primaryLight};
  background-image: url(${require('@/images/footer_page/faq/bg.svg')});
  height: 318px;
  background-repeat: no-repeat;
  background-position: left 85% bottom -40%;
  @media (max-width: 768px) {
    background-position: left 95% bottom 0%;
    background-size: 150px;
    height: 200px;
  }
`

const keywords = ['忘記密碼', '理賠', '付款', '保單變更']

export class SearchBar extends Component {
  render() {
    return (
      <Section>
        <Container className="h-100">
          <Flex className="h-100" justify="center" column>
            <h1 className="text-center">您好，需要什麼協助呢</h1>
            <Row>
              <Context.Consumer>
                {({ handleSearching, handleSearchBarChange, keyword }) => (
                  <Col xs={12} md={6} className="mx-auto">
                    <InputGroup>
                      <FormControl
                        size="lg"
                        type="text"
                        onChange={handleSearchBarChange}
                        value={keyword}
                        onKeyDown={(e) =>
                          e.key === 'Enter' ? handleSearching(keyword) : null
                        }
                      />
                      <InputGroup.Append>
                        <Button
                          variant="primary"
                          onClick={(e) => handleSearching(keyword)}
                        >
                          <i className="fas fa-search mx-2 mx-md-3"></i>
                        </Button>
                      </InputGroup.Append>
                    </InputGroup>
                    <p className="mt-1">
                      大家都在搜：
                      {keywords.map((_keyword, i) => (
                        <a
                          href="###"
                          className="ml-1"
                          key={i}
                          onClick={(e) => handleSearching(_keyword)}
                        >
                          {_keyword}
                        </a>
                      ))}
                    </p>
                  </Col>
                )}
              </Context.Consumer>
            </Row>
          </Flex>
        </Container>
      </Section>
    )
  }
}

export default SearchBar
