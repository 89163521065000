const createFormData = (values, formData = new FormData(), prefix = '') => {
  if (!values) return null;
  console.log('====', prefix, values);
  Object.keys(values).forEach((key) => {
    console.log('----', prefix ? `${prefix}[${key}]` : key, values[key]);
    if (values[key] && values[key].constructor == Object) createFormData(values[key], formData, prefix ? `${prefix}[${key}]` : key);
    else if (values[key] !== null) formData.append(prefix ? `${prefix}[${key}]` : key, values[key]);
  });
  console.log(formData);
  return formData;
};

export default createFormData;
